import { CoreFirestore } from "../../../coreFirebase";
import { Encryption } from "../../database/encryption";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  validateValueInEnum,
  validateStringNotEmpty,
} from "../../utils";
import { ActionType, ItemActions } from "./base";
import { PathsOfDateField } from "../common";
import {
  EncryptedType,
  EncryptionField,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { InvalidInput } from "../error";

export interface TastingNote extends ItemActions {
  actionType: ActionType.AddTastingNote;
  consumptionNotes: string;
  likeRating: LikeRating;
  rating: number;
  dateConsumed: Date;
}
export namespace TastingNote {
  export const datePaths: readonly PathsOfDateField<TastingNote>[] = [
    "createAt",
    "updateAt",
    "dateConsumed",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<TastingNote> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    TastingNote,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys = ItemActions.EncryptedKeys;

  export type Encrypted = EncryptedType<TastingNote, EncryptedKeys>;
  export type EncryptedPart = Pick<TastingNote, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
  ];
  export type Update = CreateFields;
  export type UpdateEncrypted = EncryptedType<Update, EncryptedKeys>;

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): TastingNote {
    return ItemActions.fromCreate(
      createFields,
      ownerId,
      ActionType.AddTastingNote
    );
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "consumptionNotes",
    "likeRating",
    "rating",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "notes",
  ];
  export function intoUpdate(
    current: TastingNote,
    update: Update
  ): UpdateObject<Update> {
    const result: UpdateObject<Update> = {
      ...ItemActions.intoUpdate(current, update),
      ...buildObjectUpdate(
        current,
        update,
        NonOptionalSimpleTypeUpdatableKeys,
        OptionalSimpleTypeUpdatableKeys
      ),
    };

    if (current.dateConsumed.getTime() !== update.dateConsumed.getTime())
      result.dateConsumed = update.dateConsumed;

    return result;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    if (data.file) throw new InvalidInput("Tasting note doesn't have file");
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    //#TODO need checks
    if (isCreate && !data.dateConsumed)
      throw new InvalidInput("Date consumed is required");
    if (
      (isCreate || data.consumptionNotes) &&
      !validateStringNotEmpty(data.consumptionNotes)
    ) {
      throw new InvalidInput("Consumption notes cannot be empty");
    }
    if (
      (isCreate || data.likeRating) &&
      !validateValueInEnum(data.likeRating!, LikeRating)
    ) {
      throw new InvalidInput("Like rating is invalid");
    }
    if ((isCreate || data.rating) && data.rating! < 0) {
      throw new InvalidInput("Rating cannot be negative");
    }
  }

  export function removeEncryptedFields<
    T extends TastingNote | UpdateObject<TastingNote>
  >(data: T): OmitKeys<T, EncryptedKeys> {
    return doRemoveEncryptedFields(data, encryptedKeysArray);
  }

  export async function encrypt(
    rawData: TastingNote,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<TastingNote, EncryptedKeys>;
}

export enum LikeRating {
  Like = "I like it",
  Neutral = "Neutral",
  DontLike = "I don't like it",
}

export const likeRatingValues = Object.values(LikeRating);
