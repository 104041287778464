import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useToast } from '@/hooks/useToast'

interface UseRedirect {
  shouldRedirect: boolean
  description?: string
  redirectTo?: string
}

export function useRedirect({ shouldRedirect, description, redirectTo = '/' }: UseRedirect) {
  const router = useRouter()
  const { toast } = useToast()

  useEffect(() => {
    if (shouldRedirect) {
      description && toast({ variant: 'error', description })
      router.replace(redirectTo)
    }
  }, [description, redirectTo, router, shouldRedirect, toast])
}
