import { useEffect, useRef, useState } from 'react'
import router from 'next/router'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { delegateQuery, fetchDelegatesOf } from '@/api/DelegateService'
import { useAuthStore } from '@/store/authStore'
import { Button, Modal } from '@/components/base'

export default function DelegateChecker() {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const logout = useAuthStore((state) => state.logout)
  const { delegatorId } = useAuthStore((state) => state.permissions)
  const { data: delegatesList, isLoading } = useSWR([delegateQuery.of], fetchDelegatesOf(database!))

  const delegateNameRef = useRef('')
  const [showModal, setShowModal] = useState(false)
  const isDelegationActive = Boolean(delegatorId)
  const isValidDelegation = isDelegationActive && delegatesList?.some((delegate) => delegate.id === delegatorId)
  const isInvalidDelegation = isDelegationActive && !isValidDelegation

  useEffect(() => {
    if (!isLoading && isInvalidDelegation) {
      setShowModal(true)
    }

    return () => {
      // set previous delegate name
      const delegateName = delegatesList?.find((delegate) => delegate.id === delegatorId)?.delegatorName
      if (delegateName) {
        delegateNameRef.current = delegateName
      }
    }
  }, [isInvalidDelegation, delegatesList, delegatorId, isLoading])

  const closeModal = () => {
    setShowModal(false)
    logout(false)
    router.replace('/auth/login/')
  }

  return showModal ? (
    <Modal className={'max-w-md'}>
      <Modal.Content>
        <div className={'mb-4 flex min-h-[50px] flex-col justify-center gap-4'}>
          <p className={'text-sm text-text/70'}>
            {t('delegates:LostDelegateAccessDesc', { delegateName: delegateNameRef.current })}
          </p>
        </div>
        <fieldset className={'flex items-center justify-center gap-x-2'}>
          <Button className={'min-w-[130px]'} variant={'solid'} size={'md'} onClick={closeModal}>
            {t('auth:BackToLogin')}
          </Button>
        </fieldset>
      </Modal.Content>
    </Modal>
  ) : null
}
