import { Encryption } from "../../database/encryption";
import {
  IsEnum,
  IsOptional,
  IsOptionalOnUpdate,
  IsString,
  NotEncrypted,
  SimpleUpdate,
  validateWithGroups,
} from "../../decorators";
import {
  EncryptedType,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { OmitKeys } from "../../utils";
import { LocationType, Optional } from "../common";

export class LocationInfo {
  @NotEncrypted
  @IsString()
  locationId!: string;

  @NotEncrypted
  @SimpleUpdate
  @IsEnum(LocationType, { message: "LocationType is not valid" })
  @IsOptionalOnUpdate()
  locationType!: LocationType;

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  roomId?: string; // room/bin for wine

  @NotEncrypted
  @SimpleUpdate
  @IsOptional()
  @IsString()
  position?: string; // position/shelf for wine

  @SimpleUpdate
  @IsOptional()
  @IsString()
  notes?: string;
}

export namespace LocationInfo {
  export const encryptedKey: keyof EncryptedPart = "notes";
  export type EncryptedKey = "notes";
  export type Encrypted = EncryptedType<LocationInfo, EncryptedKey>;
  export type EncryptedPart = Pick<LocationInfo, EncryptedKey>;

  export type Validate = OmitKeys<LocationInfo, EncryptedKey>;
  export function validateEncryptedPart(_data: EncryptedPart) {}
  export function validateEncryptedObj<T extends Validate>(data: T) {
    // if (!data.locationId) {
    //   throw new InvalidInput("LocationId is required");
    // }
    // if (!validateValueInEnum(data.locationType, LocationType)) {
    //   throw new InvalidInput("LocationType is not valid");
    // }

    validateWithGroups<LocationInfo>(data, LocationInfo);
  }

  export async function encrypt(
    rawData: LocationInfo,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, [encryptedKey], encryption);
  }
  export const decrypt = fullObjectDecryption<LocationInfo, EncryptedKey>;

  export function primaryDetailEqual(a: LocationInfo, b: LocationInfo) {
    return a.locationId === b.locationId && a.locationType === b.locationType;
  }
  export function equal(a: LocationInfo, b: LocationInfo) {
    return (
      a.locationId === b.locationId &&
      a.locationType === b.locationType &&
      a.roomId === b.roomId &&
      a.position === b.position &&
      a.notes === b.notes
    );
  }
  export function optionalEqual(
    a: Optional<LocationInfo>,
    b: Optional<LocationInfo>
  ) {
    if (a && b) {
      return equal(a, b);
    } else {
      return a === b;
    }
  }

  export function inSameRoom(a: Encrypted, b: Encrypted) {
    return (
      a.locationId === b.locationId &&
      a.locationType === b.locationType &&
      a.roomId === b.roomId
    );
  }
}
