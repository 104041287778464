import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20d%3D%22M12.441%200a1.5%201.5%200%200%201%201.101.458%201.5%201.5%200%200%201%20.458%201.1v3.637l-1.559-1.558V1.559H1.545V12.44H12.44v-2.064L14%208.818v3.623a1.5%201.5%200%200%201-.458%201.101%201.5%201.5%200%200%201-1.1.458H1.544c-.428%200-.793-.153-1.094-.458a1.512%201.512%200%200%201-.451-1.1V1.559C0%201.13.15.763.451.458A1.481%201.481%200%200%201%201.545%200H12.44ZM8.614%209.79l2.01-2.01h-7.52V6.22h7.52l-2.01-2.01%201.108-1.093L13.604%207%209.72%2010.896%208.613%209.79Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function SignOutIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 14 14'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M12.441 0a1.5 1.5 0 0 1 1.101.458 1.5 1.5 0 0 1 .458 1.1v3.637l-1.559-1.558V1.559H1.545V12.44H12.44v-2.064L14 8.818v3.623a1.5 1.5 0 0 1-.458 1.101 1.5 1.5 0 0 1-1.1.458H1.544c-.428 0-.793-.153-1.094-.458a1.512 1.512 0 0 1-.451-1.1V1.559C0 1.13.15.763.451.458A1.481 1.481 0 0 1 1.545 0H12.44ZM8.614 9.79l2.01-2.01h-7.52V6.22h7.52l-2.01-2.01 1.108-1.093L13.604 7 9.72 10.896 8.613 9.79Z'
        }
      />
    </svg>
  )
}
