import "reflect-metadata";
import {
  getMetadataStorage,
  registerDecorator,
  ValidationOptions,
  ValidatorConstraintInterface,
} from "class-validator";

export function FieldDecorator(
  decoratorName: string,
  options?: ValidationOptions,
  metadata?: unknown,
  validator?: ValidatorConstraintInterface | Function
) {
  return function (target: object, propertyKey: string): void {
    registerDecorator({
      name: decoratorName,
      target: target.constructor,
      propertyName: propertyKey as string,
      constraints: [metadata],
      options: options,
      validator: validator ? validator : () => true,
    });
  };
}

export function getAllFieldDecorators<T extends object>(cls: new () => T) {
  return getMetadataStorage().getTargetValidationMetadatas(
    cls,
    "",
    false,
    false
  );
}

export function getFieldsByDecorator<T extends object>(
  decoratorName: string,
  cls: new () => T
) {
  return Object.keys(
    getFieldMetadataByDecorator(decoratorName, cls)
  ) as string[];
}

export function getFieldMetadataByDecorator<T extends object>(
  decoratorName: string,
  cls: new () => T
) {
  if (!decoratorName)
    throw new Error("No decorator name was provided to get metadata for");
  if (!cls)
    throw new Error(
      "No class was provided to get metadata from, this may be a circular dependency and class has not been imported yet"
    );

  const fields: { [k in keyof T]?: unknown } = {};
  const storage = getMetadataStorage();

  // Retrieve metadata of all fields and their decorators
  const decorators = getAllFieldDecorators(cls);

  for (const decorator of decorators) {
    if (decorator.name === decoratorName) {
      fields[decorator.propertyName as keyof T] = decorator.constraints
        ? decorator.constraints[0]
        : undefined;
    }
  }

  return fields;
}

export function getAllFieldNames<T extends object>(cls: new () => T) {
  if (!cls)
    throw new Error(
      "No class was provided to get metadata from, this may be a circular dependency and class has not been imported yet"
    );

  // Retrieve metadata of all fields and their decorators
  const decorators = getAllFieldDecorators(cls);
  return decorators.map((d) => d.propertyName);
}
