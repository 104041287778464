export type AsyncTask =
  | { f: AsyncOnce; retry: false }
  | { f: AsyncRetry; retry: true };
export namespace AsyncTask {
  export function once(f: AsyncOnce): AsyncTask {
    return { f, retry: false };
  }
  export function retry(f: AsyncRetry): AsyncTask {
    return { f, retry: true };
  }
}
export type AsyncOnce = (...args: any) => Promise<void>;
export type AsyncRetry = (...args: any) => Promise<boolean>;
//#NOTE new -> setCallback -> await  run
export interface IAsyncTaskExecutor {
  totalTasks: number;
  finished: number;

  setCallback(callback: (...args: any) => any): void;
  run(): Promise<void>;
}

export class AsyncTaskExecutor implements IAsyncTaskExecutor {
  private tasks: AsyncTask[];
  totalTasks: number;
  finished: number = 0;
  callback?: (totalTasks: number, finished: number, ...args: any) => any;

  constructor(tasks: AsyncTask[] = []) {
    this.tasks = tasks;
    this.totalTasks = tasks.length;
  }

  setCallback(callback: (...args: any) => any) {
    this.callback = callback;
  }

  private async wrapTask({ f: task, retry }: AsyncTask) {
    if (retry) {
      let shouldRetry = await task();
      while (shouldRetry) {
        shouldRetry = await task();
      }
    } else {
      await task();
    }
    this.finished++;
    if (this.callback) {
      this.callback(this.totalTasks, this.finished);
    }
  }

  async run() {
    const tasks = this.tasks.map((t) => () => this.wrapTask(t));
    for (const task of tasks) {
      await task();
    }
  }
}
