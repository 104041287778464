import * as React from 'react'
import { useController, type Control, type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form'

import { cn } from '@/utils/classnames'

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'flex min-h-[80px] w-full rounded border bg-transparent px-3 py-2 text-sm',
        'focus-visible:border-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Textarea.displayName = 'Textarea'

interface FormTextareaProps<T extends FieldValues, U extends FieldPath<T>> extends TextareaProps {
  label?: string
  name: U
  control: Control<T>
  rules?: RegisterOptions<T>
}

export function FormTextarea<T extends FieldValues, U extends FieldPath<T>>({
  label,
  className,
  control,
  name,
  rules: rulesFromProps,
  ...props
}: FormTextareaProps<T, U>) {
  const id = React.useId()
  const rules: RegisterOptions<T, U> = {
    ...rulesFromProps,
    validate: {
      ...rulesFromProps?.validate,
      noHTMLTags: (v) => !/<[^>]*>/.test(v)
    }
  }
  const { field } = useController({ control, name, rules })

  return (
    <div className={cn('grid w-full gap-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn(
            'text-xs text-[#414554]',
            rules?.required !== undefined && 'after:ml-0.5 after:text-error after:content-["*"]'
          )}
        >
          {label}
        </label>
      )}
      <Textarea id={id} className={'min-h-[80px] resize-none bg-grey-input'} {...field} {...props} />
    </div>
  )
}
