import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23ddd%22%3E%3Cpath%20d%3D%22M9%2016h6v-6h4l-7-7-7%207h4v6Zm3-10.17L14.17%208H13v6h-2V8H9.83L12%205.83ZM5%2018h14v2H5v-2Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function UploadIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      viewBox={'0 0 24 24'}
      fill={'currentColor'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={'M9 16H15V10H19L12 3L5 10H9V16ZM12 5.83L14.17 8H13V14H11V8H9.83L12 5.83ZM5 18H19V20H5V18Z'}
        fill={'#2D2D2D'}
      />
    </svg>
  )
}
