import { CoreFirestore } from "../../../coreFirebase";
import { Encryption } from "../../database/encryption";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  validateStringNotEmpty,
} from "../../utils";
import { ActionType, ItemActions } from "./base";
import { PathsOfDateField } from "../common";
import {
  EncryptedType,
  EncryptionField,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { InvalidInput } from "../error";

export interface Literature extends ItemActions {
  actionType: ActionType.AddLiterature;
  title: string;
  date: Date;
  website?: string;
}
export namespace Literature {
  export const datePaths: readonly PathsOfDateField<Literature>[] = [
    "createAt",
    "updateAt",
    "date",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Literature> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Literature,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys = ItemActions.EncryptedKeys;

  export type Encrypted = EncryptedType<Literature, EncryptedKeys>;
  export type EncryptedPart = Pick<Literature, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
  ];
  export type Update = CreateFields;
  export type UpdateEncrypted = EncryptedType<Update, EncryptedKeys>;

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): Literature {
    return ItemActions.fromCreate(
      createFields,
      ownerId,
      ActionType.AddLiterature
    );
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "title",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "notes",
    "website",
  ];
  export function intoUpdate(
    current: Literature,
    update: Update
  ): UpdateObject<Update> {
    const result: UpdateObject<Update> = {
      ...ItemActions.intoUpdate(current, update),
      ...buildObjectUpdate(
        current,
        update,
        NonOptionalSimpleTypeUpdatableKeys,
        OptionalSimpleTypeUpdatableKeys
      ),
    };

    if (current.date.getTime() !== update.date.getTime())
      result.date = update.date;

    return result;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    ItemActions.validateEncryptedPart(data);
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    //#TODO need checks
    if (isCreate && !data.date) throw new InvalidInput("Date is required");
    if ((isCreate || data.title) && !validateStringNotEmpty(data.title)) {
      throw new InvalidInput("Title cannot be empty");
    }
  }

  export function removeEncryptedFields<
    T extends Literature | UpdateObject<Literature>
  >(data: T): OmitKeys<T, EncryptedKeys> {
    return doRemoveEncryptedFields(data, encryptedKeysArray);
  }

  export async function encrypt(
    rawData: Literature,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<Literature, EncryptedKeys>;
}
