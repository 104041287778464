import { Valuation } from "./actions/valuation";
import { AssociatedType6, TypeKVPair } from "../utils";
import { SoldInfo } from "./actions/soldInfo";
import { Offer } from "./actions/offer";
import { Consignment } from "./actions/consignment";
import { Exhibition } from "./actions/exhibition";
import { Literature } from "./actions/literature";
import { TastingNote } from "./actions/tastingNote";
import { Encryption } from "../database/encryption";
import { ErrorDataOutDated } from "./error";
import {
  ActionTypeVersion,
  VersionedType,
  validateTypeUpToDate,
} from "./typeVersion";
import { ActionType, ItemActions } from "./actions/base";
import { RentInfo } from "./actions/rentInfo";

export type Action =
  | Valuation
  | SoldInfo
  | Offer
  | Consignment
  | Exhibition
  | Literature
  | TastingNote
  | RentInfo;

export namespace Action {
  export type Encrypted =
    | Valuation.Encrypted
    | Offer.Encrypted
    | SoldInfo.Encrypted
    | Consignment.Encrypted
    | Exhibition.Encrypted
    | Literature.Encrypted
    | TastingNote.Encrypted
    | RentInfo.Encrypted;
  export type Update =
    | Offer.Update
    | SoldInfo.Update
    | Consignment.Update
    | Exhibition.Update
    | Literature.Update
    | TastingNote.Update
    | RentInfo.Update;

  export function assureVersion<T extends ItemActions.Encrypted>(
    input: T,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(input, ActionTypeVersion, errorOnCoreOutDated);
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Action);
  }

  export async function decryptAndConvertDate(
    input: ItemActions.Encrypted,
    encryption: Encryption
  ): Promise<Action> {
    switch (input.actionType) {
      case ActionType.AddValuation:
        return Valuation.decryptAndConvertDate(
          <Valuation.Encrypted>input,
          encryption
        );
      case ActionType.AddConsignment:
        return Consignment.decryptAndConvertDate(
          <Consignment.Encrypted>input,
          encryption
        );
      case ActionType.AddExhibition:
        return Exhibition.decryptAndConvertDate(
          <Exhibition.Encrypted>input,
          encryption
        );
      case ActionType.AddLiterature:
        return Literature.decryptAndConvertDate(
          <Literature.Encrypted>input,
          encryption
        );
      case ActionType.AddOffer:
        return Offer.decryptAndConvertDate(<Offer.Encrypted>input, encryption);
      case ActionType.AddTastingNote:
        return TastingNote.decryptAndConvertDate(
          <TastingNote.Encrypted>input,
          encryption
        );
      case ActionType.MarkAsSold:
        return SoldInfo.decryptAndConvertDate(
          <SoldInfo.Encrypted>input,
          encryption
        );
      case ActionType.RentOut:
        return RentInfo.decryptAndConvertDate(
          <RentInfo.Encrypted>input,
          encryption
        );
      default:
        throw new Error(`Invalid action type: "${input.actionType}"`);
    }
  }
}

export interface ActionTypeBundle<
  Original extends Action,
  Create,
  Encrypted extends Action.Encrypted,
  EncryptedPart,
  Update extends Action.Update
> extends AssociatedType6<
    TypeKVPair<"Original", Original>,
    TypeKVPair<"Create", Create>,
    TypeKVPair<"Encrypted", Encrypted>,
    TypeKVPair<"EncryptedPart", EncryptedPart>,
    TypeKVPair<"Update", Update>,
    TypeKVPair<"Type", Original["actionType"]>
  > {}

export type ActionTypeBundleExtend<T extends Action = Action> =
  ActionTypeBundle<T, any, any, any, any>;

export type ConsignmentBundle = ActionTypeBundle<
  Consignment,
  Consignment.CreateFields,
  Consignment.Encrypted,
  Consignment.EncryptedPart,
  Consignment.Update
>;
export type ExhibitionBundle = ActionTypeBundle<
  Exhibition,
  Exhibition.CreateFields,
  Exhibition.Encrypted,
  Exhibition.EncryptedPart,
  Exhibition.Update
>;
export type LiteratureBundle = ActionTypeBundle<
  Literature,
  Literature.CreateFields,
  Literature.Encrypted,
  Literature.EncryptedPart,
  Literature.Update
>;
export type OfferBundle = ActionTypeBundle<
  Offer,
  Offer.CreateFields,
  Offer.Encrypted,
  Offer.EncryptedPart,
  Offer.Update
>;
export type TastingNoteBundle = ActionTypeBundle<
  TastingNote,
  TastingNote.CreateFields,
  TastingNote.Encrypted,
  TastingNote.EncryptedPart,
  TastingNote.Update
>;
export type RentBundle = ActionTypeBundle<
  RentInfo,
  RentInfo.CreateFields,
  RentInfo.Encrypted,
  RentInfo.EncryptedPart,
  RentInfo.Update
>;
