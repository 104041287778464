import { CoreFirestore } from "../../../coreFirebase";
import { Encryption } from "../../database/encryption";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  validateStringNotEmpty,
} from "../../utils";
import { ActionType, ItemActions } from "./base";
import { PathsOfDateField } from "../common";
import {
  EncryptedType,
  EncryptionField,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { InvalidInput } from "../error";

export interface Exhibition extends ItemActions {
  actionType: ActionType.AddExhibition;
  title: string;
  startDate: Date;
  endDate: Date;
  exhibitionLocation?: string; // search
  website?: string;
}
export namespace Exhibition {
  export const datePaths: readonly PathsOfDateField<Exhibition>[] = [
    "createAt",
    "updateAt",
    "startDate",
    "endDate",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Exhibition> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Exhibition,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys = ItemActions.EncryptedKeys;

  export type Encrypted = EncryptedType<Exhibition, EncryptedKeys>;
  export type EncryptedPart = Pick<Exhibition, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
  ];
  export type Update = CreateFields;
  export type UpdateEncrypted = EncryptedType<Update, EncryptedKeys>;

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): Exhibition {
    return ItemActions.fromCreate(
      createFields,
      ownerId,
      ActionType.AddExhibition
    );
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "title",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "exhibitionLocation",
    "notes",
    "website",
  ];
  export function intoUpdate(
    current: Exhibition,
    update: Update
  ): UpdateObject<Update> {
    const result: UpdateObject<Update> = {
      ...ItemActions.intoUpdate(current, update),
      ...buildObjectUpdate(
        current,
        update,
        NonOptionalSimpleTypeUpdatableKeys,
        OptionalSimpleTypeUpdatableKeys
      ),
    };

    if (current.startDate.getTime() !== update.startDate.getTime())
      result.startDate = update.startDate;
    if (current.endDate.getTime() !== update.endDate.getTime())
      result.endDate = update.endDate;

    return result;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    ItemActions.validateEncryptedPart(data);
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    //#TODO need checks
    if (isCreate) {
      if (!data.startDate) throw new InvalidInput("Start date is required");
      if (!data.endDate) throw new InvalidInput("End date is required");
    }
    if ((isCreate || data.title) && !validateStringNotEmpty(data.title)) {
      throw new InvalidInput("Title cannot be empty");
    }
  }

  export function removeEncryptedFields<
    T extends Exhibition | UpdateObject<Exhibition>
  >(data: T): OmitKeys<T, EncryptedKeys> {
    return doRemoveEncryptedFields(data, encryptedKeysArray);
  }

  export async function encrypt(
    rawData: Exhibition,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<Exhibition, EncryptedKeys>;
}
