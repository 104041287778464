import { Amount, AssetType, TargetCurrencyExchangeRateDataMap } from "./common";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import { Event as InsuranceEvent, Insurance } from "./insurance";
import { EventEnvelope, EventWithTime } from "./event";
import Decimal from "decimal.js";
import { AllowedDecimalPlaces } from "../utils";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  InsuranceSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<InsuranceEvent>;
export interface InsuranceSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.InsuranceSummary, 2>;
  id: AssetType.Insurance;
  insurance: {
    [id: string]: Pick<Insurance, "subtype" | "value" | "insuranceType">;
  };
}
export namespace InsuranceSummary {
  export function assureVersion(
    input: InsuranceSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      InsuranceSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<InsuranceSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new InsuranceSummaryAggregate(summary));
  }

  export function defaultValue(): InsuranceSummary {
    return {
      "@type": InsuranceSummaryTypeVersion,
      id: AssetType.Insurance,
      insurance: {},
      version: 0,
    };
  }

  export type GlobalDashboardData = Amount;
  export function toGlobalDashboardData(
    input: InsuranceSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): GlobalDashboardData {
    const currency = exchangeRate.targetCurrency;
    const value = Object.values(input.insurance)
      .reduce((sum, item) => {
        return new Decimal(item.value.value)
          .mul(exchangeRate.rates[item.value.currency].rate)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .add(sum);
      }, new Decimal(0))
      .toNumber();
    return {
      currency,
      value,
    };
  }

  export type RelatedUpdates = never;
}

export class InsuranceSummaryAggregate extends AggregateBase<
  InsuranceSummary,
  never,
  InsuranceEvent
> {
  state: InsuranceSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: InsuranceSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case InsuranceEvent.Kind.AssetCreated: {
        const asset = event.data.asset;
        this.state.insurance[event.aggregateId] = {
          subtype: asset.subtype,
          value: asset.value,
          insuranceType: asset.insuranceType,
        };
        break;
      }
      case InsuranceEvent.Kind.AssetUpdated: {
        const updates = event.data.asset;
        const insurance = this.state.insurance[event.aggregateId];
        if (updates.subtype) insurance.subtype = updates.subtype;
        if (updates.value) insurance.value = updates.value;
        if (updates.insuranceType)
          insurance.insuranceType = updates.insuranceType;
        break;
      }
      case InsuranceEvent.Kind.AssetDeleted:
        delete this.state.insurance[event.aggregateId];
        break;
      case InsuranceEvent.Kind.ValueUpdated:
        this.state.insurance[event.aggregateId].value = event.data.current;
        break;
    }
    return this;
  }
}
