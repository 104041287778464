import { Amount, type Beneficiary, Ownership, AssetType } from "../common";
import { LocationInfo } from "../relations/locationInfo";
import {
  BelongingTypeVersion,
  VersionedType,
  type VersionedTypeString,
} from "../typeVersion";
import { AssetV2WithCollectableAttributesRefactored } from "../common/asset";
import {
  FirestoreCollectionRef,
  FullDelegateAccess,
  OwnerAccess,
  ReadOnlyDelegateAccess,
  UpdateNotAllowed,
} from "../../decorators/accessRules";
import {
  EncryptableObject,
  IsAmount,
  IsDate,
  IsIn,
  IsOptionalOnUpdate,
  IsStringNotEmpty,
  NotEncrypted,
  SimpleUpdate,
  isValidBeneficiary,
  isValidOwnerShip,
  IsOptional,
  IsPositive,
  IsExactly,
} from "../../decorators";
import {
  getIndexedAssetsPath,
  getPermissionCategory,
  PermissionCategory,
} from "../../refPaths";
import { OtherCollectableType } from "./type";
import { CollectableAcquisition } from "../common/acquisition";

@FirestoreCollectionRef(getIndexedAssetsPath(AssetType.Belonging))
@OwnerAccess
@FullDelegateAccess(getPermissionCategory(AssetType.Belonging))
@ReadOnlyDelegateAccess(PermissionCategory.Insurance)
export class Belonging extends AssetV2WithCollectableAttributesRefactored {
  //#NOTE use `UpdateNotAllowed`, assuming version update is not done on client side
  @UpdateNotAllowed
  @NotEncrypted
  @IsExactly(BelongingTypeVersion)
  @IsOptionalOnUpdate()
  declare "@type": VersionedTypeString<VersionedType.Belonging, 2>;

  @NotEncrypted
  @UpdateNotAllowed
  @IsOptionalOnUpdate()
  @IsIn([AssetType.OtherCollectables, AssetType.Belonging])
  declare assetType: AssetType.OtherCollectables | AssetType.Belonging;

  @NotEncrypted
  @SimpleUpdate
  @IsStringNotEmpty()
  @IsOptionalOnUpdate()
  declare subtype: OtherCollectableType | string;

  @NotEncrypted
  @SimpleUpdate
  @IsPositive()
  @IsOptionalOnUpdate()
  number!: number;

  @NotEncrypted
  @IsDate()
  @IsOptionalOnUpdate()
  purchaseDate!: Date;

  @NotEncrypted
  @IsAmount({ includeMin: false })
  @IsOptionalOnUpdate()
  price!: Amount;

  @NotEncrypted
  @IsAmount({ includeMin: false })
  @IsOptionalOnUpdate()
  purchasePrice!: Amount;

  @NotEncrypted
  @IsAmount()
  @IsOptionalOnUpdate()
  totalCost!: Amount;

  @NotEncrypted
  @SimpleUpdate
  @IsOptionalOnUpdate()
  @IsStringNotEmpty()
  brand!: string;

  @NotEncrypted
  @IsOptional()
  @SimpleUpdate
  personalRefNo?: string;

  @EncryptableObject(CollectableAcquisition)
  @IsOptional()
  acquisition?: CollectableAcquisition;

  @EncryptableObject(LocationInfo)
  @IsOptionalOnUpdate()
  location!: LocationInfo;

  @NotEncrypted
  @IsOptional()
  @isValidOwnerShip()
  ownership?: Ownership;

  @NotEncrypted
  @IsOptional()
  @isValidBeneficiary()
  beneficiary?: Beneficiary;
}

@FirestoreCollectionRef(getIndexedAssetsPath(AssetType.OtherCollectables))
@OwnerAccess
@FullDelegateAccess(getPermissionCategory(AssetType.OtherCollectables))
@ReadOnlyDelegateAccess(PermissionCategory.Insurance)
export class OtherCollectable extends Belonging {}
