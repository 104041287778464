import { useMemo } from 'react'

const DISPLAY_MODE_KEY = 'displayMode'

export function useIsAppMode() {
  const isAppMode = useMemo(() => {
    if (typeof window === 'undefined') return false
    return window.sessionStorage.getItem(DISPLAY_MODE_KEY) === 'App'
  }, [])

  return isAppMode
}
