import { Amount, AssetType, Currency } from "../common";
import { ContactLocation } from "./contactLocation";
import { RentalDetail } from "./rentalDetail";
import { OwnerDetail } from "./ownerDetail";
import { Configuration } from "./configuration";
import { OwnershipType } from "./ownershipType";
import { Type } from "./type";
import {
  IsAmount,
  IsDate,
  EncryptableObject,
  NotEncrypted,
  IsOptional,
  IsShortString,
  IsEnum,
  IsExactly,
  Notes,
  SimpleUpdate,
  IsOptionalOnUpdate,
} from "../../decorators";
import {
  FirestoreCollectionRef,
  FullDelegateAccess,
  OwnerAccess,
  ReadOnlyDelegateAccess,
  UpdateNotAllowed,
} from "../../decorators/accessRules";
import { Asset } from "../common/asset";
import { getIndexedAssetsPath, getPermissionCategory } from "../../refPaths";
import {
  PropertyTypeVersion,
  VersionedType,
  type VersionedTypeString,
} from "../typeVersion";
import { CoreFirestore } from "../../../coreFirebase";

@FirestoreCollectionRef(getIndexedAssetsPath(AssetType.Property))
@OwnerAccess
@FullDelegateAccess(getPermissionCategory(AssetType.Property))
@ReadOnlyDelegateAccess("listProperty")
@ReadOnlyDelegateAccess(getPermissionCategory(AssetType.Insurance))
export class Property extends Asset {
  //#NOTE use `UpdateNotAllowed`, assuming version update is not done on client side
  @UpdateNotAllowed
  @NotEncrypted
  @IsExactly(PropertyTypeVersion)
  @IsOptionalOnUpdate()
  declare "@type": VersionedTypeString<VersionedType.Property, 2>;

  @NotEncrypted
  @UpdateNotAllowed
  @IsExactly(AssetType.Property)
  @IsOptionalOnUpdate()
  declare assetType: AssetType.Property;

  @NotEncrypted
  @SimpleUpdate
  @IsEnum(Type)
  @IsOptionalOnUpdate()
  declare subtype: Type;

  @NotEncrypted
  @SimpleUpdate
  @IsEnum(OwnershipType)
  @IsOptionalOnUpdate()
  ownershipType!: OwnershipType;

  @NotEncrypted
  @IsDate()
  @Notes("Purchase Date for owned")
  @IsOptionalOnUpdate()
  startDate!: Date;

  @NotEncrypted
  @IsAmount()
  @Notes("Purchase Price / Rental Cost")
  @IsOptionalOnUpdate()
  price!: Amount;

  @NotEncrypted
  @IsAmount()
  @IsOptionalOnUpdate()
  totalCost!: Amount;

  @NotEncrypted
  @SimpleUpdate
  @IsShortString()
  @IsOptional()
  personalRefNo?: string;

  @EncryptableObject(ContactLocation)
  @IsOptionalOnUpdate()
  location!: ContactLocation;

  @EncryptableObject(Configuration)
  @IsOptional()
  configuration?: Configuration;

  @EncryptableObject(Object, {
    property: "ownershipType",
    propertyOnParent: true,
    subTypes: [
      { value: RentalDetail, name: OwnershipType.Rent },
      { value: OwnerDetail, name: OwnershipType.Own },
    ],
  })
  @IsOptionalOnUpdate()
  //#QUESTION how should we handle its validation?
  //#NOTE: https://stackoverflow.com/questions/60058260/class-validator-validate-discriminated-union-type
  detail!: RentalDetail | OwnerDetail;

  static default(input? : Property) {
    const property = input || new Property();
    property.id = CoreFirestore.genAssetId();
    property.startDate = new Date();
    property.updateAt = new Date();
    property.assetType = AssetType.Property;
    property.startDate = new Date();
    property.price = { currency: Currency.USD, value: 0 };
    property.totalCost = { currency: Currency.USD, value: 0 };
    property.detail = new OwnerDetail();
    property.ownershipType = OwnershipType.Own;
    property.location = new ContactLocation();
    return property
  }
}
