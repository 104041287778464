import { CoreFirestore } from "../../../coreFirebase";
import { Encryption } from "../../database/encryption";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  validateValueInEnum,
  validateStringNotEmpty,
} from "../../utils";
import { ActionType, ItemActions } from "./base";
import { Amount, PathsOfAmountField, PathsOfDateField } from "../common";
import {
  EncryptedType,
  EncryptionField,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { InvalidInput } from "../error";

export interface Offer extends ItemActions {
  actionType: ActionType.AddOffer;
  offerNumber: string;

  buyer: string; // addr
  type: Offer.Type;
  price: Amount;
  offerDate: Date;
}
export namespace Offer {
  export const datePaths: readonly PathsOfDateField<Offer>[] = [
    "createAt",
    "updateAt",
    "offerDate",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<Offer>[] = [
    "price",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Offer> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Offer,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys = ItemActions.EncryptedKeys;
  export type Encrypted = EncryptedType<Offer, EncryptedKeys>;
  export type EncryptedPart = Pick<Offer, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
  ];
  export type Update = CreateFields;
  export type UpdateEncrypted = EncryptedType<Update, EncryptedKeys>;

  export enum Type {
    Incoming = "Incoming",
    Outgoing = "Outgoing",
  }

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): Offer {
    return ItemActions.fromCreate(createFields, ownerId, ActionType.AddOffer);
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "buyer",
    "offerNumber",
    "type",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "notes",
  ];
  export function intoUpdate(
    current: Offer,
    update: Update
  ): UpdateObject<Update> {
    const result: UpdateObject<Update> = {
      ...ItemActions.intoUpdate(current, update),
      ...buildObjectUpdate(
        current,
        update,
        NonOptionalSimpleTypeUpdatableKeys,
        OptionalSimpleTypeUpdatableKeys
      ),
    };

    if (!Amount.equal(current.price, update.price)) {
      result.price = update.price;
    }
    if (current.offerDate.getTime() !== update.offerDate.getTime())
      result.offerDate = update.offerDate;

    return result;
  }

  export function assertActionIs(action: ItemActions): Offer {
    if (action.actionType !== ActionType.AddOffer)
      throw new Error(`Action ${action.id} is not ${ActionType.AddOffer}`);
    else return action as Offer;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    ItemActions.validateEncryptedPart(data);
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    if (isCreate && !data.offerDate)
      throw new InvalidInput("Offer date is required");
    if (
      (isCreate || data.offerNumber) &&
      !validateStringNotEmpty(data.offerNumber)
    )
      throw new InvalidInput("Offer number cannot be empty");
    if ((isCreate || data.type) && !validateValueInEnum(data.type!, Type)) {
      throw new InvalidInput("Invalid offer type");
    }
    if ((isCreate || data.price) && data.price!.value <= 0)
      throw new InvalidInput("Price must be positive");
    if ((isCreate || data.buyer) && !validateStringNotEmpty(data.buyer))
      throw new InvalidInput("Buyer cannot be empty");
  }

  export function removeEncryptedFields<T extends Offer | UpdateObject<Offer>>(
    data: T
  ): OmitKeys<T, EncryptedKeys> {
    return doRemoveEncryptedFields(data, encryptedKeysArray);
  }

  export async function encrypt(
    rawData: Offer,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<Offer, EncryptedKeys>;
}

export const offerTypeValues = Object.values(Offer.Type);
