import { Account, Category, TransactionType } from "./cashAndBanking";
import { supportLiabilityTypes } from "./cashAndBankingSummary";
import {
  AssetType,
  AttachmentKind,
  Country,
  Currency,
  DateFormat,
  Language,
  LocationType,
  NumberFormat,
  TimeZone,
} from "./common";
import { formatInTimeZone } from "date-fns-tz";
import { PermissionLevel } from "./delegates";
import {
  GeneralInsuranceType,
  InsuranceKind,
  InsuranceStatus,
  InsuranceSubType,
  LifeInsuranceType,
} from "./insurance";
import { InvestmentStatus, InvestmentType } from "./otherInvestments";
import { OwnershipType, Property } from "./properties";
import { PortfolioTransaction } from "./traditionalInvestments";
import {
  BottleSize,
  BottleSizeToString,
  RemovalReason,
  WineType,
} from "./wineAndSprits";
import { CreditCardType } from "./cashAndBanking/creditCard";
import { InterestFrequency } from "./cashAndBanking/savingAccount";

export const allocationTypesOptions = supportLiabilityTypes.map((value) => ({
  label: value,
  value,
}));

export const accountTypeOptions = Object.values(Account.AccountType).map(
  (value) => ({
    label: value,
    value,
  })
);

export const transactionTypeOptions = Object.values(TransactionType).map(
  (value) => ({ label: value, value })
);

export const creditCategoryOptions = [
  Category.SystemAccountCreation,
  Category.BalanceUpdate,
  Category.Transfer,
  Category.CashAdvance,
  Category.Interest,
  Category.OtherIncome,
  Category.RentalIncome,
].map((value) => ({ label: value, value }));

export const debitCategoryOptions = [
  Category.SystemAccountCreation,
  Category.BalanceUpdate,
  Category.Transfer,
  Category.BankFees,
  Category.Community,
  Category.FoodAndDrink,
  Category.Healthcare,
  Category.Payment,
  Category.Recreation,
  Category.Service,
  Category.Shops,
  Category.Tax,
  Category.Travel,
  Category.OtherExpense,
].map((value) => ({ label: value, value }));

export const LocationTypeOptions = [
  { label: "My Property", value: LocationType.MyProperty },
  { label: "Address Book", value: LocationType.Address },
  { label: "New Address", value: LocationType.NewAddress },
];

export const locationTypeValues = Object.values(LocationType);

export const assetTypeValues = Object.values(AssetType).filter(
  (value) => !["WinePurchases", "BankOrInstitution"].includes(value)
);

export const assetTypeOptions = assetTypeValues.map((value) => ({
  label: value,
  value,
}));

export const AttachmentKindOptions = Object.values(AttachmentKind).map(
  (value) => ({ label: value, value })
);

export const currencyOptions = Object.values(Currency).map((value) => ({
  label: value,
  value,
}));

export const timeZoneOptions = Object.values(TimeZone).map((value) => {
  const label = `${value} ${formatInTimeZone(new Date(), value, "xxx")}`;
  return { label, value };
});

export const dateFormatOptions = Object.values(DateFormat).map((value) => {
  const label = value.toUpperCase();
  return { label, value };
});

export const languageOptions = [
  { label: "English", value: Language.EN },
  { label: "日本語", value: Language.JA },
  { label: "العربية", value: Language.AR },
  { label: "繁體中文", value: Language.ZH_HANT },
  { label: "简体中文", value: Language.ZH_HANS },
];

export const numberFormatOptions = [
  { label: "500,150.00", value: NumberFormat.Comma },
  { label: "500.150,00", value: NumberFormat.Dot },
  { label: "500 150.00", value: NumberFormat.Space },
  { label: "500'150.00", value: NumberFormat.Apostrophe },
];

export const CountryOptions = Object.values(Country).map((value) => ({
  label: value,
  value,
}));

export const PermissionLevelOptions = Object.keys(PermissionLevel)
  .filter((k) => isNaN(Number(k)))
  .map((k) => ({
    label: k,
    value: PermissionLevel[k as keyof typeof PermissionLevel],
  }));

export const insuranceSubTypeOptions = Object.values(InsuranceSubType).map(
  (value) => ({ label: value, value })
);

export const insuranceStatusOptions = Object.values(InsuranceStatus).map(
  (value) => ({ label: value, value })
);

export const insuranceKindOptions = Object.values(InsuranceKind).map(
  (value) => ({ label: value, value })
);

export const generalInsuranceTypeOptions = Object.values(
  GeneralInsuranceType
).map((value) => ({ label: value, value }));

export const lifeInsuranceTypeOptions = Object.values(LifeInsuranceType).map(
  (value) => ({ label: value, value })
);

export const investmentTypeOptions = Object.values(InvestmentType).map(
  (value) => ({ label: value, value })
);

export const investmentStatusOptions = Object.values(InvestmentStatus).map(
  (value) => ({ label: value, value })
);

export const ownershipTypeOptions = Object.values(OwnershipType).map(
  (value) => ({
    label: value,
    value,
  })
);

export const cashTxTypeOptions = Object.values(
  PortfolioTransaction.CashTxType
).map((value) => ({
  label: value,
  value,
}));

export const holdingTxTypeOptions = Object.values(
  PortfolioTransaction.HoldingTxType
).map((value) => ({ label: value, value }));

export const wineTypeValues = Object.values(WineType);

export const wineTypeOptions = [
  { label: "Beer", value: WineType.Beer },
  { label: "Blue", value: WineType.Blue },
  { label: "Blue - Sparkling", value: WineType.BlueSparkling },
  { label: "Cider", value: WineType.Cider },
  { label: "Cider - Sparkling", value: WineType.CiderSparkling },
  { label: "Cider - Sweet/Dessert", value: WineType.CiderSweetDessert },
  { label: "Fruit/Vegetable Wine", value: WineType.FruitVegetableWine },
  { label: "Non-alcoholic", value: WineType.NonAlcoholic },
  { label: "Orange", value: WineType.Orange },
  { label: "Orange - Sparkling", value: WineType.OrangeSparkling },
  { label: "Red", value: WineType.Red },
  { label: "Red - Fortified", value: WineType.RedFortified },
  { label: "Red - Sparkling", value: WineType.RedSparkling },
  { label: "Red - Sweet/Dessert", value: WineType.RedSweetDessert },
  { label: "Rosé", value: WineType.Rose },
  { label: "Rosé - Fortified", value: WineType.RoseFortified },
  { label: "Rosé - Sparkling", value: WineType.RoseSparkling },
  { label: "Rosé - Sweet/Dessert", value: WineType.RoseSweetDessert },
  { label: "Sake", value: WineType.Sake },
  { label: "Spirits", value: WineType.Spirits },
  { label: "White", value: WineType.White },
  { label: "White - Fortified", value: WineType.WhiteFortified },
  { label: "White - Off-dry", value: WineType.WhiteOffDry },
  { label: "White - Sparkling", value: WineType.WhiteSparkling },
  { label: "White - Sweet/Dessert", value: WineType.WhiteSweetDessert },
];

export const bottleSizeOptions = Object.values(BottleSize).map((size) => ({
  label: BottleSizeToString(size),
  value: size,
}));

export const RemovalReasonOptions = Object.entries(RemovalReason).map(
  ([label, value]) => ({ label, value })
);

export const creditCardTypeValues = Object.values(CreditCardType);

export const interestFrequencyOptions = Object.values(InterestFrequency).map(
  (value) => ({ label: value, value })
);
