import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20d%3D%22M18.234.46c-1.397%200-.188.432-.94.54-.753.108.994-.108-1.263.324-2.257.46-2.714%203.379-2.822%204.46-.107%201.08-.134%201.405-.08%203.027.215.973-.86.973-.86.973-1.37-.081-1.586%204.162-1.586%204.162.188%201.054-.08%201.568%201.935%202.378.94%202.73%203.386%204.108%203.386%204.108s.107%201.757.161%203c-.215-.08-.376%203.217-6.583%204.136-6.235.918-7.417%202.945-8.6%204.08C-.2%2032.785.016%2038%20.016%2038H40s-.269-5.216-1.424-6.351c-1.182-1.136-2.472-3.163-8.68-4.082-6.234-.918-5.804-4.135-5.858-4.324.027-.486.242-2.594.242-2.594s2.015-1.676%202.956-4.406c1.505-.946%202.07-1.08%202.338-2.189%200%200%20.672-4.162-.94-4.162%200%200-1.855.108-1.64-.865.215-.973.134-1.297.027-2.378-.108-1.081.537-4.19-1.72-4.622-1.531-.27-1.048.162-1.8.054C22.748%201.973%2021.86%200%2020.464%200l-2.23.46Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function UserIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 40 40'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M18.234.46c-1.397 0-.188.432-.94.54-.753.108.994-.108-1.263.324-2.257.46-2.714 3.379-2.822 4.46-.107 1.08-.134 1.405-.08 3.027.215.973-.86.973-.86.973-1.37-.081-1.586 4.162-1.586 4.162.188 1.054-.08 1.568 1.935 2.378.94 2.73 3.386 4.108 3.386 4.108s.107 1.757.161 3c-.215-.08-.376 3.217-6.583 4.136-6.235.918-7.417 2.945-8.6 4.08C-.2 32.785.016 38 .016 38H40s-.269-5.216-1.424-6.351c-1.182-1.136-2.472-3.163-8.68-4.082-6.234-.918-5.804-4.135-5.858-4.324.027-.486.242-2.594.242-2.594s2.015-1.676 2.956-4.406c1.505-.946 2.07-1.08 2.338-2.189 0 0 .672-4.162-.94-4.162 0 0-1.855.108-1.64-.865.215-.973.134-1.297.027-2.378-.108-1.081.537-4.19-1.72-4.622-1.531-.27-1.048.162-1.8.054C22.748 1.973 21.86 0 20.464 0l-2.23.46Z'
        }
      />
    </svg>
  )
}
