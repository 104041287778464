import { Encryption } from "../../database/encryption";
import {
  ArrayUnique,
  IsAmount,
  IsArray,
  IsBoolean,
  IsEnum,
  IsLongString,
  IsOptional,
  IsReferenceId,
  IsShortString,
  IsString,
  NotEncrypted,
  SimpleUpdate,
  ValidateNestedWithType,
  validateWithGroups,
} from "../../decorators";
import {
  EncryptedType,
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  fullObjectDecryption,
  fullObjectEncryption,
  IVSaltFieldKey,
} from "../../encryption/utils";
import { OmitKeys, optionalUniqueArrayEqual } from "../../utils";
import { Amount, Optional } from "../common";

export class AuctionDetail {
  @NotEncrypted
  @IsAmount({ min: 0, message: "Buyer premium can't be negative" })
  hammerPrice!: Amount;

  @NotEncrypted
  @IsAmount({ min: 0, message: "Tax payable can't be negative" })
  taxPayable!: Amount;

  @NotEncrypted
  @IsAmount({ min: 0, message: "Hammer price can't be negative" })
  buyerPremium!: Amount;
}
export namespace AuctionDetail {
  export function validate(data: AuctionDetail) {
    // if (data.buyerPremium.value < 0) {
    //   throw new InvalidInput("Buyer premium can't be negative");
    // }
    // if (data.taxPayable.value < 0) {
    //   throw new InvalidInput("Tax payable can't be negative");
    // }
    // if (data.hammerPrice.value < 0) {
    //   throw new InvalidInput("Hammer price can't be negative");
    // }

    validateWithGroups(data, AuctionDetail);
  }
  export function equal(a: AuctionDetail, b: AuctionDetail): boolean {
    return (
      Amount.equal(a.hammerPrice, b.hammerPrice) &&
      Amount.equal(a.taxPayable, b.taxPayable) &&
      Amount.equal(a.buyerPremium, b.buyerPremium)
    );
  }
  export function optionalEqual(
    a: Optional<AuctionDetail>,
    b: Optional<AuctionDetail>
  ): boolean {
    if (a && b) {
      return equal(a, b);
    } else {
      return a === b;
    }
  }
}

export class CollectableAuctionDetail extends AuctionDetail {
  @NotEncrypted
  @IsShortString()
  @SimpleUpdate
  @IsOptional()
  lotNumber?: string;

  @NotEncrypted
  @IsShortString()
  @SimpleUpdate
  @IsOptional()
  paddleNumber?: string;

  @NotEncrypted
  @IsShortString()
  @SimpleUpdate
  @IsOptional()
  auctionNumber?: string;
}
export namespace CollectableAuctionDetail {
  export function equal(
    a: CollectableAuctionDetail,
    b: CollectableAuctionDetail
  ) {
    return (
      a.lotNumber === b.lotNumber &&
      a.paddleNumber === b.paddleNumber &&
      a.auctionNumber === b.auctionNumber &&
      AuctionDetail.equal(a, b)
    );
  }
  export function optionalEqual(
    a: Optional<CollectableAuctionDetail>,
    b: Optional<CollectableAuctionDetail>
  ) {
    if (a && b) {
      return equal(a, b);
    } else {
      return a === b;
    }
  }
}

export enum AcquisitionType {
  Direct = "Direct",
  Auction = "Auction",
}

export const acquisitionTypeValues = Object.values(AcquisitionType);

export class Acquisition {
  @NotEncrypted
  @IsEnum(AcquisitionType)
  acquisitionType!: AcquisitionType;

  @NotEncrypted
  @IsReferenceId()
  @SimpleUpdate
  @IsOptional()
  sellerId?: string; // to addr_book

  @NotEncrypted
  @IsAmount({ min: 0 })
  @IsOptional()
  otherCost?: Amount;

  @NotEncrypted
  @IsOptional()
  @IsBoolean()
  priceAsValue?: boolean;

  @NotEncrypted
  @IsArray()
  @IsOptional()
  @IsString({ each: true })
  @ArrayUnique({ message: "Insurance id should be unique" })
  insuranceIds?: string[];

  @NotEncrypted
  @ValidateNestedWithType(AuctionDetail)
  @IsOptional()
  auctionDetail?: AuctionDetail;

  @NotEncrypted
  @IsAmount({ min: 0 })
  @IsOptional()
  initialValuationAtPurchase?: Amount;

  // encrypted
  @IsLongString()
  @SimpleUpdate
  @IsOptional()
  notes?: string;
}
export namespace Acquisition {
  export function defaultValue(): Acquisition {
    return {
      acquisitionType: AcquisitionType.Direct,
    };
  }
  export function validate(data: Acquisition) {
    // if (data.otherCost) {
    //   Amount.validate("otherCost", data.otherCost);
    //   if (data.otherCost.value < 0)
    //     throw new InvalidInput("Other cost should be positive");
    // }
    // if (data.auctionDetail) {
    //   AuctionDetail.validate(data.auctionDetail);
    // // }
    // if (
    //   data.insuranceIds !== undefined &&
    //   data.insuranceIds.some((v, idx) => data.insuranceIds!.indexOf(v) !== idx)
    // ) {
    //   throw new InvalidInput("Insurance id should be unique");
    // }

    validateWithGroups(data, Acquisition);
  }
  export function equal(
    a: Acquisition,
    b: Acquisition,
    detailOptionalEqual: <T extends AuctionDetail>(
      a: Optional<T>,
      b: Optional<T>
    ) => boolean = AuctionDetail.optionalEqual
  ): boolean {
    return (
      a.acquisitionType === b.acquisitionType &&
      a.sellerId === b.sellerId &&
      Amount.optionalEqual(a.otherCost, b.otherCost) &&
      a.priceAsValue === b.priceAsValue &&
      detailOptionalEqual(a.auctionDetail, b.auctionDetail) &&
      optionalUniqueArrayEqual(a.insuranceIds, b.insuranceIds) &&
      Amount.optionalEqual(
        a.initialValuationAtPurchase,
        b.initialValuationAtPurchase
      ) &&
      a.notes === b.notes
    );
  }
}

export class CollectableAcquisition extends Acquisition {
  // itemPrice, numberOfItems are 'price' and 'number' under art, other collectable, belonging
  @NotEncrypted
  @IsAmount({ min: 0 })
  @IsOptional()
  discountAdjustment?: Amount; // affect total cost (wine does not have)

  @NotEncrypted
  @IsShortString()
  @SimpleUpdate
  @IsOptional()
  invoiceNumber?: string;

  @NotEncrypted
  @ValidateNestedWithType(CollectableAuctionDetail)
  @IsOptional()
  declare auctionDetail?: CollectableAuctionDetail; // direct don't have
}
export namespace CollectableAcquisition {
  export type EncryptedKey = "notes";
  export type Encrypted = EncryptedType<CollectableAcquisition, EncryptedKey>;
  export type EncryptedPart = Pick<CollectableAcquisition, EncryptedKey>;
  export const encryptedKeyArray: readonly (keyof EncryptedPart)[] = ["notes"];

  export async function encrypt(
    rawData: CollectableAcquisition,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeyArray, encryption);
  }
  export const decrypt = fullObjectDecryption<
    CollectableAcquisition,
    EncryptedKey
  >;

  export type Validate = OmitKeys<CollectableAcquisition, EncryptedKey>;
  export function validateEncryptedPart(_data: EncryptedPart) {}
  export function validateEncryptedObj<T extends Validate>(input: T) {
    // if (input.discountAdjustment) {
    //   Amount.validate("discountAdjustment", input.discountAdjustment);
    //   if (input.discountAdjustment.value < 0)
    //     throw new InvalidInput("Discount adjustment should be positive");
    // }
    // Acquisition.validate(input);

    validateWithGroups<CollectableAcquisition>(input, CollectableAcquisition);
  }

  export function defaultValueWithEncryptedField(
    encodedIVSalt: string
  ): CollectableAcquisition.Encrypted {
    return {
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue,
        [IVSaltFieldKey]: encodedIVSalt,
      },
      ...Acquisition.defaultValue(),
    };
  }
  export function equal(a: CollectableAcquisition, b: CollectableAcquisition) {
    return (
      a.invoiceNumber === b.invoiceNumber &&
      Amount.optionalEqual(a.discountAdjustment, b.discountAdjustment) &&
      Acquisition.equal(a, b, CollectableAuctionDetail.optionalEqual)
    );
  }
  export function optionalEqual(
    a: Optional<CollectableAcquisition>,
    b: Optional<CollectableAcquisition>
  ) {
    if (a && b) {
      return equal(a, b);
    } else {
      return a === b;
    }
  }
}
