import { useEffect, type ReactNode } from 'react'

import { cn } from '@/utils/classnames'
import { Button } from '@/components/base'
import { XIcon } from '@/components/icon'

interface ModalProps {
  className?: string
  children: ReactNode
  onBackdropClick?: () => void
}

export function Modal({ className, children, onBackdropClick }: ModalProps) {
  return (
    <Overlay className={' grid place-items-center'} onBackdropClick={onBackdropClick}>
      <Container className={className}>{children}</Container>
    </Overlay>
  )
}

interface HeaderProps {
  className?: string
  children: ReactNode
}

function Header({ className, children }: HeaderProps) {
  return <div className={cn('flex items-center justify-between px-4 py-2', className)}>{children}</div>
}

Modal.Header = Header

interface CloseButtonProps {
  className?: string
  onClose: () => void
}

function CloseButton({ className, onClose }: CloseButtonProps) {
  return (
    <Button className={cn('rounded-full p-px hover:bg-grey/25', className)} onClick={onClose}>
      <XIcon />
    </Button>
  )
}

Modal.CloseButton = CloseButton

interface ContentProps {
  className?: string
  children: ReactNode
}

function Content({ className, children }: ContentProps) {
  return <div className={cn('grid gap-2 p-4', className)}>{children}</div>
}

Modal.Content = Content

interface OverlayProps {
  children: ReactNode
  className?: string
  onBackdropClick?: () => void
}

export function Overlay({ children, className, onBackdropClick }: OverlayProps) {
  useEffect(() => {
    const overflow = document.body.style.overflow
    if (overflow !== 'hidden') {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      if (overflow !== 'hidden') {
        document.body.style.overflow = 'unset'
      }
    }
  })

  return (
    <div
      className={cn('fixed inset-0 z-50 bg-black/30 backdrop-blur-sm animate-in fade-in', className)}
      onClick={onBackdropClick}
    >
      {children}
    </div>
  )
}

interface ContainerProps {
  className?: string
  children: ReactNode
}

function Container({ className, children }: ContainerProps) {
  return (
    <div
      className={cn(
        'grid max-h-full w-full max-w-lg overflow-y-auto overflow-x-hidden rounded-md bg-white animate-in fade-in zoom-in-95 slide-in-from-bottom-4 md:max-h-[90vh]',
        className
      )}
      onClick={(event) => event.stopPropagation()}
    >
      {children}
    </div>
  )
}
