import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml;base64,ICAgICAgPHN2ZwogICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICB3aWR0aD0iNDgiCiAgICAgICAgaGVpZ2h0PSI0OCIKICAgICAgICB2aWV3Qm94PSIwIDAgMjQgMjQiCiAgICAgICAgZmlsbD0ibm9uZSIKICAgICAgICBzdHJva2U9ImN1cnJlbnRDb2xvciIKICAgICAgICBzdHJva2Utd2lkdGg9IjIiCiAgICAgICAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiCiAgICAgID4KICAgICAgICA8cGF0aCBzdHJva2U9IiMwZjg4OWIiIGQ9Im03IDEwIDUtNiA1IDYiIC8+CiAgICAgIDxwb2x5Z29uIGZpbGw9IiMwZjg4OWIiIHN0cm9rZT0iIzBmODg5YiIgcG9pbnRzPSIxMiwyMCAxNywxNCA3LDE0IiAvPgogICAgICA8L3N2Zz4=)
 */
export default function SortAscendIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      width={size}
      height={size}
      viewBox={'0 0 24 24'}
      fill={'none'}
      stroke={'#0f889b'}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      {...props}
    >
      <path d={'m7 10 5-6 5 6'} />
      <polygon fill={'#0f889b'} points={'12,20 17,14 7,14'} />
    </svg>
  )
}
