import { ErrorDataOutDated, InvalidInput } from "../error";
import { addDecimal, UpdateObject } from "../../utils";
import {
  Account,
  AccountState,
  AccountTransaction,
  Category,
  Command,
  Event,
} from "../cashAndBanking";
import {
  Amount,
  AssetType,
  AssetV2,
  Attachment,
  Beneficiary,
  MultiCurrencyAmount,
  Owner,
  Ownership,
  compareGroupUpdate,
} from "../common";
import { EncryptedType, RequireEncryptionFields } from "../../encryption/utils";
import { CoreFirestore, WithFieldValue } from "../../../coreFirebase";
import {
  CashTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "../typeVersion";

export const CASH_INSTITUTION = "SYSTEM_CASH";

export interface Cash extends AssetV2 {
  "@type": VersionedTypeString<VersionedType.Cash, 2>;
  assetType: AssetType.CashAndBanking;
  subtype: Account.Type.Cash;

  ownership?: Ownership;
  beneficiary?: Beneficiary;
  institution: typeof CASH_INSTITUTION;
}
export namespace Cash {
  export function assureVersion(
    input: Cash | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(input, CashTypeVersion, errorOnCoreOutDated);
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Cash);
  }

  export type Create = Pick<
    Cash,
    | "id"
    | "name"
    | "subtype"
    | "value"
    | "groupIds"
    | "notes"
    | "ownership"
    | "beneficiary"
    | "attachments"
  >;
  export type Update = Pick<
    Cash,
    "name" | "notes" | "groupIds" | "ownership" | "beneficiary" | "attachments"
  >;
  export type UpdateEncrypted = RequireEncryptionFields<
    EncryptedType<Update, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedKeys = AssetV2.EncryptedKeys;
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Cash, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<Cash, EncryptedKeys>;

  export function fromCreate(from: Create, ownerId: string): Cash {
    const data: WithFieldValue<Cash> = {
      ...from,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      assetType: AssetType.CashAndBanking,
      institution: CASH_INSTITUTION,
      "@type": CashTypeVersion,
    };
    return data as Cash;
  }

  export function intoUpdate(
    current: Cash,
    update: Update
  ): {
    updates: UpdateObject<Update>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
    };
  } {
    const metadata: any = {};
    const baseUpdateFields: UpdateObject<Update> = {};

    if (current.name !== update.name) {
      baseUpdateFields.name = update.name;
    }
    if (current.notes !== update.notes)
      if (update.notes) baseUpdateFields.notes = update.notes;
      else baseUpdateFields.notes = null;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;

    if (!Ownership.optionalEqual(current.ownership, update.ownership))
      if (update.ownership) baseUpdateFields.ownership = update.ownership;
      else baseUpdateFields.ownership = null;
    if (!Owner.optionalEqual(current.beneficiary, update.beneficiary))
      if (update.beneficiary) baseUpdateFields.beneficiary = update.beneficiary;
      else baseUpdateFields.beneficiary = null;
    const { attachments, newImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;

    return { updates: baseUpdateFields, metadata };
  }

  export function validateEncryptedPart(
    data: UpdateObject<EncryptedPart> & {
      attachments?: Attachment.EncryptedPart[];
    },
    _isCreate: boolean = false
  ) {
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }

  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean
  ) {
    if (isCreate || data.value) {
      Amount.validate("value", data.value!);
      if (data.value!.value < 0)
        throw new InvalidInput("Value should be positive");
    }
    if (data.ownership) {
      Ownership.validate(data.ownership);
    }
    if (data.beneficiary) {
      Owner.validate(0, data.beneficiary);
    }
  }

  export function handle(
    state: AccountState<Encrypted>,
    command: Command
  ): Event[] {
    const events: Event[] = [];
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        {
          events.push({
            kind: Event.Kind.AssetCreated,
            executerId: command.executerId,
            asset: command.asset,
          });
          const asset = command.asset as Encrypted;
          if (asset.groupIds && asset.groupIds.length > 0) {
            events.push({
              kind: Event.Kind.GroupsUpdated,
              executerId: command.executerId,
              addIds: asset.groupIds,
              removedIds: [],
            });
          }
          if (asset.ownership) {
            events.push({
              kind: Event.Kind.ShareholderUpdated,
              executerId: command.executerId,
              current: asset.ownership,
            });
          }
          if (asset.beneficiary) {
            events.push({
              kind: Event.Kind.BeneficiaryUpdated,
              executerId: command.executerId,
              current: asset.beneficiary,
            });
          }
          const txId = asset.id;
          const txData = AccountTransaction.systemAccountCreation(
            asset.subtype,
            txId,
            asset.id,
            undefined,
            command.executerId,
            asset.value,
            //#HACK nothing encrypted, and this Transaction cannot update, the decryption will be skipped
            ""
          );
          events.push({
            kind: Event.Kind.TransactionAdded,
            executerId: command.executerId,
            parentId: asset.id,
            id: txId,
            data: txData,
            valueChange: AccountTransaction.getNumericSignedValue(txData),
          });
        }
        break;
      case Command.Kind.UpdateAsset:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          const { executerId, asset, addedToGroup, removedFromGroup } = command;
          events.push({
            executerId: executerId,
            kind: Event.Kind.AssetUpdated,
            asset,
          });

          if (addedToGroup || removedFromGroup) {
            events.push({
              executerId: executerId,
              kind: Event.Kind.GroupsUpdated,
              addIds: addedToGroup ?? [],
              removedIds: removedFromGroup ?? [],
            });
          }

          if ((<Encrypted>asset).ownership) {
            events.push({
              executerId,
              kind: Event.Kind.ShareholderUpdated,
              previous: state.account.ownership,
              current: (<Encrypted>asset).ownership,
            });
          }
          if ((<Encrypted>asset).beneficiary) {
            events.push({
              executerId,
              kind: Event.Kind.BeneficiaryUpdated,
              previous: state.account.beneficiary,
              current: (<Encrypted>asset).beneficiary,
            });
          }
        }
        break;
      case Command.Kind.CloseAsset:
        if (state.account.value.value !== 0) {
          throw new InvalidInput("Account balance is not zero");
        }
        events.push({
          kind: Event.Kind.AccountClosed,
          executerId: command.executerId,
        });
        break;
      case Command.Kind.AddTransaction:
        if (state.account.closedWith) {
          throw new InvalidInput("Account is already closed");
        }
        //#NOTE value check, if required
        events.push({
          kind: Event.Kind.TransactionAdded,
          executerId: command.executerId,
          parentId: state.account.id,
          id: command.id,
          data: command.data,
          valueChange: AccountTransaction.getNumericSignedValue(command.data),
        });
        break;
      case Command.Kind.UpdateTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          events.push({
            kind: Event.Kind.TransactionUpdated,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            update: command.update,
            valueChange: AccountTransaction.calculateValueChange(
              currentTx,
              command.update
            ),
          });
        }
        break;
      case Command.Kind.DeleteTransaction:
        {
          if (state.account.closedWith) {
            throw new InvalidInput("Account is already closed");
          }
          const currentTx = state.transactions[command.id];
          if (!currentTx) {
            throw new InvalidInput("Transaction not found");
          }
          if (currentTx.category === Category.SystemAccountCreation) {
            throw new InvalidInput(
              "Cannot update system account creation transaction"
            );
          }
          events.push({
            kind: Event.Kind.TransactionDeleted,
            executerId: command.executerId,
            parentId: state.account.id,
            id: command.id,
            valueChange: Amount.toNegative(
              AccountTransaction.getNumericSignedValue(currentTx)
            ),
          });
        }
        break;

      case Command.Kind.OverwriteValue:
        //#TODO check if cash can be created from plaid
        throw new Error("check if cash can be created from plaid");
      default:
        throw new Error("unreachable");
    }
    return events;
  }

  export function updateValue(
    account: Encrypted,
    valueChange: MultiCurrencyAmount
  ) {
    account.value.value = addDecimal(
      account.value.value,
      valueChange[account.value.currency] || 0
    );
  }
}
