import { OmitKeys } from "../../utils";
import { Encrypted } from "../../database/encryption";
import {
  NotEncrypted,
  ValidateNestedWithType,
} from "../../decorators/annotations";
import {
  ContactLocationTypeVersion,
  VersionedType,
  type VersionedTypeString,
  validateTypeUpToDate,
} from "../typeVersion";
import { ErrorDataOutDated } from "../error";
import {
  IsExactly,
  IsOptional,
  IsOptionalOnUpdate,
  IsString,
  IsStringNotEmpty,
  Max,
  Min,
  UpdateNotAllowed,
} from "../../decorators";

export class LatLng {
  @NotEncrypted
  @Min(-90, { message: "Invalid center lat" })
  @Max(90, { message: "Invalid center lat" })
  lat!: number;

  @NotEncrypted
  @Min(-180, { message: "Invalid center lng" })
  @Max(180, { message: "Invalid center lng" })
  lng!: number;
}

export class ContactLocation {
  @UpdateNotAllowed
  @NotEncrypted
  @IsExactly(ContactLocationTypeVersion)
  @IsOptionalOnUpdate()
  "@type": VersionedTypeString<VersionedType.ContactLocation, 2>;

  @IsStringNotEmpty({ message: "Address is required" })
  @IsOptionalOnUpdate()
  address!: string;

  @ValidateNestedWithType(LatLng)
  @IsOptionalOnUpdate()
  center!: LatLng;

  @IsOptional()
  @IsString()
  addressLine1?: string;

  @IsOptional()
  @IsString()
  addressLine2?: string;

  @NotEncrypted
  @IsOptional()
  @IsString()
  town?: string;

  @NotEncrypted
  @IsOptional()
  @IsString()
  state?: string;

  @IsString()
  @IsOptionalOnUpdate()
  zipCode!: string;

  @NotEncrypted
  @IsString()
  @IsOptionalOnUpdate()
  country!: string;
}

export namespace ContactLocation {
  //#TODO: Replace this using getEncryptedKeysArray(ContactLocation)
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    "address",
    "center",
    "addressLine1",
    "addressLine2",
    "zipCode",
  ];
  export function assureVersion(
    input: ContactLocation | Encrypted<ContactLocation>,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input as ContactLocation,
      ContactLocationTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.ContactLocation);
  }

  //#TODO: Remove this
  export type EncryptedKeys =
    | "address"
    | "center"
    | "addressLine1"
    | "addressLine2"
    | "zipCode";
  // export type Encrypted = EncryptedType<ContactLocation, EncryptedKeys>;
  export type EncryptedPart = Pick<ContactLocation, EncryptedKeys>;

  export function newWith(
    req: OmitKeys<ContactLocation, "@type">
  ): ContactLocation {
    return {
      ...req,
      "@type": ContactLocationTypeVersion,
    };
  }

  export function equal(a: ContactLocation, b: ContactLocation) {
    return (
      a.address === b.address &&
      a.addressLine1 === b.addressLine1 &&
      a.addressLine1 === b.addressLine2 &&
      a.center.lat === b.center.lat &&
      a.center.lng === b.center.lng &&
      a.country === b.country &&
      a.state === b.state &&
      a.town === b.town &&
      a.zipCode === b.zipCode
    );
  }
}
