import { AssetType, PathsOfDateField } from "./common";
import {
  OmitKeys,
  UpdateObject,
  applyUpdateToObject,
  validateStringNotEmpty,
} from "../utils";
import {
  AggregateBase,
  AggregateRoot,
  IAggregateData,
  RepoAndAggregates,
  setObjectDeleted,
} from "./aggregate";
import { ErrorDataOutDated, InvalidInput } from "./error";
import {} from "./relations";
import { EventBase, EventWithTime, preSealEvent, SharedEvent } from "./event";
import { CommandBase, SharedCommand } from "./command";
import { CoreFirestore, WithFieldValue } from "../../coreFirebase";
import {
  GroupTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";

export type Groupable = Exclude<
  AssetType,
  | AssetType.WineAndSpirits
  | AssetType.WinePurchases
  | AssetType.BankOrInstitution
  | AssetType.Cryptocurrency
>;

//#NOTE ask the global summary thing to sync and get valuation/units and updateAt
// - names require decryption, so they must be fetch from firestore
export interface Group extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.Group, 2>;
  ownerId: string;
  name: string;
  items: GroupItem[];

  createAt: Date;
  updateAt: Date;
}
export interface GroupItem {
  assetId: string;
  assetType: Groupable;
  subtype: string;
}
export namespace GroupItem {
  export function equal(a: GroupItem, b: GroupItem): boolean {
    return (
      a.assetId === b.assetId &&
      a.assetType === b.assetType &&
      a.subtype === b.subtype
    );
  }
}

export namespace Group {
  export function assureVersion(
    input: Group,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(input, GroupTypeVersion, errorOnCoreOutDated);
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Group);
  }

  export const datePaths: readonly PathsOfDateField<Group>[] = [
    "createAt",
    "updateAt",
  ] as const;
  export function convertDate(input: Group): Group {
    CoreFirestore.convertDateFieldsFromFirestore(input, datePaths);
    return input;
  }

  export type CreateFields = OmitKeys<
    Group,
    "@type" | "ownerId" | "version" | "createAt" | "updateAt"
  >;

  export function fromCreate(from: Group.CreateFields, ownerId: string): Group {
    const group: WithFieldValue<Group> = {
      ...from,
      ownerId,
      version: 0,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      "@type": GroupTypeVersion,
    };
    return group as Group;
  }

  export function intoUpdate(
    current: Group,
    update: Group
  ): UpdateObject<Group> {
    const baseUpdateFields: UpdateObject<Group> = {};

    if (current.name !== update.name) {
      baseUpdateFields.name = update.name;
    }

    let itemsChanged = false;
    if (current.items.length !== update.items.length) {
      itemsChanged = true;
    } else {
      for (let i = 0; i < current.items.length; i++) {
        if (!GroupItem.equal(current.items[i], update.items[i])) {
          itemsChanged = true;
          break;
        }
      }
    }
    if (itemsChanged) {
      baseUpdateFields.items = update.items;
    }

    return baseUpdateFields;
  }

  export function newAggregateRoot(state: Group) {
    return new AggregateRoot(new GroupAggregate(state));
  }

  export function defaultValue(): Group {
    const group: Group = {
      id: "",
      ownerId: "",
      name: "",
      items: [],
      createAt: new Date(),
      updateAt: new Date(),
      "@type": GroupTypeVersion,
      version: 0,
    };
    return group;
  }

  export function defaultStateValue(): Group {
    const group: Group = {
      id: "",
      ownerId: "",
      name: "",
      items: [],
      createAt: new Date(),
      updateAt: new Date(),
      "@type": GroupTypeVersion,
      version: 0,
    };
    return group;
  }

  //#TODO need checks
  export async function validate(
    data: Group.CreateFields,
    isCreate: boolean = false
  ) {
    if (
      (isCreate || data.name !== undefined) &&
      !validateStringNotEmpty(data.name)
    ) {
      throw new InvalidInput("Name is required");
    }
  }

  export type RelatedUpdates = {
    [key in Groupable]: { id: string; action: "add" | "remove" }[];
  };
  export type RelatedAggregates = {
    [key in Groupable]?: RepoAndAggregates<any, any, any>;
  };
}

export namespace Command {
  enum CustomKind {
    AddItem = "AddItem",
    RemoveItem = "RemoveItem",
  }
  export type Kind = SharedCommand.Kind | CustomKind;
  export const Kind = {
    ...SharedCommand.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends CommandBase {
    kind: Kind;
  }

  export interface CreateAsset extends SharedCommand.CreateAsset<Group> {}
  export const createAsset = SharedCommand.createAsset<Group>;
  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<UpdateObject<Group>> {}
  export const updateAsset = SharedCommand.updateAsset<UpdateObject<Group>>;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;

  export interface AddItem extends BaseExtended {
    kind: CustomKind.AddItem;
    item: GroupItem;
  }
  export function addItem(executerId: string, item: GroupItem): AddItem {
    return {
      kind: CustomKind.AddItem,
      executerId,
      item,
    };
  }
  export interface RemoveItem extends BaseExtended {
    kind: CustomKind.RemoveItem;
    assetId: string;
    assetType: Groupable;
    subtype: string;
  }
  export function removeItem(
    executerId: string,
    assetId: string,
    assetType: Groupable,
    subtype: string
  ): RemoveItem {
    return {
      kind: CustomKind.RemoveItem,
      executerId,
      assetId,
      assetType,
      subtype,
    };
  }
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.DeleteAsset
  | Command.AddItem
  | Command.RemoveItem;

export namespace Event {
  enum CustomKind {
    ItemAdded = "ItemAdded",
    ItemRemoved = "ItemRemoved",
  }
  export type Kind = SharedEvent.Kind | CustomKind;
  export const Kind = {
    ...SharedEvent.Kind,
    ...CustomKind,
  };

  interface BaseExtended extends EventBase {
    kind: Kind;
  }

  export interface AssetCreated extends SharedEvent.AssetCreated<Group> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<Group>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}

  export interface ItemAdded extends BaseExtended {
    kind: CustomKind.ItemAdded;
    item: GroupItem;
  }

  export interface ItemRemoved extends BaseExtended {
    kind: CustomKind.ItemRemoved;
    assetId: string;
    assetType: Groupable;
    subtype: string;
  }
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.ItemAdded
  | Event.ItemRemoved;

class GroupAggregate extends AggregateBase<Group, Command, Event> {
  state: Group;
  kind: string;
  relatedUpdates: Group.RelatedUpdates = {
    [AssetType.CashAndBanking]: [],
    [AssetType.TraditionalInvestments]: [],
    [AssetType.OtherInvestment]: [],
    [AssetType.Insurance]: [],
    [AssetType.Property]: [],
    [AssetType.Art]: [],
    [AssetType.OtherCollectables]: [],
    [AssetType.Belonging]: [],
  };

  constructor(state: Group) {
    super();
    this.state = state;
    this.kind = "Groups";
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return [
          preSealEvent({
            executerId: command.executerId,
            kind: Event.Kind.AssetCreated,
            asset: command.asset,
          }),
        ];
      case Command.Kind.UpdateAsset:
        return [
          preSealEvent({
            executerId: command.executerId,
            kind: Event.Kind.AssetUpdated,
            asset: command.asset,
          }),
        ];
      case Command.Kind.DeleteAsset:
        return [
          preSealEvent({
            executerId: command.executerId,
            kind: Event.Kind.AssetDeleted,
          }),
        ];
      case Command.Kind.AddItem:
        return [
          preSealEvent({
            executerId: command.executerId,
            kind: Event.Kind.ItemAdded,
            item: command.item,
          }),
        ];
      case Command.Kind.RemoveItem:
        return [
          preSealEvent({
            executerId: command.executerId,
            kind: Event.Kind.ItemRemoved,
            assetId: command.assetId,
            assetType: command.assetType,
            subtype: command.subtype,
          }),
        ];
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state = event.asset;
        event.asset.items.forEach((item) => {
          this.relatedUpdates[item.assetType].push({
            id: item.assetId,
            action: "add",
          });
        });
        break;
      case Event.Kind.AssetUpdated: {
        const itemsBeforeUpdate = [...this.state.items];
        applyUpdateToObject(this.state, event.asset);
        const currentItems = this.state.items;
        currentItems.forEach((item) => {
          const itemExits = itemsBeforeUpdate.some(
            (i) => i.assetId == item.assetId
          );
          if (!itemExits) {
            this.relatedUpdates[item.assetType].push({
              id: item.assetId,
              action: "add",
            });
          }
        });
        itemsBeforeUpdate.forEach((item) => {
          const itemExits = currentItems.some((i) => i.assetId == item.assetId);
          if (!itemExits) {
            this.relatedUpdates[item.assetType].push({
              id: item.assetId,
              action: "remove",
            });
          }
        });
        this.state.updateAt = time;
        break;
      }
      case Event.Kind.AssetDeleted:
        this.state = setObjectDeleted(this.state);
        break;
      case Event.Kind.ItemAdded:
        if (this.state.items.some((item) => GroupItem.equal(item, event.item)))
          break;
        this.state.items.push(event.item);
        this.relatedUpdates[event.item.assetType].push({
          id: event.item.assetId,
          action: "add",
        });
        break;
      case Event.Kind.ItemRemoved: {
        const idx = this.state.items.findIndex((item) => {
          return item.assetId === event.assetId;
        });
        if (idx != -1) {
          this.state.items.splice(idx, 1);
          this.relatedUpdates[event.assetType].push({
            id: event.assetId,
            action: "remove",
          });
        }
        break;
      }
    }
    return this;
  }
}
