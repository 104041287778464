import { Refs } from "../refs";
import { Delegate, DelegateData, DelegateInvite } from "../types/delegates";
import type { WithId } from "../types/common";
import {
  Auth,
  CoreFirestore,
  checkAndGetData,
  getQueriedDataWithId,
} from "../../coreFirebase";
import { Params } from "../types/database";

/**
 * Represents the DelegatesData class that handles operations related to delegates in the database.
 */
export class DelegatesData {
  protected readonly refs: Refs;
  protected readonly auth: Auth;
  protected readonly params: Params;

  /**
   * Creates an instance of DelegatesData.
   * @param firestore - The Firestore instance.
   * @param userId - The ID of the user.
   * @param userToken - The ID of the current user.
   * @param refs - The references to the database collections and documents.
   */
  constructor(refs: Refs, params: Params, auth: Auth) {
    this.refs = refs;
    this.params = params;
    this.auth = auth;
  }

  /**
   * Retrieves the delegates for the current user from the database.
   * @returns A Promise that resolves to the Delegates object.
   */
  async getDelegates(): Promise<DelegateData> {
    return CoreFirestore.getDoc(this.refs.UserDelegates).then(checkAndGetData);
  }

  async getInvitesSent(): Promise<WithId<DelegateInvite>[]> {
    const userId = this.auth.currentUser?.uid;
    if (!userId) throw new Error("Cannot retrieve user id");

    return CoreFirestore.getDocsFromCollection(
      this.refs.UserDelegateInvites,
      CoreFirestore.where("ownerId", "==", userId)
    ).then(getQueriedDataWithId);
  }

  /**
   *
   * @returns List of invitations received
   */
  async listInvitations() {
    const email = this.auth.currentUser?.email;
    if (!email) throw new Error("Cannot retrieve email of user");

    const invites = await this.getDelegateeInvites();
    return invites;
  }

  /**
   * Retrieves the list of this user's delegates and invites it sent out.
   * @returns An object containing the delegates and invites.
   * @throws {Error} If the user id cannot be retrieved.
   */
  async listUserDelegates() {
    const userId = this.auth.currentUser?.uid;
    if (!userId) throw new Error("Cannot retrieve user id");

    const [rawInvites, delegates] = await Promise.all([
      CoreFirestore.getDocsFromCollection(
        this.refs.UserDelegateInvites,
        CoreFirestore.where("ownerId", "==", userId)
      ).then(getQueriedDataWithId),
      (await CoreFirestore.getDoc(this.refs.UserDelegates)).data() ?? {},
    ]);


    const invites = rawInvites.map(fixFirestoreDates)

    return { delegates, invites };
  }

  /**
   * Sets the delegates data for the current user in the database.
   * @param data - The delegates data to be set.
   * @returns A Promise that resolves when the data is set.
   */
  async setDelegates(data: DelegateData): Promise<void> {
    await CoreFirestore.setDoc(this.refs.UserDelegates, data);
  }

  /**
   * Lists all users that the current user is a delegate of.
   * @returns A Promise that resolves to an array of Delegates objects.
   */
  async listDelegates(): Promise<WithId<Delegate.EncryptedBoth>[]> {
    const userId = this.auth.currentUser?.uid;
    if (!userId) throw new Error("Cannot retrieve user id");

    const docs = await CoreFirestore.getDocsFromCollection(
      this.refs.Delegates,
      CoreFirestore.where(`${userId}`, "!=", null)
    );
    const delegates = getQueriedDataWithId(docs);

    return delegates.map(({ id, ...delegate }) => ({
      id,
      ...delegate[userId],
    }));
  }

  async createInvite(
    email: string,
    name: string,
    permissions: Delegate.EncryptedDelegator
  ): Promise<void> {
    const resp = await this.callCloudFunction("delegateCreateInvite", {
      email,
      name,
      permissions,
    });
    if (resp.status !== 200) throw Error(await resp.text());
  }

  async deleteInvite(inviteId: string) {
    await this.callCloudFunction("delegateDeleteInvite", { inviteId });
  }

  async acceptInvite(inviteId: string, encryptedPart: string, ivsalt: string) {
    await this.callCloudFunction("delegateAcceptInvite", {
      inviteId,
      encryptedPart,
      ivsalt,
    });
  }

  async rejectInvite(inviteId: string) {
    await this.callCloudFunction("delegateRejectInvite", { inviteId });
  }

  async getDelegatorName(inviteId: string): Promise<string> {
    const resp = await this.callCloudFunction("delegateGetName", { inviteId });
    const json = await resp.json();
    return json.delegatorName;
  }

  async getDelegateeInvites(): Promise<WithId<DelegateInvite>[]> {
    const resp = await this.callCloudFunction("delegateInvitesReceived", {});
    const json = await resp.json();
    return json.invites;
  }

  async editDelegate(id: string, permissions: Delegate.EncryptedDelegator) {
    await this.callCloudFunction("delegateEdit", {
      delegateId: id,
      delegate: permissions,
    });
  }

  async revokeDelegate(delegateId: string, name: string, email: string) {
    await this.callCloudFunction("delegateRevoke", { delegateId, name, email });
  }

  async reinstateDelegate(delegateId: string) {
    await this.callCloudFunction("delegateReinstate", { delegateId });
  }

  async deleteDelegate(delegateId: string) {
    await this.callCloudFunction("delegateDelete", { delegateId });
  }

  async callCloudFunction(method: string, body: any): Promise<Response> {
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const resp = fetch(`${this.params.cfEndpoint}/${method}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(body),
    });

    return resp;
  }
}


function fixFirestoreDates(invite: WithId<DelegateInvite>): WithId<DelegateInvite> {
  invite.expiration = invite.expiration ? dateFromFirestore(invite.expiration) : undefined;
  return invite;
}

function dateFromFirestore(timestamp: any): Date {
  return timestamp.toDate();
}