import { useState } from 'react'
import { InfoIcon, Loader2Icon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { currencyOptions } from 'core/remodel/types/options'
import { Preferences } from 'core/remodel/types/user'
import { fetchPreferences, updatePreferences, userQuery } from '@/api/AccountService'
import { useDebounce } from '@/hooks/useDebounce'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { Button, FormSelect, Modal, SelectItem, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'

export type PreferenceValues = Omit<Preferences, 'notification' | 'ivSalt'>

export default function BaseCurrencySelector() {
  const [action, setActon] = useState<'confirm' | 'success' | null>(null)
  const modalAction = useDebounce(action, 100)
  const { t } = useTranslation()
  const { toast } = useToast()
  const database = useAuthStore((state) => state.database)
  const { data: preferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting }
  } = useForm<PreferenceValues>({
    values: preferences
  })

  const onUpdate = async (data: PreferenceValues) => {
    try {
      await updatePreferences(database!, data)
      setActon('success')
    } catch (e) {
      e instanceof Error && toast({ variant: 'error', description: e.message })
    }
  }
  return preferences && !preferences?.baseCurrency ? (
    <>
      <Modal className={'max-w-xl'}>
        <Modal.Content className={'gap-4'}>
          <div className={'flex min-h-[320px] flex-col items-center justify-center gap-y-4 bg-finances-map bg-cover'}>
            <div className={'flex flex-col items-center gap-y-4'}>
              <p className={'text-2xl text-primary'}>{t('WelcomeToMyAssets')}</p>
              <p className={'text-center text-text'}>{t('SetBaseCurrency')}</p>
              <div className={'flex items-center gap-4'}>
                <FormSelect
                  control={control}
                  name={'baseCurrency'}
                  className={'w-40'}
                  placeholder={t('Select')}
                  rules={{ required: true }}
                >
                  {currencyOptions.map((option) => (
                    <SelectItem key={option.value} value={`${option.value}`}>
                      {option.label}
                    </SelectItem>
                  ))}
                </FormSelect>

                <Tooltip>
                  <TooltipTrigger asChild={true}>
                    <InfoIcon className={'cursor-pointer fill-primary text-white'} size={24} />
                  </TooltipTrigger>
                  <TooltipContent className={'rounded border-0 bg-primary px-2 py-1'}>
                    <span className={'whitespace-pre text-xs font-medium text-white'}>{t('SelectCantBeReserved')}</span>
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
          </div>
          <fieldset className={'flex justify-end gap-x-2'}>
            <Button
              className={'group relative min-w-[130px]'}
              variant={'solid'}
              size={'md'}
              type={'submit'}
              onClick={() => setActon('confirm')}
              disabled={!watch('baseCurrency')}
            >
              {t('Confirm')}
            </Button>
          </fieldset>
        </Modal.Content>
      </Modal>

      {action === 'confirm' && (
        <Modal className={'max-w-md'}>
          <Modal.Header className={'bg-primary'}>
            <label className={'text-sm font-medium uppercase text-white'}>{t('ConfirmSelection')}</label>
          </Modal.Header>
          <Modal.Content>
            <div className={'space-y-4'}>
              <div className={'flex min-h-[50px] flex-col items-center justify-center gap-4'}>
                <Trans
                  t={t}
                  i18nKey={'ConfirmBaseCurrencyContent'}
                  parent={(props: any) => (
                    <span className={'max-w-[416px] break-words text-center text-sm text-gray-600'} {...props} />
                  )}
                  values={{ currency: watch('baseCurrency') }}
                  components={{
                    bold: <strong />
                  }}
                />
              </div>
              <fieldset className={'flex justify-center gap-2'} disabled={isSubmitting}>
                <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={() => setActon(null)}>
                  {t('Cancel')}
                </Button>
                <Button
                  className={'group relative min-w-[130px]'}
                  variant={'solid'}
                  size={'md'}
                  onClick={handleSubmit(onUpdate)}
                >
                  <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                  <span className={'group-disabled:opacity-0'}>{t('Confirm')}</span>
                </Button>
              </fieldset>
            </div>
          </Modal.Content>
        </Modal>
      )}
    </>
  ) : (
    modalAction === 'success' && (
      <Modal className={'max-w-md'}>
        <Modal.Header className={'bg-primary'}>
          <label className={'text-sm font-medium uppercase text-white'}>{t('success')}</label>
        </Modal.Header>
        <Modal.Content>
          <div className={'space-y-4'}>
            <div className={'flex min-h-[50px] flex-col justify-center gap-4'}>
              <Trans
                t={t}
                i18nKey={'SuccessBaseCurrencyContent'}
                parent={(props: any) => (
                  <span className={'max-w-[416px] break-words text-center text-sm text-gray-600'} {...props} />
                )}
                values={{ currency: preferences?.baseCurrency }}
                components={{
                  bold: <strong />
                }}
              />
            </div>

            <fieldset className={'flex justify-center gap-2'}>
              <Button
                className={'group relative min-w-[130px]'}
                variant={'solid'}
                size={'md'}
                onClick={() => setActon(null)}
              >
                <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                <span className={'group-disabled:opacity-0'}>{t('Confirm')}</span>
              </Button>
            </fieldset>
          </div>
        </Modal.Content>
      </Modal>
    )
  )
}
