import { ExchangeRateDataMap } from 'core/remodel/database/exchangeRate'
import type { Amount, Currency } from 'core/remodel/types/common'

const jsonReviver =
  (targetExchangeRatesMap: ExchangeRateDataMap, targetCurrency: Currency) => (_key: string, value: any) => {
    try {
      const amountKeys = ['value', 'currency'].sort()
      const valueKeys = value instanceof Object ? Object.keys(value).sort() : []

      const isAmount = JSON.stringify(amountKeys) === JSON.stringify(valueKeys)
      if (!isAmount) throw 'Not an amount'

      const { value: amountValue, currency: amountCurrency } = value as Amount
      if (amountCurrency === targetCurrency) throw 'Same currency'

      const exRate = targetExchangeRatesMap[amountCurrency]?.rate
      if (!Boolean(exRate)) throw `Exchange rate from ${amountCurrency} to ${targetCurrency} not found.`

      return { value: amountValue * exRate, currency: targetCurrency }
    } catch (e) {
      return value
    }
  }

export function convertCurrency<T>(data: T, exchangeRates: ExchangeRateDataMap, targetCurrency: Currency): T {
  if (!data || !exchangeRates || !exchangeRates[targetCurrency]) {
    throw new Error('Invalid or missing data, exchange rates, or target currency.')
  }
  const stringData = JSON.stringify(data)
  const convertedData = JSON.parse(stringData, jsonReviver(exchangeRates, targetCurrency))

  return convertedData
}
