import { createContext, useContext } from 'react'
import { useJsApiLoader, type Libraries } from '@react-google-maps/api'

interface MapState {
  isLoaded: boolean
}

const MapContext = createContext<MapState>({
  isLoaded: false
})

interface MapProviderProps {
  children?: React.ReactNode | React.ReactNode[] | undefined
}

const libraries: Libraries = ['places']

export function MapProvider({ children }: MapProviderProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '',
    language: 'en',
    libraries
  })

  return <MapContext.Provider value={{ isLoaded }}>{children}</MapContext.Provider>
}

export function useMap() {
  return useContext(MapContext)
}
