import { Amount, AssetType, TargetCurrencyExchangeRateDataMap } from "./common";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import { Event as CryptoEvent, Coin } from "./cryptocurrencies";
import { EventEnvelope } from "./event";
import { AllowedDecimalPlaces, addDecimal } from "../utils";
import Decimal from "decimal.js";
import { PriceSource } from "../database/priceSource";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  CryptocurrencySummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<CryptoEvent>;
export interface CryptocurrencySummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.CryptocurrencySummary, 2>;
  id: AssetType.Cryptocurrency;
  accounts: {
    [id: string]: {
      coins: {
        [coinName: string]: Pick<Coin, "unit" | "updateAt" | "investedValue">;
      };
      ownedPercentage: number;
    };
  };
}
export namespace CryptocurrencySummary {
  export function assureVersion(
    input: CryptocurrencySummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      CryptocurrencySummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export type CoinInSummary = Pick<
    Coin,
    "coinName" | "unit" | "updateAt" | "investedValue"
  > & {
    value: Amount;
  };
  export type Display = {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;

    coins: CoinInSummary[];
  };
  export async function toDisplay(
    input: CryptocurrencySummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    priceSource: PriceSource
  ): Promise<Display> {
    const currency = exchangeRate.targetCurrency;
    const coinNames = new Set<string>();
    let totalAssets = new Decimal(0);
    const coins: {
      [coinName: string]: CoinInSummary;
    } = {};

    Object.values(input.accounts).map((account) =>
      Object.keys(account.coins).map((coinName) => coinNames.add(coinName))
    );
    const cryptoPriceMap = await priceSource.getCryptoPrice(
      Array.from(coinNames)
    );
    Object.values(input.accounts).map((account) => {
      Object.entries(account.coins).map(([coinName, coin]) => {
        const rate = exchangeRate.rates[cryptoPriceMap[coinName].currency].rate;
        const baseInvestedValue = {
          currency: exchangeRate.targetCurrency,
          value: new Decimal(coin.investedValue.value)
            .mul(exchangeRate.rates[coin.investedValue.currency].rate)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .toNumber(),
        };
        const value = new Decimal(coin.unit)
          .mul(cryptoPriceMap[coinName].value)
          .mul(rate)
          .mul(account.ownedPercentage)
          .div(100)
          .toDecimalPlaces(AllowedDecimalPlaces);
        totalAssets = value.add(totalAssets);
        if (coins[coinName]) {
          coins[coinName].unit = addDecimal(
            coins[coinName].unit,
            coin.unit
          ).toString();
          //#QUESTION use which updateAt
          if (coin.updateAt > coins[coinName].updateAt) {
            coins[coinName].updateAt = coin.updateAt;
          }
          coins[coinName].investedValue.value = addDecimal(
            coins[coinName].investedValue.value,
            baseInvestedValue.value
          );
          coins[coinName].value.value = addDecimal(
            coins[coinName].value.value,
            value
          );
        } else {
          coins[coinName] = {
            coinName,
            unit: coin.unit,
            updateAt: coin.updateAt,
            investedValue: baseInvestedValue,
            //#QUESTION should we consider ownership here
            value: {
              currency,
              value: value.toNumber(),
            },
          };
        }
      });
    });

    return {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: 0,
      },
      netValue: {
        currency,
        value: totalAssets.toNumber(),
      },
      coins: Object.values(coins),
    };
  }
  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<CryptocurrencySummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    convertDate(summary);
    return new AggregateRoot(new CryptocurrencySummaryAggregate(summary));
  }

  export function defaultValue(): CryptocurrencySummary {
    return {
      "@type": CryptocurrencySummaryTypeVersion,
      id: AssetType.Cryptocurrency,
      accounts: {},
      version: 0,
    };
  }

  export function convertDate(input: CryptocurrencySummary) {
    Object.values(input.accounts).forEach((account) => {
      Object.values(account.coins).forEach((coin) => {
        coin.updateAt = (coin.updateAt as any).toDate();
      });
    });
  }

  export type GlobalDashboardData = {
    [coin: string]: {
      unit: string;
      ownedUnit: string;
    };
  };
  export function toGlobalDashboardData(input: CryptocurrencySummary) {
    const result: GlobalDashboardData = {};
    Object.values(input.accounts).forEach((account) => {
      Object.entries(account.coins).forEach(([coin, coinData]) => {
        if (!result[coin]) {
          result[coin] = {
            unit: "0",
            ownedUnit: "0",
          };
        }
        result[coin].unit = addDecimal(
          result[coin].unit,
          coinData.unit
        ).toString();
        result[coin].ownedUnit = new Decimal(coinData.unit)
          .mul(account.ownedPercentage)
          .div(100)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .add(result[coin].ownedUnit)
          .toString();
      });
    });
    return result;
  }

  export type RelatedUpdates = never;
}

export class CryptocurrencySummaryAggregate extends AggregateBase<
  CryptocurrencySummary,
  never,
  CryptoEvent
> {
  state: CryptocurrencySummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: CryptocurrencySummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case CryptoEvent.Kind.AssetCreated:
        {
          const asset = event.data.asset;
          const coins: CryptocurrencySummary["accounts"][string]["coins"] = {};
          asset.coins.forEach((coin) => {
            coins[coin.coinName] = {
              unit: coin.unit,
              updateAt: coin.updateAt,
              investedValue: coin.investedValue,
            };
          });
          this.state.accounts[event.aggregateId] = {
            coins,
            ownedPercentage: asset.ownership?.myOwnership || 100,
          };
        }
        break;
      case CryptoEvent.Kind.AssetUpdated:
        {
          const updates = event.data.asset;
          const account = this.state.accounts[event.aggregateId];
          if (updates.ownership)
            account.ownedPercentage = updates.ownership.myOwnership;
          if (updates.coins) {
            const coins: CryptocurrencySummary["accounts"][string]["coins"] =
              {};
            updates.coins.forEach((coin) => {
              coins[coin.coinName] = {
                unit: coin.unit,
                updateAt: coin.updateAt,
                investedValue: coin.investedValue,
              };
            });
            account.coins = coins;
          }
        }
        break;
      case CryptoEvent.Kind.AssetDeleted:
        delete this.state.accounts[event.aggregateId];
        break;
    }
    return this;
  }
}
