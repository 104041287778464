import { MeasurementUnits } from "../common";
import { type Encrypted as GenericEncrypted } from "../../database/encryption";
import { Room } from "./room";
import { Bedroom, Bathroom, OtherRoom, CarPark } from "./room";
import { EncryptableObject, NotEncrypted } from "../../decorators/annotations";
import { EncryptedType, RequireEncryptionFields } from "../../encryption/utils";
import { IsArray, IsEnum, IsOptionalOnUpdate, Min } from "../../decorators";

export class Configuration {
  @NotEncrypted
  @IsEnum(MeasurementUnits)
  @IsOptionalOnUpdate()
  unit!: MeasurementUnits;

  @NotEncrypted
  @Min(0, { message: "InternalArea cannot be negative" })
  @IsOptionalOnUpdate()
  internalArea!: number;

  @NotEncrypted
  @Min(0, { message: "PatiosArea cannot be negative" })
  @IsOptionalOnUpdate()
  patiosArea!: number;

  @NotEncrypted
  @Min(0, { message: "LandArea cannot be negative" })
  @IsOptionalOnUpdate()
  landArea!: number;

  @EncryptableObject(Bedroom)
  @IsArray()
  @IsOptionalOnUpdate()
  bedroom!: Bedroom[];

  @EncryptableObject(Bathroom)
  @IsArray()
  @IsOptionalOnUpdate()
  bathroom!: Bathroom[];

  @EncryptableObject(OtherRoom)
  @IsArray()
  @IsOptionalOnUpdate()
  otherRoom!: OtherRoom[];

  @EncryptableObject(CarPark)
  @IsArray()
  @IsOptionalOnUpdate()
  carPark!: CarPark[];
}

export namespace Configuration {
  export function roomIdExists(
    configuration: Configuration | Encrypted,
    id: string
  ): boolean {
    return (<Room[]>[
      ...configuration.bedroom,
      ...configuration.bathroom,
      ...configuration.otherRoom,
      ...configuration.carPark,
    ]).some((room) => room.id === id);
  }

  export type Encrypted = RequireEncryptionFields<
    Configuration,
    {
      bedroom: EncryptedType<Bedroom, Room.EncryptedKeys>[];
      bathroom: EncryptedType<Bathroom, Room.EncryptedKeys>[];
      otherRoom: EncryptedType<OtherRoom, Room.EncryptedKeys>[];
      carPark: EncryptedType<CarPark, Room.EncryptedKeys>[];
    }
  >;

  export function defaultValue<
    T extends Configuration | GenericEncrypted<Configuration>
  >(): T {
    return <T>(<any>{
      unit: MeasurementUnits.Metric,
      internalArea: 0,
      patiosArea: 0,
      landArea: 0,
      //#HACK the encryption fields are all in array, and the array is empty on creation
      bedroom: [],
      bathroom: [],
      otherRoom: [],
      carPark: [],
    });
  }
}
