import {
  Amount,
  AssetType,
  AssetV2,
  Attachment,
  Beneficiary,
  Owner,
  Ownership,
  PathsOfDateField,
} from "./common";
import {
  EncryptedType,
  EncryptionField,
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  IVSaltFieldDefaultValue,
  IVSaltFieldKey,
  RequireEncryptionFields,
  doRemoveEncryptedFields,
  fullObjectEncryption,
  fullObjectEncryptionNotStrict,
  removeEncryptionFields,
} from "../encryption/utils";
import {
  OmitKeys,
  SimpleTypeKeysOf,
  UpdateObject,
  addCryptoUnit,
  applyUpdateToObject,
  buildObjectUpdate,
  validateStringNotEmpty,
} from "../utils";
import { AggregateBase, AggregateRoot, setObjectDeleted } from "./aggregate";
import { ErrorDataOutDated, InvalidInput } from "./error";
import { EventBase, EventWithTime, preSealEvent, SharedEvent } from "./event";
import { SharedCommand } from "./command";
import Decimal from "decimal.js";
import { Encryption } from "../database/encryption";
import { CoreFirestore, WithFieldValue } from "../../coreFirebase";
import {
  CryptocurrencyTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";

export type MultiCoinUnit = {
  [coinName: string]: string;
};
export interface Coin {
  id: string;
  coinName: string; //#NOTE would be better to use enum
  unit: string; //#NOTE unit of cryptocurrency can have 18 decimal places, will do calculation with Decimal(coin.unit)
  investedValue: Amount;

  //#TODO these are the same fields used in portfolio for price query,
  //should be filled by frontend and should not be optional when it's ready
  isin?: string | null;
  symbol?: string;

  createAt: Date;
  updateAt: Date;
}
export namespace Coin {
  export function validate(coin: Coin) {
    if (!validateStringNotEmpty(coin.id))
      throw new InvalidInput("Coin id is required");
    if (!validateStringNotEmpty(coin.coinName))
      throw new InvalidInput("Coin name is required");
    if (!validateStringNotEmpty(coin.unit) || new Decimal(coin.unit).isNaN())
      throw new InvalidInput("Coin unit is should be a string of number");
    if (!coin.investedValue || coin.investedValue.value <= 0)
      throw new InvalidInput("Invested value is required");
    Amount.validate("investedValue", coin.investedValue);
  }

  export function getValue(coins: Coin[]): MultiCoinUnit {
    const result: MultiCoinUnit = {};
    coins.forEach((coin) => {
      if (result[coin.coinName])
        result[coin.coinName] = addCryptoUnit(coin.unit, result[coin.coinName]);
      else result[coin.coinName] = coin.unit;
    });
    return result;
  }

  export function equal(a: Coin, b: Coin): boolean {
    return (
      a.id === b.id &&
      a.coinName === b.coinName &&
      a.unit === b.unit &&
      Amount.equal(a.investedValue, b.investedValue)
    );
  }
}

export interface CryptoUnit {
  coinName: string;
  unit: string;

  //#TODO these are the same fields used in portfolio for price query,
  //should be filled by frontend and should not be optional when it's ready
  isin?: string | null;
  symbol?: string;
}

export interface Cryptocurrency
  extends OmitKeys<AssetV2, "value" | "groupIds"> {
  "@type": VersionedTypeString<VersionedType.Cryptocurrency, 2>;
  assetType: AssetType.Cryptocurrency;
  subtype: "-";

  value: MultiCoinUnit;
  coins: Coin[];

  ownership?: Ownership;
  beneficiary?: Beneficiary;
}
export namespace Cryptocurrency {
  export function assureVersion(
    input: Cryptocurrency | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      CryptocurrencyTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Cryptocurrency);
  }

  export const datePaths: readonly PathsOfDateField<Cryptocurrency>[] = [
    "createAt",
    "updateAt",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Cryptocurrency> {
    const decrypted = await Cryptocurrency.decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    decrypted.coins.forEach((coin) => {
      CoreFirestore.convertDateFieldsFromFirestore(coin, [
        "createAt",
        "updateAt",
      ]);
    });
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Cryptocurrency,
    | "@type"
    | "ownerId"
    | "version"
    | "createAt"
    | "updateAt"
    | "value"
    | "valueSourceId"
    | "coins"
  > & { coins: OmitKeys<Coin, "createAt" | "updateAt">[] };
  export type UpdateInReq = Pick<
    Cryptocurrency,
    "name" | "coins" | "ownership" | "beneficiary" | "attachments"
  >;
  export type UpdateInCmd = RequireEncryptionFields<
    EncryptedType<UpdateInReq, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;

  export type EncryptedKeys = Exclude<AssetV2.EncryptedKeys, "notes">;
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Cryptocurrency, EncryptedKeys>,
    {
      attachments?: Attachment.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<Cryptocurrency, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] =
    [] as const;

  export function fromCreate(
    from: Cryptocurrency.CreateFields,
    ownerId: string
  ): Cryptocurrency {
    const createAt = new Date();
    const coins = from.coins.map((coin) => ({
      ...coin,
      createAt,
      updateAt: createAt,
    }));

    const cryptocurrency: WithFieldValue<Cryptocurrency> = {
      ...from,
      "@type": CryptocurrencyTypeVersion,
      version: 0,
      ownerId,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      coins,
      value: Coin.getValue(coins),
    };
    return cryptocurrency as Cryptocurrency;
  }

  // NON-OPTIONAL
  // OPTIONAL
  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Cryptocurrency>[] =
    ["name"];
  export function intoUpdate(
    current: Cryptocurrency,
    update: Cryptocurrency
  ): {
    updates: UpdateObject<Cryptocurrency>;
    metadata: {
      addedToGroup?: string[];
      removedFromGroup?: string[];
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      NonOptionalSimpleTypeUpdatableKeys,
      []
    );

    if (!Ownership.optionalEqual(current.ownership, update.ownership))
      if (update.ownership) baseUpdateFields.ownership = update.ownership;
      else baseUpdateFields.ownership = null;
    if (!Owner.optionalEqual(current.beneficiary, update.beneficiary))
      if (update.beneficiary) baseUpdateFields.beneficiary = update.beneficiary;
      else baseUpdateFields.beneficiary = null;

    let isCoinsUpdated = false;
    if (current.coins.length == update.coins.length) {
      for (let i = 0; i < current.coins.length; i++) {
        if (!Coin.equal(current.coins[i], update.coins[i])) {
          isCoinsUpdated = true;
          break;
        }
      }
    } else {
      isCoinsUpdated = true;
    }

    metadata.shouldEncryptRider = isCoinsUpdated;
    if (isCoinsUpdated) {
      baseUpdateFields.coins = update.coins;
    }

    return { updates: baseUpdateFields, metadata };
  }

  export function removeEncryptedFields<
    T extends Cryptocurrency | UpdateObject<Cryptocurrency>
  >(data: T): OmitKeys<T, EncryptedKeys | "attachments"> {
    const result = doRemoveEncryptedFields(
      data,
      encryptedKeysArray
    ) as OmitKeys<T, EncryptedKeys | "attachments">;
    delete (<any>result).attachments;
    return result;
  }
  export async function encrypt(
    input: Cryptocurrency,
    encryption: Encryption
  ): Promise<Encrypted> {
    const { attachments, ...rest } = (await fullObjectEncryptionNotStrict(
      input,
      encryptedKeysArray,
      encryption
    )) as OmitKeys<Encrypted, "attachments"> & {
      attachments?: Attachment[];
    };
    const result = rest as Encrypted;
    if (attachments) {
      result.attachments = await Attachment.encryptArray(
        attachments,
        encryption
      );
    }
    return result;
  }
  export async function encryptPartial<T extends Cryptocurrency.EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function decrypt(
    data: Cryptocurrency.Encrypted,
    encryption: Encryption
  ): Promise<Cryptocurrency> {
    const EncryptedPart: Cryptocurrency.EncryptedPart =
      await encryption.decryptAndStringify(
        data[EncryptionFieldKey]["data"],
        encryption.convertBase64ToIVSalt(
          data[EncryptionFieldKey][IVSaltFieldKey]
        )
      );
    const result: Cryptocurrency = {
      ...removeEncryptionFields(data),
      ...EncryptedPart,
      attachments: await Attachment.decryptArray(data.attachments, encryption),
    };
    return result;
  }

  export function newAggregateRoot(state: Cryptocurrency.Encrypted) {
    return new AggregateRoot(new CryptocurrencyAggregate(state));
  }

  export function defaultStateValue(): Encrypted {
    return {
      "@type": CryptocurrencyTypeVersion,
      assetType: AssetType.Cryptocurrency,
      subtype: "-",
      id: "",
      name: "",
      ownerId: "",
      version: 0,
      createAt: new Date(0),
      updateAt: new Date(0),
      value: {},
      coins: [],
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue, //`{name:""}`
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
  }

  //#NOTE validate encrypted keys have legal value
  export function validateEncryptedPart(
    data: EncryptedPart & {
      attachments?: Attachment.EncryptedPart[];
    },
    _isCreate: boolean = false
  ) {
    if (data.attachments) {
      data.attachments.forEach((attachment) =>
        Attachment.validateEncryptedPart(attachment)
      );
    }
  }

  //#TODO need checks
  //#NOTE validate data after encrypted
  export function validateEncryptedObj(
    data: UpdateObject<
      OmitKeys<Encrypted, typeof EncryptionFieldKey | "attachments"> & {
        attachments?: Attachment.Validate[];
      }
    >,
    isCreate: boolean = false
  ) {
    //non optional fields, if isCreate, the field must be in the data
    if (isCreate || data.coins !== undefined) {
      data.coins!.forEach((coin, idx) => {
        if (data.coins!.findIndex((c) => c.id === coin.id) != idx)
          throw new InvalidInput("Coin id must be unique");
        Coin.validate(coin);
      });
    }
    //optional fields
    if (data.ownership) {
      Ownership.validate(data.ownership);
    }
    if (data.beneficiary) {
      Owner.validate(0, data.beneficiary);
    }
    if (data.attachments) {
      Attachment.validateEncryptedObj(data.attachments);
    }
  }

  export type RelatedUpdates = Record<string, never>;
}

export namespace Command {
  export type Kind = SharedCommand.Kind;
  export const Kind = SharedCommand.Kind;

  export interface CreateAsset
    extends SharedCommand.CreateAsset<Cryptocurrency.Encrypted> {}
  export const createAsset =
    SharedCommand.createAsset<Cryptocurrency.Encrypted>;
  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<
      UpdateObject<Cryptocurrency.UpdateInCmd>
    > {}
  export const updateAsset = SharedCommand.updateAsset<
    UpdateObject<Cryptocurrency.Encrypted>
  >;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.DeleteAsset;

export namespace Event {
  export type Kind = SharedEvent.Kind;
  export const Kind = SharedEvent.Kind;

  export interface AssetCreated
    extends SharedEvent.AssetCreated<Cryptocurrency.Encrypted> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<
      UpdateObject<Cryptocurrency.UpdateInCmd>
    > {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}
  export interface ValueUpdated extends EventBase {
    kind: SharedEvent.Kind.ValueUpdated;
    previous?: MultiCoinUnit;
    current: MultiCoinUnit;
  }
  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.ValueUpdated
  | Event.GroupsUpdated;

class CryptocurrencyAggregate extends AggregateBase<
  Cryptocurrency.Encrypted,
  Command,
  Event
> {
  state: Cryptocurrency.Encrypted;
  kind: string;
  relatedUpdates: Cryptocurrency.RelatedUpdates = {};

  constructor(state: Cryptocurrency.Encrypted) {
    super();
    this.state = state;
    this.kind = state.assetType;
  }

  handle(command: Command): EventWithTime<Event>[] {
    switch (command.kind) {
      case Command.Kind.CreateAsset:
        return this.handleCreateAsset(command).map(preSealEvent);
      case Command.Kind.UpdateAsset:
        return this.handleUpdateAsset(command).map(preSealEvent);
      case Command.Kind.DeleteAsset:
        return this.handleDeleteAsset(command).map(preSealEvent);
    }
  }

  apply({ data: event, time }: EventWithTime<Event>): this {
    switch (event.kind) {
      case Event.Kind.AssetCreated:
        this.state = event.asset;
        break;
      case Event.Kind.AssetUpdated:
        applyUpdateToObject(this.state, event.asset);
        this.state.updateAt = time;
        break;
      case Event.Kind.AssetDeleted:
        this.state = setObjectDeleted(this.state);
        break;
      case Event.Kind.ValueUpdated:
        this.state.value = event.current;
        break;
    }
    return this;
  }

  private handleCreateAsset({
    executerId,
    asset,
  }: Command.CreateAsset): Event[] {
    Cryptocurrency.validateEncryptedObj(asset, true);

    const events: Event[] = [
      {
        executerId,
        kind: Event.Kind.AssetCreated,
        asset,
      },
    ];
    if (asset.coins) {
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        current: Coin.getValue(asset.coins),
      });
    }
    if (asset.ownership) {
      events.push({
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        current: asset.ownership,
      });
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        current: asset.beneficiary,
      });
    }
    return events;
  }
  private handleUpdateAsset({
    executerId,
    asset,
    addedToGroup,
    removedFromGroup,
  }: Command.UpdateAsset): Event[] {
    AssetV2.checkUpdate(this.state);
    Cryptocurrency.validateEncryptedObj(asset);
    const events: Event[] = [];
    if (asset.coins) {
      events.push({
        executerId,
        kind: Event.Kind.ValueUpdated,
        previous: this.state.value,
        current: Coin.getValue(asset.coins),
      });
    }
    events.push({
      executerId,
      kind: Event.Kind.AssetUpdated,
      asset,
    });

    if (addedToGroup || removedFromGroup) {
      events.push({
        executerId,
        kind: Event.Kind.GroupsUpdated,
        addIds: addedToGroup ?? [],
        removedIds: removedFromGroup ?? [],
      });
    }

    if (asset.ownership) {
      events.push({
        executerId,
        kind: Event.Kind.ShareholderUpdated,
        previous: this.state.ownership,
        current: asset.ownership,
      });
    }
    if (asset.beneficiary) {
      events.push({
        executerId,
        kind: Event.Kind.BeneficiaryUpdated,
        previous: this.state.beneficiary,
        current: asset.beneficiary,
      });
    }

    return events;
  }
  private handleDeleteAsset({ executerId }: Command.DeleteAsset): Event[] {
    AssetV2.checkDelete(this.state);
    return [{ executerId, kind: Event.Kind.AssetDeleted }];
  }
}
