export class DocNotExist extends Error {}
export class AlreadyExist extends Error {}

export class ExRateNotSet extends Error {}

export class InvalidInput extends Error {}
export class DataPoisoned extends Error {}
export class DataOutDated extends Error {}
export function ErrorDataOutDated(type: string) {
  throw new DataOutDated(`Data out-dated: ${type}`);
}
export class CoreOutDated extends Error {}
export function ErrorCoreOutDated(
  type: string,
  dbVersion: number,
  coreVersion: number
) {
  return new CoreOutDated(
    `Core type of "${type}" is out-dated, db: v${dbVersion}, core: v${coreVersion}`
  );
}

export class NotImplemented extends Error {}
