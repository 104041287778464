import { Refs } from "../refs";
import { Activity, ActivityKind } from "../types/activities";
import { SupportActivityType } from "../types/common";
import { getIndexedActivitiesPath } from "../refPaths";
import {
  CollectionReference,
  CoreFirestore,
  QueryConstraint,
  QueryDocumentSnapshot,
  getQueriedData,
} from "../../coreFirebase";
import { Encrypted, Encryption } from "./encryption";

export class ActivityManger {
  private readonly collectionRef: CollectionReference<Activity>;
  private readonly constraints: QueryConstraint[];
  private cursor: QueryDocumentSnapshot | null;
  private encryption: Encryption;

  constructor(
    refs: Refs,
    encryption: Encryption,
    assetType: SupportActivityType,
    batchLimit: number,
    assetId?: string,
    activityKinds?: ActivityKind[]
  ) {
    this.collectionRef = CoreFirestore.collection<Encrypted<Activity>>(
      getIndexedActivitiesPath(assetType)
    );
    this.encryption = encryption;

    this.constraints = [
      CoreFirestore.orderBy("time", "desc"),
      CoreFirestore.orderBy("sortKey"),
      CoreFirestore.limit(batchLimit),
    ];
    if (assetId)
      this.constraints.push(CoreFirestore.where("assetId", "==", assetId));
    else
      this.constraints.push(CoreFirestore.where("ownerId", "==", refs.userId));
    if (activityKinds)
      this.constraints.push(
        CoreFirestore.where("activityKind", "in", activityKinds)
      );

    this.cursor = null;
  }

  async getNext(): Promise<Activity[]> {
    const snapshots = this.cursor
      ? await CoreFirestore.getDocsFromCollection(
          this.collectionRef,
          ...this.constraints,
          CoreFirestore.startAfter(this.cursor)
        )
      : await CoreFirestore.getDocsFromCollection(
          this.collectionRef,
          ...this.constraints
        );

    this.cursor = snapshots.docs[snapshots.docs.length - 1];
    const activities = await Promise.all(
      getQueriedData(snapshots).map(async (v) =>
        Activity.tryDecryptAndConvertDate(v, this.encryption)
      )
    );
    return activities;
  }
}
