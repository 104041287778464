export enum OtherCollectableType {
  ArtworkAndPhotos = "ArtworkAndPhotos",
  AutomotiveAndIndustrial = "AutomotiveAndIndustrial",
  BeautyAndHealth = "BeautyAndHealth",
  BoatsBoatingAndOtherMarine = "BoatsBoatingAndOtherMarine",
  ClothingAndShoes = "ClothingAndShoes",
  CollectablesAndMemorabilia = "CollectablesAndMemorabilia",
  ComputersAndHomeOffice = "ComputersAndHomeOffice",
  Electronics = "Electronics",
  FurnitureAndFurnishings = "FurnitureAndFurnishings",
  GardenPets = "GardenPets",
  Handmade = "Handmade",
  JewelleryAndWatches = "JewelleryAndWatches",
  MiscellaneousHomeItems = "MiscellaneousHomeItems",
  MoviesMusicAndGames = "MoviesMusicAndGames",
  Other = "Other",
  PhotographyEquipment = "PhotographyEquipment",
  SportsAndOutdoors = "SportsAndOutdoors",
  Tools = "Tools",
  ToysKidsAndBaby = "ToysKidsAndBaby",
}

export const otherCollectableTypeValues = Object.values(OtherCollectableType);
