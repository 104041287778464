import { AccountData } from "./database/account";
import { ArtsRepo } from "./database/arts";
import { BelongingsRepo } from "./database/belongings";
import { CashAndBankingRepo } from "./database/cashAndBanking";
import { CryptocurrencyRepo } from "./database/cryptocurrencies";
import { Encryption, EncryptionManager } from "./database/encryption";
import { ExchangeRate, ExchangeRateSource } from "./database/exchangeRate";
import { GroupsRepo } from "./database/groups";
import { InsuranceRepo } from "./database/insurance";
import { OtherInvestmentRepo } from "./database/otherInvestments";
import { PropertiesRepo } from "./database/properties";
import { TraditionalInvestmentRepo } from "./database/traditionalInvestments";
import { WineAndSpiritsRepo } from "./database/wineAndSprits";
import { FullRefs, Refs } from "./refs";
import {
  Currency,
  Optional,
  SupportActivityType,
  type ActualAssetType,
  type SearchFilterResult,
  type SearchResult,
  BackupCode,
  AssetV2,
  PropertyAssets,
} from "./types/common";
import {
  DailyTimeTickerPrice,
  ExchangeRateRawResult,
  ExchangeRateResult,
  PlaidAccount,
  StockSearchInfo,
  StockSearchResult,
} from "./types/postgres";

import {
  Auth,
  CoreFirestore,
  Transaction,
  User,
  checkAndGetData,
  getQueriedData,
} from "../coreFirebase";
import { ActivityManger } from "./database/activities";
import { Attachments } from "./database/attachments";
import { DelegatesData } from "./database/delegates";
import { ActivityKind } from "./types/activities";
import { Art } from "./types/arts";
import type { Account } from "./types/cashAndBanking";
import { Delegate } from "./types/delegates";
import { EncryptionLib } from "./types/encryptionLib";
import { AssetType, CustomizedType } from "./types/enums";
import { wineTypeOptions } from "./types/options";
import { SummaryManager } from "./types/summaryManager";
import { Profile } from "./types/user";
import { RemovalReason, Wine, WineCatalogue } from "./types/wineAndSprits";
import { getBackupCodesPath } from "./refPaths";
import { DbSharedFields, Params } from "./types/database";
import {
  ExportHandler,
  newExportHandler,
  ProgressMetadata,
} from "./database/exportHandler";
import { MockPriceSource } from "./database/priceSource";
import { ComparativeNetWorthReport } from "./types/reports";
import { computeComparativeNetWorthReport } from "./database/report";
import { ClientRepo, Repo } from "./types/aggregate";

export const DEFAULT_BATCH_SIZE = 500
export type BatchUpdateContext<Model, State, Command, TEvent> = {
  model: new () => Model,
  repoFunc: (transaction: Transaction) => Promise<ClientRepo<State, Command, TEvent>>,
  updateFunc: (input: any, repo: ClientRepo<State, Command, TEvent>) => Promise<unknown>,
  amount: number,
  batchSize?: number,
  decorator?: (input: Model, index: number) => Model
  onBatchResult?: (result: BatchResult<Model>) => boolean | void
}
export type BatchResult<T> = { batchNumber: number, batchTotal: number, error: Error | undefined }

export class Database {
  readonly userId: string;
  protected readonly auth: Auth;
  protected readonly refs: FullRefs;
  protected readonly encryptionLib: EncryptionLib;
  protected readonly params: Params;

  readonly Account: AccountData;
  readonly Attachments: Attachments;
  readonly Encryption: EncryptionManager;
  readonly Delegates: DelegatesData;

  readonly group: GroupsRepo;

  readonly belonging: BelongingsRepo;
  readonly otherCollectable: BelongingsRepo;
  readonly wine: WineAndSpiritsRepo;
  readonly art: ArtsRepo;
  readonly property: PropertiesRepo;
  readonly insurance: InsuranceRepo;
  readonly cryptocurrency: CryptocurrencyRepo;
  readonly otherInvestment: OtherInvestmentRepo;
  readonly traditionalInvestment: TraditionalInvestmentRepo;
  readonly cashAndBanking: CashAndBankingRepo;

  //need initialize
  ExRate: ExchangeRate;
  readonly summaryManager: SummaryManager;

  constructor(
    userId: string,
    encryptionLib: EncryptionLib,
    params: Params,
    auth: Auth,
    rateSource?: ExchangeRateSource
  ) {
    this.userId = userId;
    this.auth = auth;
    const selfRefs = new Refs(userId);
    this.refs = { currentRefs: selfRefs, selfRefs };
    this.encryptionLib = encryptionLib;
    this.params = params;

    if (rateSource) this.ExRate = new ExchangeRate(rateSource);
    else this.ExRate = new ExchangeRate(this);
    this.summaryManager = new SummaryManager();

    const encryptionSelf = new Encryption(
      DEKExistsInFirestore(this.refs.currentRefs),
      encryptionLib
    );
    this.Encryption = {
      current: encryptionSelf,
      self: encryptionSelf,
    };

    const shared: DbSharedFields = {
      refs: this.refs,
      encryption: this.Encryption,
      exRate: this.ExRate,
      summaryManager: this.summaryManager,
    };

    this.Attachments = new Attachments(userId, this.refs, this.Encryption);

    this.Account = new AccountData(shared, userId, this.auth);

    this.group = new GroupsRepo(
      this.ExRate,
      this.refs,
      this.Encryption,
      this.summaryManager
    );

    this.belonging = new BelongingsRepo(shared, AssetType.Belonging);
    this.otherCollectable = new BelongingsRepo(
      shared,
      AssetType.OtherCollectables
    );
    this.wine = new WineAndSpiritsRepo(shared);
    this.art = new ArtsRepo(shared);
    this.property = new PropertiesRepo(shared);
    this.insurance = new InsuranceRepo(shared);
    this.cryptocurrency = new CryptocurrencyRepo(shared);
    this.otherInvestment = new OtherInvestmentRepo(shared);
    this.traditionalInvestment = new TraditionalInvestmentRepo(shared);
    this.cashAndBanking = new CashAndBankingRepo(shared);
    this.Delegates = new DelegatesData(
      this.refs.selfRefs,
      this.params,
      this.auth
    );
  }

  genAssetId(): string {
    return CoreFirestore.genAssetId();
  }

  getWineDocumentId(wineCatalogueId: string): string {
    return Wine.buildWineId(this.refs.currentRefs.userId, wineCatalogueId);
  }

  async switchToDelegate(
    data: { id: string; permissions: Delegate.EncryptedDelegator } | null
  ): Promise<void> {
    if (data === null) {
      this.refs.currentRefs = this.refs.selfRefs;
      this.Encryption.current = this.Encryption.self;
      this.summaryManager.setSummaryLoader(this.refs.currentRefs);
    } else {
      const id = data.id;
      this.refs.currentRefs = new Refs(id);
      this.Encryption.current = new Encryption(
        DEKExistsInFirestore(this.refs.currentRefs),
        this.encryptionLib
      );
      // TODO: update encryption object
      const userToken = await this.auth?.currentUser?.getIdToken();
      if (!userToken) throw new Error("User token not found");

      await this.Encryption.current.loadDEK(id, userToken);
      this.summaryManager.setSummaryLoader(
        this.refs.currentRefs,
        data?.permissions
      );
    }
  }

  async getGlobalDashboard(currency?: Currency) {
    return this.summaryManager.getGlobalDashboard(
      this.ExRate,
      this.Encryption.current,
      currency
    );
  }

  getActivityManager(
    assetType: SupportActivityType,
    assetId?: string,
    activityKinds?: ActivityKind[],
    batchLimit: number = 10
  ) {
    return new ActivityManger(
      this.refs.currentRefs,
      this.Encryption.current,
      assetType,
      batchLimit,
      assetId,
      activityKinds
    );
  }

  async initDefaultDocs(param: Pick<Profile, "name" | "email">): Promise<void> {
    const profile: Profile = {
      ...param,
    };
    return this.Account.tryInitData(profile, false);
  }

  async ensureDataExist(user?: User): Promise<void> {
    let profile: Optional<Profile> = undefined;
    if (user && user.displayName && user.email) {
      profile = {
        name: user.displayName,
        email: user.email,
      };
    }
    return this.Account.tryInitData(profile, true);
  }

  async prepareData(): Promise<void> {
    const user = this.auth.currentUser ?? undefined;
    if (user) {
      const token = await user.getIdToken();
      await this.Encryption!.self.loadDEK(user.uid, token);
      // console.log("dek: ", this.Encryption!.self.dek)
    }

    await this.ensureDataExist(user);
    const preferences = await this.Account.tryGetPreferences();
    if (preferences && preferences.baseCurrency) {
      await this.ExRate.getAndSetBaseExRate(preferences.baseCurrency);
    }
  }

  async resetSummaryLoader(): Promise<void> {
    this.summaryManager.resetSummaryLoader(this.refs.currentRefs);
  }

  unsubscribeSummaries() {
    this.summaryManager.unsubscribe();
  }

  /**
   * - Disable user
   * - Cancel subscription if is subscribed
   * @see `cloudfunctions/src/closeAccount.ts` - `closeAccount`
   */
  async closeAccount() {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/closeAccount`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw Error(resp.statusText);
  }

  async reorderMFA(primaryPhoneNumber: string) {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/reorderMFA`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ primaryPhoneNumber }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw Error(resp.statusText);
  }

  /***** one schema *****/
  /**
   * Get OneSchema user token
   */
  async getOneSchemaToken(): Promise<string> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/getOSJwt`;
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw Error(resp.statusText);
    const data = await resp.json();

    const token: string = data.jwt;
    return token;
  }

  /***** plaid *****/
  /**
   * Get plaid link token
   * @see `cloudfunctions/src/plaid.ts` - `plaidLinkToken`
   */
  async getPlaidLinkToken(): Promise<string> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/plaidLinkToken`;
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: string = data.link_token;
    return result;
  }

  /**
   * Get plaid accounts
   * @see `cloudfunctions/src/plaid.ts` - `plaidExchangeQuery`
   */
  async getPlaidAccounts(plaidPublicToken: string): Promise<PlaidAccount[]> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/plaidExchangeQuery`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify({ plaidPublicToken }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: PlaidAccount[] = data;
    return result;
  }

  /***** stripe *****/
  /**
   * make initial subscription payment
   * @see `cloudfunctions/src/stripe.ts` - `createSubscription`
   */
  async addStripeSubscription(
    payload: Record<"options" | "paymentMethod", object>
  ): Promise<Record<"clientSecret" | "status", string>> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/stripeCreateSubscription`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(payload),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: Record<"clientSecret" | "status", string> = {
      clientSecret: data.client_secret,
      status: data.status,
    };
    return result;
  }

  /**
   * Update subscription
   * @see `cloudfunctions/src/stripe.ts` - `updateSubscriptionRequest`
   */
  async updateSubscription(
    payload: Record<"plan" | "seatsWanted", any>
  ): Promise<void> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/stripeUpdateSubscriptionRequest`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(payload),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
  }

  /**
   * Cancel subscription
   * @see `cloudfunctions/src/stripe.ts` - `cancelSubscription`
   */
  async cancelSubscription(): Promise<void> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/stripeCancelSubscription`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
  }

  /**
   * Restart Canceling subscription
   * @see `cloudfunctions/src/stripe.ts` - `restartSubscription`
   */
  async restartSubscription(): Promise<void> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/stripeRestartSubscription`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
  }

  /**
   * Confirm payment intent
   * @see `cloudfunctions/src/stripe.ts` - `confirmPaymentIntent`
   */
  async confirmPaymentIntent(
    payload: Record<"paymentIntentId", string>
  ): Promise<string> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/stripeConfirmPaymentIntent`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
      body: JSON.stringify(payload),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: string = data.status;
    return result;
  }

  /**
   * Get Payment Method Details
   * @see `cloudfunctions/src/stripe.ts` - `getPaymentMethod`
   */
  async getPaymentMethod(): Promise<object> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/stripeGetPaymentMethod`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    return data;
  }

  //#TODO find where this belongs
  async getLatestTimeTickerPrices(
    pairs: StockSearchInfo[]
  ): Promise<StockSearchResult[]> {
    const result: StockSearchResult[] = [];

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];

      const snapshot =
        await CoreFirestore.getDocsFromCollection<DailyTimeTickerPrice>(
          this.refs.currentRefs.TimeTickerPrices,
          CoreFirestore.where("exchange", "==", pair.exchange),
          CoreFirestore.where("code", "==", pair.symbol),
          CoreFirestore.orderBy("date", "desc")
        );
      if (!snapshot.empty && snapshot.docs.length > 0) {
        const doc = snapshot.docs[0];
        const {
          name,
          code,
          exchange,
          currency,
          closePrice,
        }: DailyTimeTickerPrice = doc.data();
        const record: StockSearchResult = {
          nameWithCode: `${name} (${code})`,
          exchange: exchange,
          priceWithCurrency: `${currency} ${closePrice.toFixed(2)}`,
        };
        result.push(record);
      } else {
        throw new Error(
          "Attempt to search fireId which has no stock price record"
        );
      }
    }

    return result;
  }

  /**
   * Create backup codes
   * @see `cloudfunctions/src/backupCode.ts` - `createBackupCodes`
   */
  async createBackupCodes(): Promise<string[]> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const url = `${endpoint}/createBackupCodes`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();
    return data;
  }

  /**
   * Get backup codes
   */
  async getBackupCodes(): Promise<string[]> {
    const ref = CoreFirestore.doc(getBackupCodesPath(this.userId));
    const data = await CoreFirestore.getDoc<BackupCode>(ref).then(
      checkAndGetData
    );
    return data.codes;
  }

  /***** base on antony's api *****/
  /**
   * Get stock prices with query
   * @see `api/bin/api/src/api/stock_search.rs` - `get_stock_info_by_search`
   */
  async getStockPricesWithQuery(keyword: string): Promise<StockSearchResult[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { keyword };
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/stock_search?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const infos = await resp.json();

    const result = this.getLatestTimeTickerPrices(infos);
    return result;
  }

  /***** base on antony's api *****/
  /**
   * Get stock prices with query
   * @see `api/bin/api/src/api/stock_search.rs` - `get_stock_info_by_search`
   */
  async getExchangeRate(
    primary?: Currency,
    secondary?: Currency
  ): Promise<ExchangeRateResult[]> {
    if (primary === undefined && secondary === undefined) {
      throw new Error(
        "Primary and secondary currency cannot both be undefined"
      );
    }

    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params: {
      baseCurrency?: Currency;
      quoteCurrency?: Currency;
    } = {};
    if (primary) params.baseCurrency = primary;
    if (secondary) params.quoteCurrency = secondary;
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/exchange_rate?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const rates = (await resp.json()) as ExchangeRateRawResult[];
    return rates.map(({ baseCurrency, quoteCurrency, rate, date }) => ({
      baseCurrency,
      quoteCurrency,
      rate: parseFloat(rate),
      date: new Date(date),
    }));
  }

  async getRawAssetById<T extends AssetV2>(
    assetType: AssetType,
    id: string
  ): Promise<T> {
    const docRef = this.refs.currentRefs.getAssetDocRef(assetType, id);
    const data = await CoreFirestore.getDoc(docRef).then(checkAndGetData);

    return data as T;
  }

  async getAssetsByIds<T extends { id: string }>(
    assetType: AssetType,
    ids: string[]
  ): Promise<T[]> {
    switch (assetType) {
      case AssetType.CashAndBanking:
        return this.cashAndBanking.getAccountsByIds(ids) as any as Promise<T[]>;
      case AssetType.BankOrInstitution:
        return this.cashAndBanking.getInstitutionsByIds(ids) as any as Promise<
          T[]
        >;
      case AssetType.TraditionalInvestments:
        return this.traditionalInvestment.getPortfoliosByIds(
          ids
        ) as any as Promise<T[]>;
      case AssetType.OtherInvestment:
        return this.otherInvestment.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Cryptocurrency:
        return this.cryptocurrency.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Insurance:
        return this.insurance.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Property:
        return this.property.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Art:
        return this.art.getByIds(ids) as any as Promise<T[]>;
      case AssetType.WineAndSpirits:
        return this.wine.getWinesByIds(ids) as any as Promise<T[]>;
      case AssetType.WinePurchases:
        return this.wine.getWinePurchasesByIds(ids) as any as Promise<T[]>;
      case AssetType.OtherCollectables:
        return this.otherCollectable.getByIds(ids) as any as Promise<T[]>;
      case AssetType.Belonging:
        return this.belonging.getByIds(ids) as any as Promise<T[]>;
    }
  }

  async getAssetsByResults(results: SearchResult[]) {
    return await Promise.all(
      results.map((result) => {
        const { fireId, category, subType } = result;
        switch (category as ActualAssetType) {
          case AssetType.CashAndBanking:
            return this.cashAndBanking.getAccountById(
              fireId,
              subType as Account.Type
            );
          case AssetType.TraditionalInvestments:
            return this.traditionalInvestment.getPortfolioById(fireId);
          case AssetType.OtherInvestment:
            return this.otherInvestment.getById(fireId);
          case AssetType.Cryptocurrency:
            return this.cryptocurrency.getById(fireId);
          case AssetType.Insurance:
            return this.insurance.getById(fireId);
          case AssetType.Property:
            return this.property.getById(fireId);
          case AssetType.Art:
            return this.art.getById(fireId);
          case AssetType.WineAndSpirits:
            return this.wine.getWineById(fireId);
          case AssetType.OtherCollectables:
            return this.otherCollectable.getById(fireId);
          case AssetType.Belonging:
            return this.belonging.getById(fireId);
        }
      })
    );
  }

  private async fetchCurrencyCount(
    isPropertyAssets: boolean,
    endpoint: string,
    queryParams: string,
    config: any
  ) {
    const url_currencyCount = isPropertyAssets
      ? `${endpoint}/v0/firebase_search/property_assets/currency_counts?${queryParams}`
      : `${endpoint}/v0/firebase_search/currency_counts?${queryParams}`;

    const resp_currencyCount = await fetch(url_currencyCount, config);
    if (resp_currencyCount.status !== 200)
      throw new Error(resp_currencyCount.statusText);
    return await resp_currencyCount.json();
  }

  private prepareCurrencyParams(requiredCurrencies?: Currency[]) {
    if (!requiredCurrencies) return [];
    this.ExRate.checkInitialized();
    return requiredCurrencies.map((v: Currency) => [
      MapiSearchParamsType.exchangeRate,
      JSON.stringify({
        currency: v,
        rate: this.ExRate.getToBaseExchangeRate(v).rate.toString(),
      }),
    ]);
  }

  /**
   * Get assets with query
   * @see `api/bin/api/src/api/firebase_search.rs` - `get_firebase_filter_info`
   */
  async getFilterInfo(assetType: AssetType): Promise<SearchFilterResult> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const params = {
      userId: this.refs.currentRefs.userId,
      category: assetType,
    };
    const { requiredCurrencies } = await this.fetchCurrencyCount(
      false,
      endpoint,
      buildQueryParams(params),
      config
    );
    const currencyParams = this.prepareCurrencyParams(requiredCurrencies);
    const queryParams = new URLSearchParams([
      ...Object.entries(params),
      ...(currencyParams || []),
    ]).toString();

    const url = `${endpoint}/v0/firebase_search/filter_info?${queryParams}`;
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();
    const valMin = Math.floor(parseFloat(data.minValue ?? 0));
    const valMax = Math.ceil(parseFloat(data.maxValue ?? 9999999));
    const bottlePriceMin = Math.floor(parseFloat(data.minBottlePrice ?? 0));
    const bottlePriceMax = Math.ceil(
      parseFloat(data.maxBottlePrice ?? 9999999)
    );
    const result: SearchFilterResult = {
      name: data.name ?? [],
      subtype: data.subtype ?? [],
      value: {
        minimum: valMin,
        maximum: valMin === valMax ? valMax + 10 : valMax, // Handle one data case(same value) => add gap value 10
      },
      purchaseAt: {
        minimum: data.minPurchaseAt ? new Date(data.minPurchaseAt) : new Date(),
        maximum: data.maxPurchaseAt ? new Date(data.maxPurchaseAt) : new Date(),
      },
      locationId: data.locationId ?? [],
      masterVarietal: data.masterVarietal,
      artStyle: data.artStyle,
      country: data.country,
      variety: data.varietal,
      brand: data.brand,
      roomId: data.roomId,
      bottlePrice: {
        minimum: bottlePriceMin,
        maximum: bottlePriceMax,
      },
    };

    return result;
  }

  /**
   * Get assets with query
   * @see `api/bin/api/src/api/firebase_search.rs` - `get_firebase_ids_by_search`
   */
  async getAssetsWithQuery<T extends { id: string }>(
    assetType: AssetType,
    query?: Record<MapiSearchParamsType, string | string[]>
  ): Promise<T[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = {
      userId: this.refs.currentRefs.userId,
      category: assetType,
      ...query,
    };
    if (
      params[MapiSearchParamsType.valueLowerBound] != undefined ||
      params[MapiSearchParamsType.valueUpperBound] != undefined
    ) {
      throw new Error("Value filter is not supported");
    }
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/firebase_search?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data: SearchResult[] = await resp.json();

    const ids: string[] = data.map(({ fireId: id, category }) => {
      if (category === AssetType.WineAndSpirits) {
        return Wine.tryDestructDocId(id)?.wineId ?? id;
      } else {
        return id;
      }
    });
    const result = this.getAssetsByIds<T>(assetType, ids);
    return result;
  }

  /**
   * Get assets with query
   * @see `api/bin/api/src/api/firebase_search.rs` - `get_firebase_ids_by_search`
   */
  async getAssets(query?: Record<MapiSearchParamsType, string | string[]>) {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { userId: this.userId, ...query };
    if (
      params[MapiSearchParamsType.valueLowerBound] != undefined ||
      params[MapiSearchParamsType.valueUpperBound] != undefined
    ) {
      throw new Error("Value filter is not supported");
    }
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/firebase_search?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data: SearchResult[] = await resp.json();

    return this.getAssetsByResults(data);
  }

  /**
   * Get assets with query counts
   * @see `api/bin/api/src/api/firebase_search.rs` - `get_firebase_id_counts_by_search`
   */
  async getAssetsWithQueryCounts(
    assetType: AssetType,
    query?: Record<MapiSearchParamsType, any>
  ): Promise<number> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = {
      userId: this.refs.currentRefs.userId,
      category: assetType,
      ...query,
    };
    if (
      params[MapiSearchParamsType.valueLowerBound] != undefined ||
      params[MapiSearchParamsType.valueUpperBound] != undefined
    ) {
      throw new Error("Value filter is not supported");
    }
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/firebase_search/counts?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const counts: number = data;
    return counts;
  }

  async getAssetsWithQueryV2<T extends { id: string }>(
    assetType: AssetType | undefined,
    propertyId: string | undefined,
    query?: Record<MapiSearchParamsType, any>
  ): Promise<SearchAssetResult<T>> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    // two use cases:
    // 1. fetchAssets by assetType
    // 2. fetchAssets by propertyId
    if ((!assetType && !propertyId) || (assetType && propertyId)) {
      throw new Error("Exactly one parameter must be provided.");
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    // 1. build queryParams with basic params and currency params
    const params = {
      userId: this.refs.currentRefs.userId,
      ...(assetType ? { category: assetType } : { locationId: propertyId }),
      ...query,
    };
    const { count: totalCount, requiredCurrencies } =
      await this.fetchCurrencyCount(
        false,
        endpoint,
        buildQueryParams(params),
        config
      );
    const currencyParams = new URLSearchParams(
      this.prepareCurrencyParams(requiredCurrencies) || []
    ).toString();
    const queryParams = `${buildQueryParams(params)}&${currencyParams}`;

    // 2. build url and fetch data
    const searchEndpoint = requiredCurrencies
      ? "firebase_search/search_with_value"
      : "firebase_search";
    const searchUrl = `${endpoint}/v0/${searchEndpoint}?${queryParams}`;
    const response = await fetch(searchUrl, config);
    if (response.status !== 200) throw new Error(response.statusText);
    const searchResults: SearchResult[] = await response.json();
    const assetIds = searchResults.map(({ fireId: id, category }) =>
      category === AssetType.WineAndSpirits
        ? Wine.tryDestructDocId(id)?.wineId ?? id
        : id
    );

    // 3. determine the fetch case
    const isWinePurchaseFilter =
      assetType === AssetType.WineAndSpirits &&
      searchResults.some((item) => item.purchases);

    let resultList: T[] = [];
    if (!assetType) {
      //fetchAssetsOfProperty
      resultList = (await this.getAssetsByResults(searchResults)) as any as T[];
    } else if (isWinePurchaseFilter) {
      //fetchAssets-Wine
      const wines = await this.getAssetsByIds<Wine>(assetType, assetIds);
      resultList = filterWinePurchases(wines, searchResults) as any as T[];
    } else {
      //fetchAssets
      resultList = await this.getAssetsByIds<T>(assetType, assetIds);
    }

    return {
      totalCount,
      list: resultList,
    };
  }

  async getPropertyAssets<T extends { id: string }>(
    keyword: string,
    locationId: string,
    query?: Record<MapiSearchParamsType, any>
  ): Promise<SearchAssetResult<T>> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const params = {
      userId: this.refs.currentRefs.userId,
      keyword,
      locationId,
      ...query,
    };

    const { count: totalCount, requiredCurrencies } =
      await this.fetchCurrencyCount(
        true,
        endpoint,
        buildQueryParams(params),
        config
      );
    const currencyParams = this.prepareCurrencyParams(requiredCurrencies);

    const queryParams = new URLSearchParams([
      ...Object.entries(params),
      ...(currencyParams || []),
    ]).toString();

    const searchUrl = `${endpoint}/v0/firebase_search/property_assets/search_with_value?${queryParams}`;
    const response = await fetch(searchUrl, config);
    if (response.status !== 200) throw new Error(response.statusText);
    const PropertyAssets: PropertyAssets[] = await response.json();

    let resultList: T[] = [];
    resultList = await Promise.all(
      PropertyAssets.map(async (item) => {
        if (item.category === AssetType.WineAndSpirits) {
          // bottle unit
          const wine = await this.wine.getWineById(item.fireId);
          const purchase = await this.wine.getWinePurchasesByIds([
            item.purchaseId!,
          ]);
          const hasBottle = purchase[0].bottles.find(
            (bottle) =>
              bottle.bottleId === item.bottleId &&
              bottle.removal?.reason !== RemovalReason.DeleteAndExclude
          );
          if (hasBottle) {
            return {
              id: item.bottleId,
              wineId: wine.id,
              purchaseId: item.purchaseId,
              name: wine.name,
              assetType: AssetType.WineAndSpirits,
              subtype: wine.subtype,
              mainImage: wine.mainImage,
              value: wine.purchases[0].valuePerBottle,
              updateAt: wine.updateAt,
            } as any as T;
          } else {
            return null as any as T;
          }
        } else {
          return (await this.getAssetsByResults([item]))[0] as any as T;
        }
      })
    );

    return {
      totalCount,
      list: resultList.filter((item) => item !== null),
    };
  }

  async getAssetsByGlobalSearch(keyword: string): Promise<SearchResult[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };

    const params = {
      userId: this.refs.currentRefs.userId,
      keyword,
      limit: "10",
    };

    const queryParams = new URLSearchParams(params).toString();
    const searchUrl = `${endpoint}/v0/firebase_search/search_bar?${queryParams}`;
    const response = await fetch(searchUrl, config);
    if (response.status !== 200) throw new Error(response.statusText);
    const searchResults: SearchResult[] = await response.json();

    return searchResults;
  }

  /**
   * Get wines catalogues
   * @see `api/bin/api/src/api/wines.rs` - `get_wines`
   */
  async getWinesCatalogues(
    query?: Record<string, any>
  ): Promise<WineCatalogue[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { ...query };
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/wines?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const checkSubtype = (subtype: string) => {
      const matchedOptions = wineTypeOptions.find(({ label, value }) =>
        [label, value].includes(subtype)
      );
      return matchedOptions?.value ?? "-";
    };

    const result: WineCatalogue[] = data.map((item: any) => ({
      wineId: item.id,
      name: item.name ?? "-",
      subtype: checkSubtype(item.type),
      producer: item.producer ?? "-",
      vintage: item.vintage ?? 1990,
      catalogueImage: item.image?.[0],
      country: item.country ?? undefined,
      masterVarietal: item.masterVarietal ?? undefined,
      variety: item.varietal ?? undefined,
      drinkWindow: [item.drinkWindow.start, item.drinkWindow.end]
        .map((value: string) => (value ?? "").slice(0, 4))
        .join("-"),
      vineyard: item.vineyard ?? undefined,
      region: item.region ?? undefined,
      subRegion: item.subregion ?? undefined,
      appellation: item.appellation ?? undefined,
      designation: item.designation ?? undefined,
      proRating: item.score ?? undefined,
    }));
    return result;
  }

  /**
   * Get wines catalogues counts
   * @see `api/bin/api/src/api/wines.rs` - `get_wines_count`
   */
  async getWinesCataloguesCounts(query?: Record<string, any>): Promise<number> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { ...query };
    const queryParams = buildQueryParams(params);
    const url = `${endpoint}/v0/wines/counts?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const counts: number = data;
    return counts;
  }

  /**
   * Get artists with query
   * @see `api/bin/api/src/api/art_search.rs` - `get_artists_by_keyword`
   */
  async getArtistsWithKeyword(keyword: string): Promise<Art.Artist[]> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { keyword };
    const paramsEntries = Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => [key, val]) : [[key, value]]
      )
      .filter(([_key, value]) => value !== "");
    const queryParams = new URLSearchParams(paramsEntries).toString();

    const url = `${endpoint}/v0/art_search?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: Art.Artist[] = data.map((item: any) => ({
      id: item.id.toString() || "-",
      name: `${item.name} ${item.surname || ""}`,
      surname: item.surname || "Unknown",
      sourceId: item.sourceId.toString() || "-",
      source: item.source || "Unknown",
      birthYear: (item.birthYear || "Unknown").toString(),
      birthLocation: item.birthLocation || "Unknown",
      nationality: item.nationalities[0] || "Unknown",
      gender: item.artistAttributes[0] || "Unknown",
      aliasNames: item.aliasNames || [],
    }));
    return result;
  }

  async getArtistById(id: string): Promise<Art.Artist> {
    const endpoint = this.params.apiEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");
    const userToken = await this.auth?.currentUser?.getIdToken();
    if (!userToken) throw new Error("User token not found");

    const params = { id };
    const paramsEntries = Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => [key, val]) : [[key, value]]
      )
      .filter(([_key, value]) => value !== "");
    const queryParams = new URLSearchParams(paramsEntries).toString();
    const url = `${endpoint}/v0/art_search/artist?${queryParams}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
        "firebase-jwt": userToken,
      },
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();

    const result: Art.Artist = {
      id: data.id.toString() || "-",
      name: `${data.name} ${data.surname || ""}`,
      surname: data.surname || "Unknown",
      sourceId: data.sourceId.toString() || "-",
      source: data.source || "Unknown",
      birthYear: (data.birthYear || "Unknown").toString(),
      birthLocation: data.birthLocation || "Unknown",
      nationality: data.nationalities[0] || "Unknown",
      gender: data.artistAttributes[0] || "Unknown",
      aliasNames: data.aliasNames || [],
    };
    return result;
  }

  /***** CustomizedType *****/
  async addCustomizedType(type: CustomizedType, name: string): Promise<void> {
    const userDoc = this.refs.currentRefs.getCustomizedTypeRef(type);
    CoreFirestore.setDocWithOption(userDoc, { [name]: null }, { merge: true });
    // `/ User / user_id / CustomizeType / Label ? `: { something: null .....}
    // `/ User / user_id / CustomizeType / Insurance ? ` { somethingElse: null ....}
  }

  async removeCustomizedType(
    type: CustomizedType,
    name: string
  ): Promise<void> {
    const userDoc = this.refs.currentRefs.getCustomizedTypeRef(type);
    CoreFirestore.updateDoc(userDoc, { [name]: CoreFirestore.deleteField() });
  }

  async listCustomizedType(typeName: CustomizedType): Promise<string[]> {
    const userDoc = await CoreFirestore.getDoc<CustomizedTypeObject>(
      this.refs.currentRefs.getCustomizedTypeRef(typeName)
    );
    let data: string[] = userDoc.exists() ? Object.keys(userDoc.data()!) : [];

    const globalDoc = await CoreFirestore.getDoc(
      CoreFirestore.docFromCollection(
        this.refs.currentRefs.GlobalSetting,
        typeName
      )
    );
    if (globalDoc.exists()) {
      data = data.concat(Object.keys(globalDoc.data()!));
    }
    return data;
  }

  /**
   *
   * @returns {Promise<ProgressMetadata<any>[]>} The export tasks, id can be used to resumed the task
   */
  async getUnfinishedExportTasks(): Promise<ProgressMetadata<any>[]> {
    const ref = this.refs.currentRefs.getExportMetadataCollection();
    return await CoreFirestore.getDocsFromCollection<ProgressMetadata<any>>(
      ref
    ).then(getQueriedData);
  }
  /**
   * get an export handler
   *
   * @param {AssetType} assetType - desired asset type to make export
   * @param {string} taskId - task id to track the export progress, leave undefined to create a new task
   * @returns {Promise<ExportHandler>} The export handler to get export data and manage progress
   */
  async getExportHandler(
    assetType: AssetType,
    taskId?: string,
    batchSize?: number
  ): Promise<ExportHandler> {
    return newExportHandler(
      assetType,
      this.refs.currentRefs,
      this.Encryption.current,
      this.ExRate,
      new MockPriceSource(),
      taskId,
      batchSize
    );
  }

  async getComparativeNetWorthReport(): Promise<ComparativeNetWorthReport> {
    return computeComparativeNetWorthReport(
      this.summaryManager,
      this.ExRate,
      this.refs.currentRefs
    );
  }

  /**
   * Asynchronously creates multiple records of a given asset in the database with default/decorated values.
   *
   * @template Model The type of the model to be created.
   * @template RawRecord The basic js object type used to create the default raw record.
   * @param {Object} opts - The options for creating the models.
   * @param {new () => Model} opts.model - The class of the model to be created.
   * @param {() => RawRecord} opts.default - A function that returns a default raw record.
   * @param {number} opts.amount - The number of models to create.
   * @param {number} [opts.batchSize] - The number of models to create in each batch.
   * @param {(input: Model, index: number) => void} [opts.decorator] - A function that decorates each model, with specific data
   * @param {(result: BatchResult<Model>) => boolean | void} [opts.onBatchResult] - A function called after each batch update has completed, containing the results and any errors. Default behaviour is to stop after any errors. This function should return true if you wish to continue after an error.
  */
  async bulkUpdateAssets<Model, State, Command, TEvent>
    (opts: BatchUpdateContext<Model, State, Command, TEvent>): Promise<void> {

    const { model, amount, decorator, updateFunc, onBatchResult } = opts
    const batchSize = opts.batchSize || DEFAULT_BATCH_SIZE
    const errors: Error[] = []

    const batchTotal = Math.ceil(amount / batchSize)

    for (let batchNumber = 1; batchNumber <= batchTotal; batchNumber++) {
      let error: Error | undefined = undefined
      try {
        await CoreFirestore.runTransaction(async (transaction) => {
          const repo = await opts.repoFunc(transaction)
          // Batch
          const start = (batchNumber - 1) * batchSize
          const end = Math.min(start + batchSize, amount)
          for (let index = start; index < end; index++) {
            const item = new model();
            if (decorator) { decorator(item, index) }

            await updateFunc(item, repo)

          }
        })

      } catch (e) {
        if (!onBatchResult) { throw e } //Throw error, if no batch erorr handler specified
        error = e as Error
      }

      if (onBatchResult) {
        const carryOn = onBatchResult({ batchNumber, batchTotal, error })
        if (error && !carryOn) { break }
      }
    }
  }
  
  ___testGetRefs() {
    return this.refs;
  }
}

type CustomizedTypeObject = { [key: string]: null };

const DEKExistsInFirestore = (refs: Refs) => async () => {
  const dek = await CoreFirestore.getDoc(refs.Dek);
  return dek.exists();
};

export enum MapiSearchParamsType {
  keyword = "keyword",
  category = "category",
  subType = "subType",
  brand = "brand",
  valueCurrency = "valueCurrency",
  valueLowerBound = "valueLowerBound",
  valueUpperBound = "valueUpperBound",
  bottlePriceLowerBound = "bottlePriceLowerBound",
  bottlePriceUpperBound = "bottlePriceUpperBound",
  acquisitionType = "acquisitionType",
  purchaseDateLowerBound = "purchaseDateLowerBound",
  purchaseDateUpperBound = "purchaseDateUpperBound",
  locationId = "locationId",
  roomId = "roomId",
  vintage = "vintage",
  masterVarietal = "masterVarietal",
  artStyle = "artStyle",
  offset = "offset",
  limit = "limit",
  desc = "desc",
  exchangeRate = "exchangeRate",
}

export type SearchAssetResult<T> = {
  list: T[];
  totalCount: number;
};

function buildQueryParams(params: Record<string, any>) {
  return new URLSearchParams(
    Object.entries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => [key, val]) : [[key, value]]
      )
      .filter(([_key, value]) => value !== "")
  ).toString();
}

function filterWinePurchases(wines: Wine[], data: SearchResult[]): Wine[] {
  const acceptPurchaseIds = new Set(
    data.flatMap(({ purchases }) => purchases?.map((p) => p.fireId) ?? [])
  );
  return wines.map((wine) => ({
    ...wine,
    purchases: wine.purchases.filter((purchase) =>
      acceptPurchaseIds.has(purchase.id)
    ),
  }));
}
