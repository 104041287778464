import {
  ClassConstructor,
  DiscriminatorDescriptor,
  Transform,
  TransformFnParams,
  Type,
  plainToInstance,
} from "class-transformer";
import {
  FieldDecorator,
  getFieldMetadataByDecorator,
  getFieldsByDecorator,
} from "./fieldDecorator";
import { IsValidObject, ValidateNested } from "./validations";

export enum Annotations {
  Managed = "Managed",
  NotEncrypted = "NotEncrypted",
  ValidateNestedWithType = "ValidateNestedWithType",
  EncryptableObject = "EncryptableObject",
  Searchable = "Searchable",
  ToBeRemoved = "ToBeRemoved",
  UpdateNotAllowed = "UpdateNotAllowed",
  SimpleUpdate = "SimpleUpdate",
}

export const NotEncrypted = FieldDecorator(Annotations.NotEncrypted);
export const Managed = FieldDecorator(Annotations.Managed);
export const Searchable = FieldDecorator(Annotations.Searchable);
export const ToBeRemoved = FieldDecorator(Annotations.ToBeRemoved);
export const Deprecated = (version: string) =>
  function (target: unknown, propertyKey: string): void {};
export const Notes = (notes: string) =>
  function (target: unknown, propertyKey: string): void {};

/*
 ** #NOTE: Simple types (string, number, boolean) fields that can be updated through events
 ** This will affect buildUpdate() function to decide which fields to remain in UpdateObject<T>
 */
export const SimpleUpdate = FieldDecorator(Annotations.SimpleUpdate);

/**
 * Defines a field as containing an encryptable object.
 * @param {new () => T} cls - The class of the object to be encrypted (this class defines the fields to be encrypted).
 * @param {DiscriminatorDescriptor} typeDescriminator - The validation options for the field, including configuration to determine the subclass of the object if it could contain 1 of many classes
 * Uses logic from 'class-transformer' library - see https://github.com/typestack/class-transformer?tab=readme-ov-file#providing-more-than-one-type-option
 * @example Basic Single Class type
 * ```typescript
 * class Record {
 *  @EncryptableObject(User)
 *  user!: User
 * }
 * ```
 *
 * @example Multiple Classes types (determined by child data)
 * ```typescript
class User { level! : string }
class AdminUser extends User { declare level = "admin" }
class SuperUser extends User { declare level = "super" }

class Record {
  @EncryptableObject(User,
  {
    property: "level",
    subtypes: [
    {value : AdminUser name: "admin" }
    {value : SuperUser name: "super" }
    ]
  })
  user!: AdminUser | SuperUser
}
 * ```
 * @example Multiple Classes types (determined by parent data)
 * ```typescript
class Record {
  level : string
  @EncryptableObject(User,
  {
    property: "level",
    propertyOnParent: true,
    subtypes: [
    {value : AdminUser name: "admin" }
    {value : SuperUser name: "super" }
    ]
  })
  user!: AdminUser | SuperUser
}
 * ```
 */

/**
 * Decorates an object or object array field to be also included during the validation process.
 *
 * @param {Annotations} annotation - The annotation to be used for decoration.
 * @param {ClassConstructor<T>} cls - The class of the object to be validated.
 * @param {DiscriminatorDescriptor & { propertyOnParent?: boolean }} [typeDescriminator] - The validation options for the field, including configuration to determine the subclass of the object if it could contain 1 of many classes. Uses logic from 'class-transformer' library - see https://github.com/typestack/class-transformer?tab=readme-ov-file#providing-more-than-one-type-option. In our case, we only use this parameter when handling union type field like Property.detail with `RentalDetail | OwnerDetail` type.
 * @return {void}
 * @throws {Error} If no class was provided, this may be a circular dependancy, and the class has not yet been loaded.
 */

export function ValidateNestedBase<T extends object>(
  annotation: Annotations,
  cls: ClassConstructor<T>,
  typeDescriminator?: DiscriminatorDescriptor & { propertyOnParent?: boolean }
) {
  return function (target: object, propertyKey: string): void {
    if (!cls) {
      throw new Error(`${propertyKey} has been defined as an ${annotation} but
    no class was provided, this may be a circular dependancy, and the class has not yet been loaded`);
    }

    const isArray = Array.isArray(
      (<{ [k: string]: unknown }>target)[propertyKey]
    );

    FieldDecorator(
      annotation,
      { context: typeDescriminator },
      cls
    )(target, propertyKey);

    IsValidObject(cls, { each: isArray, context: typeDescriminator })(
      target,
      propertyKey
    );

    if (typeDescriminator && typeDescriminator.propertyOnParent) {
      //Replicates the functionality of @Type decorator but uses a property on the parent object, instand of the child object
      Transform(({ value, obj }: TransformFnParams) => {
        const options = typeDescriminator.subTypes.map(({ name }) => name);
        for (const index in options) {
          if (obj[typeDescriminator.property] === options[index]) {
            try {
              return plainToInstance(
                typeDescriminator.subTypes[index].value,
                value,
                { exposeUnsetFields: false }
              );
            } catch (err: any) {
              const className = typeDescriminator.subTypes[index].value.name;
              const error: Error & { inner?: Error } = new Error(
                `Could not convert ${propertyKey} to ${className}, ${err.message}`
              );
              error.inner = err;
              throw error;
            }
          }
        }
        return plainToInstance(cls, value, { exposeUnsetFields: false });
      })(target, propertyKey);
    } else {
      Type(() => cls, { discriminator: typeDescriminator })(
        target,
        propertyKey
      );
    }

    ValidateNested({ each: isArray, context: typeDescriminator })(
      target,
      propertyKey
    );
  };
}

/**
 * Decorates an object or object array field(non-encryptable) to be also included during the in-app validation process.
 * It will also generate related firestore rules for the field
 *
 * @param {ClassConstructor<T>} cls - The class of the object to be validated.
 * @param {DiscriminatorDescriptor & { propertyOnParent?: boolean }} [typeDescriminator] - The validation options for the field, including configuration to determine the subclass of the object if it could contain 1 of many classes. Uses logic from 'class-transformer' library - see https://github.com/typestack/class-transformer?tab=readme-ov-file#providing-more-than-one-type-option. In our case, we only use this parameter when handling union type field like Property.detail with `RentalDetail | OwnerDetail` type.
 * @return {void}
 */
export function ValidateNestedWithType<T extends object>(
  cls: ClassConstructor<T>,
  typeDescriminator?: DiscriminatorDescriptor & { propertyOnParent?: boolean }
) {
  return ValidateNestedBase(
    Annotations.ValidateNestedWithType,
    cls,
    typeDescriminator
  );
}

/**
 * Decorates an object or object array field(encryptable) to be also included during the in-app validation process.
 * It will also generate related firestore rules for the field
 *
 * @param {ClassConstructor<T>} cls - The class of the object to be validated.
 * @param {DiscriminatorDescriptor & { propertyOnParent?: boolean }} [typeDescriminator] - The validation options for the field, including configuration to determine the subclass of the object if it could contain 1 of many classes. Uses logic from 'class-transformer' library - see https://github.com/typestack/class-transformer?tab=readme-ov-file#providing-more-than-one-type-option. In our case, we only use this parameter when handling union type field like Property.detail with `RentalDetail | OwnerDetail` type.
 * @return {void}
 */
export function EncryptableObject<T extends object>(
  cls: ClassConstructor<T>,
  typeDescriminator?: DiscriminatorDescriptor & { propertyOnParent?: boolean }
) {
  return function (target: object, propertyKey: string): void {
    /*
    #NOTE: @EncryptableObject field will not be included into _encrypted field, it will be encrypted separately
    Adding NotEncrypted here will let firestoreRules.ts know that we need to generate firestore security rules for this field
    */
    NotEncrypted(target, propertyKey);

    ValidateNestedBase(
      Annotations.EncryptableObject,
      cls,
      typeDescriminator
    )(target, propertyKey);
  };
}

export type ClassDictionary<T extends string | number | symbol = string> = {
  [k in T]?: new () => Record<string, unknown>;
};

export function getEncryptableObjectFields<T extends object>(cls: new () => T) {
  return getFieldMetadataByDecorator(
    Annotations.EncryptableObject,
    cls
  ) as ClassDictionary<keyof T>;
}

export function hasEncryptedObjectField<T extends object>(cls: new () => T) {
  return getFieldsByDecorator(Annotations.EncryptableObject, cls).length > 0;
}
