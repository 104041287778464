// https://stackoverflow.com/questions/56857880/how-to-get-history-and-match-in-this-props-in-nextjs/65614253#65614253
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export function usePreviousRoute(ignoredPathnames = ['']) {
  const [previous, setPrevious] = useState<string | undefined>(undefined)
  const router = useRouter()

  useEffect(() => {
    const handleBeforeHistoryChange = (_url: any, { shallow }: any) => {
      const shouldPersist = ignoredPathnames.every((ignoredPathname) => !router.pathname.includes(ignoredPathname))
      if (!shallow && shouldPersist && router.asPath.length > 0) {
        setPrevious(router.asPath)
      }
    }

    router.events.on('beforeHistoryChange', handleBeforeHistoryChange)
    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange)
    }
  }, [ignoredPathnames, router.asPath, router.events, router.pathname])

  return { previous }
}
