import { Encryption } from "../database/encryption";
import { OmitKeys, UpdateObject } from "../utils";

//#TODO find out the real value
export const EncryptionFieldKey = "_encrypted";
export const EncryptionFieldDefaultValue = "";
export const IVSaltFieldKey = "_ivSalt";
export const IVSaltFieldDefaultValue = "";

export interface EncryptionField {
  [EncryptionFieldKey]: { data: string; [IVSaltFieldKey]: string };
}
export type EncryptedType<T, K extends keyof T> = OmitKeys<T, K> &
  EncryptionField;
export type RequireEncryptionFields<
  T,
  U extends { [K in keyof T]?: any }
> = Omit<T, keyof U> & U;

export function removeEncryptionFields<T extends EncryptionField>(
  input: T
): OmitKeys<T, typeof EncryptionFieldKey> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [EncryptionFieldKey]: _1, ...rest } = input;
  return rest;
}

export function doRemoveEncryptedFields<
  T extends object,
  U extends T | UpdateObject<T>,
  EncryptedKeys extends keyof T
>(
  data: U,
  encryptedKeysArray: readonly (keyof T)[]
): OmitKeys<U, EncryptedKeys> {
  const result: OmitKeys<U, EncryptedKeys> = {
    ...data,
  };
  encryptedKeysArray.forEach((key) => {
    delete (<any>result)[key];
  });
  return result;
}
export async function fullObjectEncryption<
  Raw extends object,
  EncryptedKeys extends keyof Raw
>(
  rawData: Raw,
  encryptedKeysArray: readonly EncryptedKeys[],
  encryption: Encryption
): Promise<EncryptedType<Raw, EncryptedKeys>> {
  return fullObjectEncryptionNotStrict(
    rawData,
    <any>encryptedKeysArray,
    encryption
  );
}
export async function fullObjectEncryptionNotStrict<
  T extends { [key: string]: any }
>(
  rawData: T,
  encryptedKeysArray: readonly string[],
  encryption: Encryption
): Promise<EncryptedType<T, any>> {
  const EncryptedPart: Pick<T, any> = <any>{};
  const newRawData = { ...rawData };

  const iv = encryption.generateNewIVSalt();

  encryptedKeysArray.forEach((key) => {
    EncryptedPart[key] = newRawData[key] as any;
    delete newRawData[key];
  });
  return {
    ...newRawData,
    [EncryptionFieldKey]: {
      data: await encryption.encryptAndStringify(EncryptedPart, iv),
      [IVSaltFieldKey]: encryption.convertIVSaltToBase64(iv),
    },
  };
}

export async function fullObjectDecryption<
  Raw extends object,
  EncryptedKeys extends keyof Raw
>(
  data: EncryptedType<Raw, EncryptedKeys>,
  encryption: Encryption
): Promise<Raw> {
  const encrypted = data[EncryptionFieldKey];
  const encodedIvSalt = encrypted[IVSaltFieldKey];
  const iv: Uint8Array =
    encodedIvSalt === undefined
      ? new Uint8Array(16)
      : encryption.convertBase64ToIVSalt(encodedIvSalt);
  const encryptedPart: Pick<Raw, EncryptedKeys> =
    await encryption.decryptAndStringify(encrypted["data"], iv);
  return <Raw>{
    ...removeEncryptionFields(data),
    ...encryptedPart,
  };
}
