import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2016%2014%22%3E%3Cpath%20d%3D%22M6.402.367c-.278.04.368-.04-.467.119-.836.168-1.005%201.24-1.045%201.636-.04.396-.05.515-.03%201.11.08.357-.318.357-.318.357-.507-.03-.587%201.527-.587%201.527.07.387-.03.575.716.873.349%201.001%201.254%201.507%201.254%201.507s.04.644.06%201.1c-.08-.03-.14%201.18-2.438%201.517C1.24%2010.45.802%2011.194.364%2011.611-.074%2012.027.006%2014%20.006%2014l9.51-.04a4.49%204.49%200%200%201-1.572-3.4A4.42%204.42%200%200%201%208.98%207.713l.01-.139s.746-.615%201.094-1.616c.557-.347.766-.397.866-.803%200%200%20.248-1.527-.349-1.527%200%200-.686.04-.606-.317.08-.357.05-.476.01-.873S10.203.902%209.367.744c-.567-.1-.388.06-.667.02C8.422.723%208.094%200%207.576%200l-.825.169c-.518%200-.07.158-.349.198Z%22%2F%3E%3Cpath%20d%3D%22M12.5%207C10.57%207%209%208.57%209%2010.5s1.57%203.5%203.5%203.5%203.5-1.57%203.5-3.5S14.43%207%2012.5%207Zm.5%204v2h-1v-2h-2v-1h2V8h1v2h2v1h-2Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function DelegateIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 16 14'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M6.402.367c-.278.04.368-.04-.467.119-.836.168-1.005 1.24-1.045 1.636-.04.396-.05.515-.03 1.11.08.357-.318.357-.318.357-.507-.03-.587 1.527-.587 1.527.07.387-.03.575.716.873.349 1.001 1.254 1.507 1.254 1.507s.04.644.06 1.1c-.08-.03-.14 1.18-2.438 1.517C1.24 10.45.802 11.194.364 11.611-.074 12.027.006 14 .006 14l9.51-.04a4.49 4.49 0 0 1-1.572-3.4A4.42 4.42 0 0 1 8.98 7.713l.01-.139s.746-.615 1.094-1.616c.557-.347.766-.397.866-.803 0 0 .248-1.527-.349-1.527 0 0-.686.04-.606-.317.08-.357.05-.476.01-.873S10.203.902 9.367.744c-.567-.1-.388.06-.667.02C8.422.723 8.094 0 7.576 0l-.825.169c-.518 0-.07.158-.349.198Z'
        }
      />
      <path
        d={
          'M12.5 7C10.57 7 9 8.57 9 10.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5S14.43 7 12.5 7Zm.5 4v2h-1v-2h-2v-1h2V8h1v2h2v1h-2Z'
        }
      />
    </svg>
  )
}
