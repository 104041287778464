import router from 'next/router'
import { useTranslation } from 'react-i18next'

import { Button, Modal } from '@/components/base'
import { TimeoutIcon } from '@/components/icon'

export default function DialogSessionExpired() {
  const { t } = useTranslation()
  const handleSubmit = () => {
    router.replace('/auth/login/')
  }
  return (
    <Modal>
      <Modal.Content className={'gap-4'}>
        <div className={'flex flex-col items-center justify-start gap-2 text-[#414554]'}>
          <TimeoutIcon className={'my-10 mr-2'} size={80} />
          <p className={'text-3xl font-bold'}>{t('SessionExpired')}</p>
          <p className={'text-lg'}>{t('SessionTimedOut')}</p>
          <Button className={'bg-primary p-2 px-4 text-white'} onClick={handleSubmit}>
            {t('nav:auth.login')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}
