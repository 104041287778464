import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { PermissionCategory } from 'core/remodel/refPaths'
import { useAuthStore } from '@/store/authStore'

// XXX maybe there is a better way
export function useRouteGuard() {
  const router = useRouter()
  const status = useAuthStore((state) => state.status)
  const { canView, canEdit, canCreate, canDelete } = useAuthStore((state) => state.permissions)

  const to = useMemo(() => {
    return canView('GlobalDashboard') ? '/' : '/documents/summary'
  }, [canView])

  const redirect = useCallback(
    (shouldRedirect: boolean) => {
      if (shouldRedirect) {
        router.replace(to)
      }
    },
    [router, to]
  )

  useEffect(() => {
    if (status !== 'ready') return

    const path = router.asPath

    // view
    redirect(/^\/finances/.test(path) && !canView(PermissionCategory.Finance))
    redirect(/^\/finances\/insurance/.test(path) && !canView(PermissionCategory.Insurance))
    redirect(/^\/properties/.test(path) && !canView(PermissionCategory.Property))
    redirect(/^\/collectables\/art/.test(path) && !canView(PermissionCategory.Art))
    redirect(/^\/collectables\/wine/.test(path) && !canView(PermissionCategory.Wine))
    redirect(/^\/collectables\/other/.test(path) && !canView(PermissionCategory.OtherCollectables))
    redirect(/^\/belongings/.test(path) && !canView(PermissionCategory.Belonging))
    redirect(/^\/wishlist/.test(path) && !canView('Wishlist'))

    // edit
    redirect(/^\/finances\/[\w-]+\/edit/.test(path) && !canEdit(PermissionCategory.Finance))
    redirect(/^\/finances\/insurance\/edit/.test(path) && !canEdit(PermissionCategory.Insurance))
    redirect(/^\/properties\/summary\/edit/.test(path) && !canEdit(PermissionCategory.Property))
    redirect(/^\/collectables\/art\/edit/.test(path) && !canEdit(PermissionCategory.Art))
    redirect(/^\/collectables\/wine\/edit/.test(path) && !canEdit(PermissionCategory.Wine))
    redirect(/^\/collectables\/other\/edit/.test(path) && !canEdit(PermissionCategory.OtherCollectables))
    redirect(/^\/belongings\/summary\/edit/.test(path) && !canEdit(PermissionCategory.Belonging))

    // create
    redirect(/^\/finances\/[\w-]+\/create\/$/.test(path) && !canCreate(PermissionCategory.Finance))
    redirect(/^\/finances\/insurance\/create\/$/.test(path) && !canCreate(PermissionCategory.Insurance))
    redirect(/^\/properties\/summary\/create\/$/.test(path) && !canCreate(PermissionCategory.Property))
    redirect(/^\/collectables\/art\/create\/$/.test(path) && !canCreate(PermissionCategory.Art))
    redirect(/^\/collectables\/wine\/create\/$/.test(path) && !canCreate(PermissionCategory.Wine))
    redirect(/^\/collectables\/other\/create\/$/.test(path) && !canCreate(PermissionCategory.OtherCollectables))
    redirect(/^\/belongings\/summary\/create\/$/.test(path) && !canCreate(PermissionCategory.Belonging))

    // delete
    redirect(/^\/finances\/[\w-]+\/delete/.test(path) && !canDelete(PermissionCategory.Finance))
    redirect(/^\/finances\/insurance\/delete/.test(path) && !canDelete(PermissionCategory.Insurance))
    redirect(/^\/properties\/summary\/delete/.test(path) && !canDelete(PermissionCategory.Property))
    redirect(/^\/collectables\/art\/delete/.test(path) && !canDelete(PermissionCategory.Art))
    redirect(/^\/collectables\/wine\/delete/.test(path) && !canDelete(PermissionCategory.Wine))
    redirect(/^\/collectables\/other\/delete/.test(path) && !canDelete(PermissionCategory.OtherCollectables))
    redirect(/^\/belongings\/summary\/delete/.test(path) && !canDelete(PermissionCategory.Belonging))
  }, [canDelete, canCreate, canEdit, canView, redirect, router.asPath, status])
}
