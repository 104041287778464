import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2033%2024%22%3E%3Cpath%20d%3D%22M.158%201.772A1.45%201.45%200%200%201%201.608.33h29.874c.801%200%201.45.64%201.45%201.442a1.45%201.45%200%200%201-1.45%201.442H1.608c-.8%200-1.45-.64-1.45-1.442Zm0%2010.095c0-.797.637-1.442%201.435-1.442h20.072c.793%200%201.435.64%201.435%201.442%200%20.796-.637%201.442-1.435%201.442H1.593c-.793%200-1.435-.64-1.435-1.442Zm0%2010.094a1.45%201.45%200%200%201%201.45-1.442h29.874c.801%200%201.45.64%201.45%201.442a1.45%201.45%200%200%201-1.45%201.442H1.608c-.8%200-1.45-.64-1.45-1.442Zm26.22-15.863%206.554%204.327-6.554%205.768V6.098Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function PanelIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 33 24'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M.158 1.772A1.45 1.45 0 0 1 1.608.33h29.874c.801 0 1.45.64 1.45 1.442a1.45 1.45 0 0 1-1.45 1.442H1.608c-.8 0-1.45-.64-1.45-1.442Zm0 10.095c0-.797.637-1.442 1.435-1.442h20.072c.793 0 1.435.64 1.435 1.442 0 .796-.637 1.442-1.435 1.442H1.593c-.793 0-1.435-.64-1.435-1.442Zm0 10.094a1.45 1.45 0 0 1 1.45-1.442h29.874c.801 0 1.45.64 1.45 1.442a1.45 1.45 0 0 1-1.45 1.442H1.608c-.8 0-1.45-.64-1.45-1.442Zm26.22-15.863 6.554 4.327-6.554 5.768V6.098Z'
        }
      />
    </svg>
  )
}
