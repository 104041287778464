import { Encryption } from "../database/encryption";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
} from "../utils";
import {
  EncryptedType,
  EncryptionFieldKey,
  IVSaltFieldKey,
  RequireEncryptionFields,
  doRemoveEncryptedFields,
  fullObjectEncryption,
  removeEncryptionFields,
} from "../encryption/utils";
import { ErrorDataOutDated } from "./error";
import { ContactLocation } from "./properties/contactLocation";
import { Room } from "./properties/room";
import {
  ContactTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";

//#NOTE one of the fields should exist
export interface Contact
  extends UpdateObject<OmitKeys<ContactLocation, "@type">> {
  "@type": VersionedTypeString<VersionedType.Contact, 2>;
  id: string;
  executerId: string;
  // @Encrypted
  firstName?: string;
  // @Encrypted
  lastName?: string;
  contactType?: ContactType | string;
  contactCompany?: string;
  position?: string;
  // @Encrypted
  email?: string;
  // @Encrypted
  phone?: string;

  // only for wine
  room?: Room[];
}

export enum ContactType {
  Friend = "Friend",
  Family = "Family",
  Business = "Business",
  Supplier = "Supplier",
  Customer = "Customer",
  Consultant = "Consultant",
  Location = "Location",
  Other = "Other",
}
export const contactTypeValues = Object.values(ContactType);

export namespace Contact {
  export function assureVersion(
    input: Contact | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(input, ContactTypeVersion, errorOnCoreOutDated);
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Contact);
  }

  export type CreateFields = OmitKeys<Contact, "@type" | "executerId" | "room">;
  export type UpdateFields = CreateFields;
  export type EncryptedKeys =
    | ContactLocation.EncryptedKeys
    | "firstName"
    | "lastName"
    | "email"
    | "phone";
  export type Encrypted = RequireEncryptionFields<
    EncryptedType<Contact, EncryptedKeys>,
    {
      room?: Room.Encrypted[];
    }
  >;
  export type EncryptedPart = Pick<Contact, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    ...ContactLocation.encryptedKeysArray,
    "firstName",
    "lastName",
    "email",
    "phone",
  ] as const;

  export function fromCreate(
    from: Contact.CreateFields,
    executerId: string
  ): Contact {
    const Contact: Contact = {
      ...from,
      executerId: executerId,
      "@type": ContactTypeVersion,
    };
    return Contact;
  }

  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Contact>[] = [
    "address",
    "addressLine1",
    "addressLine2",
    "town",
    "state",
    "zipCode",
    "country",
    "firstName",
    "lastName",
    "contactType",
    "contactCompany",
    "position",
    "email",
    "phone",
  ];
  export function intoUpdate(
    current: Contact,
    update: Contact.UpdateFields
  ): UpdateObject<Contact> {
    const baseUpdateFields = buildObjectUpdate(
      current,
      update,
      [],
      OptionalSimpleTypeUpdatableKeys
    );
    if (update.center) baseUpdateFields.center = update.center;

    return baseUpdateFields;
  }

  export async function encrypt(
    rawData: Contact,
    encryption: Encryption
  ): Promise<Encrypted> {
    const { room, ...rest } = await fullObjectEncryption(
      rawData,
      encryptedKeysArray,
      encryption
    );
    const result = rest as Encrypted;
    if (room) {
      result.room = await Promise.all(
        room.map((room) => Room.encrypt(room, encryption))
      );
    }
    return result;
  }

  export async function decrypt(data: Encrypted, encryption: Encryption) {
    const encryptedPart: EncryptedPart = await encryption.decryptAndStringify(
      data[EncryptionFieldKey]["data"],
      encryption.convertBase64ToIVSalt(data[EncryptionFieldKey][IVSaltFieldKey])
    );
    const { room, ...rest } = data;
    const result: Contact = {
      ...removeEncryptionFields(rest),
      ...encryptedPart,
    };
    if (room) {
      for (const roomElement of room) {
        if (roomElement[EncryptionFieldKey]) {
          if (!result.room) result.room = [];
          const decryptedRoom = await Room.decrypt(roomElement, encryption);
          result.room.push(decryptedRoom);
        }
      }
    }
    return result;
  }

  export function removeEncryptedFields<
    T extends Contact | UpdateObject<Contact>
  >(data: T): OmitKeys<T, EncryptedKeys | "room"> {
    const result = doRemoveEncryptedFields(
      data,
      encryptedKeysArray
    ) as OmitKeys<T, EncryptedKeys | "room">;
    delete (<any>result).room;
    return result;
  }

  //#NOTE validate encrypted keys have legal value
  export function validateEncryptedPart(
    _data: EncryptedPart,
    _isCreate: boolean = false
  ) {
    // if (
    //   (isCreate || data.name !== undefined) &&
    //   !validateStringNotEmpty(data.name)
    // ) {
    //   throw new InvalidInput("Name is required");
    // }
  }

  //#NOTE validate data after encrypted
  export function validateEncryptedObj(data: UpdateObject<Encrypted>) {
    //optional fields
  }
}
