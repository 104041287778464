import { Amount, Currency } from "../types/common";

export interface CryptoPriceSource {
  getCryptoPrice: (coinNames: string[]) => Promise<Record<string, Amount>>;
}

export interface StockPriceSource {
  getStockPrice: (stocks: string[]) => Promise<Record<string, Amount>>;
}

export interface PriceSource extends CryptoPriceSource, StockPriceSource {}

export class MockPriceSource implements PriceSource {
  async getStockPrice(stocks: string[]) {
    //#TODO get real stock price
    const stockPriceMap: Record<string, Amount> = {};
    stocks.map((stock) => {
      if (!stockPriceMap[stock])
        stockPriceMap[stock] = { currency: Currency.USD, value: 1 };
    });
    return stockPriceMap;
  }

  async getCryptoPrice(coinNames: string[]) {
    //#TODO get real crypto price
    const cryptoPriceMap: Record<string, Amount> = {};
    coinNames.map((coinName) => {
      if (!cryptoPriceMap[coinName])
        cryptoPriceMap[coinName] = {
          currency: Currency.USD,
          value: 1,
        };
    });
    return cryptoPriceMap;
  }
}
