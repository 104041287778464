import { CoreFirestore } from "../../../coreFirebase";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  validateStringNotEmpty,
} from "../../utils";
import { EncryptedType } from "../../encryption/utils";
import { InvalidInput } from "../error";
import {
  ActionTypeVersion,
  VersionedType,
  VersionedTypeString,
} from "../typeVersion";

export enum ActionType {
  AddValuation = "AddValuation",
  AddOffer = "AddOffer",
  MarkAsSold = "MarkAsSold",
  ArchiveProperty = "ArchiveProperty",
  AddConsignment = "AddConsignment",
  AddExhibition = "AddExhibition",
  AddLiterature = "AddLiterature",
  AddTastingNote = "AddTastingNote",
  RentOut = "RentOut",
}

export interface ActionBase {
  id: string;
  ownerId: string;
  "@type": VersionedTypeString<VersionedType.Action, 2>;
  createAt: Date;
  updateAt: Date;
  actionType: ActionType;
}

export interface ItemActions extends ActionBase {
  notes?: string;
  file?: { key: string; name: string }[];
}
export namespace ItemActions {
  export type EncryptedKeys = "notes" | "file";
  export type EncryptedPart = Pick<ItemActions, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    "notes",
    "file",
  ];
  export type Encrypted = EncryptedType<ItemActions, EncryptedKeys>;

  export type CreateFieldsExcludeKeys =
    | "@type"
    | "ownerId"
    | "actionType"
    | "createAt"
    | "updateAt";
  export type CreateFields = OmitKeys<ItemActions, CreateFieldsExcludeKeys>;

  export function fromCreate<
    T extends CreateFields,
    TActionType extends ActionType
  >(
    createFields: T,
    ownerId: string,
    actionType: TActionType
  ): T &
    Pick<ItemActions, CreateFieldsExcludeKeys> & { actionType: TActionType } {
    return {
      ...createFields,
      ownerId,
      "@type": ActionTypeVersion,
      actionType,
      createAt: <any>CoreFirestore.serverTimestamp(),
      updateAt: <any>CoreFirestore.serverTimestamp(),
    };
  }

  type Update = Pick<ItemActions, "notes" | "file">;
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<ItemActions>[] =
    ["notes"];
  export function intoUpdate(
    current: ItemActions,
    update: Update
  ): UpdateObject<ItemActions> {
    const result: UpdateObject<ItemActions> = buildObjectUpdate(
      current,
      update,
      [],
      OptionalSimpleTypeUpdatableKeys
    );

    let isFileUpdated = false;
    if (current.file && update.file) {
      if (current.file.length == update.file.length) {
        for (let i = 0; i < current.file.length; i++) {
          if (
            current.file[i].key !== update.file[i].key ||
            current.file[i].name !== update.file[i].name
          ) {
            isFileUpdated = true;
            break;
          }
        }
      } else {
        isFileUpdated = true;
      }
    } else {
      isFileUpdated = current.file !== update.file;
    }

    if (isFileUpdated)
      if (update.file) result.file = update.file;
      else result.file = null;
    return result;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    if (data.file) {
      data.file.forEach((f) => {
        if (!validateStringNotEmpty(f.name)) {
          throw new InvalidInput("File name cannot be empty");
        }
        if (!validateStringNotEmpty(f.key)) {
          throw new InvalidInput("File key cannot be empty");
        }
      });
    }
  }
}
