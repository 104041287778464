import type { Database } from 'core/remodel/database'
import type { ActivityManger } from 'core/remodel/database/activities'
import { ActivityKind, type Activity } from 'core/remodel/types/activities'
import { AssetType, LocationType, type SupportActivityType } from 'core/remodel/types/common'
import type { Contact } from 'core/remodel/types/contact'
import type { Property } from 'core/remodel/types/properties'
import type { ActivityDetails } from '@/types/common'
import { getFullName } from '@/utils/formatter'
import { isContact } from '@/utils/predicate'

// key
export const activityQuery = {
  list: 'activity-list',
  details: 'activity-details'
} as const

// key getter
export function getKey(assetType: AssetType) {
  return (index: number, prevData: { activities: ActivityDetails[]; manager: ActivityManger } | null) => {
    if (index === 0) return [activityQuery.list, assetType]
    return [activityQuery.list, assetType, prevData?.manager]
  }
}

export function getKeyWithId(assetType: AssetType, assetId: string | null) {
  return (index: number, prevData: { activities: ActivityDetails[]; manager: ActivityManger } | null) => {
    if (index === 0) return [activityQuery.list, assetType, assetId]
    return [activityQuery.list, assetType, assetId, prevData?.manager]
  }
}

// fetcher
export function fetchActivities(database: Database) {
  return async ([_key, assetType, prevManager]: [typeof activityQuery.list, SupportActivityType, ActivityManger?]) => {
    const manager = prevManager ? prevManager : database.getActivityManager(assetType)
    const activities = await manager.getNext()
    return { activities, manager }
  }
}

export function fetchActivitiesById(database: Database) {
  return async ([_key, assetType, assetId, prevManager]: [
    typeof activityQuery.list,
    SupportActivityType,
    string,
    ActivityManger?
  ]) => {
    const manager = prevManager ? prevManager : database.getActivityManager(assetType, assetId)
    const activities = await manager.getNext()
    return { activities, manager }
  }
}

export function fetchActivityDetails(database: Database) {
  return async ([_key, assetType, activity]: [typeof activityQuery.details, SupportActivityType, Activity]) => {
    // #FIXME: activity log structures have changed.
    // check the activities and display format at https://myassets.atlassian.net/wiki/spaces/MR/pages/1027276801/Activity+Log#Creation-and-editing
    switch (activity.activityKind) {
      case ActivityKind.AssetAddedToGroup:
      case ActivityKind.AssetRemovedFromGroup: {
        const { groupId } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const group = await database.group.getById(groupId)
        const groupName = group.name
        return { groupName, ...details } as ActivityDetails
      }
      case ActivityKind.AssetShareholderCreated: {
        const { shareholder } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const owners = await Promise.all(shareholder.map((owner) => database.Account.getContact(owner.contactId)))
        const ownerNames = Object.fromEntries(owners.map((owner) => [owner.id, getFullName(owner)]))
        return { ownerNames, ...details } as ActivityDetails
      }
      case ActivityKind.AssetBeneficiariesCreated: {
        const { beneficiary } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const owners = await Promise.all(beneficiary.map((owner) => database.Account.getContact(owner.contactId)))
        const ownerNames = Object.fromEntries(owners.map((owner) => [owner.id, getFullName(owner)]))
        return { ownerNames, ...details } as ActivityDetails
      }
      case ActivityKind.AssetLocationCreated:
      case ActivityKind.BottleLocationCreated: {
        const { locationId, locationType } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const location = await getLocation(database, locationId, locationType)
        const locationName = getLocationName(location)
        return { locationName, ...details } as ActivityDetails
      }
      case ActivityKind.BottleLocationRemoved: {
        const { locationId, locationType } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const location = await getLocation(database, locationId, locationType)
        const locationName = getLocationName(location)
        return { locationName, ...details } as ActivityDetails
      }
      case ActivityKind.AssetLocationEdited: {
        const { previous, current } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const [prev, curr] = await Promise.all([
          getLocation(database, previous.locationId, previous.locationType),
          getLocation(database, current.locationId, current.locationType)
        ])
        const from = getLocationName(prev)
        const to = getLocationName(curr)
        return { from, to, ...details } as ActivityDetails
      }
      case ActivityKind.AssetShareholderRemoved: {
        const { shareholder } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const owners = await Promise.all(shareholder.map((contactId) => database.Account.getContact(contactId)))
        const ownerNames = Object.fromEntries(owners.map((owner) => [owner.id, getFullName(owner)]))
        return { ownerNames, ...details } as ActivityDetails
      }
      case ActivityKind.AssetShareholderEdited: {
        const { shareholder } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const owner = await database.Account.getContact(shareholder.contactId)
        const ownerName = getFullName(owner)
        return { ownerName, ...details } as ActivityDetails
      }
      case ActivityKind.AssetBeneficiariesRemoved: {
        const { beneficiary } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const owners = await Promise.all(beneficiary.map((contactId) => database.Account.getContact(contactId)))
        const ownerNames = Object.fromEntries(owners.map((owner) => [owner.id, getFullName(owner)]))
        return { ownerNames, ...details } as ActivityDetails
      }
      case ActivityKind.AssetBeneficiariesEdited: {
        const { contactId: beneficiaryId } = activity.detail
        const details = await getAssetDetails(database, activity.assetId, assetType)
        const owner = await database.Account.getContact(beneficiaryId)
        const ownerName = getFullName(owner)
        return { ownerName, ...details } as ActivityDetails
      }
      case ActivityKind.AssetPhotoAdded: {
        const details = await getAssetDetails(database, activity.assetId, assetType)
        return { ...details } as ActivityDetails
      }
      default:
        const details = await getAssetDetails(database, activity.assetId, assetType)
        return { ...details } as ActivityDetails
    }
  }
}

async function getAssetDetails(database: Database, assetId: string, assetType: AssetType) {
  switch (assetType) {
    case AssetType.Property:
      const property = await database.property.getById(assetId)
      return { name: property.name }
    case AssetType.Art:
      const art = await database.art.getById(assetId)
      return { name: art.name }
    case AssetType.WineAndSpirits:
      const wine = await database.wine.getWineById(assetId)
      return { name: wine.name, vintage: wine.vintage }
    case AssetType.OtherCollectables:
      const other = await database.otherCollectable.getById(assetId)
      return { name: other.name }
    case AssetType.Belonging:
      const belonging = await database.belonging.getById(assetId)
      return { name: belonging.name }
  }
}

async function getLocation(database: Database, id: string, type: LocationType) {
  return type === LocationType.MyProperty ? database.property.getById(id) : database.Account.getContact(id)
}

function getLocationName(location: Contact | Property) {
  return isContact(location) ? getFullName(location) : location.name
}
