import {
  Amount,
  LocationType,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import {
  BottleSizeToString,
  Wine,
  WineCatalogueMinInfo,
  Event as WineEvent,
  WinePricingMethod,
  WinePurchase,
} from "./wineAndSprits";
import { EventEnvelope } from "./event";
import Decimal from "decimal.js";
import {
  AllowedDecimalPlaces,
  addDecimal,
  calculatePercentage,
  isPurchasedLM,
  OmitKeys,
  mulAmount,
} from "../utils";
import { IdAndRole, RoleToAsset } from "./relations";
import { DataPoisoned, InvalidInput } from "./error";
import { AssetType } from "./enums";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  VersionedType,
  VersionedTypeString,
  WineSummaryTypeVersion,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<WineEvent>;
export type WineInSummary = Pick<
  Wine.Encrypted,
  | "subtype"
  | "vintage"
  | "country"
  | "labeledVariety"
  | "catalogueImage"
  | "updateAt"
> & {
  liability: Amount;
  value: Amount;
  purchases: WinePurchaseInSummary[];
};
type WinePurchaseInSummary = Pick<
  WinePurchase,
  | "id"
  | "valuePerBottle"
  | "purchaseDate"
  | "bottleCount"
  | "bottleSize"
  | "pricingMethod"
  | "price"
> & { ownedPercentage: number };
export interface WineSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.WineSummary, 2>;
  id: AssetType.WineAndSpirits;
  wines: {
    [wineId: string]: WineInSummary;
  };
}
export interface SubtypeItem {
  label: string;
  itemNumber: number;
  value: Amount;
  liability: Amount;
  percentage: number;
  mainImage?: Wine["catalogueImage"];
}

export interface BottleLocationInfo {
  bottles: number;
  // only in leaf node
  details?: {
    [wineId: string]: number;
  };
  child?: BottleLocationNode;
}
export interface BottleLocationNode {
  located: {
    //id: room, bin or shelf
    [id: string]: BottleLocationInfo;
  };
  notLocated: BottleLocationInfo;
}
export namespace BottlesInLocation {
  export interface DisplayInfo {
    // room, bin or shelf
    location?: string;
    bottles: number;
    // only in leaf node
    details?: {
      wineDocId: string;
      bottles: number;
    }[];
    child?: DisplayNode;
  }
  export interface DisplayNode {
    located?: DisplayInfo[];
    notLocated?: DisplayInfo;
  }
}

export namespace WineSummary {
  export function assureVersion(
    input: WineSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      WineSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;

    purchasedLM: number; //bottles
    drinkingNow: number;
    typesNumber: number;
    totalBottlesNumber: number;
    subtypeItems: SubtypeItem[];

    vintage: {
      range: string;
      number: number;
      value: Amount;
    }[];
    bottleSize: { size: string; bottles: number }[];
    origin: {
      origin: string;
      number: number;
      value: Amount;
    }[];
    varietal: {
      varietal: string;
      number: number;
      value: Amount;
    }[];
  }

  export interface LocationInfo {
    id: string;
    locationType: LocationType;
    itemNumber: number;
    value: Amount;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<WineSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    convertDate(summary);
    return new AggregateRoot(new WineSummaryAggregate(summary));
  }

  export function defaultValue(): WineSummary {
    return {
      "@type": WineSummaryTypeVersion,
      id: AssetType.WineAndSpirits,
      wines: {},
      version: 0,
    };
  }

  export function convertDate(input: WineSummary) {
    Object.values(input.wines).forEach((item) => {
      item.purchases.forEach((purchase) => {
        purchase.purchaseDate = (purchase.purchaseDate as any).toDate();
      });
    });
  }

  export function toDisplay(
    input: WineSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let totalBottlesNumber = 0;
    let purchasedLM = 0;
    let totalAssets = new Decimal(0);
    const subtypesMap: { [key: string]: SubtypeItem } = {};
    const vintageMap: { [range: string]: { number: number; value: number } } =
      {};
    const bottleSizeMap: { [size: string]: number } = {};
    const originMap: { [origin: string]: { number: number; value: number } } =
      {};
    const varietalMap: {
      [varietal: string]: { number: number; value: number };
    } = {};
    let hasUnspecifiedType = false;

    //#TODO we don't know how to do drinkNow
    Object.entries(input.wines).forEach(([_id, item]) => {
      let wineAssets = new Decimal(0);
      let wineLiability = new Decimal(0);
      let bottles = 0;
      if (item.subtype == "-") hasUnspecifiedType = true;
      item.purchases.forEach((v) => {
        if (isPurchasedLM(v.purchaseDate))
          purchasedLM += v.bottleCount.bottles + v.bottleCount.consumed;
        if (v.bottleCount.bottles == 0) return;
        const netWorth = new Decimal(v.valuePerBottle.value)
          .mul(v.bottleCount.bottles)
          .mul(exchangeRate.rates[v.valuePerBottle.currency].rate)
          .mul(v.ownedPercentage)
          .div(100)
          .toDecimalPlaces(AllowedDecimalPlaces);
        wineAssets = wineAssets.add(netWorth);
        bottles += v.bottleCount.bottles;
        const bottleSizeString = BottleSizeToString(v.bottleSize);
        if (bottleSizeMap[bottleSizeString]) {
          bottleSizeMap[bottleSizeString] += v.bottleCount.bottles;
        } else {
          bottleSizeMap[bottleSizeString] = v.bottleCount.bottles;
        }
      });
      totalBottlesNumber += bottles;

      const vintageRange = vintageToRange(item.vintage);
      if (vintageMap[vintageRange]) {
        vintageMap[vintageRange].number += bottles;
        vintageMap[vintageRange].value = addDecimal(
          vintageMap[vintageRange].value,
          wineAssets
        );
      } else {
        vintageMap[vintageRange] = {
          number: bottles,
          value: wineAssets.toNumber(),
        };
      }
      const origin = WineCatalogueMinInfo.getOrigin(item);
      if (originMap[origin]) {
        originMap[origin].number += bottles;
        originMap[origin].value = addDecimal(
          originMap[origin].value,
          wineAssets
        );
      } else {
        originMap[origin] = {
          number: bottles,
          value: wineAssets.toNumber(),
        };
      }
      const varietal = item.labeledVariety;
      if (varietalMap[varietal]) {
        varietalMap[varietal].number += bottles;
        varietalMap[varietal].value = addDecimal(
          varietalMap[varietal].value,
          wineAssets
        );
      } else {
        varietalMap[varietal] = {
          number: bottles,
          value: wineAssets.toNumber(),
        };
      }

      totalAssets = totalAssets.add(wineAssets);
      wineLiability = new Decimal(item.liability.value)
        .mul(exchangeRate.rates[item.liability.currency].rate)
        .toDecimalPlaces(AllowedDecimalPlaces)
        .add(wineLiability);

      const subtypeItem: SubtypeItem = {
        label: item.subtype,
        itemNumber: 1,
        liability: {
          currency,
          value: wineLiability.toNumber(),
        },
        value: { currency, value: wineAssets.toNumber() },
        percentage: 0,
      };
      if (subtypesMap[item.subtype]) {
        subtypesMap[item.subtype].itemNumber++;
        subtypesMap[item.subtype].value.value = addDecimal(
          subtypesMap[item.subtype].value.value,
          wineAssets
        );
      } else {
        subtypesMap[item.subtype] = subtypeItem;
      }
      if (!subtypesMap[item.subtype].mainImage && item.catalogueImage) {
        subtypesMap[item.subtype].mainImage = item.catalogueImage;
      }
    });
    const result: OmitKeys<Display, "typesNumber" | "subtypeItems"> = {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: 0,
      },
      netValue: {
        currency,
        value: totalAssets.toNumber(),
      },
      purchasedLM,
      drinkingNow: 0,
      totalBottlesNumber,

      vintage: Object.entries(vintageMap).map(([range, value]) => ({
        range,
        number: value.number,
        value: { currency, value: value.value },
      })),
      bottleSize: Object.entries(bottleSizeMap).map(([size, bottles]) => ({
        size,
        bottles,
      })),
      origin: Object.entries(originMap).map(([origin, value]) => ({
        origin,
        number: value.number,
        value: { currency, value: value.value },
      })),
      varietal: Object.entries(varietalMap).map(([varietal, value]) => ({
        varietal,
        number: value.number,
        value: { currency, value: value.value },
      })),
    };
    const subtypeItems: SubtypeItem[] = Object.values(subtypesMap).map(
      (item) => {
        item.percentage = calculatePercentage(
          item.value.value,
          result.assets.value
        );
        return item;
      }
    );
    subtypeItems.sort((a, b) => b.value.value - a.value.value);
    return {
      ...result,
      typesNumber: hasUnspecifiedType
        ? subtypeItems.length - 1
        : subtypeItems.length,
      subtypeItems,
    };
  }

  export type RelatedUpdates = never;
}

export class WineSummaryAggregate extends AggregateBase<
  WineSummary,
  never,
  WineEvent
> {
  state: WineSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: WineSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case WineEvent.Kind.PurchaseAdded:
        {
          const catalogueInfo = event.data.catalogueInfo;
          const purchase = event.data.data;
          const summaryPurchase: WinePurchaseInSummary = {
            id: purchase.id,
            valuePerBottle: purchase.valuePerBottle,
            purchaseDate: purchase.purchaseDate,
            bottleCount: purchase.bottleCount,
            bottleSize: purchase.bottleSize,
            ownedPercentage: purchase.ownership?.myOwnership || 100,
            pricingMethod: purchase.pricingMethod,
            price: purchase.price,
          };
          if (this.state.wines[event.aggregateId]) {
            this.state.wines[event.aggregateId].purchases.push(summaryPurchase);
          } else {
            this.state.wines[event.aggregateId] = {
              subtype: catalogueInfo.subtype,
              vintage: catalogueInfo.vintage,
              labeledVariety: catalogueInfo.labeledVariety,
              liability: Amount.defaultValue(),
              value: Amount.defaultValue(),
              purchases: [summaryPurchase],
              updateAt: event.time,
            };
            if (catalogueInfo.country) {
              this.state.wines[event.aggregateId].country =
                catalogueInfo.country;
            }
            if (catalogueInfo.catalogueImage) {
              this.state.wines[event.aggregateId].catalogueImage =
                catalogueInfo.catalogueImage;
            }
          }
          const contacts: IdAndRole[] = [];
          if (purchase.acquisition?.sellerId) {
            contacts.push({
              contactId: purchase.acquisition.sellerId,
              role: RoleToAsset.Seller,
            });
          }
          if (purchase.ownership) {
            purchase.ownership.shareholder.forEach((v) => {
              contacts.push({
                contactId: v.contactId,
                role: RoleToAsset.Shareholder,
              });
            });
          }
          if (purchase.beneficiary) {
            purchase.beneficiary.forEach((v) => {
              contacts.push({
                contactId: v.contactId,
                role: RoleToAsset.Beneficiary,
              });
            });
          }
        }
        break;
      case WineEvent.Kind.PurchaseUpdated:
        {
          const update = event.data.update;
          const purchaseId = event.data.id;
          const purchase = this.state.wines[event.aggregateId].purchases.find(
            (v) => v.id === purchaseId
          );
          if (!purchase)
            throw new DataPoisoned(`cannot find purchase ${purchaseId}`);
          if (update.bottleSize) {
            purchase.bottleSize = update.bottleSize;
          }
          if (update.valuePerBottle || update.addBottles) {
            if (update.valuePerBottle)
              purchase.valuePerBottle = update.valuePerBottle;
            if (update.addBottles) {
              purchase.bottleCount.pendings += update.addBottles.length;
              purchase.bottleCount.bottles += update.addBottles.length;
            }
          }
          if (update.purchaseDate) {
            purchase.purchaseDate = update.purchaseDate;
          }
          if (update.pricingMethod) {
            purchase.pricingMethod = update.pricingMethod;
          }
          if (update.price) {
            purchase.price = update.price;
          }
          this.state.wines[event.aggregateId].updateAt = event.time;
        }
        break;
      case WineEvent.Kind.PurchaseDeleted:
        event.data.ids.forEach((purchaseId) => {
          const purchaseIdx = this.state.wines[
            event.aggregateId
          ].purchases.findIndex((v) => v.id === purchaseId);
          if (purchaseIdx == -1) return;
          this.state.wines[event.aggregateId].purchases.splice(purchaseIdx, 1);
        });
        this.state.wines[event.aggregateId].updateAt = event.time;
        break;
      case WineEvent.Kind.CatalogueInfoUpdated:
        {
          const update = event.data.update;
          const wine = this.state.wines[event.aggregateId];
          if (update.subtype) wine.subtype = update.subtype;
          if (update.vintage) wine.vintage = update.vintage;
          if (update.country) wine.country = update.country;
          if (update.labeledVariety)
            wine.labeledVariety = update.labeledVariety;
          if (update.catalogueImage)
            wine.catalogueImage = update.catalogueImage;
          this.state.wines[event.aggregateId].updateAt = event.time;
        }
        break;
      case WineEvent.Kind.BottleRemoved: {
        const purchaseId = event.data.purchaseId;
        const purchase = this.state.wines[event.aggregateId].purchases.find(
          (v) => v.id === purchaseId
        );
        if (!purchase)
          throw new DataPoisoned(`cannot find purchase ${purchaseId}`);
        purchase.bottleCount.bottles -= event.data.ids.length;
        purchase.bottleCount.consumed += event.data.ids.length;
        this.state.wines[event.aggregateId].updateAt = event.time;
        break;
      }
      case WineEvent.Kind.WineDeleted:
        delete this.state.wines[event.aggregateId];
        break;
      case WineEvent.Kind.ShareholderUpdated:
        {
          const update = event.data.current;
          if (!update) break;
          const purchaseId = event.data.purchaseId;
          const purchase = this.state.wines[event.aggregateId].purchases.find(
            (purchase) => purchase.id === purchaseId
          );
          if (!purchase)
            throw new DataPoisoned(`cannot find purchase ${purchaseId}`);
          purchase.ownedPercentage = update.myOwnership;
        }
        break;
      case WineEvent.Kind.ValueUpdated:
        {
          const { valuePerBottle } = event.data.current;
          const purchaseId = event.data.purchaseId;
          const purchase = this.state.wines[event.aggregateId].purchases.find(
            (purchase) => purchase.id === purchaseId
          );
          if (!purchase)
            throw new DataPoisoned(`cannot find purchase ${purchaseId}`);
          if (valuePerBottle) purchase.valuePerBottle = valuePerBottle;
          this.state.wines[event.aggregateId].updateAt = event.time;
        }
        break;
    }
    return this;
  }
}

export enum VintageRange {
  WineVintageRange1 = "Before 1970",
  WineVintageRange2 = "1970-1979",
  WineVintageRange3 = "1980-1989",
  WineVintageRange4 = "1990-1999",
  WineVintageRange5 = "2000-2009",
  WineVintageRange6 = "After 2010",
}
export function vintageToRange(vintage: number) {
  if (isNaN(vintage)) throw new InvalidInput("vintage isNaN");
  if (vintage < 1970) return VintageRange.WineVintageRange1;
  if (vintage < 1980) return VintageRange.WineVintageRange2;
  if (vintage < 1990) return VintageRange.WineVintageRange3;
  if (vintage < 2000) return VintageRange.WineVintageRange4;
  if (vintage < 2010) return VintageRange.WineVintageRange5;
  return VintageRange.WineVintageRange6;
}
