export enum Type {
  Apartment = "Apartment",
  Bungalow = "Bungalow",
  Cabin = "Cabin",
  Castle = "Castle",
  Chalet = "Chalet",
  CommercialProperty = "CommercialProperty",
  Condo = "Condo",
  CountryEstate = "CountryEstate",
  FloatingProperty = "FloatingProperty",
  HotelOrBAndB = "HotelOrBAndB",
  House = "House",
  Land = "Land",
  OfficeBuilding = "Office Building",
  Other = "Other",
  Restaurant_Cafe = "Restaurant/Cafe",
  Section = "Section",
  Shop_Store = "Shop/Store",
  Townhouse = "Townhouse",
  Villa = "Villa",
  Warehouse = "Warehouse"
}

export const typeValues = Object.values(Type);
