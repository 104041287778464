import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2018%2017%22%3E%3Cpath%20d%3D%22M2.577%206.84a6.617%206.617%200%200%200%203.47%204.442c0%20.042-.006.083-.006.125%200%201.842.767%203.508%201.999%204.705a5.401%205.401%200%200%201-2.604.688C2.439%2016.8%200%2014.381%200%2011.407c0-1.93%201.036-3.614%202.577-4.567zm12.846%200C16.963%207.793%2018%209.477%2018%2011.407c0%202.974-2.439%205.393-5.436%205.393a5.402%205.402%200%200%201-2.604-.688%206.544%206.544%200%200%200%202-4.705c0-.042-.006-.083-.007-.125a6.617%206.617%200%200%200%203.47-4.442zM10.8%2011.76a5.302%205.302%200%200%201-1.74%203.6%205.303%205.303%200%200%201-1.74-3.6%206.463%206.463%200%200%200%201.74.244c.603%200%201.185-.089%201.74-.244zM9%207.44a5.32%205.32%200%200%201%201.68%203.06c-.531.18-1.09.3-1.68.3a5.21%205.21%200%200%201-1.68-.3A5.321%205.321%200%200%201%209%207.44zM12.561%206c.604%200%201.176.122%201.719.303a5.369%205.369%200%200%201-2.483%203.657A6.556%206.556%200%200%200%209.96%206.687%205.401%205.401%200%200%201%2012.561%206zM5.439%206c.947%200%201.825.263%202.601.687A6.556%206.556%200%200%200%206.203%209.96%205.369%205.369%200%200%201%203.72%206.303%205.406%205.406%200%200%201%205.439%206zM9.06%200c2.844%200%205.155%202.238%205.34%205.051a6.505%206.505%200%200%200-1.756-.247A6.502%206.502%200%200%200%209.06%205.88a6.502%206.502%200%200%200-3.584-1.076c-.609%200-1.196.09-1.756.247.185-2.813%202.496-5.05%205.34-5.05z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function GroupIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 18 17'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M2.577 6.84a6.617 6.617 0 0 0 3.47 4.442c0 .042-.006.083-.006.125 0 1.842.767 3.508 1.999 4.705a5.401 5.401 0 0 1-2.604.688C2.439 16.8 0 14.381 0 11.407c0-1.93 1.036-3.614 2.577-4.567zm12.846 0C16.963 7.793 18 9.477 18 11.407c0 2.974-2.439 5.393-5.436 5.393a5.402 5.402 0 0 1-2.604-.688 6.544 6.544 0 0 0 2-4.705c0-.042-.006-.083-.007-.125a6.617 6.617 0 0 0 3.47-4.442zM10.8 11.76a5.302 5.302 0 0 1-1.74 3.6 5.303 5.303 0 0 1-1.74-3.6 6.463 6.463 0 0 0 1.74.244c.603 0 1.185-.089 1.74-.244zM9 7.44a5.32 5.32 0 0 1 1.68 3.06c-.531.18-1.09.3-1.68.3a5.21 5.21 0 0 1-1.68-.3A5.321 5.321 0 0 1 9 7.44zM12.561 6c.604 0 1.176.122 1.719.303a5.369 5.369 0 0 1-2.483 3.657A6.556 6.556 0 0 0 9.96 6.687 5.401 5.401 0 0 1 12.561 6zM5.439 6c.947 0 1.825.263 2.601.687A6.556 6.556 0 0 0 6.203 9.96 5.369 5.369 0 0 1 3.72 6.303 5.406 5.406 0 0 1 5.439 6zM9.06 0c2.844 0 5.155 2.238 5.34 5.051a6.505 6.505 0 0 0-1.756-.247A6.502 6.502 0 0 0 9.06 5.88a6.502 6.502 0 0 0-3.584-1.076c-.609 0-1.196.09-1.756.247.185-2.813 2.496-5.05 5.34-5.05z'
        }
      />
    </svg>
  )
}
