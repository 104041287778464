import { CoreFirestore } from "../../../coreFirebase";
import { Encryption } from "../../database/encryption";
import {
  EncryptedType,
  EncryptionField,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
} from "../../utils";
import { Amount, PathsOfAmountField, PathsOfDateField } from "../common";
import { ActionType, ItemActions } from "./base";

export interface RentInfo extends ItemActions {
  actionType: ActionType.RentOut;

  tenantId: string;
  // Property Manager/Property Management Company
  propertyManager?: string;
  introducingAgent?: string;

  startDate: Date;
  endDate: Date;
  securityDeposit?: Amount;
  incomeAmount: Amount;
  incomeTaxLiabilityPercentage?: number;
  taxWithheldAtSource: boolean;
  managementFeesAmount?: Amount;
  managementFeesPaidByTenant: boolean;
  governmentRatesAmount?: Amount;
  governmentRatesPaidByTenant: boolean;
}

export namespace RentInfo {
  export const datePaths: readonly PathsOfDateField<RentInfo>[] = [
    "createAt",
    "updateAt",
    "startDate",
    "endDate",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<RentInfo>[] = [
    "securityDeposit",
    "incomeAmount",
    "managementFeesAmount",
    "governmentRatesAmount",
  ] as const;

  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<RentInfo> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    RentInfo,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys =
    | ItemActions.EncryptedKeys
    | "propertyManager"
    | "introducingAgent";

  export type Encrypted = EncryptedType<RentInfo, EncryptedKeys>;
  export type EncryptedPart = Pick<RentInfo, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
    //RentInfo
    "propertyManager",
    "introducingAgent",
  ];
  export type Update = CreateFields;
  export type UpdateEncrypted = EncryptedType<Update, EncryptedKeys>;

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): RentInfo {
    return ItemActions.fromCreate(createFields, ownerId, ActionType.RentOut);
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "tenantId",
    "taxWithheldAtSource",
    "governmentRatesPaidByTenant",
    "managementFeesPaidByTenant",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "propertyManager",
    "introducingAgent",
    "incomeTaxLiabilityPercentage",
    "notes",
  ];
  export function intoUpdate(
    current: RentInfo,
    update: Update
  ): UpdateObject<Update> {
    const result: UpdateObject<Update> = {
      ...ItemActions.intoUpdate(current, update),
      ...buildObjectUpdate(
        current,
        update,
        NonOptionalSimpleTypeUpdatableKeys,
        OptionalSimpleTypeUpdatableKeys
      ),
    };

    if (current.startDate.getTime() !== update.startDate.getTime())
      result.startDate = update.startDate;
    if (current.endDate.getTime() !== update.endDate.getTime())
      result.endDate = update.endDate;
    if (
      !Amount.optionalEqual(current.securityDeposit, update.securityDeposit)
    ) {
      if (update.securityDeposit)
        result.securityDeposit = update.securityDeposit;
      else result.securityDeposit = null;
    }
    if (!Amount.equal(current.incomeAmount, update.incomeAmount))
      result.incomeAmount = update.incomeAmount;
    if (
      !Amount.optionalEqual(
        current.managementFeesAmount,
        update.managementFeesAmount
      )
    ) {
      if (update.managementFeesAmount)
        result.managementFeesAmount = update.managementFeesAmount;
      else result.managementFeesAmount = null;
    }
    if (
      !Amount.optionalEqual(
        current.governmentRatesAmount,
        update.governmentRatesAmount
      )
    ) {
      if (update.governmentRatesAmount)
        result.governmentRatesAmount = update.governmentRatesAmount;
      else result.governmentRatesAmount = null;
    }

    return result;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    ItemActions.validateEncryptedPart(data);
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {}

  export function removeEncryptedFields<
    T extends RentInfo | UpdateObject<RentInfo>
  >(data: T): OmitKeys<T, EncryptedKeys> {
    return doRemoveEncryptedFields(data, encryptedKeysArray);
  }

  export async function encrypt(
    rawData: RentInfo,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<RentInfo, EncryptedKeys>;
}
