import * as React from 'react'
import { InfoIcon } from 'lucide-react'
import {
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'
import { type IndicatorsContainerProps } from 'react-select'

import { Currency } from 'core/remodel/types/common'
import { currencyOptions } from 'core/remodel/types/options'
import { cn } from '@/utils/classnames'
import { FormAutocomplete, FormNumberInput, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import { ChevronDownIcon } from '@/components/icon'

interface FormPriceInput<
  Values extends FieldValues,
  PickerPath extends FieldPath<Values>,
  InputPath extends FieldPath<Values>
> {
  className?: string
  label?: string
  info?: string
  disabled?: {
    currency?: boolean
    value?: boolean
  }
  format: string | undefined
  digits?: number
  allowNegative?: boolean
  // controller props
  control: Control<Values>
  name: {
    currency: PickerPath
    value: InputPath
  }
  rules?: {
    currency?: RegisterOptions<Values, PickerPath>
    value?: RegisterOptions<Values, InputPath>
  }
  defaultValue?: {
    currency?: FieldPathValue<Values, PickerPath>
    value?: FieldPathValue<Values, InputPath>
  }
  errorClassName?: string
  onChangedCurrency?: (value: Currency) => void
  onChangedValue?: (value: number) => void
  onFocus?: () => void
}

export function FormPriceInput<
  Values extends FieldValues,
  PickerPath extends FieldPath<Values>,
  InputValues extends FieldPath<Values>
>({
  className,
  label,
  info,
  disabled = { currency: false, value: false },
  format,
  digits,
  allowNegative = false,
  name,
  control,
  rules,
  defaultValue,
  errorClassName,
  onChangedCurrency,
  onChangedValue,
  onFocus
}: FormPriceInput<Values, PickerPath, InputValues>) {
  const id = React.useId()
  const isRequired = rules?.currency?.required !== undefined || rules?.value?.required !== undefined

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      <div className={'flex items-center gap-x-1'}>
        {/* Label */}
        {label && (
          <label
            htmlFor={id}
            className={cn('text-xs text-[#414554]', isRequired && 'after:ml-0.5 after:text-error after:content-["*"]')}
          >
            {label}
          </label>
        )}

        {/* Info */}
        {info && (
          <Tooltip>
            <TooltipTrigger asChild={true}>
              <InfoIcon className={'text-primary'} size={16} />
            </TooltipTrigger>
            <TooltipContent className={'rounded border-0 bg-primary px-2 py-1'}>
              <span className={'text-xs font-medium text-white'}>{info}</span>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <div className={'flex gap-x-0.5'}>
        <FormAutocomplete
          className={'shrink-0 basis-20 text-center md:text-left'}
          options={currencyOptions}
          control={control}
          name={name.currency}
          rules={rules?.currency}
          defaultValue={defaultValue?.currency}
          isDisabled={disabled.currency}
          components={{
            IndicatorsContainer: ({ selectProps }) =>
              typeof window !== 'undefined' && window.innerWidth > 768 ? (
                <ChevronDownIcon
                  className={cn(
                    '-ml-2 mr-2 cursor-pointer rounded-full text-primary hover:bg-gray-100',
                    selectProps.menuIsOpen && 'rotate-180'
                  )}
                />
              ) : null
          }}
          onChanged={onChangedCurrency}
          onFocus={onFocus}
        />
        <FormNumberInput
          className={'grow'}
          format={format}
          digits={digits}
          allowNegative={allowNegative}
          control={control}
          name={name.value}
          rules={rules?.value}
          defaultValue={defaultValue?.value}
          disabled={disabled.value}
          errorClassName={errorClassName}
          onChanged={onChangedValue}
          onFocus={onFocus}
        />
      </div>
    </div>
  )
}

function IndicatorsContainer({ selectProps }: IndicatorsContainerProps) {
  return (
    <ChevronDownIcon
      className={cn(
        '-ml-2 mr-2 cursor-pointer rounded-full text-primary hover:bg-gray-100',
        selectProps.menuIsOpen && 'rotate-180'
      )}
      size={20}
    />
  )
}
