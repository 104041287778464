export const exRate = {
  AED: {
    AED: 1,
    AFN: 19.407834,
    ARS: 238.51865516,
    AUD: 0.4113,
    BDT: 29.8915484,
    BHD: 0.10211916,
    BIF: 778.609364,
    BMD: 0.27132232,
    BND: 0.36638684,
    BOB: 1.83126336,
    BRL: 1.37645548,
    BSD: 0.269959,
    CAD: 0.372,
    CHF: 0.2458,
    CLP: 254.63788,
    CNH: 1.95275032,
    CNY: 1.965453,
    COP: 1059.62426164,
    CRC: 138.3878,
    CZK: 6.3258048,
    DJF: 48.406516,
    DKK: 1.88185768,
    DOP: 15.719296,
    DZD: 36.660324,
    EGP: 13.01845088,
    EUR: 0.2527,
    GBP: 0.2164,
    GTQ: 2.1086016,
    HKD: 2.1205036,
    HNL: 6.7047212,
    HTG: 36.618126,
    HUF: 98.210976,
    IDR: 4364.07623876,
    ILS: 1.01009028,
    INR: 22.653,
    IQD: 355.13404,
    JMD: 42.4390696,
    JOD: 0.19214156,
    JPY: 41.586,
    KES: 36.309756,
    KRW: 367.390936,
    KZT: 120.22222222222221,
    LBP: 24313.35827332,
    MAD: 2.7272892,
    MUR: 12.5518492,
    MVR: 4.1946976,
    MWK: 470.62150476,
    MXN: 4.6105102,
    MYR: 1.28667112,
    NGN: 374.514824,
    NIO: 9.9459604,
    NOK: 2.958,
    NZD: 0.4525,
    OMR: 0.10488116963480376,
    PEN: 1.00935452,
    PHP: 15.5111192,
    PKR: 75.73,
    PLN: 1.08994188,
    QAR: 0.9961151509114453,
    RON: 1.25451408,
    RUB: 24.8336312,
    SAR: 1.0157,
    SCR: 4.00389772,
    SEK: 2.941,
    SGD: 0.36643012,
    SZL: 5.017234,
    THB: 9.9870764,
    TND: 0.84967296,
    TRY: 8.74963628,
    TTD: 1.81676456,
    TWD: 8.773938,
    TZS: 702.494992,
    UAH: 10.76726332,
    UGX: 1028.67906164,
    USD: 0.2723,
    VND: 6894.91102676,
    XAF: 165.483244,
    XCD: 0.73301172,
    XPF: 30.081764,
    ZAR: 5.0339,
    ZMW: 7.3822696,
  },
  AFN: {
    AED: 0.05138763449852261,
    AFN: 1,
    ARS: 12.289813235212131,
    AUD: 0.02115626916429726,
    BDT: 1.5401795171990857,
    BHD: 0.0052617494564308415,
    BIF: 40.11830294921113,
    BMD: 0.01398004125550538,
    BND: 0.018878296259129172,
    BOB: 0.09435691587221944,
    BRL: 0.07092267380275408,
    BSD: 0.013909795394993589,
    CAD: 0.019147014550928248,
    CHF: 0.012662095110665105,
    CLP: 13.120365724480125,
    CNH: 0.10061660255338127,
    CNY: 0.10127111557116575,
    COP: 54.59775993755923,
    CRC: 7.130512348776272,
    CZK: 0.3259407927747115,
    DJF: 2.494174053632157,
    DKK: 0.09696381780676813,
    DOP: 0.8099459218375424,
    DZD: 1.8889446395718348,
    EGP: 0.6707832970953894,
    EUR: 0.01299771422199922,
    GBP: 0.011150136589173217,
    GTQ: 0.10864693092490382,
    HKD: 0.10926018843730835,
    HNL: 0.3454646819423538,
    HTG: 1.8867703629369459,
    HUF: 5.060377989630373,
    IDR: 224.86158108936834,
    ILS: 0.05204549255728382,
    INR: 1.1659028823103084,
    IQD: 18.298489156492167,
    JMD: 2.1866978870491165,
    JOD: 0.009900206277526899,
    JPY: 2.140937726487149,
    KES: 1.870881418297374,
    KRW: 18.930032892902936,
    KZT: 6.194520327318453,
    LBP: 1252.7600078050955,
    MAD: 0.14052517143335005,
    MUR: 0.6467413725818141,
    MVR: 0.21613424764453365,
    MWK: 24.24904833584211,
    MXN: 0.23755923510062998,
    MYR: 0.06629648213190611,
    NGN: 19.29709538941852,
    NIO: 0.5124714277749902,
    NOK: 0.15163962758543792,
    NZD: 0.023270335061604502,
    OMR: 0.005372135808663656,
    PEN: 0.05200758209288064,
    PHP: 0.7992194904387578,
    PKR: 3.8921670290461057,
    PLN: 0.05615989295868874,
    QAR: 0.05091486870714166,
    RON: 0.06463957183475498,
    RUB: 1.27956737470034,
    SAR: 0.05238780175057144,
    SCR: 0.2063031722138596,
    SEK: 0.1512839382282433,
    SGD: 0.018880526286447007,
    SZL: 0.25851591681998104,
    THB: 0.5145899537269332,
    TND: 0.04377989630372972,
    TRY: 0.45083012766906394,
    TTD: 0.09360985672074483,
    TWD: 0.4520822880080281,
    TZS: 36.19646540670123,
    UAH: 0.5547895411718793,
    UGX: 53.00329040530746,
    USD: 0.013841347704343277,
    VND: 355.2643240229693,
    XAF: 8.52662095110665,
    XCD: 0.03776885766850644,
    XPF: 1.5499804872609688,
    ZAR: 0.2584690862463065,
    ZMW: 0.38037575960305514,
  },
  ARS: {
    AED: 0.00414783,
    AFN: 0.0807165,
    ARS: 1,
    AUD: 0.0017220960595514595,
    BDT: 0.1243179,
    BHD: 0.00042471,
    BIF: 3.238209,
    BMD: 0.00112842,
    BND: 0.00152379,
    BOB: 0.00761616,
    BRL: 0.0058,
    BSD: 0.00112275,
    CAD: 0.0015578842809787627,
    CHF: 0.00102204,
    CLP: 1.05903,
    CNH: 0.00812142,
    CNY: 0.00817425,
    COP: 4.40694009,
    CRC: 0.57555,
    CZK: 0.0263088,
    DJF: 0.201321,
    DKK: 0.00782658,
    DOP: 0.065376,
    DZD: 0.152469,
    EGP: 0.05414328,
    EUR: 0.00104913,
    GBP: 0.0009,
    GTQ: 0.0087696,
    HKD: 0.0088191,
    HNL: 0.0278847,
    HTG: 0.1522935,
    HUF: 0.408456,
    IDR: 18.15003981,
    ILS: 0.00420093,
    INR: 0.0941076,
    IQD: 1.47699,
    JMD: 0.1765026,
    JOD: 0.00079911,
    JPY: 0.172809,
    KES: 0.151011,
    KRW: 1.527966,
    KZT: 0.49999999999999994,
    LBP: 101.11840317,
    MAD: 0.0113427,
    MUR: 0.0522027,
    MVR: 0.0174456,
    MWK: 1.95729831,
    MXN: 0.01917495,
    MYR: 0.00535122,
    NGN: 1.557594,
    NIO: 0.0413649,
    NOK: 0.01223982,
    NZD: 0.0018783,
    OMR: 0.00043362,
    PEN: 0.00419787,
    PHP: 0.0645102,
    PKR: 0.3141621,
    PLN: 0.00453303,
    QAR: 0.00410967,
    RON: 0.00521748,
    RUB: 0.1032822,
    SAR: 0.00422856,
    SCR: 0.01665207,
    SEK: 0.01221111,
    SGD: 0.0015361995437180116,
    SZL: 0.0208665,
    THB: 0.0415359,
    TND: 0.00353376,
    TRY: 0.03638943,
    TTD: 0.00755586,
    TWD: 0.0364905,
    TZS: 2.921652,
    UAH: 0.04478067,
    UGX: 4.27824009,
    USD: 0.00112959,
    VND: 28.67569281,
    XAF: 0.688239,
    XCD: 0.00304857,
    XPF: 0.125109,
    ZAR: 0.02086272,
    ZMW: 0.0307026,
  },
  AUD: {
    AED: 2.4313153415998054,
    AFN: 47.2370895,
    ARS: 580.6877,
    AUD: 1,
    BDT: 72.7535977,
    BHD: 0.24854973,
    BIF: 1895.071867,
    BMD: 0.66037646,
    BND: 0.89175577,
    BOB: 4.45714608,
    BRL: 3.3486,
    BSD: 0.65705825,
    CAD: 0.9041,
    CHF: 0.5985,
    CLP: 619.76789,
    CNH: 4.7512,
    CNY: 4.7847,
    COP: 2579.03927267,
    CRC: 336.82465,
    CZK: 15.381,
    DJF: 117.817523,
    DKK: 4.5769,
    DOP: 38.259488,
    DZD: 89.228247,
    EGP: 31.68585064,
    EUR: 0.6142,
    GBP: 0.5267,
    GTQ: 5.1321648,
    HKD: 5.1623,
    HNL: 16.3187461,
    HTG: 89.1255405,
    HUF: 238.53,
    IDR: 10637.5996,
    ILS: 2.4499,
    INR: 55.11,
    IQD: 864.36737,
    JMD: 103.2932438,
    JOD: 0.46765693,
    JPY: 101.17,
    KES: 88.374993,
    KRW: 895.5,
    KZT: 292.6111111111111,
    LBP: 59176.73661071,
    MAD: 6.6380001,
    MUR: 30.5501801,
    MVR: 10.2095528,
    MWK: 1145.45446653,
    MXN: 11.2254,
    MYR: 3.1326,
    NGN: 911.538622,
    NIO: 24.2076587,
    NOK: 7.2028,
    NZD: 1.0987,
    OMR: 0.25376406,
    PEN: 2.45668681,
    PHP: 37.765,
    PKR: 183.885,
    PLN: 2.6488,
    QAR: 2.40507021,
    RON: 3.05338524,
    RUB: 60.4430386,
    SAR: 2.47464728,
    SCR: 9.74516141,
    SEK: 7.1569,
    SGD: 0.8939,
    SZL: 12.2115395,
    THB: 24.308,
    TND: 2.06803488,
    TRY: 21.2949,
    TTD: 4.42185718,
    TWD: 21.317,
    TZS: 1709.815676,
    UAH: 26.20664321,
    UGX: 2503.72117267,
    USD: 0.6615069127472382,
    VND: 16781.65267003,
    XAF: 402.772757,
    XCD: 1.78409091,
    XPF: 73.216567,
    ZAR: 12.2301,
    ZMW: 17.9678438,
  },
  BDT: {
    AED: 0.03318264,
    AFN: 0.645732,
    ARS: 7.93592568,
    AUD: 0.01366128,
    BDT: 1,
    BHD: 0.00339768,
    BIF: 25.905672,
    BMD: 0.00902736,
    BND: 0.01219032,
    BOB: 0.06092928,
    BRL: 0.04579704,
    BSD: 0.008982,
    CAD: 0.01236384,
    CHF: 0.00817632,
    CLP: 8.47224,
    CNH: 0.06497136,
    CNY: 0.065394,
    COP: 35.25552072,
    CRC: 4.6044,
    CZK: 0.2104704,
    DJF: 1.610568,
    DKK: 0.06261264,
    DOP: 0.523008,
    DZD: 1.219752,
    EGP: 0.43314624,
    EUR: 0.00839304,
    GBP: 0.0072,
    GTQ: 0.0701568,
    HKD: 0.0705528,
    HNL: 0.2230776,
    HTG: 1.218348,
    HUF: 3.267648,
    IDR: 145.20031848,
    ILS: 0.03360744,
    INR: 0.7528608,
    IQD: 11.81592,
    JMD: 1.4120208,
    JOD: 0.00639288,
    JPY: 1.382472,
    KES: 1.208088,
    KRW: 12.223728,
    KZT: 3.9999999999999996,
    LBP: 808.94722536,
    MAD: 0.0907416,
    MUR: 0.4176216,
    MVR: 0.1395648,
    MWK: 15.65838648,
    MXN: 0.1533996,
    MYR: 0.04280976,
    NGN: 12.460752,
    NIO: 0.3309192,
    NOK: 0.09791856,
    NZD: 0.0150264,
    OMR: 0.00346896,
    PEN: 0.03358296,
    PHP: 0.5160816,
    PKR: 2.5132968,
    PLN: 0.03626424,
    QAR: 0.03287736,
    RON: 0.04173984,
    RUB: 0.8262576,
    SAR: 0.03382848,
    SCR: 0.13321656,
    SEK: 0.09768888,
    SGD: 0.01219176,
    SZL: 0.166932,
    THB: 0.3322872,
    TND: 0.02827008,
    TRY: 0.29111544,
    TTD: 0.06044688,
    TWD: 0.291924,
    TZS: 23.373216,
    UAH: 0.35824536,
    UGX: 34.22592072,
    USD: 0.0091,
    VND: 229.40554248,
    XAF: 5.505912,
    XCD: 0.02438856,
    XPF: 1.000872,
    ZAR: 0.16690176,
    ZMW: 0.2456208,
  },
  BHD: {
    AED: 9.766264038991311,
    AFN: 190.0508582326764,
    ARS: 2335.6895528713712,
    AUD: 4.020767111676203,
    BDT: 292.71243907607544,
    BHD: 1,
    BIF: 7624.517906336088,
    BMD: 2.6569188387370204,
    BND: 3.587836406018224,
    BOB: 17.93261284170375,
    BRL: 13.47891502436957,
    BSD: 2.643568552659462,
    CAD: 3.638906547997457,
    CHF: 2.406442042805679,
    CLP: 2493.536766264039,
    CNH: 19.122271667726213,
    CNY: 19.24666242848061,
    COP: 10376.35113371477,
    CRC: 1355.159991523628,
    CZK: 61.94532739987285,
    DJF: 474.0199194744649,
    DKK: 18.428056791693155,
    DOP: 153.9309175672812,
    DZD: 358.9955499046408,
    EGP: 127.48294130112312,
    EUR: 2.470226742954016,
    GBP: 2.1190930281839373,
    GTQ: 20.648442466624285,
    HKD: 20.7649925831744,
    HNL: 65.65585929222293,
    HTG: 358.58232676414497,
    HUF: 961.7291799109981,
    IDR: 42735.136469591016,
    ILS: 9.891290527654164,
    INR: 221.5808433990252,
    IQD: 3477.6435685526594,
    JMD: 415.5838101292647,
    JOD: 1.881542699724518,
    JPY: 406.8870523415978,
    KES: 355.5626191989828,
    KRW: 3597.6689976689977,
    KZT: 1177.2739045466317,
    LBP: 238088.11464293284,
    MAD: 26.706929434202163,
    MUR: 122.91375291375292,
    MVR: 41.07649925831744,
    MWK: 4608.552447552448,
    MXN: 45.14833651197288,
    MYR: 12.599703326976055,
    NGN: 3667.429540156813,
    NIO: 97.39563466836194,
    NOK: 28.81924136469591,
    NZD: 4.422547149819877,
    OMR: 1.020979020979021,
    PEN: 9.884085611358339,
    PHP: 151.89235007416826,
    PKR: 737.56,
    PLN: 10.673235855054036,
    QAR: 9.676414494596314,
    RON: 12.284806102987922,
    RUB: 243.18287772833227,
    SAR: 9.956346683619412,
    SCR: 39.20809493536766,
    SEK: 28.751642297096843,
    SGD: 3.588260224623861,
    SZL: 49.13117185844459,
    THB: 97.79826234371689,
    TND: 8.320406865861411,
    TRY: 85.68065268065268,
    TTD: 17.790633608815426,
    TWD: 85.91862682771774,
    TZS: 6879.169315532952,
    UAH: 105.43822843822844,
    UGX: 10073.320830684466,
    USD: 2.675227394328518,
    VND: 67518.28968001695,
    XAF: 1620.4916295825387,
    XCD: 7.178003814367451,
    XPF: 294.5751218478491,
    ZAR: 49.12227166772622,
    ZMW: 72.29073956346684,
  },
  BIF: {
    AED: 0.0012809024988813262,
    AFN: 0.024926278692944157,
    ARS: 0.30633930978513124,
    AUD: 0.0005273470612922143,
    BDT: 0.03839094388286859,
    BHD: 0.00013115583336344259,
    BIF: 1,
    BMD: 0.00034847040447358405,
    BND: 0.0004705656738030189,
    BOB: 0.0023519667816376275,
    BRL: 0.0017678383328562177,
    BSD: 0.00034671943657744146,
    CAD: 0.00047726382083429454,
    CHF: 0.000315618911565004,
    CLP: 0.32704189260174377,
    CNH: 0.0025079974763827784,
    CNY: 0.0025243120502722338,
    COP: 1.3609189802140629,
    CRC: 0.1777371380290772,
    CZK: 0.008124491038101618,
    DJF: 0.06217047756954539,
    DKK: 0.0024169471457833637,
    DOP: 0.02018893777393615,
    DZD: 0.04708436052151051,
    EGP: 0.016720131405971634,
    EUR: 0.00032398464706879637,
    GBP: 0.0002779314120861254,
    GTQ: 0.0027081636793672057,
    HKD: 0.002723449907031943,
    HNL: 0.008611148940664424,
    HTG: 0.04703016389615371,
    HUF: 0.12613639206116714,
    IDR: 5.6049624375696565,
    ILS: 0.0012973004521944075,
    INR: 0.029061620173373617,
    IQD: 0.4561132403745404,
    JMD: 0.0545062409498584,
    JOD: 0.00024677530079127076,
    JPY: 0.053365610434656936,
    KES: 0.04663411163393098,
    KRW: 0.47185527555509854,
    KZT: 0.15440634004784742,
    LBP: 31.22664508992471,
    MAD: 0.0035027695865214384,
    MUR: 0.016120855695231532,
    MVR: 0.005387422491877455,
    MWK: 0.6044385368578743,
    MXN: 0.005921467700200945,
    MYR: 0.0016525245899816844,
    NGN: 0.48100477764097377,
    NIO: 0.012774005630890409,
    NOK: 0.0037798116180888883,
    NZD: 0.0005800428570237438,
    OMR: 0.0001339073543430952,
    PEN: 0.0012963554853933146,
    PHP: 0.019921567755509295,
    PKR: 0.0970172400854917,
    PLN: 0.0013998571432541878,
    QAR: 0.0012691182070088745,
    RON: 0.0016112239821456861,
    RUB: 0.03189485298817958,
    SAR: 0.0013058329465454517,
    SCR: 0.005142370365841118,
    SEK: 0.003770945606043341,
    SGD: 0.00047062126008543616,
    SZL: 0.006443839789216818,
    THB: 0.012826812599186773,
    TND: 0.0010912698964149628,
    TRY: 0.011237517405454683,
    TTD: 0.002333345377027857,
    TWD: 0.011268729103031954,
    TZS: 0.9022431844269472,
    UAH: 0.013828838719180881,
    UGX: 1.321174788285747,
    USD: 0.0003505893283903978,
    VND: 8.855417550256949,
    XAF: 0.21253693013638095,
    XCD: 0.0009414370721593326,
    XPF: 0.038635245594092295,
    ZAR: 0.006442672477286055,
    ZMW: 0.009481352191906081,
  },
  BMD: {
    AED: 3.67578561174031,
    AFN: 71.53054713670443,
    ARS: 879.0970649226352,
    AUD: 1.5133195086935716,
    BDT: 110.16988355399586,
    BHD: 0.3763758175147552,
    BIF: 2869.6841601531346,
    BMD: 1,
    BND: 1.3503748604243102,
    BOB: 6.749401818471846,
    BRL: 5.073137661509013,
    BSD: 0.994975275163503,
    CAD: 1.3695964268623386,
    CHF: 0.9057265911628649,
    CLP: 938.5069389057267,
    CNH: 7.1971606316796946,
    CNY: 7.243978305949913,
    COP: 3905.407640772053,
    CRC: 510.0494496729941,
    CZK: 23.314723241346307,
    DJF: 178.40963471048016,
    DKK: 6.935874940181847,
    DOP: 57.93587494018185,
    DZD: 135.11724357951826,
    EGP: 47.98149625139576,
    EUR: 0.9297336098261286,
    GBP: 0.7975753708725475,
    GTQ: 7.771574413782103,
    HKD: 7.815441059180093,
    HNL: 24.71127771574414,
    HTG: 134.96171638219812,
    HUF: 361.97160631679697,
    IDR: 16084.471925346947,
    ILS: 3.72284255862179,
    INR: 83.39767107991706,
    IQD: 1308.9009411389377,
    JMD: 156.4156962832988,
    JOD: 0.7081671717977349,
    JPY: 153.14244696123785,
    KES: 133.82517147870473,
    KRW: 1354.0756101451589,
    KZT: 443.09742826252636,
    LBP: 89610.6087892806,
    MAD: 10.051842399106716,
    MUR: 46.261764236720374,
    MVR: 15.46020098899346,
    MWK: 1734.5476950071782,
    MXN: 16.99274206412506,
    MYR: 4.742223640133993,
    NGN: 1380.331791354283,
    NIO: 36.65736162067316,
    NOK: 10.846865528792472,
    NZD: 1.6645397990110067,
    OMR: 0.3842718136863934,
    PEN: 3.7201308023608233,
    PHP: 57.16860743340246,
    PKR: 278.4088371351093,
    PLN: 4.01714787047376,
    QAR: 3.6419684160153136,
    RON: 4.623703940022333,
    RUB: 91.52815441059181,
    SAR: 3.747328122507577,
    SCR: 14.756978784495136,
    SEK: 10.821422874461637,
    SGD: 1.3505343754984847,
    SZL: 18.491784973680012,
    THB: 36.80890094113894,
    TND: 3.1315999361939704,
    TRY: 32.24812569787845,
    TTD: 6.695964268623385,
    TWD: 32.33769341202744,
    TZS: 2589.1529749561337,
    UAH: 39.68439942574574,
    UGX: 3791.3543627372787,
    USD: 1,
    VND: 25412.251475514437,
    XAF: 609.9138618599458,
    XCD: 2.7016270537565803,
    XPF: 110.87095230499283,
    ZAR: 18.48843515712235,
    ZMW: 27.208486201946087,
  },
  BND: {
    AED: 2.72204831374402,
    AFN: 52.97088181442325,
    ARS: 651.0022444037565,
    AUD: 1.1206662335361173,
    BDT: 81.58466717854823,
    BHD: 0.27871950859370387,
    BIF: 2125.1018841178907,
    BMD: 0.7405351131061367,
    BND: 1,
    BOB: 4.998169039040813,
    BRL: 3.7568365719685786,
    BSD: 0.7368141279310142,
    CAD: 1.0142342448762627,
    CHF: 0.6707223436300278,
    CLP: 694.9973421534464,
    CNH: 5.329750162423957,
    CNY: 5.364420294135019,
    COP: 2892.091488984703,
    CRC: 377.7095269033135,
    CZK: 17.265371212568663,
    DJF: 132.11859901954995,
    DKK: 5.136258933317584,
    DOP: 42.90354970173056,
    DZD: 100.05906325674799,
    EGP: 35.531982753529036,
    EUR: 0.688500383911169,
    GBP: 0.5906325674797709,
    GTQ: 5.755123737522887,
    HKD: 5.787608528734275,
    HNL: 18.299568838225742,
    HTG: 99.94388990608944,
    HUF: 268.0526844250192,
    IDR: 11911.11623648928,
    ILS: 2.7568956352253267,
    INR: 61.758903785954764,
    IQD: 969.287106491052,
    JMD: 115.83131533872779,
    JOD: 0.5244226566652885,
    JPY: 113.4073592817908,
    KES: 99.10223849743076,
    KRW: 1002.7405351131063,
    KZT: 328.1292041554282,
    LBP: 66359.80231527967,
    MAD: 7.443742247947553,
    MUR: 34.258460811529154,
    MVR: 11.448821688027879,
    MWK: 1284.4934735101294,
    MXN: 12.583722166440259,
    MYR: 3.5117831197212217,
    NGN: 1022.1841592345403,
    NIO: 27.14606343393775,
    NOK: 8.032484791211388,
    NZD: 1.232650168330282,
    OMR: 0.2845667710117536,
    PEN: 2.7548874844958955,
    PHP: 42.335361171815016,
    PKR: 206.17151969759615,
    PLN: 2.974839052625362,
    QAR: 2.697005492882878,
    RON: 3.4240151201937277,
    RUB: 67.77981217884354,
    SAR: 2.7750280550469557,
    SCR: 10.928060953280966,
    SEK: 8.013643612308783,
    SGD: 1.000118126513496,
    SZL: 13.693816077018488,
    THB: 27.258283621758906,
    TND: 2.3190597129525723,
    TRY: 23.880869411139333,
    TTD: 4.958596657019669,
    TWD: 23.94719744846731,
    TZS: 1917.3586911582306,
    UAH: 29.387691217293725,
    UGX: 2807.6310318350957,
    USD: 0.7406858751203614,
    VND: 18818.664520701674,
    XAF: 451.6626306774556,
    XCD: 2.000649695824228,
    XPF: 82.10383320536295,
    ZAR: 13.691335420235074,
    ZMW: 20.148839407004903,
  },
  BOB: {
    AED: 0.5446090943467574,
    AFN: 10.598057288712422,
    ARS: 130.24814473435433,
    AUD: 0.22421535261864248,
    BDT: 16.32291075817735,
    BHD: 0.0557643221781055,
    BIF: 425.17607298166007,
    BMD: 0.14816127812440916,
    BND: 0.20007326526753638,
    BOB: 1,
    BRL: 0.7516425600302514,
    BSD: 0.14741680847041028,
    CAD: 0.20292115711854794,
    CHF: 0.13419360937795424,
    CLP: 139.0503875968992,
    CNH: 1.0663405180563434,
    CNY: 1.0732770845150312,
    COP: 578.6301876536207,
    CRC: 75.56957837020231,
    CZK: 3.4543391945547364,
    DJF: 26.43339950841369,
    DKK: 1.0276280960484023,
    DOP: 8.583853280393269,
    DZD: 20.01914350538854,
    EGP: 7.108999810928342,
    EUR: 0.13775051994705995,
    GBP: 0.11816978634902628,
    GTQ: 1.1514463981849121,
    HKD: 1.1579457364341086,
    HNL: 3.6612544904518813,
    HTG: 19.996100397050483,
    HUF: 53.63017583664209,
    IDR: 2383.0959184155795,
    ILS: 0.5515811117413499,
    INR: 12.356305539799584,
    IQD: 193.92843637738702,
    JMD: 23.174749480052938,
    JOD: 0.10492295329930043,
    JPY: 22.689780676876538,
    KES: 19.82770845150312,
    KRW: 200.6215730761959,
    KZT: 65.64988130501459,
    LBP: 13276.822331726224,
    MAD: 1.4892938173567782,
    MUR: 6.854202117602571,
    MVR: 2.2906031385895256,
    MWK: 256.9928034600113,
    MXN: 2.5176663830591792,
    MYR: 0.7026139156740404,
    NGN: 204.51172244280582,
    NIO: 5.4312015503875966,
    NOK: 1.6070854603894875,
    NZD: 0.24662034411041783,
    OMR: 0.05693420306296086,
    PEN: 0.5511793344677632,
    PHP: 8.470173945925506,
    PKR: 41.24940915106826,
    PLN: 0.5951857629041407,
    QAR: 0.5395986954055587,
    RON: 0.6850538854225752,
    RUB: 13.560928341841558,
    SAR: 0.5552089241822651,
    SCR: 2.186412837965589,
    SEK: 1.6033158442049538,
    SGD: 0.2000968992248062,
    SZL: 2.7397664965021744,
    THB: 5.453653809793912,
    TND: 0.4639818491208168,
    TRY: 4.777923520514275,
    TTD: 0.9920826243146152,
    TWD: 4.791193987521271,
    TZS: 383.612214029117,
    UAH: 5.879691340518057,
    UGX: 561.7319082057099,
    USD: 0.1457725947521866,
    VND: 3765.1116586311214,
    XAF: 90.36561731896388,
    XCD: 0.4002765173000567,
    XPF: 16.426782000378143,
    ZAR: 2.7392701833995083,
    ZMW: 4.031244091510683,
  },
  BRL: {
    AED: 0.724558617762196,
    AFN: 14.099863222601286,
    ARS: 172.41379310344828,
    AUD: 0.2986322642298274,
    BDT: 21.716320530759194,
    BHD: 0.07418994764727153,
    BIF: 565.6625843067587,
    BMD: 0.19711666954894902,
    BND: 0.26618139512946687,
    BOB: 1.3304196079047905,
    BRL: 1,
    BSD: 0.19612621252377885,
    CAD: 0.2700731898344451,
    CHF: 0.1784,
    CLP: 184.9953621456758,
    CNH: 1.4186803339255114,
    CNY: 1.4275,
    COP: 769.8209473800054,
    CRC: 100.53924882481489,
    CZK: 4.595720596789662,
    DJF: 35.16751300957442,
    DKK: 1.3671765686166617,
    DOP: 11.420126715613062,
    DZD: 26.633861053028756,
    EGP: 9.457952741050514,
    EUR: 0.1831,
    GBP: 0.1572154008206644,
    GTQ: 1.531906865596554,
    HKD: 1.5387,
    HNL: 4.871004763626645,
    HTG: 26.603204049868726,
    HUF: 71.35063750845033,
    IDR: 3170.5175373779616,
    ILS: 0.7338343264106152,
    INR: 16.439071171411953,
    IQD: 258.0061942867923,
    JMD: 30.832141116543777,
    JOD: 0.1395915543886679,
    JPY: 30.129,
    KES: 26.37917210369928,
    KRW: 267.33,
    KZT: 87.34188934481355,
    LBP: 17663.74476079677,
    MAD: 1.9813856965428334,
    MUR: 9.118964893800998,
    MVR: 3.047463329507759,
    MWK: 341.90826481362114,
    MXN: 3.3398,
    MYR: 0.9344,
    NGN: 272.0864055842911,
    NIO: 7.225777037118556,
    NOK: 2.138098008080872,
    NZD: 0.3281085415127266,
    OMR: 0.07574638011539611,
    PEN: 0.733299794047825,
    PHP: 11.268885500023583,
    PKR: 54.879022749068504,
    PLN: 0.7918468093134404,
    QAR: 0.7178926847673999,
    RON: 0.9114091216375556,
    RUB: 18.024,
    SAR: 0.7386608392158096,
    SCR: 2.908846510604179,
    SEK: 2.1324,
    SGD: 0.266,
    SZL: 3.645039068027104,
    THB: 7.255647963274483,
    TND: 0.6172905497822567,
    TRY: 6.356643136761678,
    TTD: 1.319886176049806,
    TWD: 6.374298426273838,
    TZS: 510.3652113761064,
    UAH: 7.822456647853224,
    UGX: 747.3391450626503,
    USD: 0.19715310910453057,
    VND: 5009.178376593772,
    XAF: 120.22418916157028,
    XCD: 0.5325357271998366,
    XPF: 21.85451286808056,
    ZAR: 3.6464,
    ZMW: 5.363246183596146,
  },
  BSD: {
    AED: 3.6943486973947897,
    AFN: 71.89178356713427,
    ARS: 883.5365931863728,
    AUD: 1.5209619238476955,
    BDT: 110.72625250501002,
    BHD: 0.37827655310621244,
    BIF: 2884.176352705411,
    BMD: 1.0050501002004009,
    BND: 1.3571943887775553,
    BOB: 6.7834869739478965,
    BRL: 5.09875751503006,
    BSD: 1,
    CAD: 1.3765130260521043,
    CHF: 0.9103006012024049,
    CLP: 943.246492985972,
    CNH: 7.233507014028056,
    CNY: 7.28056112224449,
    COP: 3925.130340681363,
    CRC: 512.625250501002,
    CZK: 23.43246492985972,
    DJF: 179.31062124248498,
    DKK: 6.970901803607215,
    DOP: 58.22845691382766,
    DZD: 135.7995991983968,
    EGP: 48.22380761523046,
    EUR: 0.9344288577154309,
    GBP: 0.8016032064128257,
    GTQ: 7.810821643286574,
    HKD: 7.854909819639279,
    HNL: 24.83607214428858,
    HTG: 135.6432865731463,
    HUF: 363.79959919839683,
    IDR: 16165.700120240483,
    ILS: 3.7416432865731464,
    INR: 83.81883767535071,
    IQD: 1315.5110220440883,
    JMD: 157.2056112224449,
    JOD: 0.711743486973948,
    JPY: 153.91583166332666,
    KES: 134.50100200400803,
    KRW: 1360.9138276553108,
    KZT: 445.335114673792,
    LBP: 90063.15134268538,
    MAD: 10.102605210420842,
    MUR: 46.49539078156313,
    MVR: 15.538276553106213,
    MWK: 1743.3073346693388,
    MXN: 17.07855711422846,
    MYR: 4.766172344689379,
    NGN: 1387.302605210421,
    NIO: 36.84248496993988,
    NOK: 10.901643286573147,
    NZD: 1.6729458917835673,
    OMR: 0.3862124248496994,
    PEN: 3.738917835671343,
    PHP: 57.45731462925852,
    PKR: 279.81482965931866,
    PLN: 4.037434869739479,
    QAR: 3.660360721442886,
    RON: 4.647054108216433,
    RUB: 91.99038076152306,
    SAR: 3.76625250501002,
    SCR: 14.831503006012024,
    SEK: 10.876072144288578,
    SGD: 1.3573547094188378,
    SZL: 18.585170340681366,
    THB: 36.99478957915832,
    TND: 3.147414829659319,
    TRY: 32.41098196392786,
    TTD: 6.729779559118237,
    TWD: 32.501002004008015,
    TZS: 2602.228456913828,
    UAH: 39.88480961923848,
    UGX: 3810.501082164329,
    USD: 1.0060921843687376,
    VND: 25540.58589178357,
    XAF: 612.993987975952,
    XCD: 2.7152705410821647,
    XPF: 111.4308617234469,
    ZAR: 18.58180360721443,
    ZMW: 27.345891783567136,
  },
  CAD: {
    AED: 2.6881720430107525,
    AFN: 52.2056385,
    ARS: 641.8962,
    AUD: 1.1060723371308483,
    BDT: 80.4060551,
    BHD: 0.27469299,
    BIF: 2094.401621,
    BMD: 0.72983698,
    BND: 0.98555351,
    BOB: 4.92596304,
    BRL: 3.7027,
    BSD: 0.72616975,
    CAD: 1,
    CHF: 0.6615,
    CLP: 684.95707,
    CNH: 5.2524,
    CNY: 5.2897,
    COP: 2850.31091821,
    CRC: 372.25295,
    CZK: 17.024,
    DJF: 130.209949,
    DKK: 5.0618,
    DOP: 42.283744,
    DZD: 98.613561,
    EGP: 35.01867032,
    EUR: 0.6788,
    GBP: 0.5821,
    GTQ: 5.6719824,
    HKD: 5.707,
    HNL: 18.0352043,
    HTG: 98.5000515,
    HUF: 264.180264,
    IDR: 11754.0996,
    ILS: 2.7082,
    INR: 60.899,
    IQD: 955.28431,
    JMD: 114.1579594,
    JOD: 0.51684659,
    JPY: 111.8,
    KES: 97.670559,
    KRW: 989.92,
    KZT: 323.38888888888886,
    LBP: 65401.13609473,
    MAD: 7.3362063,
    MUR: 33.7635463,
    MVR: 11.2834264,
    MWK: 1265.93705139,
    MXN: 12.4087,
    MYR: 3.4584,
    NGN: 1007.417186,
    NIO: 26.7538981,
    NOK: 7.9503,
    NZD: 1.217,
    OMR: 0.28045578,
    PEN: 2.71508903,
    PHP: 41.7237638,
    PKR: 203.245,
    PLN: 2.9286,
    QAR: 2.65804323,
    RON: 3.37455012,
    RUB: 66.789,
    SAR: 2.73493864,
    SCR: 10.77018883,
    SEK: 7.8783,
    SGD: 0.9866,
    SZL: 13.4959885,
    THB: 26.878,
    TND: 2.28555744,
    TRY: 23.5398,
    TTD: 4.88696234,
    TWD: 23.615,
    TZS: 1889.659588,
    UAH: 28.96314223,
    UGX: 2767.07061821,
    USD: 0.730887297178775,
    VND: 18546.80087189,
    XAF: 445.137691,
    XCD: 1.97174733,
    XPF: 80.917721,
    ZAR: 13.5188,
    ZMW: 19.8693,
  },
  CHF: {
    AED: 4.068348250610252,
    AFN: 78.8958945,
    ARS: 969.61580843,
    AUD: 1.670843776106934,
    BDT: 121.5138407,
    BHD: 0.41513043,
    BIF: 3165.169397,
    BMD: 1.10296786,
    BND: 1.48942007,
    BOB: 7.44437328,
    BRL: 5.605381165919282,
    BSD: 1.09742575,
    CAD: 1.5117157974300832,
    CHF: 1,
    CLP: 1035.14299,
    CNH: 7.93823686,
    CNY: 8,
    COP: 4307.53910797,
    CRC: 562.56815,
    CZK: 25.739,
    DJF: 196.780093,
    DKK: 7.6562,
    DOP: 63.901408,
    DZD: 149.029977,
    EGP: 52.92204824,
    EUR: 1.0259,
    GBP: 0.8797,
    GTQ: 8.5717968,
    HKD: 8.6314,
    HNL: 27.2557451,
    HTG: 148.8584355,
    HUF: 399.61,
    IDR: 10000,
    ILS: 4.1091,
    INR: 91.74311926605505,
    IQD: 1443.67567,
    JMD: 172.5214858,
    JOD: 0.78108563,
    JPY: 168.95,
    KES: 147.604863,
    KRW: 1493.501878,
    KZT: 488.7222222222222,
    LBP: 98837.62140961,
    MAD: 11.0868591,
    MUR: 51.0252391,
    MVR: 17.0521048,
    MWK: 1913.15035923,
    MXN: 18.7563,
    MYR: 5.238344683080147,
    NGN: 1522.461602,
    NIO: 40.4318917,
    NOK: 12.0174,
    NZD: 1.8348,
    OMR: 0.42383946,
    PEN: 4.10318471,
    PHP: 63.0551366,
    PKR: 307.0759993,
    PLN: 4.4339,
    QAR: 4.01697411,
    RON: 5.1045,
    RUB: 100.9526126,
    SAR: 4.13318248,
    SCR: 16.27647331,
    SEK: 11.9483,
    SGD: 1.4889,
    SZL: 20.4253,
    THB: 40.636,
    TND: 3.45405408,
    TRY: 35.595,
    TTD: 7.38543338,
    TWD: 35.6674365,
    TZS: 2855.752516,
    UAH: 43.77061711,
    UGX: 4181.74200797,
    USD: 1.1048502927853276,
    VND: 28028.89662773,
    XAF: 672.715387,
    XCD: 2.97980781,
    XPF: 122.287097,
    ZAR: 20.4402,
    ZMW: 30.0100858,
  },
  CLP: {
    AED: 0.003916631256904904,
    AFN: 0.07621738760941617,
    ARS: 0.936697458995496,
    AUD: 0.0016124755672643834,
    BDT: 0.11738845925044616,
    BHD: 0.00040103679782442427,
    BIF: 3.057712246112008,
    BMD: 0.0010655222231664826,
    BND: 0.0014388544233874395,
    BOB: 0.007191637630662021,
    BRL: 0.0054055409195206934,
    BSD: 0.0010601682671878983,
    CAD: 0.0014593354295912297,
    CHF: 0.0009650718109968557,
    CLP: 1,
    CNH: 0.0076687345967536335,
    CNY: 0.007718619869125521,
    COP: 4.1612986317668055,
    CRC: 0.5434690235404096,
    CZK: 0.024842355740630578,
    DJF: 0.19009943061103085,
    DKK: 0.007390328885867256,
    DOP: 0.061731962267357866,
    DZD: 0.14397042576697544,
    EGP: 0.051125350556641455,
    EUR: 0.001,
    GBP: 0.0008498342823149486,
    GTQ: 0.00828078524687686,
    HKD: 0.008327526132404181,
    HNL: 0.026330415568964052,
    HTG: 0.14380470808192403,
    HUF: 0.3856887906858163,
    IDR: 17.13836228435455,
    ILS: 0.003966771479561485,
    INR: 0.08886207189598029,
    IQD: 1.3946630407070622,
    JMD: 0.16666440044191383,
    JOD: 0.0007545678592674428,
    JPY: 0.16306829300110887,
    KES: 0.14259369422962523,
    KRW: 1.4427976544573808,
    KZT: 0.47213015684163806,
    LBP: 95.48209509645619,
    MAD: 0.010710461460015298,
    MUR: 0.04929293787711397,
    MVR: 0.016473187728392964,
    MWK: 1.8481991161723463,
    MXN: 0.018106144301861138,
    MYR: 0.005052944675788222,
    NGN: 1.470774199031189,
    NIO: 0.03905923344947735,
    NOK: 0.011557576272626838,
    NZD: 0.0017736041471912978,
    OMR: 0.00040945015721934226,
    PEN: 0.003963882043001615,
    PHP: 0.060914421687770885,
    PKR: 0.2966508030933968,
    PLN: 0.004280360329735702,
    QAR: 0.0038805982833347496,
    RON: 0.00492665930143622,
    RUB: 0.09752528256989887,
    SAR: 0.003992861392028555,
    SCR: 0.015723888841675873,
    SEK: 0.01153046655902099,
    SGD: 0.0014390243902439024,
    SZL: 0.019703407835472083,
    THB: 0.039220701963117194,
    TND: 0.003336789326081414,
    TRY: 0.034361094586555625,
    TTD: 0.007134698733746919,
    TWD: 0.03445653097645959,
    TZS: 2.758800033993371,
    UAH: 0.04228460950114728,
    UGX: 4.039772329395768,
    USD: 0.0011,
    VND: 27.07731868785587,
    XAF: 0.6498767740290643,
    XCD: 0.002878643664485425,
    XPF: 0.118135463584601,
    ZAR: 0.01969983853148636,
    ZMW: 0.028991246706892158,
  },
  CNH: {
    AED: 0.5107271881025732,
    AFN: 9.938717613422284,
    ARS: 122.1449832664731,
    AUD: 0.21047314362687322,
    BDT: 15.307409295418783,
    BHD: 0.052295042000044326,
    BIF: 398.72448414193576,
    BMD: 0.13894368226246148,
    BND: 0.18762605554201114,
    BOB: 0.9377867417274319,
    BRL: 0.7048804273144352,
    BSD: 0.13824552849132296,
    CAD: 0.1903891554337065,
    CHF: 0.12584498769919547,
    CLP: 130.39960992043262,
    CNH: 1,
    CNY: 1.0065050200580687,
    COP: 542.6317183448215,
    CRC: 70.86814867350783,
    CZK: 3.239433498082848,
    DJF: 24.788891597774775,
    DKK: 0.9636960039007957,
    DOP: 8.049823799286331,
    DZD: 18.7736873600922,
    EGP: 6.6667257696314195,
    EUR: 0.1283,
    GBP: 0.11081805891088012,
    GTQ: 1.079811166027616,
    HKD: 1.0852,
    HNL: 3.4334759192357986,
    HTG: 18.75207783860458,
    HUF: 50.293667856113835,
    IDR: 2234.835756554888,
    ILS: 0.5172654535783151,
    INR: 11.58757951195727,
    IQD: 181.86351647864535,
    JMD: 21.732972805248345,
    JOD: 0.09839535450697046,
    JPY: 21.241,
    KES: 18.594162104656576,
    KRW: 188.14025133535762,
    KZT: 61.56558828382228,
    LBP: 12450.82795496354,
    MAD: 1.396639996453822,
    MUR: 6.42777987100778,
    MVR: 2.1480972539285004,
    MWK: 241.00444380416232,
    MXN: 2.3610341541257562,
    MYR: 0.658902014672311,
    NGN: 191.78838183470378,
    NIO: 5.093308805602962,
    NOK: 1.5239256324291375,
    NZD: 0.2315297168391563,
    OMR: 0.05339214078326204,
    PEN: 0.5168886721780181,
    PHP: 7.943216826614065,
    PKR: 38.683149005962015,
    PLN: 0.5581573173164299,
    QAR: 0.506028502404752,
    RON: 0.6424344511181542,
    RUB: 12.717258804494781,
    SAR: 0.5206675679868792,
    SCR: 2.050388971386777,
    SEK: 1.509433962264151,
    SGD: 0.1879310668846667,
    SZL: 2.5693166958487557,
    THB: 5.1079,
    TND: 0.4351160265076797,
    TRY: 4.480673330525943,
    TTD: 0.9303619317804029,
    TWD: 4.493118198541635,
    TZS: 359.74644828121194,
    UAH: 5.513896584587425,
    UGX: 526.7847359205656,
    USD: 0.1391,
    VND: 3530.871794587646,
    XAF: 84.74367782973914,
    XCD: 0.3753740109488242,
    XPF: 15.404818369201445,
    ZAR: 2.56885126000133,
    ZMW: 3.7804472616857643,
  },
  CNY: {
    AED: 0.506957,
    AFN: 9.86535,
    ARS: 121.243309,
    AUD: 0.2089995193011056,
    BDT: 15.19441,
    BHD: 0.051909,
    BIF: 395.7811,
    BMD: 0.137918,
    BND: 0.186241,
    BOB: 0.930864,
    BRL: 0.7005253940455342,
    BSD: 0.137225,
    CAD: 0.18904663780554662,
    CHF: 0.125,
    CLP: 129.437,
    CNH: 0.992618,
    CNY: 1,
    COP: 538.626011,
    CRC: 70.345,
    CZK: 3.21552,
    DJF: 24.6059,
    DKK: 0.956582,
    DOP: 7.9904,
    DZD: 18.6351,
    EGP: 6.617512,
    EUR: 0.1283,
    GBP: 0.11,
    GTQ: 1.07184,
    HKD: 1.0783,
    HNL: 3.40813,
    HTG: 18.61365,
    HUF: 49.9224,
    IDR: 2500,
    ILS: 0.5131,
    INR: 11.50204,
    IQD: 180.521,
    JMD: 21.57254,
    JOD: 0.097669,
    JPY: 21.1211,
    KES: 18.4569,
    KRW: 188.67924528301887,
    KZT: 61.11111111111111,
    LBP: 12358.915943,
    MAD: 1.38633,
    MUR: 6.38033,
    MVR: 2.13224,
    MWK: 239.225349,
    MXN: 2.343605,
    MYR: 0.6546,
    NGN: 190.3726,
    NIO: 5.05571,
    NOK: 1.495978,
    NZD: 0.225,
    OMR: 0.052998,
    PEN: 0.513073,
    PHP: 7.88458,
    PKR: 38.39759,
    PLN: 0.5542,
    QAR: 0.502293,
    RON: 0.637692,
    RUB: 12.6296,
    SAR: 0.516824,
    SCR: 2.035253,
    SEK: 1.492469,
    SGD: 0.1866,
    SZL: 2.55035,
    THB: 5.099439061703213,
    TND: 0.431904,
    TRY: 4.447597,
    TTD: 0.923494,
    TWD: 4.4595,
    TZS: 357.0908,
    UAH: 5.473193,
    UGX: 522.896011,
    USD: 0.138061,
    VND: 3504.806899,
    XAF: 84.1181,
    XCD: 0.372603,
    XPF: 15.2911,
    ZAR: 2.5556,
    ZMW: 3.75254,
  },
  COP: {
    AED: 0.000941204081583056,
    AFN: 0.018315769752159258,
    ARS: 0.22509738951318486,
    AUD: 0.0003874933548279754,
    BDT: 0.028209573414010265,
    BHD: 0.00009637299153753642,
    BIF: 0.7347975996651227,
    BMD: 0.000256055216761524,
    BND: 0.00034577052759525944,
    BOB: 0.0017282195456394325,
    BRL: 0.001299003363578741,
    BSD: 0.0002547686097543477,
    CAD: 0.0003506923099560448,
    CHF: 0.00023191601862688357,
    CLP: 0.24030959767370016,
    CNH: 0.001842870525612251,
    CNY: 0.001854858435345782,
    COP: 1,
    CRC: 0.13060082239511453,
    CZK: 0.0059698565132978694,
    DJF: 0.0456827176881363,
    DKK: 0.001775966961239085,
    DOP: 0.014834783016076809,
    DZD: 0.03459747509297319,
    EGP: 0.012285912423193391,
    EUR: 0.0002380631409945035,
    GBP: 0.00020422333447242302,
    GTQ: 0.00198995217109929,
    HKD: 0.002001184454495273,
    HNL: 0.006327451571959082,
    HTG: 0.03455765154275106,
    HUF: 0.09268471811696447,
    IDR: 4.118512945339359,
    ILS: 0.000953253258316929,
    INR: 0.02135440874577444,
    IQD: 0.33515091420269344,
    JMD: 0.04005105501672477,
    JOD: 0.0001813298986780644,
    JPY: 0.03921292245204994,
    KES: 0.034266633291127856,
    KRW: 0.34671812386721146,
    KZT: 0.113457408040235,
    LBP: 22.945263857671367,
    MAD: 0.0025738266843559473,
    MUR: 0.011845566069403953,
    MVR: 0.003958665115413448,
    MWK: 0.4441399860282648,
    MXN: 0.0043510802526022085,
    MYR: 0.0012142711021061329,
    NGN: 0.3534411560380436,
    NIO: 0.009386308675687034,
    NOK: 0.0027773965041580587,
    NZD: 0.0004262140990439468,
    OMR: 0.00009839480254881341,
    PEN: 0.0009525588989797227,
    PHP: 0.014638320168314338,
    PKR: 0.07128803514095423,
    PLN: 0.001028611668737253,
    QAR: 0.0009325450122014252,
    RON: 0.0011839235146035307,
    RUB: 0.02343626141738632,
    SAR: 0.0009595229146852323,
    SCR: 0.0037786014014091125,
    SEK: 0.002770881779788388,
    SGD: 0.0003458113722621539,
    SZL: 0.004734918009743128,
    THB: 0.009425111109236795,
    TND: 0.0008018625004725218,
    TRY: 0.008257300815723139,
    TTD: 0.0017145365822297802,
    TWD: 0.00828023509618439,
    TZS: 0.6629661262311374,
    UAH: 0.010161397497010222,
    UGX: 0.9707960631704435,
    USD: 0.0003,
    VND: 6.506939559961206,
    XAF: 0.1561716261044066,
    XCD: 0.0006917657008584385,
    XPF: 0.028389085725011524,
    ZAR: 0.004734060271738344,
    ZMW: 0.006966874832192239,
  },
  CRC: {
    AED: 0.007206724003127443,
    AFN: 0.14024237685691948,
    ARS: 1.7235526192337765,
    AUD: 0.0029670054730258012,
    BDT: 0.21599843627834245,
    BHD: 0.0007379202501954652,
    BIF: 5.626286161063331,
    BMD: 0.001960594214229867,
    BND: 0.0026475371383893665,
    BOB: 0.013232838154808444,
    BRL: 0.009946364347146209,
    BSD: 0.0019507427677873338,
    CAD: 0.0026852228303362,
    CHF: 0.001775762314308053,
    CLP: 1.8400312744331508,
    CNH: 0.014110711493354184,
    CNY: 0.014202501954652072,
    COP: 7.656919624706802,
    CRC: 1,
    CZK: 0.045710711493354185,
    DJF: 0.34978889757623144,
    DKK: 0.013598436278342455,
    DOP: 0.11358874120406567,
    DZD: 0.2649100860046912,
    EGP: 0.09407224394057857,
    EUR: 0.0018228303362001564,
    GBP: 0.001563721657544957,
    GTQ: 0.015236903831118061,
    HKD: 0.015322908522283034,
    HNL: 0.0484487881157154,
    HTG: 0.2646051602814699,
    HUF: 0.7096794370602033,
    IDR: 31.535122595777953,
    ILS: 0.007298983580922596,
    INR: 0.16350899139953087,
    IQD: 2.566223612197029,
    JMD: 0.3066677091477717,
    JOD: 0.0013884284597341673,
    JPY: 0.3002501954652072,
    KES: 0.2623768569194683,
    KRW: 2.6547928068803754,
    KZT: 0.8687342541916427,
    LBP: 175.6900411258796,
    MAD: 0.019707584050039095,
    MUR: 0.09070054730258013,
    MVR: 0.030311180609851447,
    MWK: 3.4007441751368255,
    MXN: 0.033315871774824084,
    MYR: 0.009297576231430805,
    NGN: 2.7062705238467553,
    NIO: 0.07187021110242377,
    NOK: 0.021266301798279905,
    NZD: 0.0032634870992963253,
    OMR: 0.0007534010946051603,
    PEN: 0.007293666927286943,
    PHP: 0.11208444096950743,
    PKR: 0.5458467552775605,
    PLN: 0.007875996872556685,
    QAR: 0.007140422204847537,
    RON: 0.009065207193119625,
    RUB: 0.17944956997654418,
    SAR: 0.007346989835809226,
    SCR: 0.02893244722439406,
    SEK: 0.021216419077404222,
    SGD: 0.002647849882720876,
    SZL: 0.036254886630179825,
    THB: 0.0721673182173573,
    TND: 0.006139796716184519,
    TRY: 0.06322548866301798,
    TTD: 0.013128068803752932,
    TWD: 0.06340109460516029,
    TZS: 5.076278342455043,
    UAH: 0.07780500390930414,
    UGX: 7.433307427677874,
    USD: 0.0019809825673534074,
    VND: 49.823113213448,
    XAF: 1.1957935887412041,
    XCD: 0.005296794370602033,
    XPF: 0.21737294761532447,
    ZAR: 0.03624831899921814,
    ZMW: 0.053344800625488666,
  },
  CZK: {
    AED: 0.15765941434044883,
    AFN: 3.0680418719211824,
    ARS: 37.70566160372195,
    AUD: 0.06501527859046877,
    BDT: 4.725335249042145,
    BHD: 0.01614326765188834,
    BIF: 123.0846332785988,
    BMD: 0.042891351943076084,
    BND: 0.057919403393541326,
    BOB: 0.28949096880131364,
    BRL: 0.21759373289545705,
    BSD: 0.04267583470169677,
    CAD: 0.0587406015037594,
    CHF: 0.038851548234197134,
    CLP: 40.253831417624525,
    CNH: 0.30869594964422553,
    CNY: 0.31070402298850575,
    COP: 167.5082136015326,
    CRC: 21.876710454296663,
    CZK: 1,
    DJF: 7.652230432402846,
    DKK: 0.298,
    DOP: 2.4849480021893817,
    DZD: 5.795361247947455,
    EGP: 2.0579912424740012,
    EUR: 0.0399,
    GBP: 0.034209085933223866,
    GTQ: 0.33333333333333337,
    HKD: 0.33521483305966066,
    HNL: 1.059900109469075,
    HTG: 5.788690476190476,
    HUF: 15.52545155993432,
    IDR: 689.8847461685824,
    ILS: 0.15967775041050905,
    INR: 3.57703886152162,
    IQD: 56.140530925013685,
    JMD: 6.708880678708265,
    JOD: 0.03037424740010947,
    JPY: 6.5543,
    KES: 5.739942528735632,
    KRW: 58.07813355227149,
    KZT: 19.005047740679924,
    LBP: 3843.5201594143405,
    MAD: 0.4311371100164204,
    MUR: 1.984229611384784,
    MVR: 0.6631089217296114,
    MWK: 74.39709564860428,
    MXN: 0.7288416803503011,
    MYR: 0.20340038314176245,
    NGN: 59.20429666119322,
    NIO: 1.572283798576902,
    NOK: 0.46523672687465795,
    NZD: 0.07162810686913545,
    OMR: 0.01648193760262726,
    PEN: 0.15956143951833607,
    PHP: 2.45203886152162,
    PKR: 11.941331417624522,
    PLN: 0.1718,
    QAR: 0.15620894909688013,
    RON: 0.1983169129720854,
    RUB: 3.9257662835249043,
    SAR: 0.16072796934865902,
    SCR: 0.6329467706622879,
    SEK: 0.46472720513058835,
    SGD: 0.05792624521072797,
    SZL: 0.7931376573617953,
    THB: 1.5787835249042146,
    TND: 0.1343185550082102,
    TRY: 1.3831657088122606,
    TTD: 0.28719896004378764,
    TWD: 1.3870073891625616,
    TZS: 111.05227148330597,
    UAH: 1.7021175424192667,
    UGX: 162.61631431308157,
    USD: 0.04302370606204019,
    VND: 1089.9658217022443,
    XAF: 26.160030103995624,
    XCD: 0.11587643678160921,
    XPF: 4.75540503557745,
    ZAR: 0.7929939792008758,
    ZMW: 1.1670087575259989,
  },
  DJF: {
    AED: 0.02060306674415486,
    AFN: 0.4009343287585498,
    ARS: 4.927408020027717,
    AUD: 0.008482274576422729,
    BDT: 0.6175108408958827,
    BHD: 0.0021096159864097638,
    BIF: 16.084804863874112,
    BMD: 0.005605078456792883,
    BND: 0.007568957038758997,
    BOB: 0.03783092672895525,
    BRL: 0.028435334614868794,
    BSD: 0.005576914479860522,
    CAD: 0.007676695426706604,
    CHF: 0.00507666860387143,
    CLP: 5.260405024811123,
    CNH: 0.04034065000670571,
    CNY: 0.04060306674415486,
    COP: 21.890116232285756,
    CRC: 2.8588671822611653,
    CZK: 0.13068085296615853,
    DJF: 1,
    DKK: 0.0388761232062229,
    DOP: 0.32473512450265996,
    DZD: 0.7573427511287943,
    EGP: 0.26894005096338686,
    EUR: 0.005211229826992713,
    GBP: 0.00447047252894631,
    GTQ: 0.04356028432205285,
    HKD: 0.04380616031114489,
    HNL: 0.1385086503643435,
    HTG: 0.7564710089856499,
    HUF: 2.0288792525369934,
    IDR: 90.15472707765211,
    ILS: 0.02086682462336269,
    INR: 0.46745048951674195,
    IQD: 7.336492467253789,
    JMD: 0.8767222495417767,
    JOD: 0.003969332558451428,
    JPY: 0.858375430282981,
    KES: 0.7501005856319014,
    KRW: 7.589700031293308,
    KZT: 2.483595849414616,
    LBP: 502.27449282489164,
    MAD: 0.056341365282310346,
    MUR: 0.2593008180964728,
    MVR: 0.08665563950109527,
    MWK: 9.722275917564488,
    MXN: 0.09524565246546561,
    MYR: 0.02658053556260897,
    NGN: 7.736867986946221,
    NIO: 0.20546738790290137,
    NOK: 0.06079753229916403,
    NZD: 0.009329876167910948,
    OMR: 0.0021538736644463323,
    PEN: 0.020851625016764273,
    PHP: 0.3204345299298136,
    PKR: 1.5605033752067594,
    PLN: 0.02251642898654388,
    QAR: 0.020413518708927537,
    RON: 0.02591622334480755,
    RUB: 0.5130224864768207,
    SAR: 0.021004068130001344,
    SCR: 0.08271402387232331,
    SEK: 0.06065492422549064,
    SGD: 0.007569851133264787,
    SZL: 0.10364790558362019,
    THB: 0.20631677768340115,
    TND: 0.01755286333765479,
    TRY: 0.18075327462112747,
    TTD: 0.03753140506951585,
    TWD: 0.18125530868612813,
    TZS: 14.512405561267828,
    UAH: 0.2224341722920113,
    UGX: 21.250838660646433,
    USD: 0.0056230951765089575,
    VND: 142.43766328400912,
    XAF: 3.418615047610533,
    XCD: 0.015142831597299835,
    XPF: 0.6214403862488266,
    ZAR: 0.10362912959899863,
    ZMW: 0.15250569985247442,
  },
  DKK: {
    AED: 0.52953963,
    AFN: 10.3048065,
    ARS: 126.64414731,
    AUD: 0.21848849658065503,
    BDT: 15.8712519,
    BHD: 0.05422131,
    BIF: 413.411349,
    BMD: 0.14406162,
    BND: 0.19453719,
    BOB: 0.97232976,
    BRL: 0.73084443,
    BSD: 0.14333775,
    CAD: 0.1975581808842704,
    CHF: 0.13061309788145556,
    CLP: 135.20283,
    CNH: 1.03683462,
    CNY: 1.04357925,
    COP: 562.61935149,
    CRC: 73.47855,
    CZK: 3.3557046979865772,
    DJF: 25.701981,
    DKK: 1,
    DOP: 8.346336,
    DZD: 19.465209,
    EGP: 6.91229208,
    EUR: 0.134,
    GBP: 0.1149,
    GTQ: 1.1195856,
    HKD: 1.1249,
    HNL: 3.5599467,
    HTG: 19.4428035,
    HUF: 52.14,
    IDR: 2317.15508241,
    ILS: 0.53631873,
    INR: 12.0144036,
    IQD: 188.56239,
    JMD: 22.5334986,
    JOD: 0.10201971,
    JPY: 22.051,
    KES: 19.279071,
    KRW: 195.070326,
    KZT: 63.83333333333333,
    LBP: 12909.44947137,
    MAD: 1.4480847,
    MUR: 6.6645447,
    MVR: 2.2272216,
    MWK: 249.88175091,
    MXN: 2.4539877300613497,
    MYR: 0.68317242,
    NGN: 198.852834,
    NIO: 5.2809189,
    NOK: 1.5678,
    NZD: 0.2403,
    OMR: 0.05535882,
    PEN: 0.53592807,
    PHP: 8.2358022,
    PKR: 40.1080281,
    PLN: 0.5789,
    QAR: 0.52466787,
    RON: 0.66609828,
    RUB: 13.1856942,
    SAR: 0.53984616,
    SCR: 2.12591427,
    SEK: 1.5593,
    SGD: 0.1947,
    SZL: 2.6639565,
    THB: 5.26,
    TND: 0.45114336,
    TRY: 4.705882352941177,
    TTD: 0.96463146,
    TWD: 4.6586205,
    TZS: 372.997572,
    UAH: 5.71699887,
    UGX: 546.18865149,
    USD: 0.144327218670169,
    VND: 3660.93011541,
    XAF: 87.865179,
    XCD: 0.38920077,
    XPF: 15.972249,
    ZAR: 2.6689,
    ZMW: 3.9196986,
  },
  DOP: {
    AED: 0.06344575991189427,
    AFN: 1.2346503303964758,
    ARS: 15.17362197136564,
    AUD: 0.026120594713656388,
    BDT: 1.9015831497797357,
    BHD: 0.006496420704845815,
    BIF: 49.53207599118943,
    BMD: 0.01726046255506608,
    BND: 0.02330809471365639,
    BOB: 0.11649779735682819,
    BRL: 0.0875647026431718,
    BSD: 0.01717373348017621,
    CAD: 0.02363986784140969,
    CHF: 0.015633259911894272,
    CLP: 16.199063876651984,
    CNH: 0.12422632158590308,
    CNY: 0.12503441629955947,
    COP: 67.40914234581497,
    CRC: 8.803689427312776,
    CZK: 0.4024229074889868,
    DJF: 3.0794328193832596,
    DKK: 0.11971640969162996,
    DOP: 1,
    DZD: 2.3321861233480177,
    EGP: 0.8281828193832599,
    EUR: 0.016047632158590307,
    GBP: 0.013766519823788546,
    GTQ: 0.1341409691629956,
    HKD: 0.13489812775330395,
    HNL: 0.42652808370044054,
    HTG: 2.329501651982379,
    HUF: 6.247797356828194,
    IDR: 277.62542538546256,
    ILS: 0.0642579845814978,
    INR: 1.4394823788546256,
    IQD: 22.592235682819382,
    JMD: 2.699807268722467,
    JOD: 0.01222329295154185,
    JPY: 2.643309471365639,
    KES: 2.3098843612334803,
    KRW: 23.371971365638768,
    KZT: 7.648066568771414,
    LBP: 1546.7205575440528,
    MAD: 0.17349944933920705,
    MUR: 0.798499449339207,
    MVR: 0.26685022026431715,
    MWK: 29.939095539647578,
    MXN: 0.29330258810572685,
    MYR: 0.08185297356828193,
    NGN: 23.825165198237887,
    NIO: 0.6327230176211454,
    NOK: 0.18722191629955948,
    NZD: 0.028730726872246697,
    OMR: 0.006632709251101322,
    PEN: 0.06421117841409692,
    PHP: 0.9867566079295154,
    PKR: 4.805465308370044,
    PLN: 0.06933783039647577,
    QAR: 0.06286205947136564,
    RON: 0.07980726872246696,
    RUB: 1.579818281938326,
    SAR: 0.0646806167400881,
    SCR: 0.25471227973568283,
    SEK: 0.1867827643171806,
    SGD: 0.023310848017621146,
    SZL: 0.31917676211453744,
    THB: 0.6353386563876652,
    TND: 0.054052863436123345,
    TRY: 0.5566175660792951,
    TTD: 0.11557544052863436,
    TWD: 0.5581635462555066,
    TZS: 44.68997797356828,
    UAH: 0.68497109030837,
    UGX: 65.44053001101321,
    USD: 0.017319016279875303,
    VND: 438.62721503303965,
    XAF: 10.52739537444934,
    XCD: 0.04663133259911894,
    XPF: 1.9136839207048457,
    ZAR: 0.3191189427312775,
    ZMW: 0.46963105726872245,
  },
  DZD: {
    AED: 0.027204415323770735,
    AFN: 0.5293961395431203,
    ARS: 6.5061796824272475,
    AUD: 0.011200047222714126,
    BDT: 0.8153650906085828,
    BHD: 0.002785549849477599,
    BIF: 21.238474706333747,
    BMD: 0.007400979871318104,
    BND: 0.009994097160734313,
    BOB: 0.04995218700194794,
    BRL: 0.037546189717254,
    BSD: 0.007363791983944277,
    CAD: 0.010136355587037365,
    CHF: 0.006703264270113925,
    CLP: 6.945870963933652,
    CNH: 0.0532660409657045,
    CNY: 0.05361253763060032,
    COP: 28.90384333864589,
    CRC: 3.7748657104067056,
    CZK: 0.1725517974145564,
    DJF: 1.3204061153414792,
    DKK: 0.05133227082226551,
    DOP: 0.4287822442594888,
    DZD: 1,
    EGP: 0.3551100879523051,
    EUR: 0.006877815605763609,
    GBP: 0.0059028392656867955,
    GTQ: 0.057517265804852134,
    HKD: 0.05784192196446491,
    HNL: 0.18288766896877398,
    HTG: 0.9988489463431911,
    HUF: 2.678944572339295,
    IDR: 119.0408529602739,
    ILS: 0.027552682840446256,
    INR: 0.6172244849772741,
    IQD: 9.6871495189186,
    JMD: 1.1576294197509003,
    JOD: 0.005241130984003306,
    JPY: 1.1334041674045217,
    KES: 0.9904374003895874,
    KRW: 10.0214863349271,
    KZT: 3.279355147603775,
    LBP: 663.2063119060268,
    MAD: 0.07439348326545069,
    MUR: 0.3423823859276312,
    MVR: 0.11442063632607284,
    MWK: 12.837352576589339,
    MXN: 0.12576294197509003,
    MYR: 0.03509710170592055,
    NGN: 10.21580780355351,
    NIO: 0.27130039549023083,
    NOK: 0.08027743344548728,
    NZD: 0.012319225547488342,
    OMR: 0.002843987958207898,
    PEN: 0.02753261318694292,
    PHP: 0.42310371288589815,
    PKR: 2.060498199634024,
    PLN: 0.02973083052948468,
    QAR: 0.026954134938905614,
    RON: 0.03421993979103949,
    RUB: 0.6773980284516853,
    SAR: 0.02773390000590284,
    SCR: 0.1092161029455168,
    SEK: 0.08008913287291188,
    SGD: 0.009995277728587451,
    SZL: 0.13685732837494835,
    THB: 0.2724219349507113,
    TND: 0.023176908092792634,
    TRY: 0.2386677291777345,
    TTD: 0.04955669677114692,
    TWD: 0.23933061802727112,
    TZS: 19.16226905141373,
    UAH: 0.2937034413552919,
    UGX: 28.059737323652676,
    USD: 0.007457955774322258,
    VND: 188.07556165515612,
    XAF: 4.513960214863349,
    XCD: 0.01999468744466088,
    XPF: 0.8205536863231214,
    ZAR: 0.13683253645003246,
    ZMW: 0.20136945870963935,
  },
  EGP: {
    AED: 0.07660839904785967,
    AFN: 1.4907944254577852,
    ARS: 18.321585060971554,
    AUD: 0.03153964813361879,
    BDT: 2.29609103844466,
    BHD: 0.00784418675780263,
    BIF: 59.80814239551057,
    BMD: 0.02084136757137728,
    BND: 0.028143658825250335,
    BOB: 0.14066676418569396,
    BRL: 0.10573112674370669,
    BSD: 0.020736645434114815,
    CAD: 0.028544262556682933,
    CHF: 0.01887658080559582,
    CLP: 19.559768082022366,
    CNH: 0.14999867019508237,
    CNY: 0.150974414553385,
    COP: 81.3940361573957,
    CRC: 10.630128060213567,
    CZK: 0.48591071689783105,
    DJF: 3.718300775276267,
    DKK: 0.14455311905743426,
    DOP: 1.2074628651976755,
    DZD: 2.8160281386720567,
    EGP: 1,
    EUR: 0.0193,
    GBP: 0.016622561470232316,
    GTQ: 0.16197023896594367,
    HKD: 0.16288447984680646,
    HNL: 0.5150168220322079,
    HTG: 2.8127867391853614,
    HUF: 7.543983297650234,
    IDR: 335.22239158765404,
    ILS: 0.07758913017460338,
    INR: 1.738121517573372,
    IQD: 27.279285628798252,
    JMD: 3.2599170201731402,
    JOD: 0.014759172329419274,
    JPY: 3.191698027899307,
    KES: 2.7890995890902803,
    KRW: 28.220787510472213,
    KZT: 9.234756372351285,
    LBP: 1867.6076360722882,
    MAD: 0.20949414220933787,
    MUR: 0.9641584329578851,
    MVR: 0.3222117315389832,
    MWK: 36.15034608172981,
    MXN: 0.3541519834040346,
    MYR: 0.0988344259897073,
    NGN: 28.76800223407226,
    NIO: 0.7639895477333475,
    NOK: 0.22606351148286546,
    NZD: 0.03469128578837485,
    OMR: 0.00800875011635793,
    PEN: 0.0775326134656046,
    PHP: 1.191471961063312,
    PKR: 5.778,
    PLN: 0.08372285535711911,
    QAR: 0.07590360244152182,
    RON: 0.09636431335523078,
    RUB: 1.90757190920092,
    SAR: 0.07809944281173951,
    SCR: 0.30755561909067936,
    SEK: 0.22553325177196504,
    SGD: 0.028146983337544382,
    SZL: 0.38539408768733624,
    THB: 0.7671478344126916,
    TND: 0.06526682535672017,
    TRY: 0.6720950411574622,
    TTD: 0.1395530525671884,
    TWD: 0.6739617548105693,
    TZS: 53.96148884958576,
    UAH: 0.8270771552813202,
    UGX: 79.01700986715248,
    USD: 0.020876826722338204,
    VND: 529.6260738174709,
    XAF: 12.711438981901354,
    XCD: 0.05630560246811792,
    XPF: 2.310702269976994,
    ZAR: 0.38532427292916127,
    ZMW: 0.5670620619955052,
  },
  EUR: {
    AED: 3.957261574990107,
    AFN: 76.8869505,
    ARS: 944.92626187,
    AUD: 1.6281341582546403,
    BDT: 118.4197063,
    BHD: 0.40455987,
    BIF: 3084.573973,
    BMD: 1.07488274,
    BND: 1.45149463,
    BOB: 7.25481552,
    BRL: 5.461496450027307,
    BSD: 1.06948175,
    CAD: 1.473187978786093,
    CHF: 0.9747538746466518,
    CLP: 1000,
    CNH: 7.79423226812159,
    CNY: 7.79423226812159,
    COP: 4197.85526573,
    CRC: 548.24335,
    CZK: 25.06265664160401,
    DJF: 191.769437,
    DKK: 7.462686567164179,
    DOP: 62.274272,
    DZD: 145.395,
    EGP: 51.81347150259067,
    EUR: 1,
    GBP: 0.8573,
    GTQ: 8.3535312,
    HKD: 8.406,
    HNL: 26.5617259,
    HTG: 145.0680195,
    HUF: 389.17,
    IDR: 17225,
    ILS: 3.9989,
    INR: 90.09009009009009,
    IQD: 1406.91503,
    JMD: 168.1285322,
    JOD: 0.76119667,
    JPY: 164.458,
    KES: 144.22,
    KRW: 1428.5714285714287,
    KZT: 476.2777777777777,
    LBP: 96320.89670849,
    MAD: 10.8195,
    MUR: 49.7259719,
    MVR: 16.6179032,
    MWK: 1864.43537907,
    MXN: 18.2734,
    MYR: 5.099,
    NGN: 1484.22,
    NIO: 39.4023653,
    NOK: 11.6621,
    NZD: 1.7902,
    OMR: 0.41304714,
    PEN: 3.9928,
    PHP: 61.34969325153374,
    PKR: 299.2568537,
    PLN: 4.3196,
    QAR: 3.91468899,
    RON: 4.972,
    RUB: 97.804,
    SAR: 4.0363,
    SCR: 15.86202179,
    SEK: 11.6305,
    SGD: 1.4524,
    SZL: 19.8765005,
    THB: 39.544,
    TND: 3.36610272,
    TRY: 34.6946,
    TTD: 7.19737642,
    TWD: 34.758,
    TZS: 2783.035844,
    UAH: 42.65607599,
    UGX: 4075.26136573,
    USD: 1.0765421466250404,
    VND: 27338.02,
    XAF: 655.585883,
    XCD: 2.90393229,
    XPF: 119.173273,
    ZAR: 19.9091,
    ZMW: 29.2459322,
  },
  GBP: {
    AED: 4.621072088724584,
    AFN: 89.685,
    ARS: 1111.111111111111,
    AUD: 1.8986140117714068,
    BDT: 138.88888888888889,
    BHD: 0.4719,
    BIF: 3598.01,
    BMD: 1.2538,
    BND: 1.6931,
    BOB: 8.4624,
    BRL: 6.3607,
    BSD: 1.2475,
    CAD: 1.7179178835251674,
    CHF: 1.1367511651699442,
    CLP: 1176.7,
    CNH: 9.0238,
    CNY: 9.090909090909092,
    COP: 4896.6001,
    CRC: 639.5,
    CZK: 29.232,
    DJF: 223.69,
    DKK: 8.703220191470844,
    DOP: 72.64,
    DZD: 169.41,
    EGP: 60.1592,
    EUR: 1.166452816983553,
    GBP: 1,
    GTQ: 9.744,
    HKD: 9.799,
    HNL: 30.983,
    HTG: 169.215,
    HUF: 453.84,
    IDR: 20166.7109,
    ILS: 4.6677,
    INR: 104.564,
    IQD: 1641.1,
    JMD: 196.114,
    JOD: 0.8879,
    JPY: 192.01,
    KES: 167.79,
    KRW: 1697.74,
    KZT: 555.5555555555555,
    LBP: 112353.7813,
    MAD: 12.603,
    MUR: 58.003,
    MVR: 19.384,
    MWK: 2174.7759,
    MXN: 21.3055,
    MYR: 5.9458,
    NGN: 1730.66,
    NIO: 45.961,
    NOK: 13.5998,
    NZD: 2.087,
    OMR: 0.4818,
    PEN: 4.6643,
    PHP: 71.678,
    PKR: 349.069,
    PLN: 5.0367,
    QAR: 4.5663,
    RON: 5.7972,
    RUB: 114.758,
    SAR: 4.6984,
    SCR: 18.5023,
    SEK: 13.5679,
    SGD: 1.6933,
    SZL: 23.185,
    THB: 46.151,
    TND: 3.9264,
    TRY: 40.4327,
    TTD: 8.3954,
    TWD: 40.545,
    TZS: 3246.28,
    UAH: 49.7563,
    UGX: 4753.6001,
    USD: 1.2550200803212852,
    VND: 31861.8809,
    XAF: 764.71,
    XCD: 3.3873,
    XPF: 139.01,
    ZAR: 23.1808,
    ZMW: 34.114,
  },
  GTQ: {
    AED: 0.47297824302134645,
    AFN: 9.204125615763546,
    ARS: 113.11698481116585,
    AUD: 0.1947249589490969,
    BDT: 14.176005747126437,
    BHD: 0.04842980295566502,
    BIF: 369.2538998357964,
    BMD: 0.12867405582922825,
    BND: 0.17375821018062396,
    BOB: 0.8684729064039408,
    BRL: 0.6527811986863711,
    BSD: 0.1280275041050903,
    CAD: 0.17623152709359605,
    CHF: 0.11654351395730705,
    CLP: 120.76149425287356,
    CNH: 0.9260878489326765,
    CNY: 0.9321120689655172,
    COP: 502.5246408045977,
    CRC: 65.63013136288998,
    CZK: 3,
    DJF: 22.95669129720854,
    DKK: 0.8924671592775041,
    DOP: 7.454844006568145,
    DZD: 17.386083743842363,
    EGP: 6.173973727422003,
    EUR: 0.11963259441707717,
    GBP: 0.10262725779967159,
    GTQ: 1,
    HKD: 1.005644499178982,
    HNL: 3.179700328407225,
    HTG: 17.366071428571427,
    HUF: 46.576354679802954,
    IDR: 2069.654238505747,
    ILS: 0.47903325123152707,
    INR: 10.73111658456486,
    IQD: 168.42159277504103,
    JMD: 20.126642036124796,
    JOD: 0.0911227422003284,
    JPY: 19.705459770114942,
    KES: 17.219827586206897,
    KRW: 174.23440065681444,
    KZT: 57.01514322203977,
    LBP: 11530.560478243022,
    MAD: 1.2934113300492611,
    MUR: 5.952688834154351,
    MVR: 1.989326765188834,
    MWK: 223.1912869458128,
    MXN: 2.186525041050903,
    MYR: 0.6102011494252874,
    NGN: 177.61288998357963,
    NIO: 4.716851395730706,
    NOK: 1.3957101806239738,
    NZD: 0.2141830870279146,
    OMR: 0.049445812807881774,
    PEN: 0.4786843185550082,
    PHP: 7.356116584564861,
    PKR: 35.82399425287356,
    PLN: 0.5169027093596059,
    QAR: 0.46862684729064036,
    RON: 0.5949507389162562,
    RUB: 11.777298850574713,
    SAR: 0.482183908045977,
    SCR: 1.8988403119868638,
    SEK: 1.3924363711001642,
    SGD: 0.1737787356321839,
    SZL: 2.379412972085386,
    THB: 4.7363505747126435,
    TND: 0.40295566502463054,
    TRY: 4.149497126436781,
    TTD: 0.8615968801313628,
    TWD: 4.161022167487685,
    TZS: 333.1568144499179,
    UAH: 5.1063526272578,
    UGX: 487.84894293924464,
    USD: 0.12862563508907326,
    VND: 3269.8974651067324,
    XAF: 78.48009031198686,
    XCD: 0.3476293103448276,
    XPF: 14.266215106732348,
    ZAR: 2.378981937602627,
    ZMW: 3.5010262725779966,
  },
  HKD: {
    AED: 0.4700874,
    AFN: 9.14787,
    ARS: 112.4256138,
    AUD: 0.19371210506944578,
    BDT: 14.089362,
    BHD: 0.0481338,
    BIF: 366.99702,
    BMD: 0.1278876,
    BND: 0.1726962,
    BOB: 0.8631648,
    BRL: 0.6498992656138298,
    BSD: 0.127245,
    CAD: 0.17522340984755563,
    CHF: 0.11585606043052112,
    CLP: 120.0234,
    CNH: 0.9214891264283082,
    CNY: 0.9273856997125104,
    COP: 499.4532102,
    CRC: 65.229,
    CZK: 2.981664,
    DJF: 22.81638,
    DKK: 0.8889679082585119,
    DOP: 7.40928,
    DZD: 17.27982,
    EGP: 6.1362384,
    EUR: 0.11896264572924102,
    GBP: 0.10205122971731809,
    GTQ: 0.993888,
    HKD: 1,
    HNL: 3.160266,
    HTG: 17.25993,
    HUF: 46.29168,
    IDR: 2057.8999,
    ILS: 0.4761054,
    INR: 10.6746,
    IQD: 167.3922,
    JMD: 20.003628,
    JOD: 0.0905658,
    JPY: 19.5613,
    KES: 17.11458,
    KRW: 173,
    KZT: 56.666666666666664,
    LBP: 11460.0856926,
    MAD: 1.285506,
    MUR: 5.916306,
    MVR: 1.977168,
    MWK: 221.8271418,
    MXN: 2.1688,
    MYR: 0.6068,
    NGN: 176.52732,
    NIO: 4.688022,
    NOK: 1.3871796,
    NZD: 0.2127,
    OMR: 0.0491436,
    PEN: 0.4757586,
    PHP: 7.311156,
    PKR: 35.6187,
    PLN: 0.5136,
    QAR: 0.4657626,
    RON: 0.5913144,
    RUB: 11.6348,
    SAR: 0.4792368,
    SCR: 1.8872346,
    SEK: 1.3843,
    SGD: 0.1729,
    SZL: 2.36487,
    THB: 4.7074,
    TND: 0.4004928,
    TRY: 4.1241354,
    TTD: 0.8563308,
    TWD: 4.1381,
    TZS: 331.12056,
    UAH: 5.0751426,
    UGX: 484.8672102,
    USD: 0.12802294171115464,
    VND: 3249.9118518,
    XAF: 78.00042,
    XCD: 0.3455046,
    XPF: 14.17902,
    ZAR: 2.3678,
    ZMW: 3.479628,
  },
  HNL: {
    AED: 0.14874931414001225,
    AFN: 2.8946519058838716,
    ARS: 35.57473130426363,
    AUD: 0.061240034857825254,
    BDT: 4.458283574863635,
    BHD: 0.01523093309234096,
    BIF: 116.12852209276053,
    BMD: 0.0404673530645838,
    BND: 0.05464609624632863,
    BOB: 0.2731304263628441,
    BRL: 0.20529645289352225,
    BSD: 0.04026401575057289,
    CAD: 0.05542394216183068,
    CHF: 0.0366523577445696,
    CLP: 37.97889165025982,
    CNH: 0.29125004034470514,
    CNY: 0.29314462769906074,
    COP: 158.04150986024592,
    CRC: 20.64035116031372,
    CZK: 0.9434851370106186,
    DJF: 7.219765677952425,
    DKK: 0.28067650001613786,
    DOP: 2.3445115063099116,
    DZD: 5.467837201045734,
    EGP: 1.9416841493722363,
    EUR: 0.03762385824484395,
    GBP: 0.032275764128715745,
    GTQ: 0.3144950456702062,
    HKD: 0.31627021269728556,
    HNL: 1,
    HTG: 5.461543427040635,
    HUF: 14.648032792176354,
    IDR: 650.8960042604008,
    ILS: 0.15065358422360647,
    INR: 3.374883000355033,
    IQD: 52.96775651163541,
    JMD: 6.32972920633896,
    JOD: 0.02865765096988671,
    JPY: 6.19726947035471,
    KES: 5.4155504631572144,
    KRW: 54.79585579188587,
    KZT: 17.930980071508746,
    LBP: 3626.304144208114,
    MAD: 0.40677145531420456,
    MUR: 1.8720911467578993,
    MVR: 0.625633411871026,
    MWK: 70.1925539812155,
    MXN: 0.6876512926443533,
    MYR: 0.19190523835651807,
    NGN: 55.858373947003194,
    NIO: 1.4834263951199043,
    NOK: 0.4389439369977084,
    NZD: 0.06735951973662976,
    OMR: 0.015550463157215247,
    PEN: 0.15054384662556886,
    PHP: 2.3134622212180873,
    PKR: 11.266468708646677,
    PLN: 0.16256334118710258,
    QAR: 0.1473808217409547,
    RON: 0.1871090598069909,
    RUB: 3.7039021398831613,
    SAR: 0.15164445018235806,
    SCR: 0.5971758706387373,
    SEK: 0.43791434012200237,
    SGD: 0.05465255139915437,
    SZL: 0.7483135913242746,
    THB: 1.4895587903043603,
    TND: 0.1267275602749895,
    TRY: 1.304996288287125,
    TTD: 0.27096795016622016,
    TWD: 1.3086208565987798,
    TZS: 104.77616757576735,
    UAH: 1.6059226027176192,
    UGX: 153.42607558983957,
    USD: 0.04056104030956186,
    VND: 1028.3665526256334,
    XAF: 24.681599586870217,
    XCD: 0.10932769583319885,
    XPF: 4.486653971532776,
    ZAR: 0.7481780331149339,
    ZMW: 1.1010554174870089,
  },
  HTG: {
    AED: 0.027235765150843604,
    AFN: 0.5300062051236593,
    ARS: 6.513677274473304,
    AUD: 0.011212953934343883,
    BDT: 0.8163047011198772,
    BHD: 0.0027887598617143874,
    BIF: 21.262949502112697,
    BMD: 0.0074095086133026036,
    BND: 0.010005614159501228,
    BOB: 0.050009750908607396,
    BRL: 0.037589457199420855,
    BSD: 0.007372277871347103,
    CAD: 0.010148036521584967,
    CHF: 0.006710988978518453,
    CLP: 6.953875247466241,
    CNH: 0.0533274236917531,
    CNY: 0.053674319652513076,
    COP: 28.937151552758326,
    CRC: 3.7792157905623025,
    CZK: 0.17275064267352186,
    DJF: 1.3219277250834738,
    DKK: 0.05139142511006708,
    DOP: 0.42927636438849986,
    DZD: 1.0011523801081466,
    EGP: 0.3555193097538635,
    EUR: 0.006888869190083622,
    GBP: 0.005909641580238159,
    GTQ: 0.05758354755784062,
    HKD: 0.05790857784475372,
    HNL: 0.18309842508051888,
    HTG: 1,
    HUF: 2.6820317347752862,
    IDR: 119.1780332712821,
    ILS: 0.027584434004077656,
    INR: 0.6179357621960229,
    IQD: 9.698312797328843,
    JMD: 1.1589634488668263,
    JOD: 0.0052471707590934615,
    JPY: 1.134710279821529,
    KES: 0.9915787607481606,
    KRW: 10.033034896433533,
    KZT: 3.2831342112434214,
    LBP: 663.9705776674646,
    MAD: 0.07447921283574152,
    MUR: 0.34277694057855396,
    MVR: 0.11455249239133647,
    MWK: 12.852146086339864,
    MXN: 0.1259078686877641,
    MYR: 0.035137546907780044,
    NGN: 10.227580297254972,
    NIO: 0.271613036669326,
    NOK: 0.08036994356292292,
    NZD: 0.012333421977957037,
    OMR: 0.002847265313358745,
    PEN: 0.027564341222704843,
    PHP: 0.42359128918831074,
    PKR: 2.062872676772154,
    PLN: 0.029765091747185536,
    QAR: 0.026985196347841505,
    RON: 0.034259374168956656,
    RUB: 0.6781786484649707,
    SAR: 0.027765860000590965,
    SCR: 0.10934196141004049,
    SEK: 0.08018142599651332,
    SGD: 0.010006796087817274,
    SZL: 0.13701504003782172,
    THB: 0.27273586856957127,
    TND: 0.023203616700647106,
    TRY: 0.23894276512129542,
    TTD: 0.04961380492273144,
    TWD: 0.23960641787075615,
    TZS: 19.18435126909553,
    UAH: 0.2940418993588039,
    UGX: 28.09207280678427,
    USD: 0.007554011179936546,
    VND: 188.292296191236,
    XAF: 4.519162012823923,
    XCD: 0.020017728924740717,
    XPF: 0.8214992760689065,
    ZAR: 0.13699021954318472,
    ZMW: 0.20160151286824454,
  },
  HUF: {
    AED: 0.010154900405429226,
    AFN: 0.19761369645690113,
    ARS: 2.428635422175216,
    AUD: 0.004192344778434579,
    BDT: 0.3043605676009166,
    BHD: 0.0010397937599153887,
    BIF: 7.927926141371409,
    BMD: 0.002762647629120395,
    BND: 0.0037306099065750045,
    BOB: 0.018646218931782126,
    BRL: 0.01401529173276926,
    BSD: 0.002748766084963864,
    CAD: 0.003783712321523004,
    CHF: 0.00250243987888191,
    CLP: 2.592763969680945,
    CNH: 0.01988321875550855,
    CNY: 0.0200125594923321,
    COP: 10.789265159527588,
    CRC: 1.4090869028732593,
    CZK: 0.06441036488630354,
    DJF: 0.4928829543451437,
    DKK: 0.019179133103183737,
    DOP: 0.1600564075445091,
    DZD: 0.37328133262823904,
    EGP: 0.13255596686056761,
    EUR: 0.0025695711385769716,
    GBP: 0.002203419707385863,
    GTQ: 0.02147012162876785,
    HKD: 0.021591309712674072,
    HNL: 0.06826855279393619,
    HTG: 0.3728516657852988,
    HUF: 1,
    IDR: 44.43572823021329,
    ILS: 0.010284902168164993,
    INR: 0.23039837828309537,
    IQD: 3.61603208179094,
    JMD: 0.43212145249427114,
    JOD: 0.0019564163581879076,
    JPY: 0.42307861801515956,
    KES: 0.36971179270227394,
    KRW: 3.740833774017275,
    KZT: 1.2241220596588127,
    LBP: 247.56253591574125,
    MAD: 0.02776969857218403,
    MUR: 0.12780495328750222,
    MVR: 0.04271108760796757,
    MWK: 4.791944077207827,
    MXN: 0.0469449585757095,
    MYR: 0.013101092896174863,
    NGN: 3.8133703507844174,
    NIO: 0.10127137317116165,
    NOK: 0.02996606733650626,
    NZD: 0.004604051565377533,
    OMR: 0.0010616076150185088,
    PEN: 0.010277410541159882,
    PHP: 0.15793671778600388,
    PKR: 0.7691455138374758,
    PLN: 0.011097964040190375,
    QAR: 0.010061475409836066,
    RON: 0.012773664727657326,
    RUB: 0.25286003878018687,
    SAR: 0.010352547153181738,
    SCR: 0.040768332451965456,
    SEK: 0.029895778247840652,
    SGD: 0.0037310505905164817,
    SZL: 0.051086285915741235,
    THB: 0.10169002291556496,
    TND: 0.008651507139079853,
    TRY: 0.08909020800282039,
    TTD: 0.018498589811387273,
    TWD: 0.08933765203595982,
    TZS: 7.152917327692579,
    UAH: 0.10963401198660322,
    UGX: 10.474176141371409,
    USD: 0.0027656396924608663,
    VND: 70.20509628944122,
    XAF: 1.6849770844350433,
    XCD: 0.007463643574828134,
    XPF: 0.30629737352370884,
    ZAR: 0.05107703155297021,
    ZMW: 0.07516745989776133,
  },
  IDR: {
    AED: 0.00046087,
    AFN: 0.0089685,
    ARS: 0.11022119,
    AUD: 0.00009400617033940627,
    BDT: 0.0138131,
    BHD: 0.00004719,
    BIF: 0.359801,
    BMD: 0.00012538,
    BND: 0.00016931,
    BOB: 0.00084624,
    BRL: 0.00063607,
    BSD: 0.00012475,
    CAD: 0.0000850766995372406,
    CHF: 0.0001,
    CLP: 0.11767,
    CNH: 0.00090238,
    CNY: 0.0004,
    COP: 0.48966001,
    CRC: 0.06395,
    CZK: 0.0029232,
    DJF: 0.022369,
    DKK: 0.00086962,
    DOP: 0.007264,
    DZD: 0.016941,
    EGP: 0.00601592,
    EUR: 0.000058055152394775034,
    GBP: 0.00004958666809668006,
    GTQ: 0.0009744,
    HKD: 0.000485932284655828,
    HNL: 0.0030983,
    HTG: 0.0169215,
    HUF: 0.045384,
    IDR: 1,
    ILS: 0.00046677,
    INR: 0.0052,
    IQD: 0.16411,
    JMD: 0.0196114,
    JOD: 0.00008879,
    JPY: 0.019201,
    KES: 0.016779,
    KRW: 0.0842,
    KZT: 0.05555555555555555,
    LBP: 11.23537813,
    MAD: 0.0012603,
    MUR: 0.0058003,
    MVR: 0.0019384,
    MWK: 0.21747759,
    MXN: 0.00213055,
    MYR: 0.0003,
    NGN: 0.173066,
    NIO: 0.0045961,
    NOK: 0.00135998,
    NZD: 0.0002087,
    OMR: 0.00004818,
    PEN: 0.00046643,
    PHP: 0.0071678,
    PKR: 0.0349069,
    PLN: 0.00050367,
    QAR: 0.00045663,
    RON: 0.00057972,
    RUB: 0.0114758,
    SAR: 0.00046984,
    SCR: 0.00185023,
    SEK: 0.00135679,
    SGD: 0.0001,
    SZL: 0.0023185,
    THB: 0.0023,
    TND: 0.00039264,
    TRY: 0.00404327,
    TTD: 0.00083954,
    TWD: 0.002,
    TZS: 0.324628,
    UAH: 0.00497563,
    UGX: 0.47536001,
    USD: 0.00012551,
    VND: 3.18618809,
    XAF: 0.076471,
    XCD: 0.00033873,
    XPF: 0.013901,
    ZAR: 0.00231808,
    ZMW: 0.0034114,
  },
  ILS: {
    AED: 0.98810528,
    AFN: 19.228464,
    ARS: 236.31423136,
    AUD: 0.4081799257112535,
    BDT: 29.6152864,
    BHD: 0.10117536,
    BIF: 771.413344,
    BMD: 0.26881472,
    BND: 0.36300064,
    BOB: 1.81433856,
    BRL: 1.36373408,
    BSD: 0.267464,
    CAD: 0.3692489476404992,
    CHF: 0.24336229344625343,
    CLP: 252.28448,
    CNH: 1.93470272,
    CNY: 1.9489378288832586,
    COP: 1049.83106144,
    CRC: 137.1088,
    CZK: 6.2673408,
    DJF: 47.959136,
    DKK: 1.86446528,
    DOP: 15.574016,
    DZD: 36.321504,
    EGP: 12.89813248,
    EUR: 0.25006876891145063,
    GBP: 0.2142382758103563,
    GTQ: 2.0891136,
    HKD: 2.1009056,
    HNL: 6.6427552,
    HTG: 36.279696,
    HUF: 97.303296,
    IDR: 4323.74281696,
    ILS: 1,
    INR: 22.4185216,
    IQD: 351.85184,
    JMD: 42.0468416,
    JOD: 0.19036576,
    JPY: 41.201,
    KES: 35.974176,
    KRW: 363.995456,
    KZT: 119.1111111111111,
    LBP: 24088.65071072,
    MAD: 2.7020832,
    MUR: 12.4358432,
    MVR: 4.1559296,
    MWK: 466.27195296,
    MXN: 4.5678992,
    MYR: 1.27477952,
    NGN: 371.053504,
    NIO: 9.8540384,
    NOK: 2.91579712,
    NZD: 0.4474528,
    OMR: 0.10329792,
    PEN: 1.00002592,
    PHP: 15.3677632,
    PKR: 74.8403936,
    PLN: 1.0822,
    QAR: 0.97901472,
    RON: 1.24291968,
    RUB: 24.6041152,
    SAR: 1.00733696,
    SCR: 3.96689312,
    SEK: 2.90895776,
    SGD: 0.36304352,
    SZL: 4.970864,
    THB: 9.8947744,
    TND: 0.84182016,
    TRY: 8.66877088,
    TTD: 1.79997376,
    TWD: 8.692848,
    TZS: 696.002432,
    UAH: 10.66775072,
    UGX: 1019.17186144,
    USD: 0.26926598093596854,
    VND: 6831.18726496,
    XAF: 163.953824,
    XCD: 0.72623712,
    XPF: 29.803744,
    ZAR: 4.96996352,
    ZMW: 7.3140416,
  },
  INR: {
    AED: 0.04414426345296429,
    AFN: 0.860976,
    ARS: 10.58123424,
    AUD: 0.018145527127563055,
    BDT: 1.3260576,
    BHD: 0.00453024,
    BIF: 34.540896,
    BMD: 0.01203648,
    BND: 0.01625376,
    BOB: 0.08123904,
    BRL: 0.06106272,
    BSD: 0.011976,
    CAD: 0.016420630880638436,
    CHF: 0.0109,
    CLP: 11.29632,
    CNH: 0.08662848,
    CNY: 0.087192,
    COP: 47.00736096,
    CRC: 6.1392,
    CZK: 0.2806272,
    DJF: 2.147424,
    DKK: 0.08348352,
    DOP: 0.697344,
    DZD: 1.626336,
    EGP: 0.57752832,
    EUR: 0.0111,
    GBP: 0.0095635209058567,
    GTQ: 0.0935424,
    HKD: 0.0936803252580893,
    HNL: 0.2974368,
    HTG: 1.624464,
    HUF: 4.356864,
    IDR: 192.30769230769232,
    ILS: 0.04480992,
    INR: 1,
    IQD: 15.75456,
    JMD: 1.8826944,
    JOD: 0.00852384,
    JPY: 1.843296,
    KES: 1.610784,
    KRW: 16.248,
    KZT: 5.333333333333333,
    LBP: 1078.59630048,
    MAD: 0.1209888,
    MUR: 0.5568288,
    MVR: 0.1860864,
    MWK: 20.87784864,
    MXN: 0.2045328,
    MYR: 0.0563,
    NGN: 16.614336,
    NIO: 0.4412256,
    NOK: 0.13055808,
    NZD: 0.0199,
    OMR: 0.00462528,
    PEN: 0.04477728,
    PHP: 0.6881088,
    PKR: 3.3344,
    PLN: 0.04835232,
    QAR: 0.04383648,
    RON: 0.05565312,
    RUB: 1.1016768,
    SAR: 0.04510464,
    SCR: 0.17762208,
    SEK: 0.13025184,
    SGD: 0.01625568,
    SZL: 0.222576,
    THB: 0.4401,
    TND: 0.03769344,
    TRY: 0.38815392,
    TTD: 0.08059584,
    TWD: 0.3872,
    TZS: 31.164288,
    UAH: 0.47766048,
    UGX: 45.63456096,
    USD: 0.01204896,
    VND: 305.87405664,
    XAF: 7.341216,
    XCD: 0.03251808,
    XPF: 1.334496,
    ZAR: 0.2217,
    ZMW: 0.3274944,
  },
  IQD: {
    AED: 0.0028082993114374507,
    AFN: 0.05464932057766133,
    ARS: 0.6716299433306928,
    AUD: 0.0011561757357869723,
    BDT: 0.08416976418256049,
    BHD: 0.00028755103284382427,
    BIF: 2.192437998903175,
    BMD: 0.0007639997562610445,
    BND: 0.0010316860642252147,
    BOB: 0.00515654134422034,
    BRL: 0.0038758759368716107,
    BSD: 0.0007601608677106819,
    CAD: 0.001046371336298824,
    CHF: 0.0006919748948875754,
    CLP: 0.717019072573274,
    CNH: 0.005498628968374871,
    CNY: 0.005534397660106027,
    COP: 2.983730485649869,
    CRC: 0.38967765523124737,
    CZK: 0.017812442873682287,
    DJF: 0.13630491743342882,
    DKK: 0.005299006763756018,
    DOP: 0.04426299433306929,
    DZD: 0.10322954116141612,
    EGP: 0.03665785144110658,
    EUR: 0.0007103162512948633,
    GBP: 0.0006093473889464384,
    GTQ: 0.005937480957894096,
    HKD: 0.00597099506428615,
    HNL: 0.0188794101517275,
    HTG: 0.10311071842057157,
    HUF: 0.2765462189994516,
    IDR: 12.288532630552679,
    ILS: 0.0028442508073852906,
    INR: 0.06371580037779538,
    IQD: 1,
    JMD: 0.11950155383584182,
    JOD: 0.0005410395466455426,
    JPY: 0.11700079215160564,
    KES: 0.1022423983913229,
    KRW: 1.0345134361099264,
    KZT: 0.3385263271924657,
    LBP: 68.46248327341418,
    MAD: 0.007679605142891963,
    MUR: 0.03534397660106026,
    MVR: 0.011811589787337762,
    MWK: 1.3251940162086406,
    MXN: 0.012982450795198342,
    MYR: 0.0036230577051977334,
    NGN: 1.0545731521540431,
    NIO: 0.028006215343367256,
    NOK: 0.008287002620193773,
    NZD: 0.001271708000731217,
    OMR: 0.000293583571994394,
    PEN: 0.0028421790262628728,
    PHP: 0.04367680214490281,
    PKR: 0.2127042837121443,
    PLN: 0.003069099993906526,
    QAR: 0.0027824629821461217,
    RON: 0.0035325086832002925,
    RUB: 0.06992748766071538,
    SAR: 0.002862957772225946,
    SCR: 0.011274328194503688,
    SEK: 0.00826756443848638,
    SGD: 0.001031807933703004,
    SZL: 0.014127719212723175,
    THB: 0.02812199134726708,
    TND: 0.0023925415879592956,
    TRY: 0.02463756017305466,
    TTD: 0.0051157150691609286,
    TWD: 0.024705989884833347,
    TZS: 1.978112241789044,
    UAH: 0.030318871488635674,
    UGX: 2.8965938090305285,
    USD: 0.0007639419404125286,
    VND: 19.414953933337397,
    XAF: 0.4659740418012309,
    XCD: 0.002064042410578271,
    XPF: 0.0847053805374444,
    ZAR: 0.0141251599536896,
    ZMW: 0.020787276826518798,
  },
  JMD: {
    AED: 0.02350010708057558,
    AFN: 0.45731054386734243,
    ARS: 5.6202611746229225,
    AUD: 0.009674984957728667,
    BDT: 0.7043403326636548,
    BHD: 0.0024062535056140815,
    BIF: 18.34652294073855,
    BMD: 0.00639322026984305,
    BND: 0.00863324392955118,
    BOB: 0.043150412515169746,
    BRL: 0.03243368652926359,
    BSD: 0.006361096097167973,
    CAD: 0.008756131637720916,
    CHF: 0.005790509601558277,
    CLP: 6.000081585200444,
    CNH: 0.04601303323577103,
    CNY: 0.0463123489399023,
    COP: 24.968131290983813,
    CRC: 3.2608584802716782,
    CZK: 0.14905616121235607,
    DJF: 1.140612092966336,
    DKK: 0.044342576256667036,
    DOP: 0.3703968100186626,
    DZD: 0.8638343004578969,
    EGP: 0.3067562744118217,
    EUR: 0.005943991759894755,
    GBP: 0.0050990750277899585,
    GTQ: 0.049685387070785354,
    HKD: 0.049965836197313804,
    HNL: 0.15798464158601627,
    HTG: 0.8628399808274778,
    HUF: 2.3141642106121947,
    IDR: 102.83157194284956,
    ILS: 0.02380095250721519,
    INR: 0.5331796812058293,
    IQD: 8.368092028106101,
    JMD: 1,
    JOD: 0.004527468717174704,
    JPY: 0.9790733960859499,
    KES: 0.8555737989128771,
    KRW: 8.656903637680124,
    KZT: 2.83281945988331,
    LBP: 572.9003605046045,
    MAD: 0.06426364257523684,
    MUR: 0.295761648836901,
    MVR: 0.09884047033868056,
    MWK: 11.089345482729431,
    MXN: 0.10863834300457896,
    MYR: 0.030318080300233537,
    NGN: 8.82476518759497,
    NIO: 0.2343585873522543,
    NOK: 0.06934640056293788,
    NZD: 0.010641769582997644,
    OMR: 0.002456734348389202,
    PEN: 0.023783615652120702,
    PHP: 0.36549149984192864,
    PKR: 1.779929020875613,
    PLN: 0.025682511192469684,
    QAR: 0.023283906299397288,
    RON: 0.02956035775110395,
    RUB: 0.58515965203912,
    SAR: 0.023957494110568342,
    SCR: 0.09434461588667815,
    SEK: 0.06918374006955137,
    SGD: 0.008634263744556737,
    SZL: 0.11822205451931019,
    THB: 0.23532741160753437,
    TND: 0.020021008189114495,
    TRY: 0.20616937087612305,
    TTD: 0.04280877448830782,
    TWD: 0.20674199700174387,
    TZS: 16.553025281213987,
    UAH: 0.2537111068052255,
    UGX: 24.23896356200985,
    USD: 0.006415602745877975,
    VND: 162.46612123560786,
    XAF: 3.899313664501259,
    XCD: 0.017272096841632927,
    XPF: 0.7088224196130821,
    ZAR: 0.11820063840419347,
    ZMW: 0.17394984549802664,
  },
  JOD: {
    AED: 5.190562000225251,
    AFN: 101.00799639599055,
    ARS: 1241.369410969704,
    AUD: 2.136952359499944,
    BDT: 155.5704471224237,
    BHD: 0.5314787701317717,
    BIF: 4052.2693997071747,
    BMD: 1.4120959567518867,
    BND: 1.9068588805045614,
    BOB: 9.530803018357924,
    BRL: 7.163757179862598,
    BSD: 1.4050005631264784,
    CAD: 1.9340015767541392,
    CHF: 1.2789728573037507,
    CLP: 1325.2618538123663,
    CNH: 10.16308142808875,
    CNY: 10.229192476630253,
    COP: 5514.810339002141,
    CRC: 720.2387656267598,
    CZK: 32.922626421894364,
    DJF: 251.93152382025005,
    DKK: 9.79412095956752,
    DOP: 81.81101475391374,
    DZD: 190.79851334609756,
    EGP: 67.75447685550175,
    EUR: 1.3128730712918122,
    GBP: 1.1262529564140107,
    GTQ: 10.97420880729812,
    HKD: 11.03615271990089,
    HNL: 34.89469534857529,
    HTG: 190.57889401959682,
    HUF: 511.1386417389346,
    IDR: 22712.817772271654,
    ILS: 5.257010924653677,
    INR: 117.76551413447461,
    IQD: 1848.2937267710329,
    JMD: 220.8739722941773,
    JOD: 1,
    JPY: 216.2518301610542,
    KES: 188.97398355670686,
    KRW: 1912.0846942223225,
    KZT: 625.6960868966726,
    LBP: 126538.7783534182,
    MAD: 14.194166009685777,
    MUR: 65.32605023088186,
    MVR: 21.831287307129184,
    MWK: 2449.347786912941,
    MXN: 23.995382362878704,
    MYR: 6.696474828246425,
    NGN: 1949.1609415474718,
    NIO: 51.76371212974435,
    NOK: 15.316814956639263,
    NZD: 2.3504899200360403,
    OMR: 0.5426286744002704,
    PEN: 5.25318166460187,
    PHP: 80.72755940984347,
    PKR: 393.1399932424823,
    PLN: 5.672598265570448,
    QAR: 5.142808874873297,
    RON: 6.529113638923302,
    RUB: 129.24653677215903,
    SAR: 5.291586890415588,
    SCR: 20.83827007545895,
    SEK: 15.280887487329656,
    SGD: 1.9070841310958444,
    SZL: 26.112174794458838,
    THB: 51.97770019146301,
    TND: 4.422119608063972,
    TRY: 45.53744791080077,
    TTD: 9.455344070278185,
    TWD: 45.663926117806064,
    TZS: 3656.1324473476748,
    UAH: 56.03817997522244,
    UGX: 5353.756166234937,
    USD: 1.4111,
    VND: 35884.5375605361,
    XAF: 861.2568982993581,
    XCD: 3.8149566392611782,
    XPF: 156.56042347111162,
    ZAR: 26.1074445320419,
    ZMW: 38.42099335510756,
  },
  JPY: {
    AED: 0.024046554128793345,
    AFN: 0.466362,
    ARS: 5.73150188,
    AUD: 0.009884353069091628,
    BDT: 0.7182812,
    BHD: 0.00245388,
    BIF: 18.709652,
    BMD: 0.00651976,
    BND: 0.00880412,
    BOB: 0.04400448,
    BRL: 0.03319061369444721,
    BSD: 0.006487,
    CAD: 0.008944543828264758,
    CHF: 0.005918910920390648,
    CLP: 6.1324,
    CNH: 0.0470787627701144,
    CNY: 0.047229,
    COP: 25.46232052,
    CRC: 3.3254,
    CZK: 0.152571594220588,
    DJF: 1.163188,
    DKK: 0.04534941725998821,
    DOP: 0.377728,
    DZD: 0.880932,
    EGP: 0.31282784,
    EUR: 0.006080579844093933,
    GBP: 0.005208062080099995,
    GTQ: 0.0506688,
    HKD: 0.051121346740758536,
    HNL: 0.1611116,
    HTG: 0.879918,
    HUF: 2.359968,
    IDR: 104.86689668,
    ILS: 0.024271255552049707,
    INR: 0.5437328,
    IQD: 8.53372,
    JMD: 1.0197928,
    JOD: 0.00461708,
    JPY: 1,
    KES: 0.872508,
    KRW: 8.828248,
    KZT: 2.8888888888888884,
    LBP: 584.23966276,
    MAD: 0.0655356,
    MUR: 0.3016156,
    MVR: 0.1007968,
    MWK: 11.30883468,
    MXN: 0.1107886,
    MYR: 0.03091816,
    NGN: 8.999432,
    NIO: 0.2389972,
    NOK: 0.0708,
    NZD: 0.0109,
    OMR: 0.00250536,
    PEN: 0.02425436,
    PHP: 0.3727256,
    PKR: 1.8151588,
    PLN: 0.0262,
    QAR: 0.02374476,
    RON: 0.03014544,
    RUB: 0.5967416,
    SAR: 0.02443168,
    SCR: 0.09621196,
    SEK: 0.0707,
    SGD: 0.00880516,
    SZL: 0.120562,
    THB: 0.2382,
    TND: 0.02041728,
    TRY: 0.21011493286827895,
    TTD: 0.04365608,
    TWD: 0.210834,
    TZS: 16.880656,
    UAH: 0.25873276,
    UGX: 24.71872052,
    USD: 0.006541933795629988,
    VND: 163.9344262295082,
    XAF: 3.976492,
    XCD: 0.01761396,
    XPF: 0.722852,
    ZAR: 0.121,
    ZMW: 0.1773928,
  },
  KES: {
    AED: 0.02746707193515704,
    AFN: 0.5345074199892723,
    ARS: 6.568996364503248,
    AUD: 0.011308182847607129,
    BDT: 0.8232373800584063,
    BHD: 0.0028124441265868047,
    BIF: 21.443530603730853,
    BMD: 0.007472435782823768,
    BND: 0.010090589427260266,
    BOB: 0.05043447166100483,
    BRL: 0.037908695393050834,
    BSD: 0.007434888849156684,
    CAD: 0.010234221348113713,
    CHF: 0.006767983789260385,
    CLP: 7.012932832707551,
    CNH: 0.053780320638893854,
    CNY: 0.054130162703379225,
    COP: 29.182907801418438,
    CRC: 3.8113117587460517,
    CZK: 0.17421777221526907,
    DJF: 1.3331545384111092,
    DKK: 0.05182788008820549,
    DOP: 0.4329221050122177,
    DZD: 1.0096549258001073,
    EGP: 0.35853864950235415,
    EUR: 0.006933851060879213,
    GBP: 0.005959830740806961,
    GTQ: 0.05807259073842303,
    HKD: 0.05840038142916741,
    HNL: 0.18465343584242208,
    HTG: 1.0084927588056498,
    HUF: 2.7048095834078314,
    IDR: 120.19018356278681,
    ILS: 0.02781870194886465,
    INR: 0.6231837415817391,
    IQD: 9.780678228738303,
    JMD: 1.1688062459026163,
    JOD: 0.005291733714762501,
    JPY: 1.1443471005423447,
    KES: 1,
    KRW: 10.11824304189761,
    KZT: 3.3110170782260893,
    LBP: 669.6095196376423,
    MAD: 0.07511174682639013,
    MUR: 0.34568806245902617,
    MVR: 0.11552535907980213,
    MWK: 12.961296263186126,
    MXN: 0.1269771738482627,
    MYR: 0.03543596161869003,
    NGN: 10.314440669884975,
    NIO: 0.27391978067822875,
    NOK: 0.08105250610882651,
    NZD: 0.012438166756064128,
    OMR: 0.002871446450920794,
    PEN: 0.02779843852434591,
    PHP: 0.42718874783956134,
    PKR: 2.0803921568627453,
    PLN: 0.03001787949222242,
    QAR: 0.027214375111746826,
    RON: 0.03455033077060612,
    RUB: 0.6839382561535252,
    SAR: 0.028001668752607425,
    SCR: 0.11027057631563264,
    SEK: 0.08086238750819477,
    SGD: 0.010091781393408427,
    SZL: 0.13817867572560938,
    THB: 0.2750521485189821,
    TND: 0.02340067942070445,
    TRY: 0.2409720483938256,
    TTD: 0.05003516300137076,
    TWD: 0.24164133738601823,
    TZS: 19.347279337266823,
    UAH: 0.2965391262888134,
    UGX: 28.330652005483042,
    USD: 0.0075,
    VND: 189.89141724775016,
    XAF: 4.5575421658024915,
    XCD: 0.02018773466833542,
    XPF: 0.8284760712795757,
    ZAR: 0.1379,
    ZMW: 0.20331366589188868,
  },
  KRW: {
    AED: 0.002714608832919057,
    AFN: 0.052826110005065555,
    ARS: 0.6492230259050267,
    AUD: 0.0011166945840312675,
    BDT: 0.08136169260310766,
    BHD: 0.0002779577556045095,
    BIF: 2.1192938848115728,
    BMD: 0.000738511197238682,
    BND: 0.0009972669548929754,
    BOB: 0.00498450881760458,
    BRL: 0.0037406950211349268,
    BSD: 0.0007348003816838855,
    CAD: 0.0010101826410214966,
    CHF: 0.0006688892292105975,
    CLP: 0.6930978830680787,
    CNH: 0.005315183714820879,
    CNY: 0.0053,
    COP: 2.884187272491665,
    CRC: 0.37667722972893375,
    CZK: 0.017218184174255184,
    DJF: 0.1317575129289526,
    DKK: 0.0051222213059714675,
    DOP: 0.042786292365144245,
    DZD: 0.09978559732350065,
    EGP: 0.03543487224192161,
    EUR: 0.0007,
    GBP: 0.0005890183420311708,
    GTQ: 0.005739394724751728,
    HKD: 0.005780346820809248,
    HNL: 0.018249555291151767,
    HTG: 0.09967073874680457,
    HUF: 0.26732008434742655,
    IDR: 11.876484560570072,
    ILS: 0.002749360915098896,
    INR: 0.06154603643525357,
    IQD: 0.9666380011073544,
    JMD: 0.11551474312910102,
    JOD: 0.0005229893858894766,
    JPY: 0.1130974118534051,
    KES: 0.09883138760941015,
    KRW: 1,
    KZT: 0.3272324122395393,
    LBP: 66.17843798225876,
    MAD: 0.007423398164618846,
    MUR: 0.034164830892834,
    MVR: 0.011417531541932214,
    MWK: 1.2809828949073474,
    MXN: 0.01254933028614511,
    MYR: 0.0035,
    NGN: 1.0193904838196661,
    NIO: 0.02707187201809464,
    NOK: 0.008010531647955517,
    NZD: 0.0012285012285012285,
    OMR: 0.0002837890371906181,
    PEN: 0.0027473582527359898,
    PHP: 0.04221965672011026,
    PKR: 0.20560804363447877,
    PLN: 0.002966708683308398,
    QAR: 0.002689634455216935,
    RON: 0.0034146571324231035,
    RUB: 0.0675945668948131,
    SAR: 0.002767443778199253,
    SCR: 0.010898194069763332,
    SEK: 0.008,
    SGD: 0.001,
    SZL: 0.013656390259992695,
    THB: 0.027111291853056798,
    TND: 0.002312721618151189,
    TRY: 0.02381560191784372,
    TTD: 0.004945044588688491,
    TWD: 0.0238,
    TZS: 1.9121184633689492,
    UAH: 0.029307373331605543,
    UGX: 2.7999576495812075,
    USD: 0.0007,
    VND: 18.76723226171263,
    XAF: 0.45042821633465663,
    XCD: 0.001995181829962185,
    XPF: 0.08187943972575305,
    ZAR: 0.0137,
    ZMW: 0.020093771720051362,
  },
  KZT: {
    AED: 0.00829566,
    AFN: 0.161433,
    ARS: 1.98398142,
    AUD: 0.00341532,
    BDT: 0.2486358,
    BHD: 0.00084942,
    BIF: 6.476418,
    BMD: 0.00225684,
    BND: 0.00304758,
    BOB: 0.01523232,
    BRL: 0.01144926,
    BSD: 0.0022455,
    CAD: 0.00309096,
    CHF: 0.00204408,
    CLP: 2.11806,
    CNH: 0.01624284,
    CNY: 0.0163485,
    COP: 8.81388018,
    CRC: 1.1511,
    CZK: 0.0526176,
    DJF: 0.402642,
    DKK: 0.01565316,
    DOP: 0.130752,
    DZD: 0.304938,
    EGP: 0.10828656,
    EUR: 0.00209826,
    GBP: 0.0018,
    GTQ: 0.0175392,
    HKD: 0.0176382,
    HNL: 0.0557694,
    HTG: 0.304587,
    HUF: 0.816912,
    IDR: 36.30007962,
    ILS: 0.00840186,
    INR: 0.1882152,
    IQD: 2.95398,
    JMD: 0.3530052,
    JOD: 0.00159822,
    JPY: 0.345618,
    KES: 0.302022,
    KRW: 3.055932,
    KZT: 1,
    LBP: 202.23680634,
    MAD: 0.0226854,
    MUR: 0.1044054,
    MVR: 0.0348912,
    MWK: 3.91459662,
    MXN: 0.0383499,
    MYR: 0.01070244,
    NGN: 3.115188,
    NIO: 0.0827298,
    NOK: 0.02447964,
    NZD: 0.0037566,
    OMR: 0.00086724,
    PEN: 0.00839574,
    PHP: 0.1290204,
    PKR: 0.6283242,
    PLN: 0.00906606,
    QAR: 0.00821934,
    RON: 0.01043496,
    RUB: 0.2065644,
    SAR: 0.00845712,
    SCR: 0.03330414,
    SEK: 0.02442222,
    SGD: 0.00304794,
    SZL: 0.041733,
    THB: 0.0830718,
    TND: 0.00706752,
    TRY: 0.07277886,
    TTD: 0.01511172,
    TWD: 0.072981,
    TZS: 5.843304,
    UAH: 0.08956134,
    UGX: 8.55648018,
    USD: 0.0022542323211830213,
    VND: 57.35138562,
    XAF: 1.376478,
    XCD: 0.00609714,
    XPF: 0.250218,
    ZAR: 0.04172544,
    ZMW: 0.0614052,
  },
  LBP: {
    AED: 0.000041019536206744474,
    AFN: 0.0007982374866452315,
    ARS: 0.009810189628215032,
    AUD: 0.00001688772712449866,
    BDT: 0.0012294290267914642,
    BHD: 0.000004200125661458267,
    BIF: 0.03202393331465027,
    BMD: 0.000011159392994991262,
    BND: 0.000015069363758031347,
    BOB: 0.00007531922737343599,
    BRL: 0.00005661313688247002,
    BSD: 0.000011103320115849097,
    CAD: 0.00001528386477189264,
    CHF: 0.000010107358976800188,
    CLP: 0.010473167759775257,
    CNH: 0.00008031594393699325,
    CNY: 0.00008083840076328611,
    COP: 0.043581978669016995,
    CRC: 0.005691842255779958,
    CZK: 0.00026017815921963993,
    DJF: 0.0019909432278270816,
    DKK: 0.00007740015422160074,
    DOP: 0.000646529196966155,
    DZD: 0.001507826421503804,
    EGP: 0.0005354443731570253,
    EUR: 0.000010375262732701638,
    GBP: 0.000008900457006692663,
    GTQ: 0.00008672605307321331,
    HKD: 0.00008721557820858141,
    HNL: 0.0002757628594383588,
    HTG: 0.001506090832387499,
    HUF: 0.004039383407917398,
    IDR: 0.1794929433318503,
    ILS: 0.00004154466317013934,
    INR: 0.0009306673864478116,
    IQD: 0.01460653999368333,
    JMD: 0.0017455042254105248,
    JOD: 0.000007902715776242415,
    JPY: 0.0017089767498550583,
    KES: 0.001493407681152962,
    KRW: 0.015110661878542401,
    KZT: 0.004944698337051479,
    LBP: 1,
    MAD: 0.00011217245965534763,
    MUR: 0.0005162532077591946,
    MVR: 0.0001725264586177306,
    MWK: 0.019356499397141343,
    MXN: 0.00018962868675609053,
    MYR: 0.000052920337270393235,
    NGN: 0.015403664923202724,
    NIO: 0.0004090739044846015,
    NOK: 0.00012104443519961888,
    NZD: 0.000018575253772967586,
    OMR: 0.000004288240185824525,
    PEN: 0.00004151440161631659,
    PHP: 0.0006379669573257166,
    PKR: 0.003106873626869201,
    PLN: 0.000044828931805608935,
    QAR: 0.00004064215682966071,
    RON: 0.00005159772935919871,
    RUB: 0.0010213986451740366,
    SAR: 0.00004181790720024481,
    SCR: 0.00016467892567492966,
    SEK: 0.00012076051062110538,
    SGD: 0.000015071143849432686,
    SZL: 0.0002063570957001694,
    THB: 0.00041076499131587307,
    TND: 0.00003494675439107807,
    TRY: 0.00035986950801450245,
    TTD: 0.00007472289675398759,
    TWD: 0.00036086902933635403,
    TZS: 0.02889337557168626,
    UAH: 0.00044285380896210216,
    UGX: 0.042309213317059946,
    USD: 0.0000111731843575419,
    VND: 0.2835853011028121,
    XAF: 0.0068062684775879465,
    XCD: 0.000030148518018770057,
    XPF: 0.001237252528500347,
    ZAR: 0.00020631971378074127,
    ZMW: 0.0003036301903263135,
  },
  MAD: {
    AED: 0.3640873,
    AFN: 7.085115,
    ARS: 87.0747401,
    AUD: 0.1498946,
    BDT: 10.912349,
    BHD: 0.0372801,
    BIF: 284.24279,
    BMD: 0.0990502,
    BND: 0.1337549,
    BOB: 0.6685296,
    BRL: 0.5024953,
    BSD: 0.0985525,
    CAD: 0.1356588,
    CHF: 0.0897124,
    CLP: 92.9593,
    CNH: 0.7128802,
    CNY: 0.7175175,
    COP: 386.8314079,
    CRC: 50.5205,
    CZK: 2.309328,
    DJF: 17.67151,
    DKK: 0.6869998,
    DOP: 5.73856,
    DZD: 13.38339,
    EGP: 4.7525768,
    EUR: 0.09242571283331023,
    GBP: 0.07934618741569467,
    GTQ: 0.769776,
    HKD: 0.774121,
    HNL: 2.447657,
    HTG: 13.367985,
    HUF: 35.85336,
    IDR: 1593.1701611,
    ILS: 0.3687483,
    INR: 8.260556,
    IQD: 129.6469,
    JMD: 15.493006,
    JOD: 0.0701441,
    JPY: 15.16879,
    KES: 13.25541,
    KRW: 134.12146,
    KZT: 43.888888888888886,
    LBP: 8875.9487227,
    MAD: 1,
    MUR: 4.582237,
    MVR: 1.531336,
    MWK: 171.8072961,
    MXN: 1.6831345,
    MYR: 0.4697182,
    NGN: 136.72214,
    NIO: 3.630919,
    NOK: 1.0743842,
    NZD: 0.164873,
    OMR: 0.0380622,
    PEN: 0.3684797,
    PHP: 5.662562,
    PKR: 27.576451,
    PLN: 0.3978993,
    QAR: 0.3607377,
    RON: 0.4579788,
    RUB: 9.065882,
    SAR: 0.3711736,
    SCR: 1.4616817,
    SEK: 1.0718641,
    SGD: 0.1337707,
    SZL: 1.831615,
    THB: 3.645929,
    TND: 0.3101856,
    TRY: 3.1941833,
    TTD: 0.6632366,
    TWD: 3.203055,
    TZS: 256.45612,
    UAH: 3.9307477,
    UGX: 375.5344079,
    USD: 0.0991529,
    VND: 2517.0885911,
    XAF: 60.41209,
    XCD: 0.2675967,
    XPF: 10.98179,
    ZAR: 1.8312832,
    ZMW: 2.695006,
  },
  MUR: {
    AED: 0.07945623502232642,
    AFN: 1.5462131269072288,
    ARS: 19.002670551523195,
    AUD: 0.03271210109821905,
    BDT: 2.3814457872868644,
    BHD: 0.008135786080030342,
    BIF: 62.031446649311235,
    BMD: 0.021616123303967033,
    BND: 0.02918986948950916,
    BOB: 0.14589590193610674,
    BRL: 0.10966156922917779,
    BSD: 0.021507508232332807,
    CAD: 0.029605365239728978,
    CHF: 0.019578297674258227,
    CLP: 20.286881713014843,
    CNH: 0.15557471165284553,
    CNY: 0.15658672827267553,
    COP: 84.41977311518369,
    CRC: 11.025291795251968,
    CZK: 0.5039739323828077,
    DJF: 3.8565246625174554,
    DKK: 0.1499267279278658,
    DOP: 1.2523490164301845,
    DZD: 2.920710997707015,
    EGP: 1.0371739392790027,
    EUR: 0.02009723634984397,
    GBP: 0.017240487560988223,
    GTQ: 0.16799131079426924,
    HKD: 0.1689395376101236,
    HNL: 0.5341620261020981,
    HTG: 2.917349102632622,
    HUF: 7.824422874678895,
    IDR: 347.6839284174956,
    ILS: 0.08047342378842473,
    INR: 1.8027343413271726,
    IQD: 28.29336413633777,
    JMD: 3.3811009775356444,
    JOD: 0.015307828905401444,
    JPY: 3.3103460165853487,
    KES: 2.892781407858214,
    KRW: 29.269865351792145,
    KZT: 9.578048644993457,
    LBP: 1937.0339689326413,
    MAD: 0.21728186473113456,
    MUR: 1,
    MVR: 0.33418961088219573,
    MWK: 37.49419685188697,
    MXN: 0.3673172077306346,
    MYR: 0.10250849094012378,
    NGN: 29.837422202299877,
    NIO: 0.7923900487905797,
    NOK: 0.23446718273192763,
    NZD: 0.03598089753978242,
    OMR: 0.008306466906884125,
    PEN: 0.08041480613071737,
    PHP: 1.2357636673965138,
    PKR: 6.018119752426598,
    PLN: 0.08683516369842938,
    QAR: 0.07872523834974052,
    RON: 0.09994655448856092,
    RUB: 1.9784838715238866,
    SAR: 0.08100270675654707,
    SCR: 0.3189886729996724,
    SEK: 0.2339172111787321,
    SGD: 0.02919331758702136,
    SZL: 0.39972070410151195,
    THB: 0.7956657414271675,
    TND: 0.06769305035946416,
    TRY: 0.6970794614071685,
    TTD: 0.14474078926952053,
    TWD: 0.6990155681602676,
    TZS: 55.967449959484846,
    UAH: 0.8578228712307984,
    UGX: 81.95438339396237,
    USD: 0.0217,
    VND: 549.3143613261383,
    XAF: 13.183973242763305,
    XCD: 0.05839870351533541,
    XPF: 2.396600175852973,
    ZAR: 0.3996482940537558,
    ZMW: 0.5881419926555522,
  },
  MVR: {
    AED: 0.23775794469665704,
    AFN: 4.626754023937268,
    ARS: 56.86194283945522,
    AUD: 0.0978848534874123,
    BDT: 7.126031778786628,
    BHD: 0.024344820470491125,
    BIF: 185.61751960379695,
    BMD: 0.06468221213371853,
    BND: 0.08734523318200578,
    BOB: 0.43656624019810153,
    BRL: 0.3281417664052827,
    BSD: 0.06435720181593066,
    CAD: 0.0885885266198927,
    CHF: 0.058584399504746185,
    CLP: 60.70470491126702,
    CNH: 0.46552827073875364,
    CNY: 0.4685565414775072,
    COP: 252.61040548906314,
    CRC: 32.991126702435,
    CZK: 1.5080478745356996,
    DJF: 11.53992983904251,
    DKK: 0.44862773421378455,
    DOP: 3.7474205530334297,
    DZD: 8.739682212133719,
    EGP: 3.103549319026001,
    EUR: 0.06013722657862154,
    GBP: 0.051588939331407346,
    GTQ: 0.5026826248452332,
    HKD: 0.5055200165084606,
    HNL: 1.5983801073049937,
    HTG: 8.729622368964094,
    HUF: 23.41312422616591,
    IDR: 1040.3792251341313,
    ILS: 0.24080169211721006,
    INR: 5.394345852249278,
    IQD: 84.66260833677259,
    JMD: 10.11731324803962,
    JOD: 0.04580581923235658,
    JPY: 9.905592241023525,
    KES: 8.656108130416838,
    KRW: 87.5846058605035,
    KZT: 28.660521850781855,
    LBP: 5796.212407139909,
    MAD: 0.6501754023937267,
    MUR: 2.99231324803962,
    MVR: 1,
    MWK: 112.1943819645068,
    MXN: 1.0991281469252991,
    MYR: 0.3067375154766818,
    NGN: 89.28291374329343,
    NIO: 2.371079240610813,
    NOK: 0.7015992571192736,
    NZD: 0.10766611638464713,
    OMR: 0.024855550969872058,
    PEN: 0.2406262897234833,
    PHP: 3.6977919933966157,
    PKR: 18.00809946347503,
    PLN: 0.2598380107304994,
    QAR: 0.23557057366900536,
    RON: 0.2990713990920347,
    RUB: 5.920243499793644,
    SAR: 0.24238547255468426,
    SCR: 0.9545140321914981,
    SEK: 0.6999535699546018,
    SGD: 0.08735555096987206,
    SZL: 1.1960895583986793,
    THB: 2.3808811390837805,
    TND: 0.2025588113908378,
    TRY: 2.0858801073049937,
    TTD: 0.43310978126289723,
    TWD: 2.091673545191911,
    TZS: 167.47214197276105,
    UAH: 2.5668747420553033,
    UGX: 245.2331871646719,
    USD: 0.0648508430609598,
    VND: 1643.7206407346264,
    XAF: 39.45057779612051,
    XCD: 0.1747472141972761,
    XPF: 7.171378456458935,
    ZAR: 1.1958728848534874,
    ZMW: 1.7599050763516302,
  },
  MWK: {
    AED: 0.002119160875380309,
    AFN: 0.04123873177001823,
    ARS: 0.5068163115105331,
    AUD: 0.0008724577093207626,
    BDT: 0.06351504998744928,
    BHD: 0.0002169878744747907,
    BIF: 1.654427934390849,
    BMD: 0.0005765191714695752,
    BND: 0.0007785169957051667,
    BOB: 0.003891159544300633,
    BRL: 0.0029247611213642746,
    BSD: 0.0005736223212699754,
    CAD: 0.0007895985972623662,
    CHF: 0.0005221687439151777,
    CLP: 0.5410672428363769,
    CNH: 0.0041493010843094225,
    CNY: 0.004176292371089821,
    COP: 2.2515423773088528,
    CRC: 0.2940532861339874,
    CZK: 0.013441384926143425,
    DJF: 0.1028565747854756,
    DKK: 0.003998664873930228,
    DOP: 0.03340114261887857,
    DZD: 0.07789768131971667,
    EGP: 0.027662252464725215,
    EUR: 0.000536009250424377,
    GBP: 0.0004598174919999803,
    GTQ: 0.004480461642047808,
    HKD: 0.004505751604107807,
    HNL: 0.01424652535463539,
    HTG: 0.07780801690877667,
    HUF: 0.20868357056927106,
    IDR: 9.273006427926665,
    ILS: 0.002146290107408308,
    INR: 0.04808035623348594,
    IQD: 0.7546064861211677,
    JMD: 0.09017664762608414,
    JOD: 0.0004082719511467825,
    JPY: 0.08828955663891622,
    KES: 0.0771527769826767,
    KRW: 0.7806505488680465,
    KZT: 0.25545416222221123,
    LBP: 51.662233934080284,
    MAD: 0.0057950798516757515,
    MUR: 0.026670793988474856,
    MVR: 0.008913102264927619,
    MWK: 1,
    MXN: 0.00979664157580558,
    MYR: 0.002733982843933483,
    NGN: 0.7957877407046859,
    NIO: 0.021133671749811095,
    NOK: 0.006253425927701332,
    NZD: 0.0009596391058039589,
    OMR: 0.00022154006764559051,
    PEN: 0.0021447267279355083,
    PHP: 0.03295879819157459,
    PKR: 0.16050803211494113,
    PLN: 0.002315962761956301,
    QAR: 0.00209966461371951,
    RON: 0.0026656539646222857,
    RUB: 0.05276773574693374,
    SAR: 0.0021604065044127075,
    SCR: 0.008507681182231235,
    SEK: 0.006238757749706533,
    SGD: 0.0007786089592035666,
    SZL: 0.010660868552019543,
    THB: 0.02122103707329109,
    TND: 0.0018054274005887226,
    TRY: 0.018591662708787603,
    TTD: 0.0038603517723366345,
    TWD: 0.018643300213139202,
    TZS: 1.492696327929696,
    UAH: 0.022878817077198618,
    UGX: 2.1857884759528554,
    USD: 0.0005825019331782907,
    VND: 14.650650165840075,
    XAF: 0.3516270343073049,
    XCD: 0.0015575397906515333,
    XPF: 0.06391922956291726,
    ZAR: 0.010658937318553144,
    ZMW: 0.015686213922087328,
  },
  MXN: {
    AED: 0.2166089,
    AFN: 4.215195,
    ARS: 51.8039593,
    AUD: 0.08908368521388993,
    BDT: 6.492157,
    BHD: 0.0221793,
    BIF: 169.10647,
    BMD: 0.0589286,
    BND: 0.0795757,
    BOB: 0.3977328,
    BRL: 0.299419126893826,
    BSD: 0.0586325,
    CAD: 0.08058861927518596,
    CHF: 0.053315419352430914,
    CLP: 55.3049,
    CNH: 0.4241186,
    CNY: 0.4268775,
    COP: 230.1402047,
    CRC: 30.0565,
    CZK: 1.373904,
    DJF: 10.51343,
    DKK: 0.4075,
    DOP: 3.41408,
    DZD: 7.96227,
    EGP: 2.8274824,
    EUR: 0.054724353431764204,
    GBP: 0.046936237121869936,
    GTQ: 0.457968,
    HKD: 0.46108447067502767,
    HNL: 1.456201,
    HTG: 7.953105,
    HUF: 21.33048,
    IDR: 947.8354123,
    ILS: 0.2193819,
    INR: 4.914508,
    IQD: 77.1317,
    JMD: 9.217358,
    JOD: 0.0417313,
    JPY: 9.02447,
    KES: 7.88613,
    KRW: 79.79378,
    KZT: 26.111111111111107,
    LBP: 5280.6277211,
    MAD: 0.592341,
    MUR: 2.726141,
    MVR: 0.911048,
    MWK: 102.2144673,
    MXN: 1,
    MYR: 0.2791,
    NGN: 81.34102,
    NIO: 2.160167,
    NOK: 0.6391906,
    NZD: 0.098089,
    OMR: 0.0226446,
    PEN: 0.2192221,
    PHP: 3.368866,
    PKR: 16.406243,
    PLN: 0.2367249,
    QAR: 0.2146161,
    RON: 0.2724684,
    RUB: 5.393626,
    SAR: 0.2208248,
    SCR: 0.8696081,
    SEK: 0.6376913,
    SGD: 0.0794,
    SZL: 1.089695,
    THB: 2.169097,
    TND: 0.1845408,
    TRY: 1.9003369,
    TTD: 0.3945838,
    TWD: 1.905615,
    TZS: 152.57516,
    UAH: 2.3385461,
    UGX: 223.4192047,
    USD: 0.0589897,
    VND: 1497.5084023,
    XAF: 35.94137,
    XCD: 0.1592031,
    XPF: 6.53347,
    ZAR: 1.089,
    ZMW: 1.603358,
  },
  MYR: {
    AED: 0.77472247,
    AFN: 15.0760485,
    ARS: 185.28182039,
    AUD: 0.31922364808785036,
    BDT: 23.2198211,
    BHD: 0.07932639,
    BIF: 604.825481,
    BMD: 0.21076378,
    BND: 0.28461011,
    BOB: 1.42252944,
    BRL: 1.0702054794520548,
    BSD: 0.20970475,
    CAD: 0.28915105250983114,
    CHF: 0.1909,
    CLP: 197.80327,
    CNH: 1.51690078,
    CNY: 1.5276504735716467,
    COP: 823.11847681,
    CRC: 107.49995,
    CZK: 4.9138992,
    DJF: 37.602289,
    DKK: 1.46183122,
    DOP: 12.210784,
    DZD: 28.477821,
    EGP: 10.11276152,
    EUR: 0.19611688566385566,
    GBP: 0.1681859463823203,
    GTQ: 1.6379664,
    HKD: 1.6479894528675016,
    HNL: 5.2082423,
    HTG: 28.4450415,
    HUF: 76.290504,
    IDR: 3333.3333333333335,
    ILS: 0.78464037,
    INR: 17.761989342806395,
    IQD: 275.86891,
    JMD: 32.9667634,
    JOD: 0.14925599,
    JPY: 32.276881,
    KES: 28.205499,
    KRW: 285.7142857142857,
    KZT: 93.38888888888889,
    LBP: 18886.67063653,
    MAD: 2.1185643,
    MUR: 9.7503043,
    MVR: 3.2584504,
    MWK: 365.57982879,
    MXN: 3.5829451809387316,
    MYR: 1,
    NGN: 290.923946,
    NIO: 7.7260441,
    NOK: 2.28612638,
    NZD: 0.3512,
    OMR: 0.08099058,
    PEN: 0.78406883,
    PHP: 12.0490718,
    PKR: 58.6784989,
    PLN: 0.84666927,
    QAR: 0.76759503,
    RON: 0.97450932,
    RUB: 19.2908198,
    SAR: 0.7912644405760405,
    SCR: 3.11023663,
    SEK: 2.28076399,
    SGD: 0.2842,
    SZL: 3.8973985,
    THB: 7.745,
    TND: 0.66002784,
    TRY: 6.81663258350375,
    TTD: 1.41126674,
    TWD: 6.8193,
    TZS: 545.699668,
    UAH: 8.36403403,
    UGX: 799.08017681,
    USD: 0.21098231,
    VND: 5355.98217929,
    XAF: 128.547751,
    XCD: 0.56940513,
    XPF: 23.367581,
    ZAR: 3.9038,
    ZMW: 5.7345634,
  },
  NGN: {
    AED: 0.0026629725075982573,
    AFN: 0.051821270497960314,
    ARS: 0.6368737360313407,
    AUD: 0.0010963447470907052,
    BDT: 0.07981405937619174,
    BHD: 0.00027267054187419827,
    BIF: 2.078981429050189,
    BMD: 0.0007244634994741891,
    BND: 0.0009782972969849652,
    BOB: 0.0048896952607675685,
    BRL: 0.003675303063571123,
    BSD: 0.0007208232697352455,
    CAD: 0.000992222620272035,
    CHF: 0.0006561658558006772,
    CLP: 0.6799140212404516,
    CNH: 0.005214080177504536,
    CNY: 0.005247997873643581,
    COP: 2.829325286306958,
    CRC: 0.36951220921498157,
    CZK: 0.016890665988697953,
    DJF: 0.1292512683022662,
    DKK: 0.005024788231079472,
    DOP: 0.04197242670426311,
    DZD: 0.0978875111229242,
    EGP: 0.03476084268429385,
    EUR: 0.0006737545646871757,
    GBP: 0.0005778142442767499,
    GTQ: 0.005630221996232651,
    HKD: 0.005662001779667873,
    HNL: 0.017902418730426542,
    HTG: 0.09777483734529023,
    HUF: 0.26223521662256016,
    IDR: 11.652612818231194,
    ILS: 0.0026970635480105857,
    INR: 0.06041856863855408,
    IQD: 0.9482509562825743,
    JMD: 0.11331746270209053,
    JOD: 0.0005130412674933262,
    JPY: 0.11094611304357875,
    KES: 0.09695145204719587,
    KRW: 0.9809783550784094,
    KZT: 0.32100791348708324,
    LBP: 64.91961523349474,
    MAD: 0.007282192920619879,
    MUR: 0.03351495961078432,
    MVR: 0.01120035131106052,
    MWK: 1.2566164931297885,
    MXN: 0.012310621381438295,
    MYR: 0.0034355679336206995,
    NGN: 1,
    NIO: 0.026556920481203703,
    NOK: 0.007858158159314944,
    NZD: 0.0012058983278055771,
    OMR: 0.00027839090289253813,
    PEN: 0.0026950989795800446,
    PHP: 0.04141656940126888,
    PKR: 0.20169704043544082,
    PLN: 0.0029102770041487063,
    QAR: 0.002638473183640923,
    RON: 0.0033497047369211745,
    RUB: 0.06630880704471126,
    SAR: 0.0027148024453098815,
    SCR: 0.01069089249188171,
    SEK: 0.007839725884922514,
    SGD: 0.0009784128598338206,
    SZL: 0.013396623253556447,
    THB: 0.026666705187616286,
    TND: 0.0022687298487282306,
    TRY: 0.023362589994568545,
    TTD: 0.004850981706401026,
    TWD: 0.023427478534200824,
    TZS: 1.8757468249107276,
    UAH: 0.02874989888250725,
    UGX: 2.7466978493753826,
    USD: 0.0007251631617113851,
    VND: 18.410248633469312,
    XAF: 0.44186033074087344,
    XCD: 0.001957230189638635,
    XPF: 0.080321958096911,
    ZAR: 0.013394196433730484,
    ZMW: 0.019711555129257045,
  },
  NIO: {
    AED: 0.10027414547116033,
    AFN: 1.951328300080503,
    ARS: 23.981460368573355,
    AUD: 0.04128282674441374,
    BDT: 3.005395879114902,
    BHD: 0.010267400622266705,
    BIF: 78.28397989599878,
    BMD: 0.027279650138160614,
    BND: 0.03683775374774265,
    BOB: 0.18412132024977698,
    BRL: 0.13839342050869216,
    BSD: 0.02714257740258045,
    CAD: 0.037362111355279476,
    CHF: 0.02470790452775179,
    CLP: 25.6021409455843,
    CNH: 0.1963360240203651,
    CNY: 0.19761319379473902,
    COP: 106.53815408716086,
    CRC: 13.913970540240639,
    CZK: 0.6360174930919693,
    DJF: 4.866952416178934,
    DKK: 0.1892082417701965,
    DOP: 1.5804703988163877,
    DZD: 3.685951132481887,
    EGP: 1.3089184308435413,
    EUR: 0.025362807597745916,
    GBP: 0.021757577076216793,
    GTQ: 0.21200583103065643,
    HKD: 0.21320249776984834,
    HNL: 0.6741150105524248,
    HTG: 3.681708404952025,
    HUF: 9.87445878027023,
    IDR: 438.7787667805313,
    ILS: 0.10155784251865713,
    INR: 2.2750592893975328,
    IQD: 35.70635973977938,
    JMD: 4.2669654707251805,
    JOD: 0.01931855268597289,
    JPY: 4.177672374404386,
    KES: 3.650703857618416,
    KRW: 36.938708905376295,
    KZT: 12.08754282012044,
    LBP: 2444.546056439155,
    MAD: 0.27421074389156025,
    MUR: 1.2620047431518027,
    MVR: 0.4217488740453863,
    MWK: 47.31785426774874,
    MXN: 0.4635560583973369,
    MYR: 0.12936620177976982,
    NGN: 37.654968342725354,
    NIO: 1,
    NOK: 0.2958986967211331,
    NZD: 0.045408063358064445,
    OMR: 0.010482800635321251,
    PEN: 0.10148386675659798,
    PHP: 1.5595396096690672,
    PKR: 7.59489567241792,
    PLN: 0.10958638845978112,
    QAR: 0.09935162420312874,
    RON: 0.126133025826244,
    RUB: 2.496856030112487,
    SAR: 0.10222580013489697,
    SCR: 0.402565218337286,
    SEK: 0.2952046300124018,
    SGD: 0.0368421052631579,
    SZL: 0.5044494245120863,
    THB: 1.0041339396444813,
    TND: 0.08542895063205762,
    TRY: 0.8797175866495507,
    TTD: 0.18266356258567046,
    TWD: 0.8821609625552099,
    TZS: 70.63118731098105,
    UAH: 1.0825765322773657,
    UGX: 103.42682056526185,
    USD: 0.027304276122683574,
    VND: 693.2373294749897,
    XAF: 16.638236765953742,
    XCD: 0.07369944083026914,
    XPF: 3.0245207893648964,
    ZAR: 0.5043580426883663,
    ZMW: 0.7422379843780597,
  },
  NOK: {
    AED: 0.3380662609871535,
    AFN: 6.564942,
    ARS: 80.68191108,
    AUD: 0.13883489753984563,
    BDT: 10.1111892,
    BHD: 0.03454308,
    BIF: 263.374332,
    BMD: 0.09177816,
    BND: 0.12393492,
    BOB: 0.61944768,
    BRL: 0.46560324,
    BSD: 0.091317,
    CAD: 0.12578141705344453,
    CHF: 0.0832126749546491,
    CLP: 86.13444,
    CNH: 0.6562,
    CNY: 0.664839,
    COP: 358.43112732,
    CRC: 46.8114,
    CZK: 2.1397824,
    DJF: 16.374108,
    DKK: 0.6378364587319811,
    DOP: 5.317248,
    DZD: 12.400812,
    EGP: 4.40365344,
    EUR: 0.08574784987266444,
    GBP: 0.0735304930954867,
    GTQ: 0.7132608,
    HKD: 0.7172868,
    HNL: 2.2679556,
    HTG: 12.386538,
    HUF: 33.221088,
    IDR: 1476.20323788,
    ILS: 0.34167564,
    INR: 7.6540848,
    IQD: 120.12852,
    JMD: 14.3555448,
    JOD: 0.06499428,
    JPY: 14.124293785310735,
    KES: 12.282228,
    KRW: 124.274568,
    KZT: 40.666666666666664,
    LBP: 8224.29679116,
    MAD: 0.9225396,
    MUR: 4.2458196,
    MVR: 1.4189088,
    MWK: 159.19359588,
    MXN: 1.5595626,
    MYR: 0.43523256,
    NGN: 126.684312,
    NIO: 3.3643452,
    NOK: 1,
    NZD: 0.1527684,
    OMR: 0.03526776,
    PEN: 0.34142676,
    PHP: 5.2468296,
    PKR: 25.5518508,
    PLN: 0.3687,
    QAR: 0.33425316,
    RON: 0.42435504,
    RUB: 8.4002856,
    SAR: 0.34392288,
    SCR: 1.35436836,
    SEK: 0.9952,
    SGD: 0.12394956,
    SZL: 1.697142,
    THB: 3.3782532,
    TND: 0.28741248,
    TRY: 2.95967364,
    TTD: 0.61454328,
    TWD: 2.967894,
    TZS: 237.627696,
    UAH: 3.64216116,
    UGX: 347.96352732,
    USD: 0.09229008619894051,
    VND: 2332.28968188,
    XAF: 55.976772,
    XCD: 0.24795036,
    XPF: 10.175532,
    ZAR: 1.698,
    ZMW: 2.4971448,
  },
  NZD: {
    AED: 2.2099447513812156,
    AFN: 42.923241,
    ARS: 527.51861534,
    AUD: 0.910166560480568,
    BDT: 66.1094966,
    BHD: 0.22585134,
    BIF: 1722.007586,
    BMD: 0.60006868,
    BND: 0.81031766,
    BOB: 4.05010464,
    BRL: 3.04423102,
    BSD: 0.5970535,
    CAD: 0.8216926869350862,
    CHF: 0.5450185306300415,
    CLP: 563.16862,
    CNH: 4.3191,
    CNY: 4.444444444444445,
    COP: 2343.51280786,
    CRC: 306.0647,
    CZK: 13.961,
    DJF: 107.058034,
    DKK: 4.161464835622139,
    DOP: 34.765504,
    DZD: 81.079626,
    EGP: 28.79219312,
    EUR: 0.5585968048262764,
    GBP: 0.4791566842357451,
    GTQ: 4.6634784,
    HKD: 4.701457451810061,
    HNL: 14.8284638,
    HTG: 80.986299,
    HUF: 217.2,
    IDR: 9651.78783674,
    ILS: 2.23396122,
    INR: 50.25125628140704,
    IQD: 785.43046,
    JMD: 93.8601604,
    JOD: 0.42494894,
    JPY: 91.74311926605505,
    KES: 80.304294,
    KRW: 814,
    KZT: 265.88888888888886,
    LBP: 53772.51973018,
    MAD: 6.0317958,
    MUR: 27.7602358,
    MVR: 9.2771824,
    MWK: 1040.84774574,
    MXN: 10.1968123,
    MYR: 2.847380410022779,
    NGN: 828.293876,
    NIO: 21.9969346,
    NOK: 6.50886428,
    NZD: 1,
    OMR: 0.23058948,
    PEN: 2.23233398,
    PHP: 34.339,
    PKR: 167.205,
    PLN: 2.4117,
    QAR: 2.18543118,
    RON: 2.77453992,
    RUB: 54.9231788,
    SAR: 2.24865424,
    SCR: 8.85520078,
    SEK: 6.4791,
    SGD: 0.8108,
    SZL: 11.096341,
    THB: 22.066,
    TND: 1.87917504,
    TRY: 19.3636,
    TTD: 4.01803844,
    TWD: 19.425,
    TZS: 1553.669608,
    UAH: 23.81336518,
    UGX: 2275.07300786,
    USD: 0.6003842459173871,
    VND: 15249.09619874,
    XAF: 365.990206,
    XCD: 1.62116178,
    XPF: 66.530186,
    ZAR: 11.1131,
    ZMW: 16.3269604,
  },
  OMR: {
    AED: 9.5346,
    AFN: 185.4416745,
    ARS: 2279.04354563,
    AUD: 3.92325398,
    BDT: 285.6134687,
    BHD: 0.97574763,
    BIF: 7439.605277,
    BMD: 2.59248226,
    BND: 3.50082287,
    BOB: 17.49770448,
    BRL: 13.15201939,
    BSD: 2.57945575,
    CAD: 3.55065444,
    CHF: 2.34808012,
    CLP: 2433.06259,
    CNH: 18.65851126,
    CNY: 18.77988525,
    COP: 10124.70002677,
    CRC: 1322.29415,
    CZK: 60.4430064,
    DJF: 462.523813,
    DKK: 17.98113274,
    DOP: 150.197728,
    DZD: 350.289057,
    EGP: 124.39117784,
    EUR: 2.41031789,
    GBP: 2.0755500207555,
    GTQ: 20.1476688,
    HKD: 20.2613923,
    HNL: 64.0635491,
    HTG: 349.8858555,
    HUF: 938.404968,
    IDR: 41698.70812793,
    ILS: 9.65140329,
    INR: 216.2069828,
    IQD: 3393.30247,
    JMD: 405.5049178,
    JOD: 1.83591083,
    JPY: 397.019077,
    KES: 346.939383,
    KRW: 3510.416998,
    KZT: 1148.7222222222222,
    LBP: 232313.91359401,
    MAD: 26.0592231,
    MUR: 119.9328031,
    MVR: 40.0802968,
    MWK: 4496.78412843,
    MXN: 44.05338235,
    MYR: 12.29413066,
    NGN: 3578.485682,
    NIO: 95.0335597,
    NOK: 28.12030646,
    NZD: 4.3152899,
    OMR: 1,
    PEN: 9.64437311,
    PHP: 148.2086006,
    PKR: 722.43,
    PLN: 10.41438459,
    QAR: 9.44173851,
    RON: 11.98687044,
    RUB: 237.2851166,
    SAR: 9.71488168,
    SCR: 38.25720571,
    SEK: 28.05434683,
    SGD: 3.50123641,
    SZL: 47.9396245,
    THB: 95.4264227,
    TND: 8.11861728,
    TRY: 83.60269379,
    TTD: 17.35916858,
    TWD: 83.8348965,
    TZS: 6712.333156,
    UAH: 102.88110151,
    UGX: 9829.01892677,
    USD: 2.59517027,
    VND: 65880.81113693,
    XAF: 1581.190867,
    XCD: 7.00392021,
    XPF: 287.430977,
    ZAR: 47.93094016,
    ZMW: 70.5375178,
  },
  PEN: {
    AED: 0.9880796689749801,
    AFN: 19.22796561113136,
    ARS: 236.30810625388588,
    AUD: 0.40679201595094655,
    BDT: 29.614518791672918,
    BHD: 0.10117273760264134,
    BIF: 771.3933494843814,
    BMD: 0.2688077525030551,
    BND: 0.36299123126728555,
    BOB: 1.81429153356345,
    BRL: 1.3636987329288424,
    BSD: 0.26745706751281006,
    CAD: 0.3681581373410801,
    CHF: 0.24346632935274315,
    CLP: 252.27794095577042,
    CNH: 1.9346525738052869,
    CNY: 1.9472375276032843,
    COP: 1049.8038505241943,
    CRC: 137.10524623201766,
    CZK: 6.2671783547370445,
    DJF: 47.957892931415216,
    DKK: 1.8644169543125442,
    DOP: 15.573612331968354,
    DZD: 36.32056257101816,
    EGP: 12.897798169071457,
    EUR: 0.25045081146062914,
    GBP: 0.21439444289604012,
    GTQ: 2.089059451579015,
    HKD: 2.100851145938297,
    HNL: 6.642583024248011,
    HTG: 36.27875565465343,
    HUF: 97.30077396393885,
    IDR: 4323.630748451,
    ILS: 1.0007289411058464,
    INR: 22.41794052698154,
    IQD: 351.84272023669143,
    JMD: 42.04575177411401,
    JOD: 0.19036082584739403,
    JPY: 41.16587698046866,
    KES: 35.973243573526574,
    KRW: 363.98602148232317,
    KZT: 119.1080238311334,
    LBP: 24088.02634907703,
    MAD: 2.7020131638187936,
    MUR: 12.435520871299016,
    MVR: 4.155821881096841,
    MWK: 466.25986750423425,
    MXN: 4.5677808031215825,
    MYR: 1.2747464785712754,
    NGN: 371.04388654246077,
    NIO: 9.8537829899449,
    NOK: 2.9157215444975666,
    NZD: 0.4474412023240357,
    OMR: 0.10329524258731212,
    PEN: 1,
    PHP: 15.367364877902364,
    PKR: 74.83845378727783,
    PLN: 1.0798404905344852,
    QAR: 0.978989344596188,
    RON: 1.2428874643569239,
    RUB: 24.603477477863773,
    SAR: 1.0073108505027548,
    SCR: 3.9667903007954033,
    SEK: 2.908882361769183,
    SGD: 0.36303411015586473,
    SZL: 4.970735158544691,
    THB: 9.894517934095148,
    TND: 0.841798340587012,
    TRY: 8.668546191282722,
    TTD: 1.7999271058894153,
    TWD: 8.692622687219947,
    TZS: 695.9843920845572,
    UAH: 10.667474219068241,
    UGX: 1019.1454451900606,
    USD: 0.2634,
    VND: 6831.010205175481,
    XAF: 163.94957442703085,
    XCD: 0.7262182964217567,
    XPF: 29.802971506978537,
    ZAR: 4.969834701884527,
    ZMW: 7.313852024955513,
  },
  PHP: {
    AED: 0.06406093,
    AFN: 1.2466215,
    ARS: 15.32074541,
    AUD: 0.026479544551833708,
    BDT: 1.9200209,
    BHD: 0.00655941,
    BIF: 50.012339,
    BMD: 0.01742782,
    BND: 0.02353409,
    BOB: 0.11762736,
    BRL: 0.08841373,
    BSD: 0.01734025,
    CAD: 0.02386908,
    CHF: 0.01578484,
    CLP: 16.35613,
    CNH: 0.12543082,
    CNY: 0.12624675,
    COP: 68.06274139,
    CRC: 8.88905,
    CZK: 0.4063248,
    DJF: 3.109291,
    DKK: 0.12087718,
    DOP: 1.009696,
    DZD: 2.354799,
    EGP: 0.83621288,
    EUR: 0.0163,
    GBP: 0.013951282122827088,
    GTQ: 0.1354416,
    HKD: 0.1362061,
    HNL: 0.4306637,
    HTG: 2.3520885,
    HUF: 6.308376,
    IDR: 280.31728151,
    ILS: 0.06488103,
    INR: 1.4534396,
    IQD: 22.81129,
    JMD: 2.7259846,
    JOD: 0.01234181,
    JPY: 2.668939,
    KES: 2.332281,
    KRW: 23.598586,
    KZT: 7.722222222222221,
    LBP: 1561.71756007,
    MAD: 0.1751817,
    MUR: 0.8062417,
    MVR: 0.2694376,
    MWK: 30.22938501,
    MXN: 0.29614645,
    MYR: 0.08264662,
    NGN: 24.056174,
    NIO: 0.6388579,
    NOK: 0.18903722,
    NZD: 0.029121407146393313,
    OMR: 0.00669702,
    PEN: 0.06483377,
    PHP: 1,
    PKR: 4.8520591,
    PLN: 0.07001013,
    QAR: 0.06347157,
    RON: 0.08058108,
    RUB: 1.5951362,
    SAR: 0.06530776,
    SCR: 0.25718197,
    SEK: 0.18859381,
    SGD: 0.02353687,
    SZL: 0.3222715,
    THB: 0.6414989,
    TND: 0.05457696,
    TRY: 0.56201453,
    TTD: 0.11669606,
    TWD: 0.5635755,
    TZS: 45.123292,
    UAH: 0.69161257,
    UGX: 66.07504139,
    USD: 0.01744589,
    VND: 442.88014451,
    XAF: 10.629469,
    XCD: 0.04708347,
    XPF: 1.932239,
    ZAR: 0.32221312,
    ZMW: 0.4741846,
  },
  PKR: {
    AED: 0.013204806549584048,
    AFN: 0.25692628105045134,
    ARS: 3.1575760093276686,
    AUD: 0.005438181472115724,
    BDT: 0.39571259550404075,
    BHD: 0.0013558218992353165,
    BIF: 10.30744637879617,
    BMD: 0.0035918400087088798,
    BND: 0.004850330450426706,
    BOB: 0.024242771486439645,
    BRL: 0.018221898822295877,
    BSD: 0.0035737920010083966,
    CAD: 0.004920170237890231,
    CHF: 0.0032532250070902886,
    CLP: 3.370966771612489,
    CNH: 0.0258510495059716,
    CNY: 0.02601921110153007,
    COP: 14.027599414442417,
    CRC: 1.8320160197554065,
    CZK: 0.08374275573024245,
    DJF: 0.6408188638922391,
    DKK: 0.024912553105546468,
    DOP: 0.20809639354969933,
    DZD: 0.48531952135537676,
    EGP: 0.17307026652821045,
    EUR: 0.00333945437721482,
    GBP: 0.002864763127060839,
    GTQ: 0.027914251910080814,
    HKD: 0.028075140305513675,
    HNL: 0.08875895596572597,
    HTG: 0.4847608925455999,
    HUF: 1.3001440975852911,
    IDR: 57.7728497804159,
    ILS: 0.013371854848181878,
    INR: 0.29990403071017274,
    IQD: 4.701362767819543,
    JMD: 0.5618201559004093,
    JOD: 0.002543623180517319,
    JPY: 0.5500631680269517,
    KES: 0.4806786050895382,
    KRW: 4.863622951336269,
    KZT: 1.591535070589355,
    LBP: 321.8669698540976,
    MAD: 0.036104609690347754,
    MUR: 0.16616485565890984,
    MVR: 0.055530568454947304,
    MWK: 6.230217807940551,
    MXN: 0.061035210803594704,
    MYR: 0.017033308600878337,
    NGN: 4.957930953479112,
    NIO: 0.13166737808284323,
    NOK: 0.038960205575402,
    NZD: 0.005980682395861368,
    OMR: 0.0013842171559874314,
    PEN: 0.013362114653549872,
    PHP: 0.20534049142146682,
    PKR: 1,
    PLN: 0.014428952442067327,
    QAR: 0.013088833915786443,
    RON: 0.016607604800197094,
    RUB: 0.3287544869352478,
    SAR: 0.013478178828476696,
    SCR: 0.05300470680581776,
    SEK: 0.03886881963164876,
    SGD: 0.004851660480799554,
    SZL: 0.06641953310090555,
    THB: 0.13207422571485175,
    TND: 0.011248205942091678,
    TRY: 0.11583010808751279,
    TTD: 0.02405083235692657,
    TWD: 0.11621825788831426,
    TZS: 9.29982324411506,
    UAH: 0.14254001357897722,
    UGX: 13.617938287272716,
    USD: 0.0035954409808362994,
    VND: 91.27674156112401,
    XAF: 2.190713010894694,
    XCD: 0.00970381214029318,
    XPF: 0.3982307222927272,
    ZAR: 0.0665,
    ZMW: 0.09772852931655346,
  },
  PLN: {
    AED: 0.9125226,
    AFN: 17.75763,
    ARS: 218.2379562,
    AUD: 0.37752944729688914,
    BDT: 27.349938,
    BHD: 0.0934362,
    BIF: 712.40598,
    BMD: 0.2482524,
    BND: 0.3352338,
    BOB: 1.6755552,
    BRL: 1.2594186,
    BSD: 0.247005,
    CAD: 0.3414600833162603,
    CHF: 0.2255350819820023,
    CLP: 232.9866,
    CNH: 1.7867124,
    CNY: 1.804402742692169,
    COP: 969.5268198,
    CRC: 126.621,
    CZK: 5.820721769499418,
    DJF: 44.29062,
    DKK: 1.7274140611504578,
    DOP: 14.38272,
    DZD: 33.54318,
    EGP: 11.9115216,
    EUR: 0.2315029169367534,
    GBP: 0.19854269660690532,
    GTQ: 1.929312,
    HKD: 1.9470404984423677,
    HNL: 6.134634,
    HTG: 33.50457,
    HUF: 89.86032,
    IDR: 3993.0087582,
    ILS: 0.9240436148586213,
    INR: 20.703672,
    IQD: 324.9378,
    JMD: 38.830572,
    JOD: 0.1758042,
    JPY: 38.16793893129771,
    KES: 33.22242,
    KRW: 336.15252,
    KZT: 109.99999999999999,
    LBP: 22246.0486974,
    MAD: 2.495394,
    MUR: 11.484594,
    MVR: 3.838032,
    MWK: 430.6056282,
    MXN: 4.218489,
    MYR: 1.1772684,
    NGN: 342.67068,
    NIO: 9.100278,
    NOK: 2.7122321670735015,
    NZD: 0.4146452709706846,
    OMR: 0.0953964,
    PEN: 0.9235314,
    PHP: 14.192244,
    PKR: 69.115662,
    PLN: 1,
    QAR: 0.9041274,
    RON: 1.1501,
    RUB: 22.7735,
    SAR: 0.9302832,
    SCR: 3.6634554,
    SEK: 2.6864442,
    SGD: 0.3352734,
    SZL: 4.59063,
    THB: 9.137898,
    TND: 0.7774272,
    TRY: 8.19000819000819,
    TTD: 1.6622892,
    TWD: 8.02791,
    TZS: 642.76344,
    UAH: 9.8517474,
    UGX: 941.2128198,
    USD: 0.24916529625753725,
    VND: 6308.6524182,
    XAF: 151.41258,
    XCD: 0.6706854,
    XPF: 27.52398,
    ZAR: 4.5897984,
    ZMW: 6.754572,
  },
  QAR: {
    AED: 1.0039,
    AFN: 19.6141095,
    ARS: 241.05374253,
    AUD: 0.41496138,
    BDT: 30.2092497,
    BHD: 0.10320453,
    BIF: 786.884787,
    BMD: 0.27420606,
    BND: 0.37028097,
    BOB: 1.85072688,
    BRL: 1.39108509,
    BSD: 0.27282825,
    CAD: 0.37555164,
    CHF: 0.24835572,
    CLP: 257.34429,
    CNH: 1.97350506,
    CNY: 1.98634275,
    COP: 1070.88644187,
    CRC: 139.85865,
    CZK: 6.3930384,
    DJF: 48.921003,
    DKK: 1.90185894,
    DOP: 15.886368,
    DZD: 37.049967,
    EGP: 13.15681704,
    EUR: 0.25493859,
    GBP: 0.21899568578499004,
    GTQ: 2.1310128,
    HKD: 2.1430413,
    HNL: 6.7759821,
    HTG: 37.0073205,
    HUF: 99.254808,
    IDR: 4410.45967383,
    ILS: 1.02082599,
    INR: 22.8681468,
    IQD: 358.90857,
    JMD: 42.8901318,
    JOD: 0.19418373,
    JPY: 41.992587,
    KES: 36.695673,
    KRW: 371.295738,
    KZT: 121.49999999999999,
    LBP: 24571.77197031,
    MAD: 2.7562761,
    MUR: 12.6852561,
    MVR: 4.2392808,
    MWK: 475.62348933,
    MXN: 4.65951285,
    MYR: 1.30034646,
    NGN: 378.495342,
    NIO: 10.0516707,
    NOK: 2.97427626,
    NZD: 0.4564269,
    OMR: 0.10536966,
    PEN: 1.02008241,
    PHP: 15.6759786,
    PKR: 76.401,
    PLN: 1.10152629,
    QAR: 1,
    RON: 1.26784764,
    RUB: 25.0975746,
    SAR: 1.02754008,
    SCR: 4.04645301,
    SEK: 2.96729973,
    SGD: 0.37032471,
    SZL: 5.0705595,
    THB: 10.0932237,
    TND: 0.85870368,
    TRY: 8.84263149,
    TTD: 1.83607398,
    TWD: 8.8671915,
    TZS: 709.961436,
    UAH: 10.88170281,
    UGX: 1039.61234187,
    USD: 0.27449037,
    VND: 6968.19335283,
    XAF: 167.242077,
    XCD: 0.74080251,
    XPF: 30.401487,
    ZAR: 5.06964096,
    ZMW: 7.4607318,
  },
  RON: {
    AED: 0.79361814,
    AFN: 15.443757,
    ARS: 189.80088918,
    AUD: 0.32673228,
    BDT: 23.7861582,
    BHD: 0.08126118,
    BIF: 619.577322,
    BMD: 0.21590436,
    BND: 0.29155182,
    BOB: 1.45722528,
    BRL: 1.09531254,
    BSD: 0.2148195,
    CAD: 0.29570184,
    CHF: 0.19590557351356647,
    CLP: 202.62774,
    CNH: 1.55389836,
    CNY: 1.5640065,
    COP: 843.19453722,
    CRC: 110.1219,
    CZK: 5.0337504,
    DJF: 38.519418,
    DKK: 1.49748564,
    DOP: 12.508608,
    DZD: 29.172402,
    EGP: 10.35941424,
    EUR: 0.2011263073209976,
    GBP: 0.17249706754985164,
    GTQ: 1.6779168,
    HKD: 1.6873878,
    HNL: 5.3352726,
    HTG: 29.138823,
    HUF: 78.151248,
    IDR: 3472.70761698,
    ILS: 0.80377794,
    INR: 18.0059208,
    IQD: 282.59742,
    JMD: 33.7708308,
    JOD: 0.15289638,
    JPY: 33.064122,
    KES: 28.893438,
    KRW: 292.350828,
    KZT: 95.66666666666666,
    LBP: 19347.32113986,
    MAD: 2.1702366,
    MUR: 9.9881166,
    MVR: 3.3379248,
    MWK: 374.49640998,
    MXN: 3.6688071,
    MYR: 1.02386676,
    NGN: 298.019652,
    NIO: 7.9144842,
    NOK: 2.34188556,
    NZD: 0.3593814,
    OMR: 0.08296596,
    PEN: 0.80319246,
    PHP: 12.3429516,
    PKR: 60.1096818,
    PLN: 0.8694896095991653,
    QAR: 0.78631686,
    RON: 1,
    RUB: 19.7613276,
    SAR: 0.80906448,
    SCR: 3.18609606,
    SEK: 2.33639238,
    SGD: 0.29158626,
    SZL: 3.992457,
    THB: 7.9472022,
    TND: 0.67612608,
    TRY: 6.96251094,
    TTD: 1.44568788,
    TWD: 6.981849,
    TZS: 559.009416,
    UAH: 8.56803486,
    UGX: 818.56993722,
    USD: 0.21612822,
    VND: 5486.61589098,
    XAF: 131.683062,
    XCD: 0.58329306,
    XPF: 23.937522,
    ZAR: 3.99173376,
    ZMW: 5.8744308,
  },
  RUB: {
    AED: 0.04009569,
    AFN: 0.7802595,
    ARS: 9.58924353,
    AUD: 0.01650738,
    BDT: 1.2017397,
    BHD: 0.00410553,
    BIF: 31.302687,
    BMD: 0.01090806,
    BND: 0.01472997,
    BOB: 0.07362288,
    BRL: 0.055481580115401684,
    BSD: 0.01085325,
    CAD: 0.014972525415861894,
    CHF: 0.00987972,
    CLP: 10.23729,
    CNH: 0.07850706,
    CNY: 0.07917907138785077,
    COP: 42.60042087,
    CRC: 5.56365,
    CZK: 0.2543184,
    DJF: 1.946103,
    DKK: 0.07565694,
    DOP: 0.631968,
    DZD: 1.473867,
    EGP: 0.52338504,
    EUR: 0.010224530694041143,
    GBP: 0.008713989438644801,
    GTQ: 0.0847728,
    HKD: 0.0859490494035136,
    HNL: 0.2695521,
    HTG: 1.4721705,
    HUF: 3.948408,
    IDR: 175.45038483,
    ILS: 0.04060899,
    INR: 0.9097068,
    IQD: 14.27757,
    JMD: 1.7061918,
    JOD: 0.00772473,
    JPY: 1.670487,
    KES: 1.459773,
    KRW: 14.770338,
    KZT: 4.833333333333333,
    LBP: 977.47789731,
    MAD: 0.1096461,
    MUR: 0.5046261,
    MVR: 0.1686408,
    MWK: 18.92055033,
    MXN: 0.18535785,
    MYR: 0.05172846,
    NGN: 15.056742,
    NIO: 0.3998607,
    NOK: 0.11831826,
    NZD: 0.0181569,
    OMR: 0.00419166,
    PEN: 0.04057941,
    PHP: 0.6235986,
    PKR: 3.0369003,
    PLN: 0.043910685665356664,
    QAR: 0.03972681,
    RON: 0.05043564,
    RUB: 1,
    SAR: 0.04087608,
    SCR: 0.16097001,
    SEK: 0.11804073,
    SGD: 0.01473171,
    SZL: 0.2017095,
    THB: 0.4015137,
    TND: 0.03415968,
    TRY: 0.35176449,
    TTD: 0.07303998,
    TWD: 0.3527415,
    TZS: 28.242636,
    UAH: 0.43287981,
    UGX: 41.35632087,
    USD: 0.01091937,
    VND: 277.19836383,
    XAF: 6.652977,
    XCD: 0.02946951,
    XPF: 1.209387,
    ZAR: 0.20167296,
    ZMW: 0.2967918,
  },
  SAR: {
    AED: 0.9845426799251747,
    AFN: 19.0884130767921,
    ARS: 234.59303167035586,
    AUD: 0.40383960497190535,
    BDT: 29.39958283671037,
    BHD: 0.10043844713093819,
    BIF: 765.7947386344288,
    BMD: 0.26685680231568193,
    BND: 0.3603567171803167,
    BOB: 1.8011237868210455,
    BRL: 1.3538012940575515,
    BSD: 0.2655159203132981,
    CAD: 0.3654861229354674,
    CHF: 0.2416993018900051,
    CLP: 250.44696066746127,
    CNH: 1.9206112719223565,
    CNY: 1.9331048867699643,
    COP: 1042.1845947556615,
    CRC: 136.11016516260855,
    CZK: 6.221692491060787,
    DJF: 47.609824621147624,
    DKK: 1.8508854077983994,
    DOP: 15.460582325898178,
    DZD: 36.05695555933935,
    EGP: 12.804188659969352,
    EUR: 0.24775165374228872,
    GBP: 0.2128384130767921,
    GTQ: 2.0738974970202624,
    HKD: 2.085603609739486,
    HNL: 6.59437255235825,
    HTG: 36.01545206878937,
    HUF: 96.59458539077133,
    IDR: 4292.250744934446,
    ILS: 0.9934658607185425,
    INR: 22.25523582496169,
    IQD: 349.2891197003235,
    JMD: 41.740592542142004,
    JOD: 0.1889792269708837,
    JPY: 40.86710369487485,
    KES: 35.712157330154945,
    KRW: 361.34428741699304,
    KZT: 118.24356282044005,
    LBP: 23913.20051506896,
    MAD: 2.6824025200068107,
    MUR: 12.345266473693172,
    MVR: 4.125659799080538,
    MWK: 462.8758513536523,
    MXN: 4.534628809807594,
    MYR: 1.2638,
    NGN: 368.350927975481,
    NIO: 9.78226630342244,
    NOK: 2.894559850161757,
    NZD: 0.4441937680912651,
    OMR: 0.10254554742039844,
    PEN: 0.9927422101140814,
    PHP: 15.255831772518304,
    PKR: 74.194,
    PLN: 1.0720032351438789,
    QAR: 0.9718840456325558,
    RON: 1.2338668482887791,
    RUB: 24.424910607866508,
    SAR: 1,
    SCR: 3.9380001702707306,
    SEK: 2.8877703047846075,
    SGD: 0.36039928486293205,
    SZL: 4.934658607185425,
    THB: 9.822705601907032,
    TND: 0.8356887451047165,
    TRY: 8.605631704410012,
    TTD: 1.7868636131449005,
    TWD: 8.629533458198535,
    TZS: 690.9330836029286,
    UAH: 10.590051932572791,
    UGX: 1011.7487016856802,
    USD: 0.2666382252559727,
    VND: 6781.432168397752,
    XAF: 162.75966286395368,
    XCD: 0.7209475566150179,
    XPF: 29.586667801804868,
    ZAR: 4.933764685850503,
    ZMW: 7.260769623701686,
  },
  SCR: {
    AED: 0.2490879512276852,
    AFN: 4.847235208595688,
    ARS: 59.57161542078553,
    AUD: 0.10254941277570896,
    BDT: 7.4656123833253165,
    BHD: 0.025504937224020797,
    BIF: 194.46285056452442,
    BMD: 0.06776454819130595,
    BND: 0.0915075423055512,
    BOB: 0.4573701647903234,
    BRL: 0.3437788815444566,
    BSD: 0.06742404998297509,
    CAD: 0.09281008307075336,
    CHF: 0.061376153235003214,
    CLP: 63.597498689352136,
    CNH: 0.48771233846602857,
    CNY: 0.49088491701031767,
    COP: 264.64818427979225,
    CRC: 34.56327051231469,
    CZK: 1.5799116866551726,
    DJF: 12.089848289131622,
    DKK: 0.470006431632824,
    DOP: 3.9259983893894272,
    DZD: 9.156158964020689,
    EGP: 3.2514444150186734,
    EUR: 0.06300297800813953,
    GBP: 0.054047334655691455,
    GTQ: 0.5266372288850575,
    HKD: 0.5296098322911206,
    HNL: 1.6745485696372884,
    HTG: 9.14561973376283,
    HUF: 24.52884236013901,
    IDR: 1089.9569729168807,
    ILS: 0.252276743972371,
    INR: 5.651405500937721,
    IQD: 88.69708090345524,
    JMD: 10.599438988666273,
    JOD: 0.047988628440788446,
    JPY: 10.377628727239316,
    KES: 9.06860228187847,
    KRW: 91.75832193835362,
    KZT: 30.026297030939695,
    LBP: 6072.422417753469,
    MAD: 0.6811585586656794,
    MUR: 3.1349075520340715,
    MVR: 1.047653534965923,
    MWK: 117.54084086843257,
    MXN: 1.1515054885068343,
    MYR: 0.32135464239581024,
    NGN: 93.53756019521897,
    NIO: 2.484069548110235,
    NOK: 0.7350329418504726,
    NZD: 0.11279678742642807,
    OMR: 0.02604000583711214,
    PEN: 0.25209298303454164,
    PHP: 3.874004853450652,
    PKR: 18.86624906092756,
    PLN: 0.27222021046032113,
    QAR: 0.2467963442382839,
    RON: 0.3133232084659745,
    RUB: 6.20236403041784,
    SAR: 0.2539359971463007,
    SCR: 1,
    SEK: 0.7333088318749561,
    SGD: 0.09151835177248234,
    SZL: 1.2530874539922063,
    THB: 2.4943385416948165,
    TND: 0.21221145479210693,
    TRY: 2.185279667933176,
    TTD: 0.45374899336839203,
    TWD: 2.19134918361501,
    TZS: 175.45278154607806,
    UAH: 2.6891953973289806,
    UGX: 256.9194154240284,
    USD: 0.06956134615117071,
    VND: 1722.0497397620836,
    XAF: 41.33053728455381,
    XCD: 0.18307453667922366,
    XPF: 7.513119990487669,
    ZAR: 1.2528604551866525,
    ZMW: 1.8437707744442582,
  },
  SEK: {
    AED: 0.34002040122407345,
    AFN: 6.6097845,
    ARS: 81.23301703,
    AUD: 0.13972530006008188,
    BDT: 10.1802547,
    BHD: 0.03477903,
    BIF: 265.173337,
    BMD: 0.09240506,
    BND: 0.12478147,
    BOB: 0.62367888,
    BRL: 0.4689551678859501,
    BSD: 0.09194075,
    CAD: 0.1269309368772451,
    CHF: 0.08369391461546831,
    CLP: 86.72279,
    CNH: 0.6625,
    CNY: 0.66938025,
    COP: 360.87942737,
    CRC: 47.13115,
    CZK: 2.1518,
    DJF: 16.485953,
    DKK: 0.6413134098634002,
    DOP: 5.353568,
    DZD: 12.485517,
    EGP: 4.43373304,
    EUR: 0.08598082627574051,
    GBP: 0.07370337340340068,
    GTQ: 0.7181328,
    HKD: 0.7223867658744492,
    HNL: 2.2834471,
    HTG: 12.4711455,
    HUF: 33.448008,
    IDR: 1486.28659333,
    ILS: 0.34400949,
    INR: 7.7063668,
    IQD: 120.94907,
    JMD: 14.4536018,
    JOD: 0.06543823,
    JPY: 14.144271570014144,
    KES: 12.366123,
    KRW: 125,
    KZT: 40.94444444444444,
    LBP: 8280.47368181,
    MAD: 0.9288411,
    MUR: 4.2748211,
    MVR: 1.4286008,
    MWK: 160.28098383,
    MXN: 1.57021535,
    MYR: 0.43820546,
    NGN: 127.549642,
    NIO: 3.3873257,
    NOK: 1.004823151125402,
    NZD: 0.15434242410211294,
    OMR: 0.03550866,
    PEN: 0.34375891,
    PHP: 5.2826686,
    PKR: 25.7263853,
    PLN: 0.37120479,
    QAR: 0.33653631,
    RON: 0.42725364,
    RUB: 8.4576646,
    SAR: 0.34627208,
    SCR: 1.36361951,
    SEK: 1,
    SGD: 0.12479621,
    SZL: 1.7087345,
    THB: 3.4013287,
    TND: 0.28937568,
    TRY: 2.97988999,
    TTD: 0.61874098,
    TWD: 2.9881665,
    TZS: 239.250836,
    UAH: 3.66703931,
    UGX: 350.34032737,
    USD: 0.09250522654529982,
    VND: 2348.22062233,
    XAF: 56.359127,
    XCD: 0.24964401,
    XPF: 10.245037,
    ZAR: 1.7053,
    ZMW: 2.5142018,
  },
  SGD: {
    AED: 2.72097648,
    AFN: 52.950024,
    ARS: 650.9571,
    AUD: 1.1186933661483387,
    BDT: 81.5525424,
    BHD: 0.27860976,
    BIF: 2124.265104,
    BMD: 0.74024352,
    BND: 0.99960624,
    BOB: 4.99620096,
    BRL: 3.7593984962406015,
    BSD: 0.736524,
    CAD: 1.0135819987837016,
    CHF: 0.6716367788300087,
    CLP: 694.72368,
    CNH: 5.3211,
    CNY: 5.359056806002144,
    COP: 2890.95269904,
    CRC: 377.5608,
    CZK: 17.2585728,
    DJF: 132.066576,
    DKK: 5.136106831022086,
    DOP: 42.886656,
    DZD: 100.019664,
    EGP: 35.51799168,
    EUR: 0.6885155604516662,
    GBP: 0.5905628063544558,
    GTQ: 5.7528576,
    HKD: 5.78368999421631,
    HNL: 18.2923632,
    HTG: 99.904536,
    HUF: 267.947136,
    IDR: 10000,
    ILS: 2.75581008,
    INR: 61.7345856,
    IQD: 968.90544,
    JMD: 115.7857056,
    JOD: 0.52421616,
    JPY: 113.362704,
    KES: 99.063216,
    KRW: 1000,
    KZT: 327.99999999999994,
    LBP: 66333.67247952,
    MAD: 7.4408112,
    MUR: 34.2449712,
    MVR: 11.4443136,
    MWK: 1283.98769136,
    MXN: 12.594458438287154,
    MYR: 3.518648838845883,
    NGN: 1021.781664,
    NIO: 27.1353744,
    NOK: 8.02932192,
    NZD: 1.23334977799704,
    OMR: 0.28445472,
    PEN: 2.75380272,
    PHP: 42.3186912,
    PKR: 206.115,
    PLN: 2.97366768,
    QAR: 2.69594352,
    RON: 3.42266688,
    RUB: 67.7531232,
    SAR: 2.77393536,
    SCR: 10.92375792,
    SEK: 8.01048816,
    SGD: 1,
    SZL: 13.688424,
    THB: 27.2291,
    TND: 2.31814656,
    TRY: 23.87146608,
    TTD: 4.95664416,
    TWD: 23.948,
    TZS: 1916.603712,
    UAH: 29.37611952,
    UGX: 2806.52549904,
    USD: 0.7410701052319549,
    VND: 18760.63,
    XAF: 451.484784,
    XCD: 1.99986192,
    XPF: 82.071504,
    ZAR: 13.6992,
    ZMW: 20.1409056,
  },
  SZL: {
    AED: 0.19863497,
    AFN: 3.8654235,
    ARS: 47.50533289,
    AUD: 0.08177794,
    BDT: 5.9534461,
    BHD: 0.02033889,
    BIF: 155.074231,
    BMD: 0.05403878,
    BND: 0.07297261,
    BOB: 0.36472944,
    BRL: 0.27414617,
    BSD: 0.05376725,
    CAD: 0.07401132,
    CHF: 0.04895888922072136,
    CLP: 50.71577,
    CNH: 0.38892578,
    CNY: 0.39145575,
    COP: 211.04346431,
    CRC: 27.56245,
    CZK: 1.2598992,
    DJF: 9.641039,
    DKK: 0.37480622,
    DOP: 3.130784,
    DZD: 7.301571,
    EGP: 2.59286152,
    EUR: 0.05024167,
    GBP: 0.04313133491481561,
    GTQ: 0.4199664,
    HKD: 0.4223369,
    HNL: 1.3353673,
    HTG: 7.2931665,
    HUF: 19.560504,
    IDR: 869.18523979,
    ILS: 0.20117787,
    INR: 4.5067084,
    IQD: 70.73141,
    JMD: 8.4525134,
    JOD: 0.03826849,
    JPY: 8.275631,
    KES: 7.231749,
    KRW: 73.172594,
    KZT: 23.944444444444443,
    LBP: 4842.44797403,
    MAD: 0.5431893,
    MUR: 2.4999293,
    MVR: 0.8354504,
    MWK: 93.73284129,
    MXN: 0.91826705,
    MYR: 0.25626398,
    NGN: 74.591446,
    NIO: 1.9809191,
    NOK: 0.58615138,
    NZD: 0.0899497,
    OMR: 0.02076558,
    PEN: 0.20103133,
    PHP: 3.0893218,
    PKR: 15.0448739,
    PLN: 0.21708177,
    QAR: 0.19680753,
    RON: 0.24985932,
    RUB: 4.9460698,
    SAR: 0.20250104,
    SCR: 0.79744913,
    SEK: 0.58477649,
    SGD: 0.07298123,
    SZL: 1,
    THB: 1.9891081,
    TND: 0.16922784,
    TRY: 1.74264937,
    TTD: 0.36184174,
    TWD: 1.7474895,
    TZS: 139.914668,
    UAH: 2.14449653,
    UGX: 204.88016431,
    USD: 0.05409481,
    VND: 1373.24706679,
    XAF: 32.959001,
    XCD: 0.14599263,
    XPF: 5.991331,
    ZAR: 0.99909248,
    ZMW: 1.4703134,
  },
  THB: {
    AED: 0.10000879,
    AFN: 1.9461645,
    ARS: 23.91799823,
    AUD: 0.041138719763040975,
    BDT: 2.9974427,
    BHD: 0.01024023,
    BIF: 78.076817,
    BMD: 0.02720746,
    BND: 0.03674027,
    BOB: 0.18363408,
    BRL: 0.13802719,
    BSD: 0.02707075,
    CAD: 0.037205149192648265,
    CHF: 0.02460872133083965,
    CLP: 25.53439,
    CNH: 0.19577517179271325,
    CNY: 0.1961,
    COP: 106.25622217,
    CRC: 13.87715,
    CZK: 0.6343344,
    DJF: 4.854073,
    DKK: 0.19011406844106463,
    DOP: 1.576288,
    DZD: 3.676197,
    EGP: 1.30545464,
    EUR: 0.025288286465709083,
    GBP: 0.021668002860176377,
    GTQ: 0.2114448,
    HKD: 0.21243149084420274,
    HNL: 0.6723311,
    HTG: 3.6719655,
    HUF: 9.848328,
    IDR: 434.7826086956522,
    ILS: 0.10128909,
    INR: 2.2722108611679164,
    IQD: 35.61187,
    JMD: 4.2556738,
    JOD: 0.01926743,
    JPY: 4.198152812762385,
    KES: 3.641043,
    KRW: 36.885,
    KZT: 12.055555555555554,
    LBP: 2438.07705421,
    MAD: 0.2734851,
    MUR: 1.2586651,
    MVR: 0.4206328,
    MWK: 47.19263703,
    MXN: 0.46232935,
    MYR: 0.1291155584247902,
    NGN: 37.555322,
    NIO: 0.9973537,
    NOK: 0.29511566,
    NZD: 0.045318589685488984,
    OMR: 0.01045506,
    PEN: 0.10121531,
    PHP: 1.5554126,
    PKR: 7.5715,
    PLN: 0.10929639,
    QAR: 0.09908871,
    RON: 0.12579924,
    RUB: 2.4902486,
    SAR: 0.10195528,
    SCR: 0.40149991,
    SEK: 0.29442343,
    SGD: 0.036725415089004045,
    SZL: 0.5031145,
    THB: 1,
    TND: 0.08520288,
    TRY: 0.87738959,
    TTD: 0.18218018,
    TWD: 0.8795,
    TZS: 70.444276,
    UAH: 1.07971171,
    UGX: 103.15312217,
    USD: 0.02723567,
    VND: 691.40281553,
    XAF: 16.594207,
    XCD: 0.07350441,
    XPF: 3.016517,
    ZAR: 0.5024,
    ZMW: 0.7402738,
  },
  TND: {
    AED: 1.17245328,
    AFN: 22.815864,
    ARS: 280.40270736,
    AUD: 0.48269856,
    BDT: 35.1405264,
    BHD: 0.12005136,
    BIF: 915.333744,
    BMD: 0.31896672,
    BND: 0.43072464,
    BOB: 2.15283456,
    BRL: 1.61816208,
    BSD: 0.317364,
    CAD: 0.43685568,
    CHF: 0.28889664,
    CLP: 299.35248,
    CNH: 2.29565472,
    CNY: 2.310588,
    COP: 1245.69506544,
    CRC: 162.6888,
    CZK: 7.4366208,
    DJF: 56.906736,
    DKK: 2.21231328,
    DOP: 18.479616,
    DZD: 43.097904,
    EGP: 15.30450048,
    EUR: 0.29655408,
    GBP: 0.25468622656886714,
    GTQ: 2.4788736,
    HKD: 2.4928656,
    HNL: 7.8820752,
    HTG: 43.048296,
    HUF: 115.456896,
    IDR: 5130.41125296,
    ILS: 1.18746288,
    INR: 26.6010816,
    IQD: 417.49584,
    JMD: 49.8914016,
    JOD: 0.22588176,
    JPY: 48.847344,
    KES: 42.685776,
    KRW: 431.905056,
    KZT: 141.33333333333331,
    LBP: 28582.80196272,
    MAD: 3.2062032,
    MUR: 14.7559632,
    MVR: 4.9312896,
    MWK: 553.26298896,
    MXN: 5.4201192,
    MYR: 1.51261152,
    NGN: 440.279904,
    NIO: 11.6924784,
    NOK: 3.45978912,
    NZD: 0.5309328,
    OMR: 0.12256992,
    PEN: 1.18659792,
    PHP: 18.2348832,
    PKR: 88.8031536,
    PLN: 1.28133648,
    QAR: 1.16166672,
    RON: 1.47480768,
    RUB: 29.1944352,
    SAR: 1.19527296,
    SCR: 4.70698512,
    SEK: 3.45167376,
    SGD: 0.43077552,
    SZL: 5.898264,
    THB: 11.7408144,
    TND: 1,
    TRY: 10.28607888,
    TTD: 2.13578976,
    TWD: 10.314648,
    TZS: 825.853632,
    UAH: 12.65800272,
    UGX: 1209.31586544,
    USD: 0.31929744,
    VND: 8105.66250096,
    XAF: 194.542224,
    XCD: 0.86172912,
    XPF: 35.364144,
    ZAR: 5.89719552,
    ZMW: 8.6786016,
  },
  TRY: {
    AED: 0.11398447296371501,
    AFN: 2.218130374671986,
    ARS: 27.260408036069816,
    AUD: 0.04695960065555602,
    BDT: 3.4163189695469263,
    BHD: 0.01167124629322306,
    BIF: 88.9876263519379,
    BMD: 0.031009554148003965,
    BND: 0.04187452235443094,
    BOB: 0.20929594115653913,
    BRL: 0.15731573701484194,
    BSD: 0.030853739671107792,
    CAD: 0.042481244530539766,
    CHF: 0.028093833403567917,
    CLP: 29.10268174027458,
    CNH: 0.22318074231995388,
    CNY: 0.22463253752531984,
    COP: 121.1049497065494,
    CRC: 15.816406027794335,
    CZK: 0.7229791727982549,
    DJF: 5.532403228080242,
    DKK: 0.2125,
    DOP: 1.7965656510695551,
    DZD: 4.189925481108113,
    EGP: 1.4878848061099061,
    EUR: 0.02882292921665043,
    GBP: 0.024732456650186606,
    GTQ: 0.24099305759941828,
    HKD: 0.24235334271517855,
    HNL: 0.7662857043927316,
    HTG: 4.185102652061326,
    HUF: 11.22457812612069,
    IDR: 498.7723031110957,
    ILS: 0.11544368790607602,
    INR: 2.5861245971701123,
    IQD: 40.58843460862124,
    JMD: 4.850381003494696,
    JOD: 0.021959948259700688,
    JPY: 4.7593,
    KES: 4.14985890133481,
    KRW: 41.98928095328781,
    KZT: 13.740253694548112,
    LBP: 2778.7850254867967,
    MAD: 0.3117031511623018,
    MUR: 1.4345566830807737,
    MVR: 0.4794139397072172,
    MWK: 53.78755067062056,
    MXN: 0.5269373551605507,
    MYR: 0.1467,
    NGN: 42.80347342621195,
    NIO: 1.1367284400992266,
    NOK: 0.33635646395120783,
    NZD: 0.05164328947096614,
    OMR: 0.011916097614059908,
    PEN: 0.11535959755346539,
    PHP: 1.7727730277720755,
    PKR: 8.633333910423989,
    PLN: 0.1221,
    QAR: 0.1129358168017471,
    RON: 0.1433789976924618,
    RUB: 2.8382472602621145,
    SAR: 0.11620297432523675,
    SCR: 0.45760733267874765,
    SEK: 0.33556749858406687,
    SGD: 0.04187946884576098,
    SZL: 0.5734220074345765,
    THB: 1.141427606862762,
    TND: 0.09710951779129269,
    TRY: 1,
    TTD: 0.20763886656097663,
    TWD: 1.002777454881816,
    TZS: 80.28847937436778,
    UAH: 1.2305955328236797,
    UGX: 117.56820840557272,
    USD: 0.030913047779206646,
    VND: 788.0225881526586,
    XAF: 18.9131569249642,
    XCD: 0.08377625041117709,
    XPF: 3.43805879894244,
    ZAR: 0.5675,
    ZMW: 0.8437230261644659,
  },
  TTD: {
    AED: 0.54659182,
    AFN: 10.636641,
    ARS: 130.72233134,
    AUD: 0.22503164,
    BDT: 16.3823366,
    BHD: 0.05596734,
    BIF: 426.723986,
    BMD: 0.14870068,
    BND: 0.20080166,
    BOB: 1.00364064,
    BRL: 0.75437902,
    BSD: 0.1479535,
    CAD: 0.20365992,
    CHF: 0.13468216,
    CLP: 139.55662,
    CNH: 1.07022268,
    CNY: 1.0771845,
    COP: 580.73677186,
    CRC: 75.8447,
    CZK: 3.4669152,
    DJF: 26.529634,
    DKK: 1.03136932,
    DOP: 8.615104,
    DZD: 20.092026,
    EGP: 7.13488112,
    EUR: 0.13825202,
    GBP: 0.11911284751173262,
    GTQ: 1.1556384,
    HKD: 1.1621614,
    HNL: 3.6745838,
    HTG: 20.068899,
    HUF: 53.825424,
    IDR: 2391.77191274,
    ILS: 0.55358922,
    INR: 12.4012904,
    IQD: 194.63446,
    JMD: 23.2591204,
    JOD: 0.10530494,
    JPY: 22.772386,
    KES: 19.899894,
    KRW: 201.351964,
    KZT: 65.88888888888889,
    LBP: 13325.15846218,
    MAD: 1.4947158,
    MUR: 6.8791558,
    MVR: 2.2989424,
    MWK: 257.92842174,
    MXN: 2.5268323,
    MYR: 0.70517188,
    NGN: 205.256276,
    NIO: 5.4509746,
    NOK: 1.61293628,
    NZD: 0.2475182,
    OMR: 0.05714148,
    PEN: 0.55318598,
    PHP: 8.5010108,
    PKR: 41.3995834,
    PLN: 0.59735262,
    QAR: 0.54156318,
    RON: 0.68754792,
    RUB: 13.6102988,
    SAR: 0.55723024,
    SCR: 2.19437278,
    SEK: 1.60915294,
    SGD: 0.20082538,
    SZL: 2.749741,
    THB: 5.4735086,
    TND: 0.46567104,
    TRY: 4.79531822,
    TTD: 1,
    TWD: 4.808637,
    TZS: 385.008808,
    UAH: 5.90109718,
    UGX: 563.77697186,
    USD: 0.14885486,
    VND: 3778.81907474,
    XAF: 90.694606,
    XCD: 0.40173378,
    XPF: 16.486586,
    ZAR: 2.74924288,
    ZMW: 4.0459204,
  },
  TWD: {
    AED: 0.11337402,
    AFN: 2.206251,
    ARS: 27.11441274,
    AUD: 0.0469109161701928,
    BDT: 3.3980226,
    BHD: 0.01160874,
    BIF: 88.511046,
    BMD: 0.03084348,
    BND: 0.04165026,
    BOB: 0.20817504,
    BRL: 0.15647322,
    BSD: 0.0306885,
    CAD: 0.04234596654668643,
    CHF: 0.02793576,
    CLP: 28.94682,
    CNH: 0.22198548,
    CNY: 0.22424038569346338,
    COP: 120.45636246,
    CRC: 15.7317,
    CZK: 0.7191072,
    DJF: 5.502774,
    DKK: 0.21392652,
    DOP: 1.786944,
    DZD: 4.167486,
    EGP: 1.47991632,
    EUR: 0.028770355026181024,
    GBP: 0.024663953631767172,
    GTQ: 0.2397024,
    HKD: 0.24165679901404025,
    HNL: 0.7621818,
    HTG: 4.162689,
    HUF: 11.164464,
    IDR: 500,
    ILS: 0.11482542,
    INR: 2.5826446280991737,
    IQD: 40.37106,
    JMD: 4.8244044,
    JOD: 0.02184234,
    JPY: 4.723446,
    KES: 4.127634,
    KRW: 42.016806722689076,
    KZT: 13.666666666666666,
    LBP: 2763.90301998,
    MAD: 0.3100338,
    MUR: 1.4268738,
    MVR: 0.4768464,
    MWK: 53.49948714,
    MXN: 0.5241153,
    MYR: 0.14664261727743316,
    NGN: 42.574236,
    NIO: 1.1306406,
    NOK: 0.33455508,
    NZD: 0.05148005148005148,
    OMR: 0.01185228,
    PEN: 0.11474178,
    PHP: 1.7632788,
    PKR: 8.6045,
    PLN: 0.12390282,
    QAR: 0.11233098,
    RON: 0.14261112,
    RUB: 2.8230468,
    SAR: 0.11558064,
    SCR: 0.45515658,
    SEK: 0.33377034,
    SGD: 0.04175714047102055,
    SZL: 0.570351,
    THB: 1.137009664582149,
    TND: 0.09658944,
    TRY: 0.99464442,
    TTD: 0.20652684,
    TWD: 1,
    TZS: 79.858488,
    UAH: 1.22400498,
    UGX: 116.93856246,
    USD: 0.03087546,
    VND: 783.80227014,
    XAF: 18.811866,
    XCD: 0.08332758,
    XPF: 3.419646,
    ZAR: 0.572,
    ZMW: 0.8392044,
  },
  TZS: {
    AED: 0.0014196865335091241,
    AFN: 0.02762700691252757,
    ARS: 0.33953075520287834,
    AUD: 0.0005844843944453343,
    BDT: 0.042550550168192515,
    BHD: 0.00014536638860480303,
    BIF: 1.108348632896731,
    BMD: 0.0003862266964032677,
    BND: 0.000521550821247705,
    BOB: 0.0026067991670465887,
    BRL: 0.0019593811994036247,
    BSD: 0.000384286013529332,
    CAD: 0.0005289747033527607,
    CHF: 0.0003498157891494264,
    CLP: 0.3624764345651022,
    CNH: 0.002779735574257304,
    CNY: 0.0027978178099239744,
    COP: 1.5083726912034696,
    CRC: 0.19699471394950527,
    CZK: 0.00900476853506167,
    DJF: 0.0689065638207425,
    DKK: 0.0026788200648126467,
    DOP: 0.0223763815813793,
    DZD: 0.05218588661483298,
    EGP: 0.018531734785662356,
    EUR: 0.0003590879406582303,
    GBP: 0.00030804490062471504,
    GTQ: 0.0030015895116872232,
    HKD: 0.003018531981221583,
    HNL: 0.009544155156055546,
    HTG: 0.05212581785921116,
    HUF: 0.13980309769952068,
    IDR: 6.2122524551178575,
    ILS: 0.0014378611826459825,
    INR: 0.032210406988922705,
    IQD: 0.5055324864152199,
    JMD: 0.060411917641115365,
    JOD: 0.00027351306726468446,
    JPY: 0.05914770136895153,
    KES: 0.051686853875820934,
    KRW: 0.5229801495866037,
    KZT: 0.17113605590261946,
    LBP: 34.61000939536947,
    MAD: 0.0038822898825732837,
    MUR: 0.017867528370935346,
    MVR: 0.005971142353709476,
    MWK: 0.6699286259965253,
    MXN: 0.006563050630259866,
    MYR: 0.0018315733701344307,
    NGN: 0.5331209877151694,
    NIO: 0.014158051677612528,
    NOK: 0.004189349039515999,
    NZD: 0.0006428897076037803,
    OMR: 0.0001484160331209877,
    PEN: 0.0014368138299838584,
    PHP: 0.022080042386978325,
    PKR: 0.10752892541616865,
    PLN: 0.0015515297509765022,
    QAR: 0.0014066254297226363,
    RON: 0.0017857978979015981,
    RUB: 0.03535061670589105,
    SAR: 0.001447318161095161,
    SCR: 0.0056995391648286655,
    SEK: 0.004179522407186071,
    SGD: 0.00052161243022783,
    SZL: 0.0071420210209840185,
    THB: 0.014216580208731225,
    TND: 0.001209507497812881,
    TRY: 0.012455087053488917,
    TTD: 0.0025861601587047326,
    TWD: 0.012489680495829072,
    TZS: 1,
    UAH: 0.015327174488953509,
    UGX: 1.4643222704141354,
    USD: 0.00038605939523795734,
    VND: 9.814889935557007,
    XAF: 0.23556501595672583,
    XCD: 0.0010434404918860973,
    XPF: 0.04282132163584164,
    ZAR: 0.0071407272324013945,
    ZMW: 0.01050864373991153,
  },
  UAH: {
    AED: 0.09262545647485847,
    AFN: 1.8024853134175973,
    ARS: 22.152207861115077,
    AUD: 0.0381338644553554,
    BDT: 2.776150959777958,
    BHD: 0.009484226118099617,
    BIF: 72.31265186519094,
    BMD: 0.025198819044020555,
    BND: 0.034027851749426706,
    BOB: 0.17007695507905532,
    BRL: 0.1278370779177712,
    BSD: 0.02507220191211967,
    CAD: 0.0345122125238412,
    CHF: 0.02282324047407062,
    CLP: 23.64926652504306,
    CNH: 0.1813599483884453,
    CNY: 0.1825396984904424,
    COP: 98.4116604329502,
    CRC: 12.852643785812049,
    CZK: 0.587503492020106,
    DJF: 4.495712100779198,
    DKK: 0.17477585752959926,
    DOP: 1.4599156287746475,
    DZD: 3.404794970687129,
    EGP: 1.2090770415002723,
    EUR: 0.023428188993152625,
    GBP: 0.020097957444584907,
    GTQ: 0.19583449734003533,
    HKD: 0.1969398849994875,
    HNL: 0.6226950155055742,
    HTG: 3.4008758689854353,
    HUF: 9.121257006650414,
    IDR: 405.3096974654466,
    ILS: 0.09381123596408897,
    INR: 2.101522822235576,
    IQD: 32.98275796230829,
    JMD: 3.9414908262873243,
    JOD: 0.01784497641504694,
    JPY: 3.859008808934748,
    KES: 3.3722362796269016,
    KRW: 34.12110627196958,
    KZT: 11.165531913658281,
    LBP: 2258.0815153055996,
    MAD: 0.2532945576741036,
    MUR: 1.1657418256582583,
    MVR: 0.3895788071058338,
    MWK: 43.70855348970884,
    MXN: 0.42819703233560374,
    MYR: 0.11949843537401295,
    NGN: 34.782731031045316,
    NIO: 0.9237222221105669,
    NOK: 0.2733282016548658,
    NZD: 0.041944437186848704,
    OMR: 0.009683195896801009,
    PEN: 0.09374290290877738,
    PHP: 1.440581393712957,
    PKR: 7.015573907223809,
    PLN: 0.1012273822611408,
    QAR: 0.09177330307920806,
    RON: 0.11651187889774763,
    RUB: 2.3064014004256745,
    SAR: 0.09442824325763773,
    SCR: 0.37185843802694335,
    SEK: 0.2726870768123836,
    SGD: 0.03403187134091562,
    SZL: 0.46597114335270107,
    THB: 0.9275408340250381,
    TND: 0.07891262011041818,
    TRY: 0.8126146839696682,
    TTD: 0.16873039193026812,
    TWD: 0.814871684590695,
    TZS: 65.24359729320709,
    UAH: 1,
    UGX: 95.53765251837456,
    USD: 0.0253,
    VND: 640.3587264326327,
    XAF: 15.369109037448524,
    XCD: 0.06807781125204246,
    XPF: 2.7938170643717477,
    ZAR: 0.4658867319314338,
    ZMW: 0.6856217202645695,
  },
  UGX: {
    AED: 0.0009695178187159665,
    AFN: 0.018866753221416333,
    ARS: 0.23186887344604357,
    AUD: 0.0003991501094086564,
    BDT: 0.029058186867675303,
    BHD: 0.00009927212850740221,
    BIF: 0.7569021214047854,
    BMD: 0.00026375798839283935,
    BND: 0.00035617215676177726,
    BOB: 0.0017802086464951059,
    BRL: 0.0013380805844395703,
    BSD: 0.00026243267707773735,
    CAD: 0.0003612419984592309,
    CHF: 0.0002388926237190209,
    CLP: 0.24753870229849584,
    CNH: 0.001898308610351973,
    CNY: 0.0019106571459387171,
    COP: 1.0300824631840613,
    CRC: 0.13452961682662368,
    CZK: 0.006149444502073281,
    DJF: 0.04705696636113753,
    DKK: 0.001829392421966669,
    DOP: 0.015281049830001477,
    DZD: 0.03563825236371903,
    EGP: 0.012655502931346707,
    EUR: 0.0002452246666689527,
    GBP: 0.00021036687541301592,
    GTQ: 0.0020498148340244273,
    HKD: 0.002061385012172143,
    HNL: 0.006517796900921472,
    HTG: 0.03559723082301349,
    HUF: 0.09547290273744315,
    IDR: 4.2424079593906106,
    ILS: 0.0009819294643653345,
    INR: 0.021996801960686596,
    IQD: 0.34523307924030044,
    JMD: 0.041255889404748204,
    JOD: 0.00018678474867921683,
    JPY: 0.040392543748053185,
    KES: 0.03529745802554994,
    KRW: 0.35714825906369363,
    KZT: 0.11687048634056439,
    LBP: 23.635513912918338,
    MAD: 0.0026512537308302398,
    MUR: 0.012201909874581163,
    MVR: 0.004077751513005901,
    MWK: 0.45750081080652955,
    MXN: 0.0044819714641120105,
    MYR: 0.00125079936783071,
    NGN: 0.36407353660229014,
    NIO: 0.009668671960857625,
    NOK: 0.002860947432241934,
    NZD: 0.00043903566898696424,
    OMR: 0.00010135476057399107,
    PEN: 0.00098121421698893,
    PHP: 0.015078676895854156,
    PKR: 0.07343255483354605,
    PLN: 0.0010595548413927373,
    QAR: 0.0009605982631984546,
    RON: 0.0012195388501443358,
    RUB: 0.024141281888646882,
    SAR: 0.000988387727440514,
    SCR: 0.0038922710389542444,
    SEK: 0.0028542367289162585,
    SGD: 0.00035621423013685986,
    SZL: 0.004877356006450774,
    THB: 0.009708641667186099,
    TND: 0.0008259844996216657,
    TRY: 0.008505700763511849,
    TTD: 0.0017661140658424338,
    TWD: 0.00852932496362073,
    TZS: 0.6829097803157653,
    UAH: 0.010467077363112644,
    UGX: 1,
    USD: 0.0003,
    VND: 6.702684329714652,
    XAF: 0.1608696532970874,
    XCD: 0.0007125757170865088,
    XPF: 0.029243099351163343,
    ZAR: 0.004876472465574039,
    ZMW: 0.007176455587839625,
  },
  USD: {
    AED: 3.6724201248622843,
    AFN: 72.2473,
    ARS: 878.24244192,
    AUD: 1.5117,
    BDT: 109.89010989010988,
    BHD: 0.3738,
    BIF: 2852.3401,
    BMD: 1,
    BND: 1.3501,
    BOB: 6.86,
    BRL: 5.0722,
    BSD: 0.99388325,
    CAD: 1.3682,
    CHF: 0.9051,
    CLP: 909.0909090909091,
    CNH: 7.1890726096333575,
    CNY: 7.236936,
    COP: 3333.3333333333335,
    CRC: 504.8,
    CZK: 23.243,
    DJF: 177.838,
    DKK: 6.9287,
    DOP: 57.74,
    DZD: 134.085,
    EGP: 47.9,
    EUR: 0.9289,
    GBP: 0.7968,
    GTQ: 7.7745,
    HKD: 7.8111,
    HNL: 24.6542,
    HTG: 132.38,
    HUF: 361.58,
    IDR: 16068.83524512,
    ILS: 3.7138,
    INR: 83.3165952,
    IQD: 1309,
    JMD: 155.87,
    JOD: 0.7086669973779322,
    JPY: 152.86,
    KES: 133.33333333333334,
    KRW: 1428.5714285714287,
    KZT: 443.61,
    LBP: 89500,
    MAD: 10.0420704,
    MUR: 46.08294930875576,
    MVR: 15.42,
    MWK: 1716.7325,
    MXN: 16.9762224,
    MYR: 4.73761344,
    NGN: 1379,
    NIO: 36.6243,
    NOK: 10.8354,
    NZD: 1.6656,
    OMR: 0.38389824,
    PEN: 3.7965072133637054,
    PHP: 57.1130304,
    PKR: 278.13,
    PLN: 4.0134,
    QAR: 3.63842784,
    RON: 4.61920896,
    RUB: 91.4391744,
    SAR: 3.7504,
    SCR: 14.3758,
    SEK: 10.8102,
    SGD: 1.3494,
    SZL: 18.473808,
    THB: 36.7731168,
    TND: 3.12855552,
    TRY: 32.3488,
    TTD: 6.68945472,
    TWD: 32.306256,
    TZS: 2590.275,
    UAH: 39.52569169960474,
    UGX: 3333.3333333333335,
    USD: 1,
    VND: 25370,
    XAF: 608.89,
    XCD: 2.69866191,
    XPF: 110.75,
    ZAR: 18.4963,
    ZMW: 27.1,
  },
  VND: {
    AED: 0.00014464620009297695,
    AFN: 0.0028148055753984065,
    ARS: 0.03459343481508024,
    AUD: 0.000059550784398293325,
    BDT: 0.004335305892126413,
    BHD: 0.000014810801706311068,
    BIF: 0.11292522281696182,
    BMD: 0.000039351098070296285,
    BND: 0.00005313873356421968,
    BOB: 0.0002655963728745217,
    BRL: 0.0001996335376421547,
    BSD: 0.00003915336963047904,
    CAD: 0.000053895123310187255,
    CHF: 0.000035641335913725045,
    CLP: 0.03693127859253281,
    CNH: 0.0002832161738449032,
    CNY: 0.00028505850073653376,
    COP: 0.1536820790765055,
    CRC: 0.02007100591478264,
    CZK: 0.0009174599607520346,
    DJF: 0.00702061503217784,
    DKK: 0.00027293429497440624,
    DOP: 0.002279840296559517,
    DZD: 0.005317011903085735,
    EGP: 0.001888124564548228,
    EUR: 0.000036579093877318106,
    GBP: 0.000031385466637658546,
    GTQ: 0.00030581998691734487,
    HKD: 0.0003075461875824161,
    HNL: 0.0009724159128345748,
    HTG: 0.005310891737091391,
    HUF: 0.014243980178834955,
    IDR: 0.6329416321432549,
    ILS: 0.00014649794262459878,
    INR: 0.003281789933500128,
    IQD: 0.05150668929906144,
    JMD: 0.006155129404177768,
    JOD: 0.000027867155827577024,
    JPY: 0.0061,
    KES: 0.005266167447132727,
    KRW: 0.05328436212941842,
    KZT: 0.017436370354254747,
    LBP: 3.5262758546059345,
    MAD: 0.00039555103603441065,
    MUR: 0.0018204512213841086,
    MVR: 0.0006083758853043733,
    MWK: 0.06825635645383384,
    MXN: 0.0006686830594486342,
    MYR: 0.0001866117075341902,
    NGN: 0.05431757169113014,
    NIO: 0.0014425074321334245,
    NOK: 0.00042683606917882867,
    NZD: 0.00006550146887279338,
    OMR: 0.000015121517826023888,
    PEN: 0.00014639123203803075,
    PHP: 0.0022496474776540895,
    PKR: 0.01095569345374083,
    PLN: 0.0001580791798138948,
    QAR: 0.0001433154563075402,
    RON: 0.0001819478271918341,
    RUB: 0.0036017333804044195,
    SAR: 0.0001474614764503749,
    SCR: 0.0005807033193699497,
    SEK: 0.0004258348727930874,
    SGD: 0.00005330311402122423,
    SZL: 0.0007276720439941134,
    THB: 0.0014484706707945795,
    TND: 0.0001232318962061025,
    TRY: 0.0012689991569204567,
    TTD: 0.00026349354660979855,
    TWD: 0.0012725237448238658,
    TZS: 0.10188601263649819,
    UAH: 0.0015616246936633299,
    UGX: 0.14919395734732033,
    USD: 0.00003941663381947182,
    VND: 1,
    XAF: 0.024000780192483865,
    XCD: 0.00010631199114174079,
    XPF: 0.0043628937173009145,
    ZAR: 0.0007275402250342352,
    ZMW: 0.0010706838088770836,
  },
  XAF: {
    AED: 0.006026729086843378,
    AFN: 0.11727975310902171,
    ARS: 1.4413462619816662,
    AUD: 0.002481202024296792,
    BDT: 0.18063187352068102,
    BHD: 0.0006170966771717383,
    BIF: 4.705064665036419,
    BMD: 0.001639575786899609,
    BND: 0.0022140419243896377,
    BOB: 0.011066155797622627,
    BRL: 0.00831779367341868,
    BSD: 0.0016313373697218552,
    CAD: 0.0022455571393077112,
    CHF: 0.0014850073884217546,
    CLP: 1.5387532528670997,
    CNH: 0.01180029030612912,
    CNY: 0.011877051431261523,
    COP: 6.4032118057825835,
    CRC: 0.8362647278053118,
    CZK: 0.038226255704776974,
    DJF: 0.29251611722090726,
    DKK: 0.01137189261288593,
    DOP: 0.09499025774476598,
    DZD: 0.22153496096559477,
    EGP: 0.07866929947300283,
    EUR: 0.0015243687149376886,
    GBP: 0.0013076852663101044,
    GTQ: 0.012742085234925657,
    HKD: 0.012814007924572712,
    HNL: 0.04051601260608596,
    HTG: 0.22127996233866432,
    HUF: 0.5934798812621778,
    IDR: 26.371710713865387,
    ILS: 0.006103882517555674,
    INR: 0.13673680218644976,
    IQD: 2.146042290541512,
    JMD: 0.2564553883171398,
    JOD: 0.0011610937479567417,
    JPY: 0.25108864798420316,
    KES: 0.2194165108341724,
    KRW: 2.2201095840253164,
    KZT: 0.7264918146167246,
    LBP: 146.92338442023774,
    MAD: 0.016480757411306245,
    MUR: 0.07584966850178498,
    MVR: 0.025348171202155063,
    MWK: 2.843922401956297,
    MXN: 0.02786088844136993,
    MYR: 0.007775235056426619,
    NGN: 2.2631585829922454,
    NIO: 0.06010252252487871,
    NOK: 0.017784258084764158,
    NZD: 0.0027291391507891878,
    OMR: 0.0006300427613082084,
    PEN: 0.00609943638765022,
    PHP: 0.09373226451857566,
    PKR: 0.4564723882256018,
    PLN: 0.006586418380824102,
    QAR: 0.00597128323155183,
    RON: 0.007580913025852937,
    RUB: 0.15006734579121497,
    SAR: 0.006144028455231394,
    SCR: 0.024195185102849445,
    SEK: 0.017742542924768866,
    SGD: 0.0022143034614429,
    SZL: 0.03031868289939977,
    THB: 0.06035098272547763,
    TND: 0.005134495429639994,
    TRY: 0.05287324606713656,
    TTD: 0.01097854088477985,
    TWD: 0.053020099122543185,
    TZS: 4.245112526317166,
    UAH: 0.06506558041610545,
    UGX: 6.216212812700239,
    USD: 0.0016423327694657492,
    VND: 41.665312209857326,
    XAF: 1,
    XCD: 0.004429522302572216,
    XPF: 0.1817813288697676,
    ZAR: 0.03031319062128127,
    ZMW: 0.0446103751749029,
  },
  XCD: {
    AED: 1.35772302,
    AFN: 26.421201,
    ARS: 324.71162574,
    AUD: 0.55897404,
    BDT: 40.6933926,
    BHD: 0.13902174,
    BIF: 1059.973746,
    BMD: 0.36936948,
    BND: 0.49878726,
    BOB: 2.49302304,
    BRL: 1.87386222,
    BSD: 0.3675135,
    CAD: 0.50588712,
    CHF: 0.33454776,
    CLP: 346.65582,
    CNH: 2.65841148,
    CNY: 2.6757045,
    COP: 1442.53838946,
    CRC: 188.3967,
    CZK: 8.6117472,
    DJF: 65.899074,
    DKK: 2.56190052,
    DOP: 21.399744,
    DZD: 49.908186,
    EGP: 17.72290032,
    EUR: 0.34341522,
    GBP: 0.2952203820151743,
    GTQ: 2.8705824,
    HKD: 2.8867854,
    HNL: 9.1275918,
    HTG: 49.850739,
    HUF: 133.701264,
    IDR: 5941.11303114,
    ILS: 1.37510442,
    INR: 30.8045544,
    IQD: 483.46806,
    JMD: 57.7751844,
    JOD: 0.26157534,
    JPY: 56.566146,
    KES: 49.430934,
    KRW: 500.154204,
    KZT: 163.66666666666666,
    LBP: 33099.42397098,
    MAD: 3.7128438,
    MUR: 17.0876838,
    MVR: 5.7105264,
    MWK: 640.68898014,
    MXN: 6.2766003,
    MYR: 1.75163268,
    NGN: 509.852436,
    NIO: 13.5401106,
    NOK: 4.00650108,
    NZD: 0.6148302,
    OMR: 0.14193828,
    PEN: 1.37410278,
    PHP: 21.1163388,
    PKR: 102.8357274,
    PLN: 1.48381182,
    QAR: 1.34523198,
    RON: 1.70785512,
    RUB: 33.8077068,
    SAR: 1.38414864,
    SCR: 5.45077758,
    SEK: 3.99710334,
    SGD: 0.49884618,
    SZL: 6.830301,
    THB: 13.5960846,
    TND: 1.15671744,
    TRY: 11.91147342,
    TTD: 2.47328484,
    TWD: 11.944557,
    TZS: 956.354088,
    UAH: 14.65820598,
    UGX: 1400.41058946,
    USD: 0.36975246,
    VND: 9386.51011314,
    XAF: 225.283566,
    XCD: 1,
    XPF: 40.952346,
    ZAR: 6.82906368,
    ZMW: 10.0499844,
  },
  XPF: {
    AED: 0.03315372994748579,
    AFN: 0.6451694122724984,
    ARS: 7.929011581900582,
    AUD: 0.013649377742608444,
    BDT: 0.9936767139054744,
    BHD: 0.0033947198043306234,
    BIF: 25.88310193511258,
    BMD: 0.009019495000359685,
    BND: 0.012179699302208474,
    BOB: 0.06087619595712538,
    BRL: 0.04575713977411697,
    BSD: 0.008974174519818717,
    CAD: 0.012353068124595351,
    CHF: 0.008169196460686281,
    CLP: 8.464858643263074,
    CNH: 0.06491475433422056,
    CNY: 0.06533702611322927,
    COP: 35.224804690310044,
    CRC: 4.60038846126178,
    CZK: 0.21028702971009278,
    DJF: 1.6091648082871735,
    DKK: 0.06255808934609021,
    DOP: 0.5225523343644342,
    DZD: 1.218689302927847,
    EGP: 0.43276886554924104,
    EUR: 0.00838572764549313,
    GBP: 0.007193727069994964,
    GTQ: 0.07009567657003093,
    HKD: 0.07049133155888065,
    HNL: 0.22288324580965396,
    HTG: 1.2172865261491979,
    HUF: 3.2648010934465144,
    IDR: 145.0738141140925,
    ILS: 0.03357815984461549,
    INR: 0.7522048773469534,
    IQD: 11.805625494568735,
    JMD: 1.4107905906049925,
    JOD: 0.006387310265448529,
    JPY: 1.381267534709733,
    KES: 1.207035465074455,
    KRW: 12.21307819581325,
    KZT: 3.996515038886091,
    LBP: 808.2424379541039,
    MAD: 0.09066254226314653,
    MUR: 0.4172577512409179,
    MVR: 0.13944320552478237,
    MWK: 15.64474426300266,
    MXN: 0.1532659520897777,
    MYR: 0.042772462412776054,
    NGN: 12.449895690957485,
    NIO: 0.33063088986403855,
    NOK: 0.09783324940651751,
    NZD: 0.01501330839507949,
    OMR: 0.0034659377023235735,
    PEN: 0.03355370117257751,
    PHP: 0.515631968923099,
    PKR: 2.511107114596072,
    PLN: 0.03623264513344363,
    QAR: 0.032848715919718004,
    RON: 0.04170347457017481,
    RUB: 0.825537731098482,
    SAR: 0.03379900726566434,
    SCR: 0.1331004963671678,
    SEK: 0.09760376951298468,
    SGD: 0.012181138047622473,
    SZL: 0.16678656211783324,
    THB: 0.3319976980073376,
    TND: 0.028245449967628226,
    TRY: 0.2908618085029854,
    TTD: 0.060394216243435724,
    TWD: 0.2916696640529458,
    TZS: 23.352852312783252,
    UAH: 0.35793324221279044,
    UGX: 34.19610171930077,
    USD: 0.009029345372460496,
    VND: 229.20567513128552,
    XAF: 5.501115027695849,
    XCD: 0.024367311704193942,
    XPF: 1,
    ZAR: 0.16675634846413925,
    ZMW: 0.24540680526580821,
  },
  ZAR: {
    AED: 0.1986531317666223,
    AFN: 3.856455,
    ARS: 47.3951117,
    AUD: 0.08176548024954824,
    BDT: 5.939633,
    BHD: 0.0202917,
    BIF: 154.71443,
    BMD: 0.0539134,
    BND: 0.0728033,
    BOB: 0.3638832,
    BRL: 0.27424308907415534,
    BSD: 0.0536425,
    CAD: 0.07397106252034204,
    CHF: 0.048923200360074755,
    CLP: 50.5981,
    CNH: 0.3880234,
    CNY: 0.39129754265143213,
    COP: 210.5538043,
    CRC: 27.4985,
    CZK: 1.256976,
    DJF: 9.61867,
    DKK: 0.3746862003072427,
    DOP: 3.12352,
    DZD: 7.28463,
    EGP: 2.5868456,
    EUR: 0.05022828756699198,
    GBP: 0.04313914964108227,
    GTQ: 0.418992,
    HKD: 0.42233296731142833,
    HNL: 1.332269,
    HTG: 7.276245,
    HUF: 19.51512,
    IDR: 867.1685687,
    ILS: 0.2007111,
    INR: 4.510599909788001,
    IQD: 70.5673,
    JMD: 8.432902,
    JOD: 0.0381797,
    JPY: 8.264462809917354,
    KES: 7.25163161711385,
    KRW: 72.99270072992701,
    KZT: 23.888888888888886,
    LBP: 4831.2125959,
    MAD: 0.541929,
    MUR: 2.494129,
    MVR: 0.833512,
    MWK: 93.5153637,
    MXN: 0.9182736455463728,
    MYR: 0.256160663968441,
    NGN: 74.41838,
    NIO: 1.976323,
    NOK: 0.5889281507656066,
    NZD: 0.08998389288317392,
    OMR: 0.0207174,
    PEN: 0.2005649,
    PHP: 3.082154,
    PKR: 15.037593984962406,
    PLN: 0.2165781,
    QAR: 0.1963509,
    RON: 0.2492796,
    RUB: 4.934594,
    SAR: 0.2020312,
    SCR: 0.7955989,
    SEK: 0.5864070837975722,
    SGD: 0.07299696332632563,
    SZL: 0.996955,
    THB: 1.9904458598726114,
    TND: 0.1688352,
    TRY: 1.7621145374449338,
    TTD: 0.3610022,
    TWD: 1.7482517482517483,
    TZS: 139.59004,
    UAH: 2.1395209,
    UGX: 204.4048043,
    USD: 0.054064867027459544,
    VND: 1370.0608787,
    XAF: 32.88253,
    XCD: 0.1456539,
    XPF: 5.97743,
    ZAR: 1,
    ZMW: 1.466902,
  },
  ZMW: {
    AED: 0.1350970276132966,
    AFN: 2.6289793046842935,
    ARS: 32.30966465380782,
    AUD: 0.055619393797267984,
    BDT: 4.049100076215043,
    BHD: 0.013833030427390513,
    BIF: 105.47018819253093,
    BMD: 0.03675323913935628,
    BND: 0.04963065017294952,
    BOB: 0.24806237908190185,
    BRL: 0.1864542416603154,
    BSD: 0.03656856422583103,
    CAD: 0.05032889935729996,
    CHF: 0.03328838599988274,
    CLP: 34.493169959547394,
    CNH: 0.26451896582048423,
    CNY: 0.26623966699888607,
    COP: 143.53638095796447,
    CRC: 18.745969396728615,
    CZK: 0.8568915987571085,
    DJF: 6.5571319692794745,
    DKK: 0.25491587031717183,
    DOP: 2.1293310664243417,
    DZD: 4.965996365128686,
    EGP: 1.7634754059916748,
    EUR: 0.034170721697836665,
    GBP: 0.029313478337339507,
    GTQ: 0.2856305329190362,
    HKD: 0.2872427742275898,
    HNL: 0.9082194993257899,
    HTG: 4.960280236852904,
    HUF: 13.303629008618161,
    IDR: 591.1564431025386,
    ILS: 0.1368265228351996,
    INR: 3.065134548865568,
    IQD: 48.10634929940787,
    JMD: 5.748783490649,
    JOD: 0.026027437415723748,
    JPY: 5.628480975552558,
    KES: 4.918508530222196,
    KRW: 49.766664712434775,
    KZT: 16.28526574296639,
    LBP: 3293.4801342557307,
    MAD: 0.3694377674854898,
    MUR: 1.7002696840007034,
    MVR: 0.568212464090989,
    MWK: 63.75024623321803,
    MXN: 0.6245383127161869,
    MYR: 0.17429207949815323,
    NGN: 50.73166441929999,
    NIO: 1.347276777862461,
    NOK: 0.3986574426921498,
    NZD: 0.06117722929002755,
    OMR: 0.014123233862930174,
    PEN: 0.13672685700885268,
    PHP: 2.101131500263821,
    PKR: 10.232426569736765,
    PLN: 0.14764319634167788,
    QAR: 0.1338541361317934,
    RON: 0.16993609661722459,
    RUB: 3.363956147036407,
    SAR: 0.13772644662015593,
    SCR: 0.5423667702409568,
    SEK: 0.3977223427331887,
    SGD: 0.049636512868616985,
    SZL: 0.6796329952512165,
    THB: 1.3528463387465557,
    TND: 0.11509644134372984,
    TRY: 1.1852230755701472,
    TTD: 0.2460983760333001,
    TWD: 1.1885149791874303,
    TZS: 95.15975845693849,
    UAH: 1.4585302221961658,
    UGX: 139.3445535557249,
    USD: 0.03690036900369004,
    VND: 933.9825555490414,
    XAF: 22.416310019346895,
    XCD: 0.09929354517207012,
    XPF: 4.0748666236735644,
    ZAR: 0.6795098786421997,
    ZMW: 1,
  },
};
