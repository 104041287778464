import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20d%3D%22M9.75%209.75h6.674a.75.75%200%201%200%200-1.5H9.75V1.576a.75.75%200%201%200-1.5%200V8.25H1.576a.75.75%200%201%200%200%201.5H8.25v6.674a.748.748%200%200%200%20.75.75.75.75%200%200%200%20.75-.75V9.75Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function PlusIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 18 18'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M9.75 9.75h6.674a.75.75 0 1 0 0-1.5H9.75V1.576a.75.75 0 1 0-1.5 0V8.25H1.576a.75.75 0 1 0 0 1.5H8.25v6.674a.748.748 0 0 0 .75.75.75.75 0 0 0 .75-.75V9.75Z'
        }
      />
    </svg>
  )
}
