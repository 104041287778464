import { createHash } from 'crypto'
import { mutate } from 'swr'

import { Database } from 'core/remodel/database'
import { WithId } from 'core/remodel/types/common'
import { Delegate } from 'core/remodel/types/delegates'
import { delay } from '@/utils/delay'
import type { DelegateValues, DelegateViewData } from '@/components/form'

export const delegateQuery = {
  user: 'user-delegates',
  of: 'delegates-of',
  invitations: 'delegate-invitations',
  delegator: 'delegator-name'
} as const

export type DelegateMix = (Delegate | Delegate.EncryptedDelegatee) & {
  type: 'Invite' | 'Delegate'
}

export function fetchUserDelegates(database: Database) {
  return async ([_key]: [typeof delegateQuery.user]) => {
    const resp = await database.Delegates.listUserDelegates()
    const { invites: respInvites, delegates: respDelegates } = resp

    const rawInvites = []
    const invites = []
    const rawDelegates: DelegateViewData = {}
    const currentTime = new Date()

    for (let i = 0; i < respInvites.length; i++) {
      const invite = respInvites[i]
      const decryptedDelegate = await Delegate.decryptByDelegator(invite.permissions, database.Encryption.self)

      rawInvites.push({
        ...invite,
        permissions: decryptedDelegate
      })

      const expired = invite.expiration && invite.status != "rejected" && invite.expiration < currentTime

      invites.push({
        id: invite.id,
        ...decryptedDelegate,
        type: 'Invite',
        status: expired ? "expired" : invite.status
      })
    }

    const entries = Object.entries(respDelegates)
    const delegates = []
    for (let i = 0; i < entries.length; i++) {
      const [id, data] = entries[i]
      const decrypted = (await Delegate.decryptByDelegator(
        data,
        database.Encryption.self
      )) as Delegate.EncryptedDelegatee     

      rawDelegates[id] = {
        ...decrypted,
        delegatorName: ''
      }

      delegates.push({ id, ...decrypted, type: 'Delegate' })
    }

    const list = [...invites, ...delegates] as WithId<DelegateMix>[]
    return { list, raw: { delegates: rawDelegates, invites: rawInvites } }
  }
}

export function fetchDelegatesOf(database: Database) {
  return async ([_key]: [typeof delegateQuery.of]) => {
    const delegatesOf = await database.Delegates.listDelegates()
    const activeList = delegatesOf.filter(({ status }) => status === 'active')
    
    const decryptedList: WithId<Delegate.EncryptedDelegator>[] = await Promise.all(
      activeList.map(async ({ id, ...delegate }) => {
        const decrypted = (await Delegate.decryptByDelegatee(
          delegate,
          database.Encryption.self
        )) as Delegate.EncryptedDelegator
        return { id, ...decrypted }
      })
    )

    return decryptedList
  }
}

export function fetchInvitations(database: Database) {
  return async ([_key]: [typeof delegateQuery.invitations]) => {
    const invitations = await database.Delegates.listInvitations()
    const pendingList = invitations.filter(({ status }) => status === 'pending')
    const withNameList = await Promise.all(
      pendingList.map(async (invite) => {
        const delegatorName = await database.Delegates.getDelegatorName(invite.id)
        return { ...invite, permissions: { ...invite.permissions, delegatorName } }
      })
    )

    return withNameList
  }
}

export function fetchDelegatorName(database: Database, id: string) {
  return async ([_key]: [typeof delegateQuery.delegator]) => {
    const delegatorName = await database.Delegates.getDelegatorName(id)
    return delegatorName
  }
}

function getPermissions(data: DelegateValues) {
  const {
    Finance,
    Insurance,
    Property,
    Art,
    WineAndSpirits,
    OtherCollectables,
    Belonging,
    maxPermission,
    listProperty,
    delegatorName,
    email,
    ...others
  } = data
  return {
    Finance: +Finance,
    Insurance: +Insurance,
    Property: +Property,
    Art: +Art,
    WineAndSpirits: +WineAndSpirits,
    OtherCollectables: +OtherCollectables,
    Belonging: +Belonging,
    maxPermission: +maxPermission,
    listProperty: +listProperty,
    // Since anyone can read /UserDelegateInvites, we don't want to show delegatorName in firestore
    delegatorName: '',
    email: email,
    ...others
  } satisfies Delegate
}

export async function inviteDelegate(database: Database, data: DelegateValues) {
  const permissions = getPermissions(data)

  const encrypted = (await Delegate.encryptByDelegator(
    permissions,
    database.Encryption.self
  )) as Delegate.EncryptedDelegator
  await database.Delegates.createInvite(data.email, data.name, encrypted)
  await delay()
  mutate([delegateQuery.user])
}

export async function acceptInvite(database: Database, id: string, delegatorName: string) {
  const encryptPart = {
    delegatorName
  }
  
  const iv = database.Encryption.self.generateNewIVSalt()
  const ivSalt = database.Encryption.self.convertIVSaltToBase64(iv)
  const encrypted = await database.Encryption.self.encryptAndStringify(encryptPart, iv)
  await database.Delegates.acceptInvite(id, encrypted, ivSalt)
  await delay()
  mutate([delegateQuery.invitations])
  mutate([delegateQuery.of])
}

export async function rejectInvite(database: Database, id: string) {
  await database.Delegates.rejectInvite(id)
  await delay()
  mutate([delegateQuery.invitations])
  mutate([delegateQuery.of])
}

export async function revokeDelegate(database: Database, id: string, name: string, email: string) {
  await database.Delegates.revokeDelegate(id, name, email)
  await delay()
  mutate([delegateQuery.user])
}

export async function reinstateDelegate(database: Database, id: string) {
  await database.Delegates.reinstateDelegate(id)
  await delay()
  mutate([delegateQuery.user])
}

export async function updateDelegate(database: Database, id: string, data: DelegateValues) {
  const permissions = getPermissions(data)
  const encrypted = (await Delegate.encryptByDelegator(
    permissions,
    database.Encryption.self
  )) as Delegate.EncryptedDelegator
  await database.Delegates.editDelegate(id, encrypted)
  await delay()
  mutate([delegateQuery.user])
}

export async function deleteDelegate(database: Database, id: string) {
  await database.Delegates.deleteDelegate(id)
  await delay()
  mutate([delegateQuery.user])
}

export async function deleteInvite(database: Database, id: string) {
  await database.Delegates.deleteInvite(id)
  await delay()
  mutate([delegateQuery.user])
}
