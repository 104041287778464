import { add } from "date-fns";
import { Amount } from "./common";

export interface SubscriptionInfo {
  subscription: Subscription;
  stripe?: {
    customerId?: string;
    paymentMethod?: any;
    lastSuccessfulPaymentIntent?: string;
  };
}

export interface Integration {
  name: string;
  token: string;
  date: Date;
}

export type SubscriptionPlan = MonthlyPlan | AnnualPlan | Trial;

export const trial = {
  plan: "Trial",
  basePrice: 0,
  delegateUnitPrice: 0,
  currency: "USD",
} as const;

export type Trial = typeof trial;

export const monthlyPlan = {
  plan: "Monthly",
  basePrice: 20,
  delegateUnitPrice: 5,
  currency: "USD",
} as const;

export type MonthlyPlan = typeof monthlyPlan;

export const annualPlan = {
  plan: "Annual",
  basePrice: 100,
  delegateUnitPrice: 5,
  currency: "USD",
} as const;

export type AnnualPlan = typeof annualPlan;

export interface Subscription {
  status: SubscribeStatus;
  currentPlan: SubscriptionPlan;
  delegates: {
    seats: number;
    consumed: number;
    requested: number;
  };
  //#NOTE keep the start date to use for reoccuring billing
  startDate?: Date;
  periodStart: Date;
  periodEnd: Date;
  cancelDate?: Date;
}

export enum SubscribeStatus {
  Cancelling = "Cancelling",
  Canceled = "Canceled",
  Subscribed = "Subscribed",
  Expired = "Expired",
}

export interface BillingHistory {
  plan: SubscriptionPlan;
  date: Date;
  price: Amount;
  intent?: any;
  context?: any;
}

// #NOTE one should also edit the checking function in `firestore.template.rules` if below changes
export function defaultSubscription(): Subscription {
  const [periodStart, periodEnd] = getNextBillingDate(new Date(), "Monthly");
  return {
    status: SubscribeStatus.Subscribed,
    currentPlan: {
      plan: "Trial",
      basePrice: 0,
      delegateUnitPrice: 0,
      currency: "USD",
    },
    periodStart,
    periodEnd,
    delegates: {
      seats: 1,
      consumed: 0,
      requested: 1,
    },
  };
}

/// `subscribeDate` is used to maintain the "real" DAY that the subscription is started on so we
/// lose that information as we keep moving the Start and End forward.
export function getNextBillingDate(
  startDate: Date,
  plan: "Monthly" | "Annual",
  subscribeDate?: Date
): [Date, Date] {
  if (subscribeDate) {
    startDate.setDate(subscribeDate.getDate());
  }

  let endDate: Date;
  if (plan === "Monthly") {
    endDate = add(startDate, { months: 1 });
  } else {
    endDate = add(startDate, { years: 1 });
  }

  // Discard minutes, hours, and seconds
  startDate.setMinutes(0);
  startDate.setHours(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  endDate.setMinutes(0);
  endDate.setHours(0);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);

  return [startDate, endDate];
}
