import React, { ElementType, useEffect, useRef, useState } from 'react'

import { cn } from '@/utils/classnames'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/base/Tooltip'

type AllowedElementType = 'p' | 'div' | 'span' | 'label'

type ElementTypeMap = {
  p: HTMLParagraphElement
  div: HTMLDivElement
  span: HTMLSpanElement
  label: HTMLLabelElement
}

interface TruncatedTextProps<T extends AllowedElementType = 'p'> {
  as?: T
  children: React.ReactNode
  className?: string
}

const TruncatedText = <T extends AllowedElementType = 'p'>({ as, children, className }: TruncatedTextProps<T>) => {
  const Component = (as || 'p') as ElementType
  const textRef = useRef<ElementTypeMap[T]>(null)
  const [isTruncated, setIsTruncated] = useState<boolean>(false)

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, clientWidth } = textRef.current
      setIsTruncated(scrollWidth > clientWidth)
    }
  }, [children])

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Component
          ref={textRef as React.Ref<ElementTypeMap[T]>}
          className={cn('truncate', isTruncated && 'cursor-default', className)}
        >
          {children}
        </Component>
      </TooltipTrigger>
      {isTruncated && (
        <TooltipContent
          className={
            'pointer-events-none fixed w-60 overflow-hidden rounded border border-white bg-background-main p-2'
          }
        >
          <span className={'text-white'}>{children}</span>
        </TooltipContent>
      )}
    </Tooltip>
  )
}

export default TruncatedText
