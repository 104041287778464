import { CoreFirestore } from "../../coreFirebase";
import { Encrypted, Encryption } from "../database/encryption";
import { EncryptionFieldKey, IVSaltFieldKey } from "../encryption/utils";
import { Offer } from "./actions/offer";
import { SaleType } from "./actions/soldInfo";
import { ACTIVITY_MISSING_VALUE } from "./activityUtils";
import {
  Amount,
  AssetType,
  LocationType,
  Optional,
  Owner,
  SupportActivityType,
} from "./common";
import { DataPoisoned, InvalidInput } from "./error";
import { EventBase } from "./event";
import { RemovalReason } from "./wineAndSprits";

export namespace Activity {
  export async function tryDecryptAndConvertDate(
    input: Encrypted<Activity>,
    encryption: Encryption
  ) {
    await tryDecrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(input, ["time"]);
    return input as unknown as Activity;
  }

  /**
   * A generic function to decrypt all kinds of activities
   **/
  async function tryDecrypt<T extends Activity>(
    input: Encrypted<T>,
    encryption: Encryption
  ) {
    async function decrypt<U extends object>(
      data: Optional<Encrypted<U>>,
      encryption: Encryption
    ) {
      if (!data) throw new DataPoisoned("data for decryption is undefined");
      if (!data[EncryptionFieldKey])
        throw new DataPoisoned("Encryption field is missing");
      const decrypted = await encryption.decryptAndStringify(
        data[EncryptionFieldKey]["data"],
        encryption.convertBase64ToIVSalt(
          data[EncryptionFieldKey][IVSaltFieldKey]
        )
      );
      delete data[EncryptionFieldKey];
      return decrypted;
    }

    if (input.activityKind === ActivityKind.AssetValuationRemoved) {
      const activity = input as Encrypted<Activity.AssetValuationRemoved>;
      const decrypted = (await decrypt(
        activity.detail?.valuation,
        encryption
      )) as { valuationName: string };
      activity.detail!.valuation!.name = decrypted.valuationName;
    }
  }

  export interface Base {
    ownerId: string;
    executerId: string;
    assetId: string;
    sortKey: number; // sort ascending, enable create to show at bottom
    activityKind: ActivityKind;
    time: Date;
  }

  export interface AssetCreated extends Base {
    activityKind: ActivityKind.AssetCreated;
    detail: {
      assetName: string;
      value: Amount;
      // for belonging / other collectables logs in summary
      items?: number;
      subtype?: string;
    };
  }

  export interface AssetDeleted extends Base {
    activityKind: ActivityKind.AssetDeleted;
  }

  export interface AssetAddedToGroup extends Base {
    activityKind: ActivityKind.AssetAddedToGroup;
    detail: {
      groupId: string;
    };
  }

  export interface AssetRemovedFromGroup extends Base {
    activityKind: ActivityKind.AssetRemovedFromGroup;
    detail: {
      groupId: string;
    };
  }

  export interface AssetPhotoAdded extends Base {
    activityKind: ActivityKind.AssetPhotoAdded;
    detail: {
      number: number;
    };
  }

  export interface AssetPhotoRemoved extends Base {
    activityKind: ActivityKind.AssetPhotoRemoved;
    detail: {
      number: number;
    };
  }

  export interface AssetMainPhotoChanged extends Base {
    activityKind: ActivityKind.AssetMainPhotoChanged;
  }

  export interface AssetPrimaryDetailsEdited extends Base {
    activityKind: ActivityKind.AssetPrimaryDetailsEdited;
  }

  export interface AssetAttributesEdited extends Base {
    activityKind: ActivityKind.AssetAttributesEdited;
  }

  export interface AssetLocationCreated extends Base {
    activityKind: ActivityKind.AssetLocationCreated;
    detail: {
      locationType: LocationType;
      locationId: string;
    };
  }

  export interface ValueUpdated extends Base {
    activityKind: ActivityKind.ValueUpdated;
    detail: {
      previous: Amount;
      current: Amount;
    };
  }

  export interface AssetLocationEdited extends Base {
    activityKind: ActivityKind.AssetLocationEdited;
    detail: {
      previous: {
        locationType: LocationType;
        locationId: string;
      };
      current: {
        locationType: LocationType;
        locationId: string;
      };
    };
  }

  export interface AssetShareholderCreated extends Base {
    activityKind: ActivityKind.AssetShareholderCreated;
    detail: {
      shareholder: Owner[];
      // only for wine purchase
      purchaseDate?: Date;
    };
  }

  export interface AssetShareholderEdited extends Base {
    activityKind: ActivityKind.AssetShareholderEdited;
    detail: {
      myOwnership: {
        previousPercent: number;
        currentPercent: number;
      };
      shareholder: {
        contactId: string;
        previousPercent: number;
        currentPercent: number;
      };
      // only for wine purchase
      purchaseDate?: Date;
    };
  }

  export interface AssetShareholderRemoved extends Base {
    activityKind: ActivityKind.AssetShareholderRemoved;
    detail: {
      shareholder: string[]; // addr
      // only for wine purchase
      purchaseDate?: Date;
    };
  }

  export interface AssetBeneficiariesCreated extends Base {
    activityKind: ActivityKind.AssetBeneficiariesCreated;
    detail: {
      beneficiary: Owner[];
      // only for wine purchase
      purchaseDate?: Date;
    };
  }

  export interface AssetBeneficiariesEdited extends Base {
    activityKind: ActivityKind.AssetBeneficiariesEdited;
    detail: {
      contactId: string;
      previousPercent: number;
      currentPercent: number;
      // only for wine purchase
      purchaseDate?: Date;
    };
  }

  export interface AssetBeneficiariesRemoved extends Base {
    activityKind: ActivityKind.AssetBeneficiariesRemoved;
    detail: {
      beneficiary: string[]; // addr
      // only for wine purchase
      purchaseDate?: Date;
    };
  }

  // ****** action activity ******
  export interface AssetValuationCreated extends Base {
    activityKind: ActivityKind.AssetValuationCreated;
    detail: {
      previous: Amount;
      current: Amount;
    };
  }

  export interface AssetValuationRemoved extends Base {
    activityKind: ActivityKind.AssetValuationRemoved;
    detail: {
      value: Amount;
      valuation: {
        valuationId: string;
        // encrypted
        name: string;
      };
    };
  }

  export interface AssetOfferCreated extends Base {
    activityKind: ActivityKind.AssetOfferCreated;
    detail: {
      actionId: string;
      type: Offer.Type;
      offerNumber: string;
      buyer: string; // addr
    };
  }

  export interface AssetOfferEdited extends Base {
    activityKind: ActivityKind.AssetOfferEdited;
    detail: {
      actionId: string;
      offerNumber: string;
    };
  }

  export interface AssetOfferRemoved extends Base {
    activityKind: ActivityKind.AssetOfferRemoved;
    detail: {
      offerNumber: string;
    };
  }

  export interface AssetConsignmentCreated extends Base {
    activityKind: ActivityKind.AssetConsignmentCreated;
    detail: {
      actionId: string;
      consignee: string; // addr
      price: Amount;
    };
  }

  export interface AssetConsignmentEdited extends Base {
    activityKind: ActivityKind.AssetConsignmentEdited;
    detail: {
      actionId: string;
      consignee: string; // addr
    };
  }

  export interface AssetConsignmentRemoved extends Base {
    activityKind: ActivityKind.AssetConsignmentRemoved;
    detail: {
      consignee: string; // addr
    };
  }

  export interface AssetLiteratureCreated extends Base {
    activityKind: ActivityKind.AssetLiteratureCreated;
    detail: {
      actionId: string;
      title: string;
    };
  }

  export interface AssetLiteratureEdited extends Base {
    activityKind: ActivityKind.AssetLiteratureEdited;
    detail: {
      actionId: string;
      title: string;
    };
  }

  export interface AssetLiteratureRemoved extends Base {
    activityKind: ActivityKind.AssetLiteratureRemoved;
    detail: {
      title: string;
    };
  }

  export interface AssetExhibitionCreated extends Base {
    activityKind: ActivityKind.AssetExhibitionCreated;
    detail: {
      actionId: string;
      title: string;
    };
  }

  export interface AssetExhibitionEdited extends Base {
    activityKind: ActivityKind.AssetExhibitionEdited;
    detail: {
      actionId: string;
      title: string;
    };
  }

  export interface AssetExhibitionRemoved extends Base {
    activityKind: ActivityKind.AssetExhibitionRemoved;
    detail: {
      title: string;
    };
  }

  export interface AssetSoldInfoCreated extends Base {
    activityKind: ActivityKind.AssetSoldInfoCreated;
    detail: {
      actionId: string;
      assetName: string;
      saleType: SaleType;
      salePrice: Amount;
    };
  }

  export interface AssetSoldInfoEdited extends Base {
    activityKind: ActivityKind.AssetSoldInfoEdited;
    detail: {
      actionId: string;
      title: string;
    };
  }

  export interface AssetSoldInfoRemoved extends Base {
    activityKind: ActivityKind.AssetSoldInfoRemoved;
    detail: {
      title: string;
    };
  }

  // ****** wine related activity ******

  export interface BottlesBought extends Base {
    activityKind: ActivityKind.NewBottlesBought;
    detail: {
      bottles: number;
      vintage: number;
      wineName: string;
      value: Amount;
    };
  }

  export interface BottlesRemoved extends Base {
    activityKind: ActivityKind.RemoveBottles;
    detail: {
      bottles: number;
      vintage: number;
      wineName: string;
      value: Amount;
      removalReason?: RemovalReason;
    };
  }

  export interface BottleLocationCreated extends Base {
    activityKind: ActivityKind.BottleLocationCreated;
    detail: {
      bottles: number;
      locationType: LocationType;
      locationId: string;
      room?: {
        roomId: string;
        position?: string; // position/shelf
      };
      vintage: number;
      wineName: string;
    };
  }

  export interface BottleLocationRemoved extends Base {
    activityKind: ActivityKind.BottleLocationRemoved;
    detail: {
      bottles: number;
      locationType: LocationType;
      locationId: string;
      room?: {
        roomId: string;
        position?: string; // position/shelf
      };
      vintage: number;
      wineName: string;
    };
  }

  export interface VintageCreated extends Base {
    activityKind: ActivityKind.VintageCreated;
    detail: {
      vintage: number;
      wineName: string;
    };
  }
}

export type Activity =
  | Activity.AssetCreated
  | Activity.AssetDeleted
  | Activity.ValueUpdated
  | Activity.AssetAddedToGroup
  | Activity.AssetRemovedFromGroup
  | Activity.AssetPhotoAdded
  | Activity.AssetPhotoRemoved
  | Activity.AssetMainPhotoChanged
  | Activity.AssetPrimaryDetailsEdited
  | Activity.AssetAttributesEdited
  | Activity.AssetLocationCreated
  | Activity.AssetLocationEdited
  | Activity.AssetShareholderCreated
  | Activity.AssetShareholderEdited
  | Activity.AssetShareholderRemoved
  | Activity.AssetBeneficiariesCreated
  | Activity.AssetBeneficiariesEdited
  | Activity.AssetBeneficiariesRemoved
  // ****** action ******
  | Activity.AssetValuationCreated
  | Activity.AssetValuationRemoved
  | Activity.AssetOfferCreated
  | Activity.AssetOfferEdited
  | Activity.AssetOfferRemoved
  | Activity.AssetConsignmentCreated
  | Activity.AssetConsignmentEdited
  | Activity.AssetConsignmentRemoved
  | Activity.AssetLiteratureCreated
  | Activity.AssetLiteratureEdited
  | Activity.AssetLiteratureRemoved
  | Activity.AssetExhibitionCreated
  | Activity.AssetExhibitionEdited
  | Activity.AssetExhibitionRemoved
  | Activity.AssetSoldInfoCreated
  | Activity.AssetSoldInfoEdited
  | Activity.AssetSoldInfoRemoved
  // ****** wine ******
  | Activity.BottlesBought
  | Activity.BottlesRemoved
  | Activity.BottleLocationCreated
  | Activity.BottleLocationRemoved
  | Activity.VintageCreated;

export enum ActivityKind {
  AssetCreated = "AssetCreated",
  AssetDeleted = "AssetDeleted",
  ValueUpdated = "ValueUpdated",
  AssetLocationCreated = "AssetLocationCreated",
  AssetLocationEdited = "AssetLocationEdited",
  AssetRemovedFromGroup = "AssetRemovedFromGroup",
  AssetAddedToGroup = "AssetAddedToGroup",
  AssetPhotoRemoved = "AssetPhotoRemoved",
  AssetPhotoAdded = "AssetPhotoAdded",
  AssetMainPhotoChanged = "AssetMainPhotoChanged",
  AssetPrimaryDetailsEdited = "AssetPrimaryDetailsEdited",
  AssetAttributesEdited = "AssetAttributesEdited",
  AssetShareholderRemoved = "AssetShareholderRemoved",
  AssetShareholderEdited = "AssetShareholderEdited",
  AssetShareholderCreated = "AssetShareholderCreated",
  AssetBeneficiariesRemoved = "AssetBeneficiariesRemoved",
  AssetBeneficiariesEdited = "AssetBeneficiariesEdited",
  AssetBeneficiariesCreated = "AssetBeneficiariesCreated",
  // action
  AssetValuationCreated = "AssetValuationCreated",
  AssetValuationRemoved = "AssetValuationRemoved",
  AssetOfferCreated = "AssetOfferCreated",
  AssetOfferEdited = "AssetOfferEdited",
  AssetOfferRemoved = "AssetOfferRemoved",
  AssetConsignmentCreated = "AssetConsignmentCreated",
  AssetConsignmentEdited = "AssetConsignmentEdited",
  AssetConsignmentRemoved = "AssetConsignmentRemoved",
  AssetLiteratureCreated = "AssetLiteratureCreated",
  AssetLiteratureEdited = "AssetLiteratureEdited",
  AssetLiteratureRemoved = "AssetLiteratureRemoved",
  AssetExhibitionCreated = "AssetExhibitionCreated",
  AssetExhibitionEdited = "AssetExhibitionEdited",
  AssetExhibitionRemoved = "AssetExhibitionRemoved",
  AssetSoldInfoCreated = "AssetSoldInfoCreated",
  AssetSoldInfoEdited = "AssetSoldInfoEdited",
  AssetSoldInfoRemoved = "AssetSoldInfoRemoved",
  // wine
  VintageCreated = "VintageCreated",
  NewBottlesBought = "NewBottlesBought",
  RemoveBottles = "RemoveBottles",
  BottleLocationCreated = "BottleLocationCreated",
  BottleLocationRemoved = "BottleLocationRemoved",
}

/**
 * The higher the number, the lower it displays at activity log
 * Currently, we only ensure create related activities are at the bottom
 */
export const activitySortKeyMap = {
  [ActivityKind.AssetCreated]: 15,
  [ActivityKind.AssetDeleted]: 0,
  [ActivityKind.ValueUpdated]: 0,
  [ActivityKind.AssetLocationCreated]: 0,
  [ActivityKind.AssetLocationEdited]: 0,
  [ActivityKind.AssetRemovedFromGroup]: 0,
  [ActivityKind.AssetAddedToGroup]: 0,
  [ActivityKind.AssetPhotoRemoved]: 0,
  [ActivityKind.AssetPhotoAdded]: 0,
  [ActivityKind.AssetMainPhotoChanged]: 0,
  [ActivityKind.AssetPrimaryDetailsEdited]: 0,
  [ActivityKind.AssetAttributesEdited]: 0,
  [ActivityKind.AssetShareholderRemoved]: 0,
  [ActivityKind.AssetShareholderEdited]: 0,
  [ActivityKind.AssetShareholderCreated]: 0,
  [ActivityKind.AssetBeneficiariesRemoved]: 0,
  [ActivityKind.AssetBeneficiariesEdited]: 0,
  [ActivityKind.AssetBeneficiariesCreated]: 0,
  // action
  [ActivityKind.AssetValuationCreated]: 0,
  [ActivityKind.AssetValuationRemoved]: 0,
  [ActivityKind.AssetOfferCreated]: 0,
  [ActivityKind.AssetOfferEdited]: 0,
  [ActivityKind.AssetOfferRemoved]: 0,
  [ActivityKind.AssetConsignmentCreated]: 0,
  [ActivityKind.AssetConsignmentEdited]: 0,
  [ActivityKind.AssetConsignmentRemoved]: 0,
  [ActivityKind.AssetLiteratureCreated]: 0,
  [ActivityKind.AssetLiteratureEdited]: 0,
  [ActivityKind.AssetLiteratureRemoved]: 0,
  [ActivityKind.AssetExhibitionCreated]: 0,
  [ActivityKind.AssetExhibitionEdited]: 0,
  [ActivityKind.AssetExhibitionRemoved]: 0,
  [ActivityKind.AssetSoldInfoCreated]: 0,
  [ActivityKind.AssetSoldInfoEdited]: 0,
  [ActivityKind.AssetSoldInfoRemoved]: 0,
  // wine
  [ActivityKind.VintageCreated]: 12,
  [ActivityKind.NewBottlesBought]: 11,
  [ActivityKind.RemoveBottles]: 0,
  [ActivityKind.BottleLocationCreated]: 0,
  [ActivityKind.BottleLocationRemoved]: 0,
};

export type EventToActivitiesFunction<TEvent> = (
  event: TEvent,
  assetType: SupportActivityType,
  assetId: string,
  ownerId: string,
  time: Date
) => Encrypted<Activity>[];

// NOTE Bellow defines which event kinds are known to the activity mapping function.
enum ShareEventKind {
  AssetCreated = "AssetCreated",
  AssetUpdated = "AssetUpdated",
  AssetDeleted = "AssetDeleted",

  AssetArchived = "AssetArchived",
  ArchivedAssetRestored = "ArchivedAssetRestored",

  ShareholderUpdated = "ShareholderUpdated",
  BeneficiaryUpdated = "BeneficiaryUpdated",
  LocationUpdated = "LocationUpdated",
  ValuationAdded = "ValuationAdded",
  ValuationUpdated = "ValuationUpdated",
  ValuationDeleted = "ValuationDeleted",
  ValueUpdated = "ValueUpdated", //pure update or point to valuation
  InsuranceUpdated = "InsuranceUpdated",

  ImageAdded = "ImageAdded",
  ImageRemoved = "ImageRemoved",
  MainImageSet = "MainImageSet",

  GroupsUpdated = "GroupsUpdated",
}

enum ValuationEventKind {
  ValuationAdded = "ValuationAdded",
  ValuationDeleted = "ValuationDeleted",
}

enum SellEventKind {
  SoldInfoAdded = "SoldInfoAdded",
  SoldInfoUpdated = "SoldInfoUpdated",
  SoldInfoDeleted = "SoldInfoDeleted",
}

enum ActionEventKind {
  ActionAdded = "ActionAdded",
  ActionUpdated = "ActionUpdated",
  ActionDeleted = "ActionDeleted",
}

enum WineCustomEventKind {
  PurchaseAdded = "PurchaseAdded",
  PurchaseUpdated = "PurchaseUpdated",
  PurchaseDeleted = "PurchaseDeleted",
  CatalogueInfoUpdated = "CatalogueInfoUpdated",
  BottleLocationUpdated = "BottleLocationUpdated",
  BottleRemoved = "BottleRemoved",
  WineDeleting = "WineDeleting",
  WineDeleted = "WineDeleted",
}

const artKnownEvents = {
  ...ShareEventKind,
  ...ValuationEventKind,
  ...SellEventKind,
  ...ActionEventKind,
};

const belongingKnownEvents = {
  ...ShareEventKind,
  ...ValuationEventKind,
};

const propertyKnownEvents = {
  ...ShareEventKind,
  ...ValuationEventKind,
  ...SellEventKind,
  ...ActionEventKind,
};

const wineKnownEvents = {
  ...ShareEventKind,
  ...WineCustomEventKind,
  ...ActionEventKind,
};

export function validateActMapFuncKnowEventKind<T extends EventBase>(
  assetType: SupportActivityType,
  events: T[]
) {
  let knownEvents;
  switch (assetType) {
    case AssetType.Art:
      knownEvents = artKnownEvents;
      break;
    case AssetType.Belonging:
    case AssetType.OtherCollectables:
      knownEvents = belongingKnownEvents;
      break;
    case AssetType.Property:
      knownEvents = propertyKnownEvents;
      break;
    case AssetType.WineAndSpirits:
      knownEvents = wineKnownEvents;
      break;
    default:
      throw new InvalidInput(`AssetType ${assetType} does not have activity`);
  }
  for (const kind of events.map((e) => e.kind)) {
    if (!Object.values(knownEvents).includes(<any>kind)) {
      throw new InvalidInput(
        `Unknown event kind for ${assetType} to generate activity: ${kind}. ` +
          "Please first ensure this event kind should be handled by `toActivities` or not. " +
          "Then, add it to `knownEvents`."
      );
    }
  }
}
