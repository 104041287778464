import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23ddd%22%20viewBox%3D%220%200%2040%2041%22%3E%3Cmask%20id%3D%22a%22%20width%3D%2240%22%20height%3D%2241%22%20x%3D%220%22%20y%3D%220%22%20maskUnits%3D%22userSpaceOnUse%22%20style%3D%22maskType%3A%26quot%3Bluminance%26quot%3B%22%3E%3Cpath%20d%3D%22M37.904%2022.763c2.648%202.25%202.788%206.288.414%208.727l-8.16%208.386a1.326%201.326%200%200%201-1.91%200l-8.16-8.386c-2.37-2.44-2.23-6.477.417-8.727%202.311-1.96%205.752-1.608%207.87.57l.83.853.83-.852c2.122-2.178%205.558-2.531%207.87-.57ZM28.454%200c.924%200%201.674.75%201.674%201.674v8.369a1.674%201.674%200%200%201-3.348%200V3.348H3.349v33.475h13.39a1.674%201.674%200%200%201%200%203.348H1.674A1.676%201.676%200%200%201%200%2038.497V1.674C0%20.75.75%200%201.674%200h26.78ZM14.227%2024.27a1.674%201.674%200%200%201%200%203.347H7.532a1.674%201.674%200%200%201%200-3.347h6.695Zm0-5.858a1.674%201.674%200%200%201%200%203.347H7.532a1.674%201.674%200%200%201%200-3.347h6.695Zm0-5.859a1.674%201.674%200%200%201%200%203.348H7.532a1.674%201.674%200%200%201%200-3.348h6.695Zm8.369%200a1.674%201.674%200%200%201%200%203.348h-1.674a1.674%201.674%200%200%201%200-3.348h1.674Zm-8.369-5.858a1.674%201.674%200%200%201%200%203.348H7.532a1.674%201.674%200%200%201%200-3.348h6.695Zm8.369%200a1.674%201.674%200%200%201%200%203.348h-1.674a1.674%201.674%200%200%201%200-3.348h1.674Z%22%2F%3E%3C%2Fmask%3E%3Cg%20mask%3D%22url(%23a)%22%3E%3Cpath%20d%3D%22M0%200h40v40.282H0V0Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E)
 */
export default function WishlistIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 40 41'}
      width={size}
      height={size}
      {...props}
    >
      <mask
        id={'mask0_1_245'}
        width={'40'}
        height={'41'}
        x={'0'}
        y={'0'}
        maskUnits={'userSpaceOnUse'}
        style={{ maskType: 'luminance' }}
      >
        <path
          fill={'#fff'}
          d={
            'M37.904 22.763c2.648 2.25 2.788 6.288.414 8.727l-8.16 8.386a1.326 1.326 0 0 1-1.91 0l-8.16-8.386c-2.37-2.44-2.23-6.477.417-8.727 2.311-1.96 5.752-1.608 7.87.57l.83.853.83-.852c2.122-2.178 5.558-2.531 7.87-.57ZM28.454 0c.924 0 1.674.75 1.674 1.674v8.369a1.674 1.674 0 0 1-3.348 0V3.348H3.349v33.475h13.39a1.674 1.674 0 0 1 0 3.348H1.674A1.676 1.676 0 0 1 0 38.497V1.674C0 .75.75 0 1.674 0h26.78ZM14.227 24.27a1.674 1.674 0 0 1 0 3.347H7.532a1.674 1.674 0 0 1 0-3.347h6.695Zm0-5.858a1.674 1.674 0 0 1 0 3.347H7.532a1.674 1.674 0 0 1 0-3.347h6.695Zm0-5.859a1.674 1.674 0 0 1 0 3.348H7.532a1.674 1.674 0 0 1 0-3.348h6.695Zm8.369 0a1.674 1.674 0 0 1 0 3.348h-1.674a1.674 1.674 0 0 1 0-3.348h1.674Zm-8.369-5.858a1.674 1.674 0 0 1 0 3.348H7.532a1.674 1.674 0 0 1 0-3.348h6.695Zm8.369 0a1.674 1.674 0 0 1 0 3.348h-1.674a1.674 1.674 0 0 1 0-3.348h1.674Z'
          }
        />
      </mask>
      <g mask={'url(#mask0_1_245)'}>
        <path d={'M0 0h40v40.282H0V0Z'} />
      </g>
    </svg>
  )
}
