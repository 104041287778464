import {
  EncryptableObject,
  IsOptional,
  IsOptionalOnUpdate,
  isValidBeneficiary,
  isValidOwnerShip,
  NotEncrypted,
} from "../../decorators";
import { Optional, Owner, Ownership } from "../common";

import type { Beneficiary } from "../common";
import { Acquisition } from "../common/acquisition";

export class OwnerDetail {
  // currencyKindValuation: Amount, valuation in asset
  @EncryptableObject(Acquisition) //#NOTE: Cannot use IsValidObject here, otherwise we'll get errros in firestoreRules.ts: TypeError: Cannot read properties of undefined (reading 'subTypes')
  @IsOptionalOnUpdate()
  acquisition!: Acquisition;

  @NotEncrypted
  @IsOptional()
  @isValidOwnerShip()
  ownership?: Ownership;

  @NotEncrypted
  @IsOptional()
  @isValidBeneficiary()
  beneficiary?: Beneficiary;
}
export namespace OwnerDetail {
  export function optionalEqual(
    a: Optional<OwnerDetail>,
    b: Optional<OwnerDetail>
  ) {
    if (a && b) {
      return (
        Acquisition.equal(a.acquisition, b.acquisition) &&
        Ownership.optionalEqual(a.ownership, b.ownership) &&
        Owner.optionalEqual(a.beneficiary, b.beneficiary)
      );
    } else {
      return a === b;
    }
  }
}
