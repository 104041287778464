import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils/classnames'

export const buttonVariants = cva(
  'inline-flex items-center justify-center rounded border border-transparent text-grey transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        solid: 'border-primary bg-primary text-white hover:bg-primary-hover',
        outline: 'border-primary text-primary hover:bg-primary/10',
        ghost: 'text-primary hover:bg-primary/10',
        unstyled: ''
      },
      size: {
        sm: 'gap-x-1 px-2 py-1 text-sm/none',
        md: 'gap-x-2 px-4 py-2 text-base/none',
        unstyled: ''
      }
    },
    defaultVariants: {
      variant: 'unstyled',
      size: 'unstyled'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, type = 'button', ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} type={type} {...props} />
  }
)
Button.displayName = 'Button'
