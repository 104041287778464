import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml;base64,PHN2ZwogICAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICB3aWR0aD0iNDgiCiAgICAgICAgaGVpZ2h0PSI0OCIKICAgICAgICB2aWV3Qm94PSIwIDAgMjQgMjQiCiAgICAgICAgZmlsbD0ibm9uZSIKICAgICAgICBzdHJva2U9IiNmZmYiCiAgICAgICAgc3Ryb2tlLXdpZHRoPSIyIgogICAgICAgIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIKICAgICAgICBzdHJva2UtbGluZWpvaW49InJvdW5kIgogICAgICA+CiAgICAgICAgPHBhdGggc3Ryb2tlPSIjZmZmIiBkPSJtNyAxMCA1LTYgNSA2IiAvPgogICAgICAgIDxwYXRoIHN0cm9rZT0iI2ZmZiIgZD0ibTcgMTQgNSA2IDUtNiIgLz4KICAgICAgPC9zdmc+)
 */
export default function ChevronsUpDownIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      width={size}
      height={size}
      viewBox={'0 0 24 24'}
      fill={'none'}
      stroke={'currentColor'}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      {...props}
    >
      <path stroke={'currentColor'} d={'m7 10 5-6 5 6'} />
      <path stroke={'currentColor'} d={'m7 14 5 6 5-6'} />
    </svg>
  )
}
