import { MouseEvent, useRef, useState } from 'react'

import { cn } from '@/utils/classnames'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/base/Tooltip'

interface CardViewTipProps {
  tip: string
  label: string
  className?: string
}

export const CardViewTip = ({ tip, label, className }: CardViewTipProps) => {
  const textRef = useRef<HTMLParagraphElement>(null)
  const [isTruncated, setIsTruncated] = useState<boolean>(false)

  const checkIsOverflow = (e: MouseEvent<HTMLParagraphElement, globalThis.MouseEvent>) => {
    if (e.target) {
      setIsTruncated((e.target as HTMLElement).offsetWidth < (e.target as HTMLElement).scrollWidth)
    }
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <p
          ref={textRef}
          onMouseOver={(e) => checkIsOverflow(e)}
          className={cn('truncate', isTruncated && 'cursor-default', className)}
        >
          {label}
        </p>
      </TooltipTrigger>
      <TooltipContent
        className={'pointer-events-none absolute overflow-hidden rounded border border-white bg-background-main p-2'}
      >
        <span className={'whitespace-nowrap text-white'}>{tip}</span>
        {isTruncated && <span className={'ml-2 text-white'}>{label}</span>}
      </TooltipContent>
    </Tooltip>
  )
}
