import { CoreFirestore } from "../../../coreFirebase";
import { Encryption } from "../../database/encryption";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  validateValueInEnum,
  validateStringNotEmpty,
} from "../../utils";
import { ActionType, ItemActions } from "./base";
import { Amount, PathsOfAmountField, PathsOfDateField } from "../common";
import {
  EncryptedType,
  EncryptionField,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { InvalidInput } from "../error";

export interface Consignment extends ItemActions {
  actionType: ActionType.AddConsignment;

  //   @Encrypted
  consignor: string;
  consignee: string; // addr
  type: ConsignmentType;
  price: Amount;
  startDate: Date;
  endDate: Date;
  // artist?
  // gallery?
  //OutgoingAuction
  lowEstimate?: Amount;
  highEstimate?: Amount;
}
export namespace Consignment {
  export const datePaths: readonly PathsOfDateField<Consignment>[] = [
    "createAt",
    "updateAt",
    "startDate",
    "endDate",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<Consignment>[] = [
    "price",
    "lowEstimate",
    "highEstimate",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Consignment> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Consignment,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys = ItemActions.EncryptedKeys | "consignor";

  export type Encrypted = EncryptedType<Consignment, EncryptedKeys>;
  export type EncryptedPart = Pick<Consignment, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
    //Consignment
    "consignor",
  ];
  export type Update = CreateFields;
  export type UpdateEncrypted = EncryptedType<Update, EncryptedKeys>;

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): Consignment {
    return ItemActions.fromCreate(
      createFields,
      ownerId,
      ActionType.AddConsignment
    );
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "type",
    "consignor",
    "consignee",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "notes",
  ];
  export function intoUpdate(
    current: Consignment,
    update: Update
  ): UpdateObject<Update> {
    const result: UpdateObject<Update> = {
      ...ItemActions.intoUpdate(current, update),
      ...buildObjectUpdate(
        current,
        update,
        NonOptionalSimpleTypeUpdatableKeys,
        OptionalSimpleTypeUpdatableKeys
      ),
    };

    if (current.startDate.getTime() !== update.startDate.getTime())
      result.startDate = update.startDate;
    if (current.endDate.getTime() !== update.endDate.getTime())
      result.endDate = update.endDate;
    if (!Amount.equal(current.price, update.price)) {
      result.price = update.price;
    }
    if (update.type) {
      if (update.type == ConsignmentType.OutgoingAuction) {
        if (update.lowEstimate) result.lowEstimate = update.lowEstimate;
        if (update.highEstimate) result.highEstimate = update.highEstimate;
      } else {
        if (current.lowEstimate) result.lowEstimate = null;
        if (current.highEstimate) result.highEstimate = null;
      }
    } else if (current.type == ConsignmentType.OutgoingAuction) {
      if (!Amount.optionalEqual(current.lowEstimate, update.lowEstimate))
        if (update.lowEstimate) result.lowEstimate = update.lowEstimate;
        else result.lowEstimate = null;
      if (!Amount.optionalEqual(current.highEstimate, update.highEstimate))
        if (update.highEstimate) result.highEstimate = update.highEstimate;
        else result.highEstimate = null;
    }

    return result;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    if (!validateStringNotEmpty(data.consignor)) {
      throw new InvalidInput("Consignor name cannot be empty");
    }
    ItemActions.validateEncryptedPart(data);
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    if (
      (isCreate || data.type) &&
      !validateValueInEnum(data.type!, ConsignmentType)
    )
      throw new InvalidInput("Invalid type");
    if ((isCreate || data.price) && data.price!.value <= 0)
      throw new InvalidInput("Price must be positive");
    if (
      (isCreate || data.consignee) &&
      !validateStringNotEmpty(data.consignee)
    ) {
      throw new InvalidInput("Consignee name cannot be empty");
    }
    // optional fields
    if (data.lowEstimate && data.lowEstimate!.value < 0)
      throw new InvalidInput("Low estimate cannot be negative");
    if (data.highEstimate && data.highEstimate!.value < 0)
      throw new InvalidInput("High estimate cannot be negative");
  }

  export function removeEncryptedFields<
    T extends Consignment | UpdateObject<Consignment>
  >(data: T): OmitKeys<T, EncryptedKeys> {
    return doRemoveEncryptedFields(data, encryptedKeysArray);
  }

  export async function encrypt(
    rawData: Consignment,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<Consignment, EncryptedKeys>;
}

export enum ConsignmentType {
  Incoming = "Incoming",
  Outgoing = "Outgoing",
  OutgoingAuction = "OutgoingAuction",
}

export const consignmentTypeValues = Object.values(ConsignmentType);
