import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%20%20%20%20viewBox%3D%220%200%2024%2024%22%20%20%20%20%20%20fill%3D%22none%22%20%20%20%20%20%20stroke%3D%22%23ddd%22%20%20%20%20%20%20stroke-width%3D%222%22%20%20%20%20%20%20stroke-linecap%3D%22round%22%20%20%20%20%20%20stroke-linejoin%3D%22round%22%20%20%20%20%20%20width%3D%2224%22%20%20%20%20%20%20height%3D%2224%22%20%20%20%20%3E%3Cpath%20d%3D%22M5%203h14%22%20%2F%3E%3Cpath%20d%3D%22m18%2013-6-6-6%206%22%20%2F%3E%3Cpath%20d%3D%22M12%207v14%22%20%2F%3E%3C%2Fsvg%3E)
 */
export default function ImportIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
    xmlns={'http://www.w3.org/2000/svg'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    stroke={'currentColor'}
    strokeWidth={'2'}
    strokeLinecap={'round'}
    strokeLinejoin={'round'}
    width={size}
    height={size}
    {...props}
  >
    <path d={'M5 3h14'} />
    <path d={'m18 13-6-6-6 6'} />
    <path d={'M12 7v14'} />
  </svg>
  )
}
