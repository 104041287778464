import * as React from 'react'
import { OTPInput, OTPInputContext } from 'input-otp'
import { Dot } from 'lucide-react'
import {
  useController,
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'

import { cn } from '@/utils/classnames'

const InputOTP = React.forwardRef<React.ElementRef<typeof OTPInput>, React.ComponentPropsWithoutRef<typeof OTPInput>>(
  ({ className, containerClassName, ...props }, ref) => (
    <OTPInput
      ref={ref}
      containerClassName={cn('has-[:disabled]:opacity-50 flex items-center gap-2', containerClassName)}
      className={cn('disabled:cursor-not-allowed', className)}
      {...props}
    />
  )
)
InputOTP.displayName = 'InputOTP'

const InputOTPGroup = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('flex items-center', className)} {...props} />
)
InputOTPGroup.displayName = 'InputOTPGroup'

const InputOTPSlot = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & { index: number }
>(({ index, className, ...props }, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext)
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index]

  return (
    <div
      ref={ref}
      className={cn(
        'relative flex h-10 w-10 items-center justify-center rounded-md border border-[#E6E7EB] bg-[#FBFCFE] text-sm font-semibold transition-all',
        isActive && 'ring-ring z-10 ring-2 ring-primary',
        className
      )}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className={'pointer-events-none absolute inset-0 flex items-center justify-center'}>
          <div className={'h-4 w-px animate-caret-blink bg-text duration-1000'} />
        </div>
      )}
    </div>
  )
})
InputOTPSlot.displayName = 'InputOTPSlot'

const InputOTPSeparator = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
  ({ ...props }, ref) => (
    <div ref={ref} role={'separator'} {...props}>
      <Dot />
    </div>
  )
)
InputOTPSeparator.displayName = 'InputOTPSeparator'

interface FormInputOTPProps<TValues extends FieldValues, TPath extends FieldPath<TValues>> {
  length: number
  // controller props
  control: Control<TValues>
  name: TPath
  rules?: RegisterOptions<TValues, TPath>
  defaultValue?: FieldPathValue<TValues, TPath>
}

function FormInputOTP<TValues extends FieldValues, TPath extends FieldPath<TValues>>({
  length,
  control,
  name,
  rules,
  defaultValue
}: FormInputOTPProps<TValues, TPath>) {
  const { field } = useController({ control, name, rules, defaultValue })

  return (
    <InputOTP maxLength={length} {...field}>
      {Array.from({ length }, (_, i) => (
        <InputOTPSlot key={i} index={i} />
      ))}
    </InputOTP>
  )
}

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator, FormInputOTP }
