import { CoreFirestore, FullMetadata, UploadResult } from "../../coreFirebase";
import { FullRefs } from "../refs";
import { EncryptionManager } from "./encryption";

export class Attachments {
  protected readonly refs: FullRefs;
  protected readonly encryption: EncryptionManager;

  protected readonly userId: string;

  constructor(userId: string, refs: FullRefs, encryption: EncryptionManager) {
    this.refs = refs;
    this.encryption = encryption;
    this.userId = userId;
  }

  /***** Attachment *****/
  upload(
    assetId: string,
    attachmentKey: string,
    data: Blob | Uint8Array | ArrayBuffer,
    contentType: string,
    iv: Uint8Array
  ): Promise<UploadResult> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetId,
      attachmentKey
    );
    const encrypted = this.encryption.current
      .encryptBytes(data, iv)
      .then((encryptedData) => {
        console.log(encryptedData);
        return CoreFirestore.uploadBytes(storageRef, encryptedData, {
          contentType,
        });
      });

    return encrypted;
  }

  getUrl(assetId: string, attachmentKey: string): Promise<string> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetId,
      attachmentKey
    );
    return CoreFirestore.getDownloadURL(storageRef);
  }

  getMeta(assetId: string, attachmentKey: string): Promise<FullMetadata> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetId,
      attachmentKey
    );
    return CoreFirestore.getMetadata(storageRef);
  }

  delete(assetId: string, attachmentKey: string): Promise<void> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetId,
      attachmentKey
    );
    return CoreFirestore.deleteObject(storageRef);
  }

  // TODO revisit
  // async addToFavorite(
  //   fileAssetType: FileAssetType,
  //   assetId: string,
  //   attachmentKey: string
  // ) {
  //   const favoritePath =
  //     this.refs.currentRefs.getFavoriteFileDocRef(fileAssetType);
  //   await CoreFirestore.runTransaction(async (transaction) => {
  //     const currentData = await transaction
  //       .get(favoritePath)
  //       .then((data) => data.data());
  //     if (currentData) {
  //       transaction.update(favoritePath, {
  //         ...currentData,
  //         [assetId]: {
  //           ...currentData[assetId],
  //           [attachmentKey]: null,
  //         },
  //       });
  //     } else {
  //       transaction.set(favoritePath, {
  //         [assetId]: {
  //           [attachmentKey]: null,
  //         },
  //       });
  //     }
  //   });
  // }

  // TODO delete from favorite when attachment deleted
  // async removeFromFavorite(
  //   fileAssetType: FileAssetType,
  //   assetId: string,
  //   attachmentKey: string
  // ) {
  //   const favoritePath =
  //     this.refs.currentRefs.getFavoriteFileDocRef(fileAssetType);
  //   await CoreFirestore.runTransaction(async (transaction) => {
  //     const currentData = await transaction
  //       .get(favoritePath)
  //       .then(checkAndGetData);
  //     delete currentData[assetId][attachmentKey];
  //     if (Object.keys(currentData[assetId]).length === 0) {
  //       transaction.update(favoritePath, {
  //         [assetId]: CoreFirestore.deleteField(),
  //       });
  //     } else {
  //       transaction.update(favoritePath, {
  //         [assetId]: currentData[assetId],
  //       });
  //     }
  //   });
  // }

  // async listFavorite(fileAssetType: FileAssetType): Promise<{
  //   [assetId: string]: string[]; // attachmentKeys
  // }> {
  //   const favoritePath =
  //     this.refs.currentRefs.getFavoriteFileDocRef(fileAssetType);
  //   const data = await CoreFirestore.getDoc(favoritePath).then(checkAndGetData);
  //   const result: { [assetId: string]: string[] } = {};
  //   Object.entries(data).map(([assetId, v]) => {
  //     result[assetId] = Object.keys(v);
  //   });
  //   return result;
  // }
}
