import { useCallback } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

export function useQueryParamsHandler() {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const onUrlUpdate = useCallback(
    (data: Record<string, string | string[]>, removedKey: string[] = []) => {
      // without removedKey, and add keyword, finally remove empty value
      const paramsEntries = [
        ...Array.from(searchParams.entries()).filter(([key, _value]) => !removedKey.includes(key)),
        ...Object.entries(data)
      ]
        .flatMap(([key, value]) => (Array.isArray(value) ? value.map((val) => [key, val]) : [[key, value]]))
        .filter(([_key, value]) => value !== '')
      const query = new URLSearchParams(paramsEntries).toString()

      router.replace({ pathname, query })
    },
    [pathname, router, searchParams]
  )

  return { onUrlUpdate }
}
