import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2012%2016%22%3E%3Cpath%20d%3D%22M1.999%200h7.202A1.998%201.998%200%200%201%2011.2%201.999v12.002A2.001%202.001%200%200%201%209.201%2016H1.999a2.003%202.003%200%200%201-1.763-1.057A1.99%201.99%200%200%201%200%2014.001V1.999A1.998%201.998%200%200%201%201.999%200ZM5.6%208.8a4%204%200%201%200%200-8%204%204%200%200%200%200%208ZM4%203.2a1.6%201.6%200%201%201%202.762%201.1A2.4%202.4%200%200%201%208%206.4v.517A3.192%203.192%200%200%201%205.6%208a3.192%203.192%200%200%201-2.4-1.083V6.4a2.4%202.4%200%200%201%201.238-2.1A1.595%201.595%200%200%201%204%203.2Zm-1.601%207.2h.002a.8.8%200%200%201%20.799.799v.002a.797.797%200%200%201-.799.799h-.002a.792.792%200%200%201-.565-.234.798.798%200%200%201-.234-.565v-.002a.798.798%200%200%201%20.799-.799Zm6.702%200H4.499a.497.497%200%200%200-.499.499v.602a.498.498%200%200%200%20.499.499h4.602a.497.497%200%200%200%20.499-.499v-.602a.498.498%200%200%200-.499-.499Zm-6.7%202.4h-.002a.8.8%200%200%200-.799.799v.002a.798.798%200%200%200%20.799.799h.002a.792.792%200%200%200%20.565-.234.798.798%200%200%200%20.234-.565v-.002a.797.797%200%200%200-.799-.799Zm2.098%200h4.602a.497.497%200%200%201%20.499.499v.602a.498.498%200%200%201-.499.499H4.499A.497.497%200%200%201%204%2013.901v-.602a.498.498%200%200%201%20.499-.499Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ContactIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 12 16'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M1.999 0h7.202A1.998 1.998 0 0 1 11.2 1.999v12.002A2.001 2.001 0 0 1 9.201 16H1.999a2.003 2.003 0 0 1-1.763-1.057A1.99 1.99 0 0 1 0 14.001V1.999A1.998 1.998 0 0 1 1.999 0ZM5.6 8.8a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM4 3.2a1.6 1.6 0 1 1 2.762 1.1A2.4 2.4 0 0 1 8 6.4v.517A3.192 3.192 0 0 1 5.6 8a3.192 3.192 0 0 1-2.4-1.083V6.4a2.4 2.4 0 0 1 1.238-2.1A1.595 1.595 0 0 1 4 3.2Zm-1.601 7.2h.002a.8.8 0 0 1 .799.799v.002a.797.797 0 0 1-.799.799h-.002a.792.792 0 0 1-.565-.234.798.798 0 0 1-.234-.565v-.002a.798.798 0 0 1 .799-.799Zm6.702 0H4.499a.497.497 0 0 0-.499.499v.602a.498.498 0 0 0 .499.499h4.602a.497.497 0 0 0 .499-.499v-.602a.498.498 0 0 0-.499-.499Zm-6.7 2.4h-.002a.8.8 0 0 0-.799.799v.002a.798.798 0 0 0 .799.799h.002a.792.792 0 0 0 .565-.234.798.798 0 0 0 .234-.565v-.002a.797.797 0 0 0-.799-.799Zm2.098 0h4.602a.497.497 0 0 1 .499.499v.602a.498.498 0 0 1-.499.499H4.499A.497.497 0 0 1 4 13.901v-.602a.498.498 0 0 1 .499-.499Z'
        }
      />
    </svg>
  )
}
