import { useId } from 'react'
import {
  useController,
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { cn } from '@/utils/classnames'
import { Input } from '@/components/base'

interface FormPercentInputProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  label?: string
  className?: string
  inputClassName?: string
  max?: number
  min?: number
  // controller props
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
  defaultValue?: FieldPathValue<Values, Path>
}

export function FormPercentInput<Value extends FieldValues, Path extends FieldPath<Value>>({
  label,
  className,
  inputClassName,
  max = 100,
  min = 0,
  control,
  name,
  rules,
  defaultValue
}: FormPercentInputProps<Value, Path>) {
  const id = useId()
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules, defaultValue })
  const { value, onChange, ref, ...fieldProps } = field
  const isRequired = rules?.required !== undefined

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn('text-xs text-[#414554]', isRequired && 'after:ml-0.5 after:text-error after:content-["*"]')}
        >
          {label}
        </label>
      )}
      <NumericFormat
        id={id}
        getInputRef={ref}
        className={cn('h-[38px] border text-right', inputClassName)}
        customInput={Input}
        value={value}
        onValueChange={({ floatValue }) => onChange(floatValue ?? 0)}
        suffix={'%'}
        isAllowed={({ floatValue }) => {
          if (floatValue === undefined) return true
          return floatValue >= min && floatValue <= max
        }}
        {...fieldProps}
      />
      {error?.message && <p className={'text-xs text-red-500'}>{error.message}</p>}
    </div>
  )
}
