import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20d%3D%22M20%203.333C10.8%203.333%203.333%2010.8%203.333%2020c0%209.2%207.467%2016.667%2016.667%2016.667%209.2%200%2016.667-7.467%2016.667-16.667C36.667%2010.8%2029.2%203.333%2020%203.333Zm1.667%2028.334h-3.334v-3.334h3.334v3.334Zm3.45-12.917-1.5%201.533c-1.2%201.217-1.95%202.217-1.95%204.717h-3.334v-.833c0-1.834.75-3.5%201.95-4.717l2.067-2.1c.617-.6.983-1.433.983-2.35%200-1.833-1.5-3.333-3.333-3.333A3.343%203.343%200%200%200%2016.667%2015h-3.334A6.665%206.665%200%200%201%2020%208.333%206.665%206.665%200%200%201%2026.667%2015c0%201.467-.6%202.8-1.55%203.75Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function SupportIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 40 40'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M20 3.333C10.8 3.333 3.333 10.8 3.333 20c0 9.2 7.467 16.667 16.667 16.667 9.2 0 16.667-7.467 16.667-16.667C36.667 10.8 29.2 3.333 20 3.333Zm1.667 28.334h-3.334v-3.334h3.334v3.334Zm3.45-12.917-1.5 1.533c-1.2 1.217-1.95 2.217-1.95 4.717h-3.334v-.833c0-1.834.75-3.5 1.95-4.717l2.067-2.1c.617-.6.983-1.433.983-2.35 0-1.833-1.5-3.333-3.333-3.333A3.343 3.343 0 0 0 16.667 15h-3.334A6.665 6.665 0 0 1 20 8.333 6.665 6.665 0 0 1 26.667 15c0 1.467-.6 2.8-1.55 3.75Z'
        }
      />
    </svg>
  )
}
