import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%2216%22%20viewBox%3D%220%200%2014%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.75%208.75H7V12.5H10.75V8.75ZM10%200.5V2H4V0.5H2.5V2H0.2575L0.25%2015.5H13.75V2H11.5V0.5H10ZM12.25%2014H1.75V5.75H12.25V14Z%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ActivityIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 14 16'}
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      {...props}
    >
      <path
        d={
          'M10.75 8.75H7V12.5H10.75V8.75ZM10 0.5V2H4V0.5H2.5V2H0.2575L0.25 15.5H13.75V2H11.5V0.5H10ZM12.25 14H1.75V5.75H12.25V14Z'
        }
      />
    </svg>
  )
}
