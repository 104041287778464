import { Group, Command, Groupable, Event, GroupItem } from "../types/groups";

import {
  AggregateRoot,
  BaseStateWriter,
  Domain,
  Repo,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import { ExchangeRate } from "./exchangeRate";
import { FullRefs, Refs } from "../refs";
import { InvalidInput } from "../types/error";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { AsyncTask, AsyncTaskExecutor } from "../types/asyncTask";
import { AssetType } from "../types/enums";
import { ArtsRepo } from "./arts";
import { BelongingsRepo } from "./belongings";
import { PropertiesRepo } from "./properties";
import { CashAndBankingRepo } from "./cashAndBanking";
import { TraditionalInvestmentRepo } from "./traditionalInvestments";
import { OtherInvestmentRepo } from "./otherInvestments";
import { InsuranceRepo } from "./insurance";
import { SummaryManager, SyncedAssetTypes } from "../types/summaryManager";
import { TypeResult } from "../types/typeVersion";
import { EncryptionManager } from "./encryption";

export class GroupsRepo {
  // Note that while groups are not supported in delegates, we are still using delegate refs for consistency.
  protected readonly refs: FullRefs;
  protected readonly encryption: EncryptionManager;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  constructor(
    exRate: ExchangeRate,
    refs: FullRefs,
    encryption: EncryptionManager,
    summaryManager: SummaryManager
  ) {
    this.exRate = exRate;
    this.refs = refs;
    this.encryption = encryption;
    this.summaryManager = summaryManager;
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    aggregateRoot: AggregateRoot<Group, Command, Event>
  ) {
    const asset = aggregateRoot.state();
    const data = aggregateRoot.relatedUpdates() as Group.RelatedUpdates;
    const repoAndAggregates: Group.RelatedAggregates = {};
    // * read
    if (data[AssetType.CashAndBanking].length > 0) {
      repoAndAggregates[AssetType.CashAndBanking] = {
        repo: await CashAndBankingRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.CashAndBanking].map(({ id, action }) =>
              CashAndBankingRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.TraditionalInvestments].length > 0) {
      repoAndAggregates[AssetType.TraditionalInvestments] = {
        repo: await TraditionalInvestmentRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.TraditionalInvestments].map(({ id, action }) =>
              TraditionalInvestmentRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.OtherInvestment].length > 0) {
      repoAndAggregates[AssetType.OtherInvestment] = {
        repo: await OtherInvestmentRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.OtherInvestment].map(({ id, action }) =>
              OtherInvestmentRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Insurance].length > 0) {
      repoAndAggregates[AssetType.Insurance] = {
        repo: await InsuranceRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: (
          await Promise.all(
            data[AssetType.Insurance].map(({ id, action }) =>
              InsuranceRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Property].length > 0) {
      repoAndAggregates[AssetType.Property] = {
        repo: await PropertiesRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: (
          await Promise.all(
            data[AssetType.Property].map(({ id, action }) =>
              PropertiesRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Art].length > 0) {
      repoAndAggregates[AssetType.Art] = {
        repo: await ArtsRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: (
          await Promise.all(
            data[AssetType.Art].map(({ id, action }) =>
              ArtsRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.OtherCollectables].length > 0) {
      repoAndAggregates[AssetType.OtherCollectables] = {
        repo: await BelongingsRepo.newRepo(
          this.refs.currentRefs,
          transaction,
          AssetType.OtherCollectables
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.OtherCollectables].map(({ id, action }) =>
              BelongingsRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                AssetType.OtherCollectables,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Belonging].length > 0) {
      repoAndAggregates[AssetType.Belonging] = {
        repo: await BelongingsRepo.newRepo(
          this.refs.currentRefs,
          transaction,
          AssetType.Belonging
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.Belonging].map(({ id, action }) =>
              BelongingsRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                AssetType.Belonging,
                asset.id,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    // * update
    for (const repoAndAggregate of Object.values(repoAndAggregates)) {
      const groupData = repoAndAggregate as Group.RelatedAggregates[Groupable];
      if (groupData) {
        groupData.aggregates.forEach((aggregate) => {
          groupData.repo.commitWithState(aggregate);
        });
      }
    }
  }

  async getAll(): Promise<Group[]> {
    const collectionRef = this.refs.currentRefs.Groups;
    const groups = (
      await CoreFirestore.getDocsFromCollection(
        collectionRef,
        CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
      ).then(getQueriedData)
    ).map((v) => Group.convertDate(v));
    return groups.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getByIds(ids: string[]): Promise<Group[]> {
    const result = await CoreFirestore.getDocsByIdsPure(
      this.refs.currentRefs.Groups,
      ids
    );
    return result.map((v) => Group.convertDate(v));
  }

  async getById(id: string): Promise<Group> {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      id
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => Group.convertDate(v));
  }

  async isGroupNameExists(groupName: string) {
    const groups = await CoreFirestore.getDocsFromCollection(
      this.refs.currentRefs.Groups,
      CoreFirestore.where("name", "==", groupName)
    );
    return !groups.empty;
  }

  async add(req: Group.CreateFields) {
    const newDocRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      req.id
    );
    Group.validate(req);
    const isGroupNameExists = await this.isGroupNameExists(req.name);
    if (isGroupNameExists) {
      throw new InvalidInput("Group name already exists");
    }

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);

      const repo = await GroupsRepo.newRepo(this.refs.currentRefs, transaction);

      const group = Group.fromCreate(req, this.refs.currentRefs.userId);
      Group.validate(group, true);

      const ar = Group.newAggregateRoot(Group.defaultStateValue());
      ar.handle(Command.createAsset(this.refs.selfRefs.userId, group));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async update(req: Group) {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      req.id
    );
    Group.validate(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction
        .get(docRef)
        .then(checkAndGetData)
        .then(Group.convertDate);
      const result = Group.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Group.handleOutDated();

      const repo = await GroupsRepo.newRepo(this.refs.currentRefs, transaction);

      const updates = Group.intoUpdate(currentData, req);
      const ar = Group.newAggregateRoot(currentData);
      ar.handle(Command.updateAsset(this.refs.selfRefs.userId, updates));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  private getAsyncRemoveTask(item: GroupItem, groupId: string) {
    switch (item.assetType) {
      case AssetType.Art:
        return ArtsRepo.removeGroup(this.refs, item.assetId, groupId);
      case AssetType.Belonging:
        return BelongingsRepo.removeGroup(
          this.refs,
          item.assetId,
          AssetType.Belonging,
          groupId
        );
      case AssetType.OtherCollectables:
        return BelongingsRepo.removeGroup(
          this.refs,
          item.assetId,
          AssetType.OtherCollectables,
          groupId
        );
      case AssetType.Property:
        return PropertiesRepo.removeGroup(this.refs, item.assetId, groupId);
      case AssetType.CashAndBanking:
        return CashAndBankingRepo.removeGroup(this.refs, item.assetId, groupId);
      case AssetType.TraditionalInvestments:
        return TraditionalInvestmentRepo.removeGroup(
          this.refs,
          item.assetId,
          groupId
        );
      case AssetType.OtherInvestment:
        return OtherInvestmentRepo.removeGroup(
          this.refs,
          item.assetId,
          groupId
        );
      case AssetType.Insurance:
        return InsuranceRepo.removeGroup(this.refs, item.assetId, groupId);
    }
  }

  private doDelete(docRef: DocumentReference<Group>, id: string) {
    return AsyncTask.retry(async () => {
      const result = await CoreFirestore.runTransaction(async (transaction) => {
        const currentData = await transaction.get(docRef).then(checkAndGetData);
        const result = Group.assureVersion(currentData);
        if (result === TypeResult.DataOutDated) Group.handleOutDated();
        const items = (await this.getById(id)).items;
        if (items.length > 0) {
          return items;
        } else {
          const repo = await GroupsRepo.newRepo(
            this.refs.currentRefs,
            transaction
          );
          const ar = Group.newAggregateRoot(currentData);
          ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
          const events = ar.applyAllChanges();
          //commit
          repo.manualCommit(ar, events);
          return undefined;
        }
      });

      if (result) {
        for (const item of result) {
          await this.getAsyncRemoveTask(item, id);
        }
        return true;
      } else {
        return false;
      }
    });
  }

  async delete(id: string) {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      id
    );
    const items = (await this.getById(id)).items;
    const tasks = items.reduce((tasks, item) => {
      tasks.push(AsyncTask.once(() => this.getAsyncRemoveTask(item, id)));
      return tasks;
    }, [] as AsyncTask[]);

    return new AsyncTaskExecutor([...tasks, this.doDelete(docRef, id)]);
  }
}

export namespace GroupsRepo {
  export async function newRepo(
    refs: Refs,
    transaction: Transaction
  ): Promise<Repo<Group, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      Domain.Groups,
      new BaseStateWriter(refs.Groups)
    );
  }

  export async function newArAndUpdateGroupItem(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    groupId: string,
    assetId: string,
    assetType: Groupable,
    subtype: string,
    isCreate: boolean = true
  ) {
    const docRef = CoreFirestore.docFromCollection(refs.Groups, groupId);
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = Group.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) Group.handleOutDated();
    const ar = Group.newAggregateRoot(currentData);
    ar.handle(
      isCreate
        ? Command.addItem(executerId, { assetId, assetType, subtype })
        : Command.removeItem(executerId, assetId, assetType, subtype)
    );
    return ar;
  }
}
