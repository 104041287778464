import {
  IsDate,
  Managed,
  NotEncrypted,
  IsReferenceId,
  IsShortString,
  UpdateNotAllowed,
  IsOptionalOnUpdate,
} from "../../decorators";

export class DatabaseDocument {
  @NotEncrypted
  @UpdateNotAllowed
  @IsOptionalOnUpdate()
  @IsReferenceId()
  id!: string;

  @NotEncrypted
  @Managed
  @UpdateNotAllowed
  @IsOptionalOnUpdate()
  @IsShortString()
  ownerId!: string;

  @NotEncrypted
  @Managed
  @UpdateNotAllowed
  @IsShortString()
  "@type": string;

  @NotEncrypted
  @Managed
  @UpdateNotAllowed
  @IsOptionalOnUpdate()
  @IsDate()
  createAt!: Date;

  @NotEncrypted
  @Managed
  @IsOptionalOnUpdate()
  @IsDate()
  updateAt!: Date;

}
