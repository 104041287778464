import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { AssetType } from 'core/remodel/types/common'
import { useAuthStore } from '@/store/authStore'

const siteConfig = {
  name: 'MyAssets',
  description: 'The Best Life Organizational Tool'
}

export function usePageInfo() {
  const router = useRouter()
  const { t, ready } = useTranslation()
  const tracePageView = useAuthStore((state) => state.tracePageView)

  const generatePageName = useMemo(() => {
    return (type: AssetType, Action: string) => {
      switch (type) {
        case AssetType.CashAndBanking:
          return (
            t('finances:PageTabs.MyFinances') + ' ' + t('finances:PageTabs.CashAndBanking') + (Action && ' ' + Action)
          )
        case AssetType.TraditionalInvestments:
          return (
            t('finances:PageTabs.MyFinances') +
            ' ' +
            t('finances:PageTabs.TraditionalInvestments') +
            (Action && ' ' + Action)
          )
        case AssetType.OtherInvestment:
          return (
            t('finances:PageTabs.MyFinances') + ' ' + t('finances:PageTabs.OtherInvestments') + (Action && ' ' + Action)
          )
        case AssetType.Cryptocurrency:
          return (
            t('finances:PageTabs.MyFinances') + ' ' + t('finances:PageTabs.Cryptocurrencies') + (Action && ' ' + Action)
          )
        case AssetType.Insurance:
          return t('finances:PageTabs.MyFinances') + ' ' + t('finances:PageTabs.Insurances') + (Action && ' ' + Action)
        case AssetType.Property:
          return t('properties:PageTabs.MyProperties') + (Action && ' ' + Action)
        case AssetType.Art:
          return (
            t('collectables:PageTabs.MyCollectables') + ' ' + t('collectables:PageTabs.Art') + (Action && ' ' + Action)
          )
        case AssetType.WineAndSpirits:
          return (
            t('collectables:PageTabs.MyCollectables') +
            ' ' +
            t('collectables:PageTabs.WineAndSpirits') +
            (Action && ' ' + Action)
          )
        case AssetType.OtherCollectables:
          return (
            t('collectables:PageTabs.MyCollectables') +
            ' ' +
            t('collectables:PageTabs.OtherCollectables') +
            (Action && ' ' + Action)
          )
        case AssetType.Belonging:
          return t('belongings:PageTabs.MyBelongings') + (Action && ' ' + Action)
        default:
          return ''
      }
    }
  }, [t])

  const pageMap: Record<string, string> = useMemo(() => {
    return {
      // root
      '/': t('nav:dashboard'),
      // error
      '/_error': t('NotFound'),
      // auth
      '/auth/proxy': t('nav:auth.authProxy'),
      '/auth/login': t('nav:auth.login'),
      '/auth/two-factor/sms': t('nav:auth.login'),
      '/auth/two-factor/backup-codes': t('nav:auth.login'),
      '/auth/close-account': t('nav:auth.login'),
      '/auth/signup': t('nav:auth.signUp'),
      '/auth/verify-email': t('nav:auth.signUp'),
      '/auth/verify-fail': t('auth:VerifyFailed'),
      '/auth/reset': t('nav:auth.reset'),
      '/auth/forgot': t('nav:auth.forgot'),
      // account
      '/account/profile': t('nav:account.profile'),
      '/account/profile/personal': t('nav:account.profile') + ' ' + t('common:pageTabAction.personal'),
      '/account/profile/preferences': t('nav:account.profile') + ' ' + t('common:pageTabAction.preferences'),
      '/account/profile/recovery': t('nav:account.profile') + ' ' + t('common:pageTabAction.recovery'),
      '/account/profile/backup-codes': t('nav:account.profile') + ' ' + t('common:pageTabAction.backup-codes'),
      '/account/profile/reset-password': t('nav:account.profile') + ' ' + t('common:pageTabAction.reset-password'),
      '/account/profile/close-account': t('nav:account.profile') + ' ' + t('common:pageTabAction.close-account'),
      '/account/identity': t('nav:account.identity'),
      '/account/contacts': t('nav:account.contacts'),
      '/account/contacts/create': t('nav:account.contacts') + ' ' + t('common:pageTabAction.create'),
      '/account/contacts/delete': t('nav:account.contacts') + ' ' + t('common:pageTabAction.delete'),
      '/account/contacts/edit': t('nav:account.contacts') + ' ' + t('common:pageTabAction.edit'),
      '/account/contacts/info': t('nav:account.contacts') + ' ' + t('common:pageTabAction.info'),
      '/account/delegates': t('nav:account.delegates'),
      '/account/delegates/create': t('nav:account.delegates') + ' ' + t('common:pageTabAction.create'),
      '/account/delegates/edit': t('nav:account.delegates') + ' ' + t('common:pageTabAction.edit'),
      '/account/delegates/revoke': t('nav:account.delegates') + ' ' + t('common:pageTabAction.revoke'),
      '/account/delegates/delete': t('nav:account.delegates') + ' ' + t('common:pageTabAction.delete'),
      '/account/subscription': t('nav:account.subscription'),
      '/account/subscription/cancel': t('nav:account.subscription') + ' ' + t('common:pageTabAction.cancel'),
      '/account/subscription/manage': t('nav:account.subscription') + ' ' + t('common:pageTabAction.manage'),
      '/account/subscription/payments-proxy':
        t('nav:account.subscription') + ' ' + t('common:pageTabAction.payments-proxy'),
      // finances
      '/finances': t('finances:PageTabs.MyFinances'),
      '/finances/summary': t('finances:PageTabs.MyFinances') + ' ' + t('common:pageTabAction.summary'),
      '/finances/accounts': generatePageName(AssetType.CashAndBanking, ''),
      '/finances/accounts/info': generatePageName(AssetType.CashAndBanking, t('common:pageTabAction.info')),
      '/finances/accounts/edit': generatePageName(AssetType.CashAndBanking, t('common:pageTabAction.edit')),
      '/finances/accounts/create': generatePageName(AssetType.CashAndBanking, t('common:pageTabAction.create')),
      '/finances/accounts/delete': generatePageName(AssetType.CashAndBanking, t('common:pageTabAction.delete')),
      '/finances/accounts/plaid-proxy': generatePageName(AssetType.CashAndBanking, t('common:pageTabAction.list')),
      '/finances/tradit-invest': generatePageName(AssetType.TraditionalInvestments, ''),
      '/finances/tradit-invest/info': generatePageName(
        AssetType.TraditionalInvestments,
        t('common:pageTabAction.info')
      ),
      '/finances/tradit-invest/edit': generatePageName(
        AssetType.TraditionalInvestments,
        t('common:pageTabAction.edit')
      ),
      '/finances/tradit-invest/create': generatePageName(
        AssetType.TraditionalInvestments,
        t('common:pageTabAction.create')
      ),
      '/finances/tradit-invest/delete': generatePageName(
        AssetType.TraditionalInvestments,
        t('common:pageTabAction.delete')
      ),
      '/finances/tradit-invest/transaction': generatePageName(
        AssetType.TraditionalInvestments,
        t('common:pageTabAction.list')
      ),
      '/finances/other-invest': generatePageName(AssetType.OtherInvestment, ''),
      '/finances/other-invest/info': generatePageName(AssetType.OtherInvestment, t('common:pageTabAction.info')),
      '/finances/other-invest/edit': generatePageName(AssetType.OtherInvestment, t('common:pageTabAction.edit')),
      '/finances/other-invest/create': generatePageName(AssetType.OtherInvestment, t('common:pageTabAction.create')),
      '/finances/other-invest/delete': generatePageName(AssetType.OtherInvestment, t('common:pageTabAction.delete')),
      '/finances/crypto': generatePageName(AssetType.Cryptocurrency, ''),
      '/finances/crypto/create': generatePageName(AssetType.Cryptocurrency, t('common:pageTabAction.create')),
      '/finances/crypto/delete': generatePageName(AssetType.Cryptocurrency, t('common:pageTabAction.delete')),
      '/finances/crypto/transaction': generatePageName(AssetType.Cryptocurrency, t('common:pageTabAction.list')),
      '/finances/insurance': generatePageName(AssetType.Insurance, ''),
      '/finances/insurance/info': generatePageName(AssetType.Insurance, t('common:pageTabAction.info')),
      '/finances/insurance/edit': generatePageName(AssetType.Insurance, t('common:pageTabAction.edit')),
      '/finances/insurance/create': generatePageName(AssetType.Insurance, t('common:pageTabAction.create')),
      '/finances/insurance/delete': generatePageName(AssetType.Insurance, t('common:pageTabAction.delete')),
      // properties
      '/properties': generatePageName(AssetType.Property, ''),
      '/properties/summary': generatePageName(AssetType.Property, '') + ' ' + t('common:pageTabAction.summary'),
      '/properties/summary/list': generatePageName(AssetType.Property, t('common:pageTabAction.list')),
      '/properties/summary/info': generatePageName(AssetType.Property, t('common:pageTabAction.info')),
      '/properties/summary/edit': generatePageName(AssetType.Property, t('common:pageTabAction.edit')),
      '/properties/summary/edit-assets': generatePageName(AssetType.Property, t('common:pageTabAction.edit')),
      '/properties/summary/create': generatePageName(AssetType.Property, t('common:pageTabAction.create')),
      '/properties/summary/delete': generatePageName(AssetType.Property, t('common:pageTabAction.delete')),
      // collectables
      '/collectables': t('collectables:PageTabs.MyCollectables'),
      '/collectables/summary': t('collectables:PageTabs.MyCollectables') + ' ' + t('common:pageTabAction.summary'),
      '/collectables/art': generatePageName(AssetType.Art, ''),
      '/collectables/art/list': generatePageName(AssetType.Art, t('common:pageTabAction.list')),
      '/collectables/art/info': generatePageName(AssetType.Art, t('common:pageTabAction.info')),
      '/collectables/art/edit': generatePageName(AssetType.Art, t('common:pageTabAction.edit')),
      '/collectables/art/create': generatePageName(AssetType.Art, t('common:pageTabAction.create')),
      '/collectables/art/delete': generatePageName(AssetType.Art, t('common:pageTabAction.delete')),
      '/collectables/other': generatePageName(AssetType.OtherCollectables, ''),
      '/collectables/other/list': generatePageName(AssetType.OtherCollectables, t('common:pageTabAction.list')),
      '/collectables/other/info': generatePageName(AssetType.OtherCollectables, t('common:pageTabAction.info')),
      '/collectables/other/edit': generatePageName(AssetType.OtherCollectables, t('common:pageTabAction.edit')),
      '/collectables/other/create': generatePageName(AssetType.OtherCollectables, t('common:pageTabAction.create')),
      '/collectables/other/delete': generatePageName(AssetType.OtherCollectables, t('common:pageTabAction.delete')),
      '/collectables/wine': generatePageName(AssetType.WineAndSpirits, ''),
      '/collectables/wine/list': generatePageName(AssetType.WineAndSpirits, t('common:pageTabAction.list')),
      '/collectables/wine/info': generatePageName(AssetType.WineAndSpirits, t('common:pageTabAction.info')),
      '/collectables/wine/create': generatePageName(AssetType.WineAndSpirits, t('common:pageTabAction.create')),
      '/collectables/wine/delete': generatePageName(AssetType.WineAndSpirits, t('common:pageTabAction.delete')),
      '/collectables/wine/add-purchase': generatePageName(
        AssetType.WineAndSpirits,
        t('common:pageTabAction.add-purchase')
      ),
      '/collectables/wine/catalogue': generatePageName(AssetType.WineAndSpirits, t('common:pageTabAction.catalogue')),
      '/collectables/wine/reports': generatePageName(AssetType.WineAndSpirits, t('common:pageTabAction.reports')),
      '/collectables/wine/location-details': generatePageName(
        AssetType.WineAndSpirits,
        t('common:pageTabAction.location-details')
      ),
      // belongings
      '/belongings': generatePageName(AssetType.Belonging, ''),
      '/belongings/summary': generatePageName(AssetType.Belonging, '') + ' ' + t('common:pageTabAction.summary'),
      '/belongings/summary/list': generatePageName(AssetType.Belonging, t('common:pageTabAction.list')),
      '/belongings/summary/info': generatePageName(AssetType.Belonging, t('common:pageTabAction.info')),
      '/belongings/summary/edit': generatePageName(AssetType.Belonging, t('common:pageTabAction.edit')),
      '/belongings/summary/create': generatePageName(AssetType.Belonging, t('common:pageTabAction.create')),
      '/belongings/summary/delete': generatePageName(AssetType.Belonging, t('common:pageTabAction.delete')),
      // groups
      '/groups': t('nav:groups'),
      '/groups/summary': t('nav:groups') + ' ' + t('common:pageTabAction.summary'),
      '/groups/summary/info': t('nav:groups') + ' ' + t('common:pageTabAction.info'),
      '/groups/summary/edit': t('nav:groups') + ' ' + t('common:pageTabAction.edit'),
      '/groups/summary/create': t('nav:groups') + ' ' + t('common:pageTabAction.create'),
      '/groups/summary/delete': t('nav:groups') + ' ' + t('common:pageTabAction.delete'),
      // documents
      '/documents': t('nav:documents'),
      '/documents/summary': t('nav:documents') + ' ' + t('common:pageTabAction.summary'),
      '/documents/summary/create': t('nav:documents') + ' ' + t('common:pageTabAction.create'),
      '/documents/summary/log': t('nav:documents') + ' ' + t('common:pageTabAction.log'),
      // wishlist
      '/wishlist': t('nav:wishlist'),
      '/wishlist/info': t('nav:wishlist') + ' ' + t('common:pageTabAction.info'),
      '/wishlist/edit': t('nav:wishlist') + ' ' + t('common:pageTabAction.edit'),
      '/wishlist/create': t('nav:wishlist') + ' ' + t('common:pageTabAction.create'),
      '/wishlist/delete': t('nav:wishlist') + ' ' + t('common:pageTabAction.delete'),
      // setup
      '/setup': t('nav:setup'),
      '/setup/invitation': t('nav:setup'),
      '/setup/identity': t('nav:setup'),
      '/setup/plan': t('nav:setup')
    }
  }, [generatePageName, t])
  const title: string = useMemo(() => {
    try {
      if (!ready) throw new Error('i18n not ready')
      const pageName = pageMap[router.pathname]
      if (!pageName) throw new Error(`not matched name '${router.pathname}'`)

      return router.pathname !== '/_error' ? `${pageName} - ${siteConfig.name}` : pageName
    } catch (e) {
      e instanceof Error && console.warn(e.message)
      return siteConfig.name
    }
  }, [ready, pageMap, router])

  useEffect(() => {
    // Track the initial page load
    tracePageView(window.location.pathname)

    // Subscribe to route changes
    router.events.on('routeChangeComplete', tracePageView)

    // Clean up the subscription on unmount
    return () => router.events.off('routeChangeComplete', tracePageView)
  }, [router.events, tracePageView])

  return { title, desc: siteConfig.description }
}