import { ReCaptchaProvider } from "next-recaptcha-v3";


interface ReCaptchaProps {
  children?: React.ReactNode | React.ReactNode[] | undefined
}


export function ReCaptcha({ children }: ReCaptchaProps) {

  const isDev = process.env.NEXT_PUBLIC_ENV === 'development'
  if (isDev) {
    return children
  }

  return <ReCaptchaProvider useEnterprise={true} reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY_WEB}>{children}</ReCaptchaProvider>
}
