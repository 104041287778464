import * as React from 'react'
import { useEffect, useState } from 'react'
import { AlertCircleIcon, Loader2Icon } from 'lucide-react'
import {
  useController,
  useWatch,
  type Control,
  type FieldPath,
  type FieldValues,
  type PathValue,
  type RegisterOptions
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelectPrimitive, { type ActionMeta, type GroupBase, type Props, type SelectInstance } from 'react-select'
import CreatableSelectPrimitive, { type CreatableProps } from 'react-select/creatable'

import { cn } from '@/utils/classnames'
import { toast } from '@/hooks/useToast'
import { XIcon } from '@/components/icon'

export const autocompleteClassNames = {
  control: ({ isFocused, isDisabled }: { isFocused: boolean; isDisabled: boolean }) =>
    cn(
      'min-h-9 rounded border bg-grey-input hover:cursor-text',
      isFocused && 'border-primary',
      isDisabled && 'pointer-events-auto opacity-50 hover:cursor-not-allowed'
    ),
  placeholder: () => cn('text-sm font-light text-text-placeholder'),
  input: () => cn('text-sm'),
  valueContainer: () => cn('gap-1 px-3'),
  singleValue: () => cn('text-sm'),
  multiValue: () => cn('items-center gap-x-1 rounded-md bg-primary py-0.5 pl-2 pr-1'),
  multiValueLabel: () => cn('text-sm text-white'),
  multiValueRemove: () => cn('border-l border-gray-200 pl-0.5 text-white'),
  indicatorsContainer: () => cn('gap-1 p-1'),
  indicatorsSeparator: () => cn('bg-gray-300'),
  clearIndicator: () => cn('rounded-md p-1 text-gray-500 hover:bg-red-50 hover:text-red-800'),
  dropdownIndicator: () => cn('rounded-md p-1 text-gray-500 hover:bg-gray-100 hover:text-black'),
  menu: () => cn('my-1 rounded-md border border-gray-200 bg-white py-1 shadow-md'),
  groupHeading: () => cn('mb-1 ml-3 mt-2 text-sm text-gray-500'),
  option: ({ isSelected, isFocused }: { isSelected: boolean; isFocused: boolean }) =>
    cn('px-4 py-2 hover:cursor-pointer', { 'bg-grey/25': isSelected, 'bg-primary text-white': isFocused }),
  noOptionsMessage: () => cn('whitespace-nowrap rounded-sm py-4 text-grey')
}

type AutocompleteProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = Props<
  Option,
  IsMulti,
  Group
> & {
  innerRef?: React.Ref<SelectInstance<Option, IsMulti, Group>>
}

export function Autocomplete<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  unstyled,
  classNames,
  innerRef,
  ...props
}: AutocompleteProps<Option, IsMulti, Group>) {
  // HACK handle the allocation asset type of the loan
  const [timestamp, setTimestamp] = useState<string>(`${new Date().getTime()}`)

  useEffect(() => {
    if (!props.value) setTimestamp(`${new Date().getTime()}`)
  }, [props.value])

  return (
    <SelectPrimitive
      key={timestamp}
      ref={innerRef}
      unstyled={true}
      classNames={autocompleteClassNames}
      menuPosition={'fixed'}
      maxMenuHeight={200}
      {...props}
    />
  )
}

type CreatableAutocompleteProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = CreatableProps<
  Option,
  IsMulti,
  Group
> & {
  innerRef?: React.Ref<SelectInstance<Option, IsMulti, Group>>
}

export function CreatableAutocomplete<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  unstyled,
  classNames,
  innerRef,
  ...props
}: CreatableAutocompleteProps<Option, IsMulti, Group>) {
  return (
    <CreatableSelectPrimitive
      ref={innerRef}
      unstyled={true}
      classNames={autocompleteClassNames}
      menuPosition={'fixed'}
      createOptionPosition={'first'}
      maxMenuHeight={200}
      {...props}
    />
  )
}

interface FormAutocompleteProps<
  Values extends FieldValues,
  Path extends FieldPath<Values>,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> extends AutocompleteProps<Option, IsMulti, Group> {
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
  label?: string
  defaultValue?: PathValue<Values, Path>
  disabled?: boolean
  onCancel?: () => void
  onChanged?: (newValue: any) => void
  onFocus?: () => void
}

export function FormAutocomplete<
  Values extends FieldValues,
  Path extends FieldPath<Values>,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  control,
  name,
  rules,
  label,
  className,
  defaultValue,
  disabled,
  onCancel,
  onChanged,
  onFocus,
  ...props
}: FormAutocompleteProps<Values, Path, Option, IsMulti, Group>) {
  const id = React.useId()
  const { t } = useTranslation()
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules, defaultValue })
  const value = useWatch({ control, name })
  const { value: _, onChange, ref, ...fieldProps } = field
  const isRequired = rules?.required !== undefined

  const displayValue = props.isMulti
    ? (props.options?.filter((option: any) => value?.includes(option.value)) as Option[] | undefined)
    : (props.options?.find((option: any) => value === option.value) as Option | undefined)

  const handleChange = (newValue: any) => {
    if (props.isMulti) {
      onChange(newValue.map((option: any) => option.value))
    } else {
      onChange(newValue.value)
    }
    onChanged?.(newValue.value)
  }

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      {label && (
        <label
          className={cn('text-xs text-[#414554]', { 'after:ml-0.5 after:text-error after:content-["*"]': isRequired })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className={'relative'}>
        <Autocomplete
          innerRef={ref}
          inputId={id}
          instanceId={id}
          components={{ IndicatorsContainer: () => null }}
          value={displayValue ?? null}
          onChange={handleChange}
          noOptionsMessage={() => t('NoResultsFound')}
          isDisabled={disabled}
          onFocus={onFocus}
          maxMenuHeight={typeof window !== 'undefined' && window.innerWidth > 768 ? 200 : 120}
          pageSize={3}
          {...fieldProps}
          {...props}
        />
        {error && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
        {value && onCancel && (
          <XIcon
            className={cn('absolute right-0 top-0.5 mx-2 my-1.5 cursor-pointer text-primary', error && 'right-6')}
            onClick={onCancel}
          />
        )}
      </div>
      {error?.message && <p className={'text-xs text-red-500'}>{error.message}</p>}
    </div>
  )
}

interface FormCreatableAutocompleteProps<
  Values extends FieldValues,
  Path extends FieldPath<Values>,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> extends CreatableAutocompleteProps<Option, IsMulti, Group> {
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
  label?: string
  errorClassName?: string
  onCreate: (value: string) => Promise<string>
  onChanged?: () => void
}

export function FormCreatableAutocomplete<
  Values extends FieldValues,
  Path extends FieldPath<Values>,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  control,
  name,
  rules,
  label,
  className,
  options = [],
  isDisabled,
  errorClassName,
  onCreate,
  onChanged,
  ...props
}: FormCreatableAutocompleteProps<Values, Path, Option, IsMulti, Group>) {
  const id = React.useId()
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const { t } = useTranslation()
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules })
  const value = useWatch({ control, name })
  const { value: _value, onChange, ref, ...fieldProps } = field
  const isRequired = rules?.required !== undefined
  const hasError = error !== undefined

  const allOptionsRef = React.useRef<Option[]>([])
  const displayValueRef = React.useRef<Option | Option[] | undefined>(undefined)

  const tempValue = props.isMulti
    ? (options as Option[]).filter((option: any) => value.includes(option.value))
    : (options as Option[]).find((option: any) => value === option.value)

  if (!tempValue) {
    displayValueRef.current = undefined
  }

  allOptionsRef.current = options as Option[]
  const allOptions = allOptionsRef.current
  const displayValue = displayValueRef.current ?? tempValue ?? null

  const handleChange = async (newValue: any, actionMeta: ActionMeta<any>) => {
    if ([newValue, actionMeta.option?.value].some((v) => /<[^>]*>/.test(v))) {
      toast({ variant: 'error', description: t('Toast.Error.InvalidInput') })
      return
    }
    if (actionMeta.action === 'create-option') {
      const fallbackValue = displayValueRef.current
      displayValueRef.current = newValue
      try {
        setIsCreating(true)
        const id = await onCreate(actionMeta.option.value)
        allOptionsRef.current = [...allOptionsRef.current, { label: actionMeta.option.value, value: id } as Option]
        onChange(id)
      } catch (e) {
        displayValueRef.current = fallbackValue
        console.error(e)
      } finally {
        setIsCreating(false)
      }
      return
    } else {
      displayValueRef.current = newValue
      onChange(props.isMulti ? newValue.map((v: any) => v.value) : newValue.value)
    }
    onChanged?.()
  }

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      {label && (
        <label
          className={cn('text-xs text-[#414554]', { 'after:ml-0.5 after:text-error after:content-["*"]': isRequired })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className={'relative'}>
        <CreatableAutocomplete
          innerRef={ref}
          inputId={id}
          instanceId={id}
          value={displayValue}
          onChange={handleChange}
          components={{ IndicatorsContainer: () => null }}
          options={allOptions}
          noOptionsMessage={() => t('NoResultsFound')}
          isDisabled={isCreating || isDisabled}
          {...fieldProps}
          {...props}
        />
        {hasError && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
        {isCreating && <Loader2Icon className={'absolute right-0 top-0.5 mx-2 my-1.5 animate-spin text-primary'} />}
      </div>
      {hasError && <p className={cn('text-xs text-red-500', errorClassName)}>{error.message}</p>}
    </div>
  )
}
