import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2036%2041%22%3E%3Cpath%20d%3D%22M1.437%202.287h-.95v38.096h.95V2.287Z%22%2F%3E%3Cpath%20d%3D%22M35.513%2039.91H.487v1h35.026v-1ZM10.72%2022.356H5.16V36.17h5.56V22.356Zm7.593-3.738h-5.561V36.17h5.56V18.617ZM33.5%2013.341h-5.562V36.17h5.561V13.34Zm-7.594%2012.665h-5.561V36.17h5.56V26.006Zm-2.82-8.726a1.694%201.694%200%201%200%200-3.388%201.694%201.694%200%200%200%200%203.389Zm7.632-12.982a1.694%201.694%200%201%200%200-3.389%201.694%201.694%200%200%200%200%203.389ZM15.533%208.732a1.694%201.694%200%201%200%200-3.388%201.694%201.694%200%200%200%200%203.388Zm-7.907%204.433a1.694%201.694%200%201%200%200-3.389%201.694%201.694%200%200%200%200%203.389Z%22%2F%3E%3Cpath%20d%3D%22m15.593%206.646-8%204.62.378.654%208-4.619-.378-.655Z%22%2F%3E%3Cpath%20d%3D%22m15.55%206.809.259.448-7.794%204.5-.259-.449%207.794-4.5Zm.088-.326-.207.12-7.794%204.5-.207.119.12.206.259.448.121.207.206-.12%207.796-4.5.206-.118-.123-.202-.26-.449-.117-.206v-.005ZM30.11%202.73l-7.02%2012.738.662.365%207.02-12.737-.663-.365Z%22%2F%3E%3Cpath%20d%3D%22m30.157%202.894.454.248-6.906%2012.53-.453-.248%206.905-12.53Zm-.093-.323-.115.208-6.905%2012.528-.116.21.209.115.454.25.208.115.115-.208%206.904-12.53.116-.21-.209-.113-.453-.25-.208-.115ZM15.943%206.942l-.58.485%207.407%208.861.58-.484-7.407-8.862Z%22%2F%3E%3Cpath%20d%3D%22m15.932%207.11%207.251%208.68-.397.332-7.252-8.68.398-.332Zm.028-.34-.187.153-.397.332-.186.153.153.186%207.252%208.681.153.186.186-.152.398-.333.186-.152-.152-.186-7.254-8.681-.152-.186Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FinanceIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 36 41'}
      width={size}
      height={size}
      {...props}
    >
      <path d={'M1.437 2.287h-.95v38.096h.95V2.287Z'} />
      <path
        d={
          'M35.513 39.91H.487v1h35.026v-1ZM10.72 22.356H5.16V36.17h5.56V22.356Zm7.593-3.738h-5.561V36.17h5.56V18.617ZM33.5 13.341h-5.562V36.17h5.561V13.34Zm-7.594 12.665h-5.561V36.17h5.56V26.006Zm-2.82-8.726a1.694 1.694 0 1 0 0-3.388 1.694 1.694 0 0 0 0 3.389Zm7.632-12.982a1.694 1.694 0 1 0 0-3.389 1.694 1.694 0 0 0 0 3.389ZM15.533 8.732a1.694 1.694 0 1 0 0-3.388 1.694 1.694 0 0 0 0 3.388Zm-7.907 4.433a1.694 1.694 0 1 0 0-3.389 1.694 1.694 0 0 0 0 3.389Z'
        }
      />
      <path d={'m15.593 6.646-8 4.62.378.654 8-4.619-.378-.655Z'} />
      <path
        d={
          'm15.55 6.809.259.448-7.794 4.5-.259-.449 7.794-4.5Zm.088-.326-.207.12-7.794 4.5-.207.119.12.206.259.448.121.207.206-.12 7.796-4.5.206-.118-.123-.202-.26-.449-.117-.206v-.005ZM30.11 2.73l-7.02 12.738.662.365 7.02-12.737-.663-.365Z'
        }
      />
      <path
        d={
          'm30.157 2.894.454.248-6.906 12.53-.453-.248 6.905-12.53Zm-.093-.323-.115.208-6.905 12.528-.116.21.209.115.454.25.208.115.115-.208 6.904-12.53.116-.21-.209-.113-.453-.25-.208-.115ZM15.943 6.942l-.58.485 7.407 8.861.58-.484-7.407-8.862Z'
        }
      />
      <path
        d={
          'm15.932 7.11 7.251 8.68-.397.332-7.252-8.68.398-.332Zm.028-.34-.187.153-.397.332-.186.153.153.186 7.252 8.681.153.186.186-.152.398-.333.186-.152-.152-.186-7.254-8.681-.152-.186Z'
        }
      />
    </svg>
  )
}
