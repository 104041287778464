import { Amount, AssetType, TargetCurrencyExchangeRateDataMap } from "./common";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import {
  Loan,
  Event as OIEvent,
  OtherInvestment,
  PercentOfCompany,
} from "./otherInvestments";
import { EventEnvelope } from "./event";
import Decimal from "decimal.js";
import { AllowedDecimalPlaces, calculatePercentage } from "../utils";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  OtherInvestmentSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<OIEvent>;
export interface OtherInvestmentSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.OtherInvestmentSummary, 2>;
  id: AssetType.OtherInvestment;
  items: {
    [id: string]: Pick<OtherInvestment.Encrypted, "name" | "value"> & {
      ownedPercentage: number;
      purchasePrice: Amount;
    };
  };
}
export namespace OtherInvestmentSummary {
  export function assureVersion(
    input: OtherInvestmentSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      OtherInvestmentSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;

    items: { name: string; value: Amount; percentage: number }[];
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<OtherInvestmentSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new OtherInvestmentSummaryAggregate(summary));
  }

  export function defaultValue(): OtherInvestmentSummary {
    return {
      "@type": OtherInvestmentSummaryTypeVersion,
      id: AssetType.OtherInvestment,
      items: {},
      version: 0,
    };
  }

  export async function toDisplay(
    input: OtherInvestmentSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Promise<Display> {
    const currency = exchangeRate.targetCurrency;
    let totalAssets = new Decimal(0);
    const items: Display["items"] = [];
    for (const item of Object.values(input.items)) {
      const value = new Decimal(item.value.value)
        .mul(exchangeRate.rates[item.value.currency].rate)
        .mul(item.ownedPercentage)
        .div(100)
        .toDecimalPlaces(AllowedDecimalPlaces);
      totalAssets = totalAssets.add(value);
      items.push({
        name: item.name,
        value: {
          currency,
          value: value.toNumber(),
        },
        percentage: 0,
      });
    }
    items.forEach((item) => {
      item.percentage = calculatePercentage(item.value.value, totalAssets);
    });
    return {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: 0,
      },
      netValue: {
        currency,
        value: totalAssets.toNumber(),
      },
      items,
    };
  }

  export type GlobalDashboardData = Amount;
  export function toGlobalDashboardData(
    input: OtherInvestmentSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): GlobalDashboardData {
    const currency = exchangeRate.targetCurrency;
    const value = Object.values(input.items)
      .reduce((sum, item) => {
        return new Decimal(item.value.value)
          .mul(item.ownedPercentage)
          .div(100)
          .mul(exchangeRate.rates[item.value.currency].rate)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .add(sum);
      }, new Decimal(0))
      .toNumber();
    return {
      currency,
      value,
    };
  }

  export type RelatedUpdates = never;
}

export class OtherInvestmentSummaryAggregate extends AggregateBase<
  OtherInvestmentSummary,
  never,
  OIEvent
> {
  state: OtherInvestmentSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: OtherInvestmentSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case OIEvent.Kind.AssetCreated: {
        const asset = event.data.asset;
        this.state.items[event.aggregateId] = {
          name: asset.name,
          value: asset.value,
          ownedPercentage: asset.ownership?.myOwnership || 100,
          // #NOTE beside loan use initialAmount, other subtypes use investmentAmount
          purchasePrice:
            (<OtherInvestment.Encrypted<Loan>>asset).initialAmount ||
            (<OtherInvestment.Encrypted<PercentOfCompany>>asset)
              .investmentAmount ||
            Amount.defaultValue(),
        };
        break;
      }
      case OIEvent.Kind.AssetUpdated: {
        const updates = event.data.asset;
        const item = this.state.items[event.aggregateId];
        if (updates.name) item.name = updates.name;
        if (updates.value) item.value = updates.value;
        if (updates.ownership)
          item.ownedPercentage = updates.ownership.myOwnership || 100;
        break;
      }
      case OIEvent.Kind.AssetDeleted:
        delete this.state.items[event.aggregateId];
        break;
      case OIEvent.Kind.ValueUpdated:
        this.state.items[event.aggregateId].value = event.data.current;
        break;
    }
    return this;
  }
}
