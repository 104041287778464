import type { SVGBaseProps } from '@/types/common'

export default function TimeoutIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg height={'156px'} viewBox={'0 0 156 156'} width={'156px'} {...props}>
      <defs>
        <linearGradient id={'gradient_1'} x1={'-0.330284983'} x2={'1.10516107'} y1={'-0.178741157'} y2={'1.1014148'}>
          <stop offset={'0'} stopColor={'#EAC140'} />
          <stop offset={'1'} stopColor={'#D18E1E'} />
        </linearGradient>
        <filter colorInterpolationFilters={'sRGB'} filterUnits={'userSpaceOnUse'} id={'filter_1'}>
          <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
          <feBlend in={'SourceGraphic'} in2={'BackgroundImageFix'} mode={'normal'} result={'shape'} />
          <feColorMatrix
            in={'SourceAlpha'}
            result={'hardAlpha'}
            type={'matrix'}
            values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
          />
          <feOffset dx={'0'} dy={'3'} />
          <feGaussianBlur stdDeviation={'3.5'} />
          <feComposite in2={'hardAlpha'} k2={'-1'} k3={'1'} operator={'arithmetic'} />
          <feColorMatrix type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1019608 0'} />
          <feBlend in2={'shape'} mode={'normal'} result={'effect0_innerShadow'} />
        </filter>
      </defs>
      <g transform={'translate(1.5 1.5)'}>
        <g filter={'url(#filter_1)'}>
          <path
            d={
              'M76.055 152.109C118.059 152.109 152.11 118.059 152.11 76.0547C152.11 34.0508 118.059 0 76.055 0C34.051 0 0 34.0508 0 76.0547C0 118.059 34.051 152.109 76.055 152.109Z'
            }
            fill={'#FAFAFA'}
            stroke={'none'}
          />
        </g>
        <path
          d={
            'M31.602 47.5C31.602 54.1324 53.0002 60.7649 53.0002 60.7649L53.0002 95L4.42246e-07 95L4.42246e-07 89.7639C4.42246e-07 73.7482 4.42246e-07 60.7649 4.42246e-07 60.7649C4.42246e-07 60.7649 21.3982 54.1324 21.3982 47.5C21.3982 40.8676 4.42246e-07 34.2351 4.42246e-07 34.2351L4.42246e-07 0L53.0002 0L53.0002 5.23614C53.0002 21.2518 53.0002 34.2351 53.0002 34.2351C53.0002 34.2351 31.602 40.8676 31.602 47.5Z'
          }
          fill={'none'}
          stroke={'#4E505B'}
          strokeWidth={'3'}
          transform={'translate(49.05489 28.64844)'}
        />
        <path
          d={
            'M20.4328 28.5468C9.05236 27.428 0.160982 17.8302 0.160982 6.15577L0.160984 4.01465L0.160962 3.20252C0.160962 2.85013 0.34643 2.52375 0.649167 2.3434C3.27153 0.781132 7.47292 -7.10543e-14 13.2533 0C22.4837 1.06581e-13 25.0848 4.77686 31.9777 4.77686C36.1051 4.77686 40.0705 4.20648 43.8739 3.06572C44.4029 2.90708 44.9604 3.20729 45.119 3.73628C45.147 3.82948 45.1612 3.92626 45.1612 4.02356L45.1612 6.15575C45.1612 17.8302 36.2698 27.428 24.8894 28.5468L23.2194 60.6056C33.2769 64.0167 31.7261 64.7488 43.3394 62.7959C46.3033 67.7134 45.1612 77.6174 45.1612 78.416C45.1612 79.653 44.1584 80.6558 42.9214 80.6558L2.40074 80.6558C1.16376 80.6558 0.160984 79.653 0.160984 78.416C-0.392729 70.2256 0.481949 65.0189 2.78502 62.7959C14.2559 64.7493 11.9245 64.3127 22.1035 60.6186L20.4328 28.5468Z'
          }
          fill={'url(#gradient_1)'}
          stroke={'none'}
          transform={'translate(52.89392 38.99268)'}
        />
      </g>
    </svg>
  )
}
