import {
  Amount,
  AssetType,
  Currency,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import {
  HoldingItem,
  HoldingType,
  Event as TIEvent,
} from "./traditionalInvestments";
import { EventEnvelope } from "./event";
import { AllowedDecimalPlaces, addDecimal, mulAmount } from "../utils";
import Decimal from "decimal.js";
import { PriceSource } from "../database/priceSource";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  TraditionalInvestmentSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<TIEvent>;
export interface TraditionalInvestmentSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.TraditionalInvestmentSummary, 2>;
  id: AssetType.TraditionalInvestments;
  portfolio: {
    [id: string]: {
      holdings: {
        //#TODO need symbol
        [holdingId: string]: {
          holdingType: HoldingType;
          holdingName: string;
          isin?: HoldingItem.Holding["isin"];
          symbol?: HoldingItem.Holding["symbol"];
          exchange?: HoldingItem.Holding["exchange"];
          currency?: Currency;
          unit: number;
          purchasePrice: Amount;
        };
      };
      ownedPercentage: number;
    };
  };
}
export namespace TraditionalInvestmentSummary {
  export function assureVersion(
    input: TraditionalInvestmentSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      TraditionalInvestmentSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
  }
  export async function toDisplay(
    input: TraditionalInvestmentSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    priceSource: PriceSource
  ): Promise<Display> {
    const currency = exchangeRate.targetCurrency;
    let totalAssets = new Decimal(0);
    const stocks = new Set<string>();
    Object.values(input.portfolio).map((portfolio) => {
      Object.values(portfolio.holdings).map((holding) => {
        stocks.add(holding.holdingName);
      });
    });
    const stockPriceMap = await priceSource.getStockPrice(Array.from(stocks));

    Object.values(input.portfolio).forEach((portfolio) => {
      Object.values(portfolio.holdings).forEach((holding) => {
        if (holding.holdingType == HoldingType.Cash) {
          if (holding.currency) {
            const rate = exchangeRate.rates[holding.currency].rate;
            const baseValue = mulAmount(holding.unit, rate);
            totalAssets = new Decimal(baseValue)
              .mul(portfolio.ownedPercentage)
              .div(100)
              .toDecimalPlaces(AllowedDecimalPlaces)
              .add(totalAssets);
          }
        } else {
          const rate =
            exchangeRate.rates[stockPriceMap[holding.holdingName].currency]
              .rate;
          totalAssets = new Decimal(holding.unit)
            .mul(portfolio.ownedPercentage)
            .div(100)
            .mul(stockPriceMap[holding.holdingName].value)
            .mul(rate)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .add(totalAssets);
        }
      });
    });

    return {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: 0,
      },
      netValue: {
        currency,
        value: totalAssets.toNumber(),
      },
    };
  }
  export interface GlobalDashboardData {
    holdings: {
      name: string;
      unit: number;
      ownedUnit: number; //sum of ownedPercentage * unit
    }[];

    cashInBase: Amount;
    cashInBaseOwned: Amount; //sum of ownedPercentage * cash in base
  }
  export function toGlobalDashboardData(
    input: TraditionalInvestmentSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): GlobalDashboardData {
    const currency = exchangeRate.targetCurrency;
    const holdingsMap: { [name: string]: { unit: number; ownedUnit: number } } =
      {};
    const result: GlobalDashboardData = {
      holdings: [],
      cashInBase: { currency, value: 0 },
      cashInBaseOwned: { currency, value: 0 },
    };

    Object.values(input.portfolio).forEach((portfolio) => {
      Object.values(portfolio.holdings).forEach((holding) => {
        if (holding.holdingType == HoldingType.Cash) {
          if (holding.currency) {
            const rate = exchangeRate.rates[holding.currency].rate;
            const baseValue = mulAmount(holding.unit, rate);
            result.cashInBase.value = addDecimal(
              result.cashInBase.value,
              baseValue
            );
            result.cashInBaseOwned.value = new Decimal(baseValue)
              .mul(portfolio.ownedPercentage)
              .div(100)
              .toDecimalPlaces(AllowedDecimalPlaces)
              .add(result.cashInBaseOwned.value)
              .toNumber();
          }
        } else {
          if (!holdingsMap[holding.holdingName])
            holdingsMap[holding.holdingName] = { unit: 0, ownedUnit: 0 };
          holdingsMap[holding.holdingName].unit = addDecimal(
            holding.unit,
            holdingsMap[holding.holdingName].unit
          );
          //#NOTE we'll keep all the decimal places here so that the unit will be more precise in price calculation
          holdingsMap[holding.holdingName].ownedUnit = new Decimal(holding.unit)
            .mul(portfolio.ownedPercentage)
            .div(100)
            .add(holdingsMap[holding.holdingName].ownedUnit)
            .toNumber();
        }
      });
    });
    result.holdings = Object.entries(holdingsMap).map(([name, holding]) => ({
      name,
      ...holding,
    }));
    return result;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<TraditionalInvestmentSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(
      new TraditionalInvestmentSummaryAggregate(summary)
    );
  }

  export function defaultValue(): TraditionalInvestmentSummary {
    return {
      "@type": TraditionalInvestmentSummaryTypeVersion,
      id: AssetType.TraditionalInvestments,
      portfolio: {},
      version: 0,
    };
  }

  export type RelatedUpdates = never;
}

export class TraditionalInvestmentSummaryAggregate extends AggregateBase<
  TraditionalInvestmentSummary,
  never,
  TIEvent
> {
  state: TraditionalInvestmentSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: TraditionalInvestmentSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case TIEvent.Kind.AssetCreated:
        this.state.portfolio[event.aggregateId] = {
          holdings: {},
          ownedPercentage: event.data.asset.ownership?.myOwnership || 100,
        };
        break;
      case TIEvent.Kind.AssetDeleted:
        delete this.state.portfolio[event.aggregateId];
        break;
      case TIEvent.Kind.ShareholderUpdated:
        this.state.portfolio[event.aggregateId].ownedPercentage =
          event.data.current?.myOwnership || 100;
        break;
      case TIEvent.Kind.HoldingAdded:
        {
          const portfolio = this.state.portfolio[event.aggregateId];
          if (portfolio) {
            const holding: TraditionalInvestmentSummary["portfolio"][string]["holdings"][string] =
              {
                holdingName: event.data.holding.name,
                holdingType: event.data.holding.holdingType,
                unit: 0,
                purchasePrice: event.data.holding.investedValue,
              };
            if (event.data.holding.holdingType === HoldingType.Holding) {
              holding.isin = event.data.holding.isin;
              holding.symbol = event.data.holding.symbol;
              holding.exchange = event.data.holding.exchange;
            } else {
              holding.currency = event.data.holding.investedValue.currency;
            }
            portfolio.holdings[event.data.holding.id] = holding;
          }
        }
        break;
      case TIEvent.Kind.TransactionAdded:
      case TIEvent.Kind.TransactionUpdated:
      case TIEvent.Kind.TransactionDeleted: {
        const portfolio = this.state.portfolio[event.aggregateId];
        if (portfolio) {
          if (event.data.unitChange) {
            portfolio.holdings[event.data.parentId].unit = addDecimal(
              portfolio.holdings[event.data.parentId].unit,
              event.data.unitChange
            );
          }
          if (event.data.investedValueChange) {
            portfolio.holdings[event.data.parentId].purchasePrice.value =
              addDecimal(
                portfolio.holdings[event.data.parentId].purchasePrice.value,
                event.data.investedValueChange.value
              );
          }
        }
        break;
      }
    }
    return this;
  }
}
