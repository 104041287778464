import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2017%2016%22%3E%3Cpath%20d%3D%22M16%208c0%204.42-3.58%208-8%208s-8-3.58-8-8%203.58-8%208-8%208%203.58%208%208ZM7.25%205.424c.201-.165.509-.252.937-.252.416%200%20.75.104.991.313.201.165.322.4.362.696.013.14.214.235.415.235l2.13-.017c.227%200%20.428-.13.414-.279-.053-.6-.361-1.114-.897-1.523-.562-.426-1.312-.696-2.236-.818V2.735c0-.13-.16-.235-.362-.235H7.705c-.2%200-.361.104-.361.235v1.01c-.991.095-1.781.33-2.397.713-.67.418-1.018.957-1.018%201.61%200%20.722.322%201.262.95%201.636.644.374%201.661.74%203.054%201.097.576.165.978.322%201.205.496.228.165.335.4.335.705%200%20.26-.107.47-.335.635-.214.165-.535.243-.964.243-.509%200-.924-.104-1.245-.313-.268-.174-.415-.435-.469-.792-.013-.139-.214-.243-.415-.235l-2.13.026c-.227%200-.428.131-.414.279.053.73.415%201.297%201.098%201.697.736.435%201.647.687%202.731.774v.949c0%20.13.161.235.362.235h1.299c.2%200%20.361-.104.361-.235v-.975c.87-.104%201.594-.33%202.143-.678.643-.41.978-.949.978-1.62%200-.704-.322-1.244-.965-1.626-.642-.383-1.66-.758-3.026-1.14-.59-.166-.99-.34-1.218-.505-.202-.174-.322-.392-.322-.653s.107-.479.308-.644Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function BillingIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 17 16'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M16 8c0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8 8 3.58 8 8ZM7.25 5.424c.201-.165.509-.252.937-.252.416 0 .75.104.991.313.201.165.322.4.362.696.013.14.214.235.415.235l2.13-.017c.227 0 .428-.13.414-.279-.053-.6-.361-1.114-.897-1.523-.562-.426-1.312-.696-2.236-.818V2.735c0-.13-.16-.235-.362-.235H7.705c-.2 0-.361.104-.361.235v1.01c-.991.095-1.781.33-2.397.713-.67.418-1.018.957-1.018 1.61 0 .722.322 1.262.95 1.636.644.374 1.661.74 3.054 1.097.576.165.978.322 1.205.496.228.165.335.4.335.705 0 .26-.107.47-.335.635-.214.165-.535.243-.964.243-.509 0-.924-.104-1.245-.313-.268-.174-.415-.435-.469-.792-.013-.139-.214-.243-.415-.235l-2.13.026c-.227 0-.428.131-.414.279.053.73.415 1.297 1.098 1.697.736.435 1.647.687 2.731.774v.949c0 .13.161.235.362.235h1.299c.2 0 .361-.104.361-.235v-.975c.87-.104 1.594-.33 2.143-.678.643-.41.978-.949.978-1.62 0-.704-.322-1.244-.965-1.626-.642-.383-1.66-.758-3.026-1.14-.59-.166-.99-.34-1.218-.505-.202-.174-.322-.392-.322-.653s.107-.479.308-.644Z'
        }
      />
    </svg>
  )
}
