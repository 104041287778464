import * as React from 'react'
import { AlertCircleIcon, EyeIcon, EyeOffIcon } from 'lucide-react'
import {
  useController,
  type Control,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'

import { cn } from '@/utils/classnames'
import { Button } from '@/components/base'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'flex h-9 w-full rounded bg-grey-input px-3 text-sm text-text',
        'file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-light placeholder:text-text-placeholder',
        'focus:ring-1 focus:ring-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Input.displayName = 'Input'

interface FormInputProps<Values extends FieldValues, Path extends FieldPath<Values>> extends InputProps {
  label?: string
  hint?: string
  prefix?: string
  errorClassName?: string
  onChanged?: () => void
  // controller props
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
  defaultValue?: FieldPathValue<Values, Path>
  displayErrorMode?: 'default' | 'tooltip' | 'none'
}

export function FormInput<Values extends FieldValues, Path extends FieldPath<Values>>({
  label,
  hint,
  prefix,
  errorClassName,
  onChanged,
  name,
  control,
  rules: rulesFromProps,
  type,
  className,
  defaultValue,
  displayErrorMode = 'default',
  ...props
}: FormInputProps<Values, Path>) {
  const id = React.useId()
  const [inputType, setInputType] = React.useState(type)
  const isRequired = Boolean(rulesFromProps?.required)
  const rules: RegisterOptions<Values, Path> = {
    ...rulesFromProps,
    validate: {
      ...rulesFromProps?.validate,
      noHTMLTags: (v) => !/<[^>]*>/.test(v),
      notOnlySpaces: isRequired ? (v) => v?.toString()?.trim() !== '' : () => true
    }
  }
  const {
    field: { onChange, ...field },
    fieldState: { error }
  } = useController({ control, name, rules, defaultValue })
  const isPasswordField = type === 'password'
  const autoComplete = isPasswordField ? 'off' : 'on'

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.value)
    onChanged?.()
  }

  return (
    <div className={cn('relative grid gap-y-1', className)}>
      {label && (
        <label
          htmlFor={id}
          className={cn(
            'text-xs text-[#414554]',
            rules?.required !== undefined && 'after:ml-0.5 after:text-error after:content-["*"]'
          )}
        >
          {label}
        </label>
      )}
      <div className={'relative flex items-center rounded border bg-white'}>
        {prefix && (
          <div className={'flex h-full basis-16 items-center justify-center border-r font-light text-gray-400'}>
            {prefix}
          </div>
        )}
        <Input
          id={id}
          className={cn({ 'pr-10': isPasswordField || error !== undefined })}
          type={inputType}
          autoComplete={autoComplete}
          onChange={handleChange}
          {...field}
          {...props}
        />
        {error && !isPasswordField && <AlertCircleIcon className={'absolute right-2 text-red-500'} />}
        {isPasswordField && (
          <Button
            className={'absolute right-2 text-gray-700'}
            onClick={() => setInputType((prev) => (prev === 'password' ? 'text' : 'password'))}
          >
            {inputType === 'password' ? <EyeOffIcon /> : <EyeIcon />}
          </Button>
        )}
      </div>

      {error?.message &&
        {
          default: <p className={cn('text-xs text-red-500', errorClassName)}>{error.message}</p>,
          tooltip: (
            <div
              className={cn(
                'flex w-max max-w-[370px] rounded bg-red-200 p-1.5',
                'absolute left-full ml-4 self-center',
                'before:absolute before:right-full before:self-center before:border-8 before:border-solid before:border-transparent before:border-r-red-200',
                errorClassName
              )}
            >
              <p className={'text-sm text-red-500'} dangerouslySetInnerHTML={{ __html: error.message }} />
            </div>
          ),
          none: <></>
        }[displayErrorMode]}
      {hint && <p className={'text-xs text-white'}>{hint}</p>}
    </div>
  )
}
