import {
  Attachment,
  AttachmentKind,
  Amount,
  Optional,
  Owner,
  Ownership,
} from "./common";
import { UpdateObject, optionalUniqueArrayEqual } from "../utils";
import { Action } from "./actions";
import { SoldInfo } from "./actions/soldInfo";
import { Valuation } from "./actions/valuation";
import { ActionCommand } from "./command";
import { CoreFirestore } from "../../coreFirebase";
import { ActionType } from "./actions/base";
import { EncryptionField } from "../encryption/utils";
import { LocationInfo } from "./relations/locationInfo";

export interface EventWithTime<Event> {
  time: Date;
  data: Event;
}

//#NOTE using serverTimestamp as time
// - the reason is: currently, only event going into firestore will be handled and pre-sealed with this function
export function preSealEvent<Event>(evt: Event): EventWithTime<Event> {
  return {
    time: <any>CoreFirestore.serverTimestamp(),
    data: evt,
  };
}
export function sealEvent<Event>(
  sequence: number,
  version: number,
  aggregateId: string,
  domain: string,
  evt: EventWithTime<Event>
): EventEnvelope<Event> {
  return {
    sequence,
    version,
    aggregateId,
    domain,
    time: evt.time,
    data: evt.data,
  };
}
export function unsealEvent<Event>(envelope: EventWithTime<Event>): Event {
  return envelope.data;
}

export type EventEnvelope<Event> = {
  sequence: number;
  version: number;
  aggregateId: string;
  domain: string;
  //maybe? subtype: string;
  time: Date;
  data: Event;
};

export interface EventBase {
  executerId: string;
  kind: string;
}
export namespace SharedEvent {
  export enum Kind {
    AssetCreated = "AssetCreated",
    AssetUpdated = "AssetUpdated",
    AssetDeleted = "AssetDeleted",

    AssetArchived = "AssetArchived",
    ArchivedAssetRestored = "ArchivedAssetRestored",

    ShareholderUpdated = "ShareholderUpdated",
    BeneficiaryUpdated = "BeneficiaryUpdated",
    LocationUpdated = "LocationUpdated",
    ValuationAdded = "ValuationAdded",
    ValuationUpdated = "ValuationUpdated",
    ValuationDeleted = "ValuationDeleted",
    ValueUpdated = "ValueUpdated", //pure update or point to valuation
    InsuranceUpdated = "InsuranceUpdated",

    ImageAdded = "ImageAdded",
    ImageRemoved = "ImageRemoved",
    MainImageSet = "MainImageSet",

    GroupsUpdated = "GroupsUpdated",
  }

  export interface AssetCreated<T extends object> extends EventBase {
    kind: Kind.AssetCreated;
    asset: T;
  }
  export interface AssetUpdated<T extends object> extends EventBase {
    kind: Kind.AssetUpdated;
    asset: T;
    // For activity log: locationType or locationId updated
    locationPrimaryDetailsUpdated?: boolean;
  }
  export interface AssetDeleted extends EventBase {
    kind: Kind.AssetDeleted;
  }
  export interface AssetArchived extends EventBase {
    kind: Kind.AssetArchived;
  }
  export interface ArchivedAssetRestored extends EventBase {
    kind: Kind.ArchivedAssetRestored;
  }
  export interface ShareholderUpdated extends EventBase {
    kind: Kind.ShareholderUpdated;
    previous?: Ownership;
    current?: Ownership;
  }
  export interface BeneficiaryUpdated extends EventBase {
    kind: Kind.BeneficiaryUpdated;
    previous?: Owner[];
    current?: Owner[];
  }
  export interface LocationUpdated extends EventBase {
    kind: Kind.LocationUpdated;
    previous?: LocationInfo.Encrypted;
    current: LocationInfo.Encrypted;
  }
  export interface ValueUpdated extends EventBase {
    kind: Kind.ValueUpdated;
    previous?: Amount;
    current: Amount;
    valuationId?: string;
    valuationName?: EncryptionField; // { valuationName: string }
    // use to determine if this value updated is a revert. e.g., valuationDeleted
    revertedFromId?: string;
  }
  export interface InsuranceUpdated extends EventBase {
    kind: Kind.InsuranceUpdated;
    previous?: string[];
    current?: string[];
  }
  export interface ImageAdded extends EventBase {
    kind: Kind.ImageAdded;
    images: string[];
  }
  export interface ImageRemoved extends EventBase {
    kind: Kind.ImageRemoved;
    images: string[];
  }
  export interface MainImageSet extends EventBase {
    kind: Kind.MainImageSet;
    previous?: string;
    current?: string;
  }
  export interface GroupsUpdated extends EventBase {
    kind: Kind.GroupsUpdated;
    addIds: string[];
    removedIds: string[];
  }

  export function attachmentEventOnCreate(
    executerId: string,
    attachments: Attachment.Encrypted[],
    mainImage: Optional<string>
  ): (ImageAdded | MainImageSet)[] {
    const events: (ImageAdded | MainImageSet)[] = [];
    const images = attachments
      .filter((attachment) => attachment.type === AttachmentKind.AssetImage)
      .map((attachment) => attachment.key!);
    if (images.length > 0) {
      events.push({
        executerId,
        kind: Kind.ImageAdded,
        images,
      });
      if (mainImage) {
        events.push({
          executerId,
          kind: Kind.MainImageSet,
          current: mainImage,
        });
      }
    }
    return events;
  }

  export function insuranceEventOnUpdate(
    executerId: string,
    previousInsuranceIds: Optional<string[]>,
    currentInsuranceIds: Optional<string[]>
  ): Optional<InsuranceUpdated> {
    if (optionalUniqueArrayEqual(previousInsuranceIds, currentInsuranceIds)) {
      return undefined;
    } else {
      const event: InsuranceUpdated = {
        executerId,
        kind: Kind.InsuranceUpdated,
      };
      if (previousInsuranceIds) event.previous = previousInsuranceIds;
      if (currentInsuranceIds) event.current = currentInsuranceIds;
      return event;
    }
  }
}

export namespace TxEvent {
  export enum Kind {
    TransactionAdded = "TransactionAdded",
    TransactionUpdated = "TransactionUpdated",
    TransactionDeleted = "TransactionDeleted",
  }
  export interface TransactionAdded<Tx> extends EventBase {
    kind: Kind.TransactionAdded;
    parentId: string;
    id: string;
    data: Tx;
  }
  export interface TransactionUpdated<TxUpdate> extends EventBase {
    kind: Kind.TransactionUpdated;
    parentId: string;
    id: string;
    update: TxUpdate;
  }
  export interface TransactionDeleted extends EventBase {
    kind: Kind.TransactionDeleted;
    parentId: string;
    id: string;
  }
}

export namespace ValuationEvent {
  export enum Kind {
    ValuationAdded = "ValuationAdded",
    // ValuationUpdated = "ValuationUpdated",
    ValuationDeleted = "ValuationDeleted",
  }
  export interface ValuationAdded extends EventBase {
    kind: Kind.ValuationAdded;
    data: Valuation.Encrypted;
  }
  // export interface ValuationUpdated extends EventBase {
  //   kind: Kind.ValuationUpdated;
  //   id: string;
  //   update: Valuation.Update;
  // }
  export interface ValuationDeleted extends EventBase {
    kind: Kind.ValuationDeleted;
    id: string;
  }
}

export namespace SellEvent {
  export enum Kind {
    SoldInfoAdded = "SoldInfoAdded",
    SoldInfoUpdated = "SoldInfoUpdated",
    SoldInfoDeleted = "SoldInfoDeleted",
  }
  export interface SoldInfoAdded extends EventBase {
    kind: Kind.SoldInfoAdded;
    data: SoldInfo.Encrypted;
    logInfo: {
      assetName: string;
    };
  }
  export interface SoldInfoUpdated extends EventBase {
    kind: Kind.SoldInfoUpdated;
    id: string;
    update: UpdateObject<SoldInfo.UpdateEncrypted>;
    logInfo: {
      currentTitle: string;
    };
  }
  export interface SoldInfoDeleted extends EventBase {
    kind: Kind.SoldInfoDeleted;
    id: string;
    logInfo: {
      title: string;
    };
  }
}

export namespace ActionEvent {
  export enum Kind {
    ActionAdded = "ActionAdded",
    ActionUpdated = "ActionUpdated",
    ActionDeleted = "ActionDeleted",
  }
  export interface ActionAdded<T extends Action.Encrypted> extends EventBase {
    kind: Kind.ActionAdded;
    data: ActionCommand.AddData<T>;
  }
  export interface ActionUpdated<T extends Action.Update> extends EventBase {
    kind: Kind.ActionUpdated;
    id: string;
    actionType: ActionType;
    update: ActionCommand.UpdateData<T>;
    // offer: current offerNumber
    // consignment: current contactId
    // literature, exhibition: current title
    logInfo: string;
  }
  export interface ActionDeleted extends EventBase {
    kind: Kind.ActionDeleted;
    id: string;
    actionType: ActionType;
    // offer: offerNumber
    // consignment: contactId
    // literature, exhibition: title
    logInfo: string;
  }
}
