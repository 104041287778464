import { Sequence, getSeqDocPath } from "./aggregate";
import { Refs } from "../refs";
import {
  CashAndBankingSummary,
  CashAndBankingSummaryAggregate,
} from "./cashAndBankingSummary";
import {
  TraditionalInvestmentSummary,
  TraditionalInvestmentSummaryAggregate,
} from "./traditionalInvestmentSummary";
import {
  OtherInvestmentSummary,
  OtherInvestmentSummaryAggregate,
} from "./otherInvestmentSummary";
import {
  CryptocurrencySummary,
  CryptocurrencySummaryAggregate,
} from "./cryptocurrencySummary";
import {
  InsuranceSummary,
  InsuranceSummaryAggregate,
} from "./insuranceSummary";
import { PropertySummary, PropertySummaryAggregate } from "./propertySummary";
import { RelationsOfAsset, RelationSearchKeyword } from "./relations";
import { ArtSummary, ArtSummaryAggregate } from "./artSummary";
import {
  BelongingSummary,
  BelongingSummaryAggregate,
} from "./belongingSummary";
import { WineSummary, WineSummaryAggregate } from "./wineSummary";
import { AssetType } from "./enums";
import { mulAmount, resolveObject } from "../utils";
import { Amount, Currency, Optional } from "./common";
import { ExchangeRate } from "../database/exchangeRate";
import { Encryption } from "../database/encryption";
import { GlobalDashboard } from "./globalDashboard";
import { MockPriceSource } from "../database/priceSource";
import { HoldingType, HoldingUnit } from "./traditionalInvestments";
import { CryptoUnit } from "./cryptocurrencies";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
  getQueriedData,
} from "../../coreFirebase";
import { Delegate, PermissionLevel } from "./delegates";
import { PermissionCategory } from "../refPaths";
import { SummaryLoader } from "./summaryLoader";

export type SyncedAssetTypes =
  | AssetType.CashAndBanking
  | AssetType.TraditionalInvestments
  | AssetType.OtherInvestment
  | AssetType.Cryptocurrency
  | AssetType.Insurance
  | AssetType.Property
  | AssetType.Art
  | AssetType.OtherCollectables
  | AssetType.Belonging
  | AssetType.WineAndSpirits;
const SyncedAssetTypesArray: SyncedAssetTypes[] = [
  AssetType.CashAndBanking,
  AssetType.TraditionalInvestments,
  AssetType.OtherInvestment,
  AssetType.Cryptocurrency,
  AssetType.Insurance,
  AssetType.Property,
  AssetType.Art,
  AssetType.OtherCollectables,
  AssetType.Belonging,
  AssetType.WineAndSpirits,
];

function checkPermission(
  permissions: Optional<Delegate.EncryptedDelegator>,
  category: PermissionCategory
): { read: boolean; write: boolean } {
  if (!permissions) {
    return { read: true, write: true };
  } else {
    const permission = permissions[category];
    return {
      read: permission >= PermissionLevel.View,
      write: permission > PermissionLevel.View,
    };
  }
}

export type SummaryPermissions = {
  [key in PermissionCategory]: {
    read: boolean;
    write: boolean;
  };
};

function checkReadSummaryPermissionChanged(
  a: SummaryPermissions,
  b: SummaryPermissions
) {
  return Object.keys(a).some((key) => {
    const typedKey = key as PermissionCategory;
    return a[typedKey].read !== b[typedKey].read;
  });
}

export class SummaryManager {
  currentUserId: Optional<string>;
  isDelegate: boolean = false;
  permissions!: SummaryPermissions;
  relationCollectionRef: Optional<CollectionReference<RelationsOfAsset>>;

  [AssetType.CashAndBanking]: Optional<
    SummaryLoader<CashAndBankingSummary, CashAndBankingSummaryAggregate>
  >;
  [AssetType.TraditionalInvestments]: Optional<
    SummaryLoader<
      TraditionalInvestmentSummary,
      TraditionalInvestmentSummaryAggregate
    >
  >;
  [AssetType.OtherInvestment]: Optional<
    SummaryLoader<OtherInvestmentSummary, OtherInvestmentSummaryAggregate>
  >;
  [AssetType.Cryptocurrency]: Optional<
    SummaryLoader<CryptocurrencySummary, CryptocurrencySummaryAggregate>
  >;
  [AssetType.Insurance]: Optional<
    SummaryLoader<InsuranceSummary, InsuranceSummaryAggregate>
  >;
  [AssetType.Property]: Optional<
    SummaryLoader<PropertySummary, PropertySummaryAggregate>
  >;

  [AssetType.Art]: Optional<SummaryLoader<ArtSummary, ArtSummaryAggregate>>;
  [AssetType.OtherCollectables]: Optional<
    SummaryLoader<BelongingSummary, BelongingSummaryAggregate>
  >;
  [AssetType.Belonging]: Optional<
    SummaryLoader<BelongingSummary, BelongingSummaryAggregate>
  >;
  [AssetType.WineAndSpirits]: Optional<
    SummaryLoader<WineSummary, WineSummaryAggregate>
  >;

  get<T extends SyncedAssetTypes>(assetType: T) {
    if (!this[assetType]) {
      throw new Error(
        `SummaryManager does not have permission of ${assetType}`
      );
    }
    return this[assetType] as NonNullable<SummaryManager[T]>;
  }

  setSummaryLoader(
    refs: Refs,
    permissions: Optional<Delegate.EncryptedDelegator> = undefined
  ): void {
    const convertedPermission = Object.fromEntries(
      permissions
        ? Object.values(PermissionCategory).map((category) => [
            category as PermissionCategory,
            checkPermission(permissions, category),
          ])
        : Object.values(PermissionCategory).map((category) => [
            category as PermissionCategory,
            { read: true, write: true },
          ])
    ) as SummaryPermissions;
    this.isDelegate = permissions !== undefined;

    this.doSetSummaryLoader(refs, convertedPermission);
  }

  resetSummaryLoader(refs: Refs): void {
    const convertedPermission = this.permissions
      ? this.permissions
      : (Object.fromEntries(
          Object.values(PermissionCategory).map((category) => [
            category as PermissionCategory,
            { read: true, write: true },
          ])
        ) as SummaryPermissions);
    this.doSetSummaryLoader(refs, convertedPermission, true);
  }

  private doSetSummaryLoader(
    refs: Refs,
    convertedPermission: SummaryPermissions,
    force: boolean = false
  ) {
    //#NOTE don't fetch if input user is current user and the permission is not changed
    if (
      !force &&
      this.currentUserId == refs.userId &&
      !checkReadSummaryPermissionChanged(this.permissions, convertedPermission)
    ) {
      console.log("Skip setSummaryLoader. No user and permission changes.");
      return;
    }
    this.unsubscribe();

    const maxReadPermission = Object.values(convertedPermission).some(
      (permission) => permission.read === true
    );

    // * wipe out all previous data
    this[AssetType.CashAndBanking] = undefined;
    this[AssetType.TraditionalInvestments] = undefined;
    this[AssetType.OtherInvestment] = undefined;
    this[AssetType.Cryptocurrency] = undefined;
    this[AssetType.Insurance] = undefined;
    this[AssetType.Property] = undefined;
    this[AssetType.Art] = undefined;
    this[AssetType.OtherCollectables] = undefined;
    this[AssetType.Belonging] = undefined;
    this[AssetType.WineAndSpirits] = undefined;

    this.currentUserId = refs.userId;
    this.permissions = convertedPermission;
    this.relationCollectionRef = refs.Relations;

    if (maxReadPermission) {
      this[AssetType.CashAndBanking] = new SummaryLoader(
        refs,
        AssetType.CashAndBanking,
        this.permissions[PermissionCategory.Finance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.CashAndBanking)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.CashAndBankingSummary,
          aggregateConstructor: CashAndBankingSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return CashAndBankingSummary.newAggregateRoot(
              transaction,
              refs.CashAndBankingSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Finance].read) {
      this[AssetType.TraditionalInvestments] = new SummaryLoader(
        refs,
        AssetType.TraditionalInvestments,
        this.permissions[PermissionCategory.Finance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.TraditionalInvestments)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.TraditionalInvestmentSummary,
          aggregateConstructor: TraditionalInvestmentSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return TraditionalInvestmentSummary.newAggregateRoot(
              transaction,
              refs.TraditionalInvestmentSummary
            );
          },
        }
      );
      this[AssetType.OtherInvestment] = new SummaryLoader(
        refs,
        AssetType.OtherInvestment,
        this.permissions[PermissionCategory.Finance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.OtherInvestment)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.OtherInvestmentSummary,
          aggregateConstructor: OtherInvestmentSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return OtherInvestmentSummary.newAggregateRoot(
              transaction,
              refs.OtherInvestmentSummary
            );
          },
        }
      );
      this[AssetType.Cryptocurrency] = new SummaryLoader(
        refs,
        AssetType.Cryptocurrency,
        this.permissions[PermissionCategory.Finance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Cryptocurrency)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.CryptocurrencySummary,
          aggregateConstructor: CryptocurrencySummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return CryptocurrencySummary.newAggregateRoot(
              transaction,
              refs.CryptocurrencySummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Insurance].read) {
      this[AssetType.Insurance] = new SummaryLoader(
        refs,
        AssetType.Insurance,
        this.permissions[PermissionCategory.Insurance].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Insurance)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.InsuranceSummary,
          aggregateConstructor: InsuranceSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return InsuranceSummary.newAggregateRoot(
              transaction,
              refs.InsuranceSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Property].read) {
      this[AssetType.Property] = new SummaryLoader(
        refs,
        AssetType.Property,
        this.permissions[PermissionCategory.Property].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Property)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.PropertySummary,
          aggregateConstructor: PropertySummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return PropertySummary.newAggregateRoot(
              transaction,
              refs.PropertySummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Art].read) {
      this[AssetType.Art] = new SummaryLoader(
        refs,
        AssetType.Art,
        this.permissions[PermissionCategory.Art].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Art)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.ArtSummary,
          aggregateConstructor: ArtSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return ArtSummary.newAggregateRoot(transaction, refs.ArtSummary);
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.OtherCollectables].read) {
      this[AssetType.OtherCollectables] = new SummaryLoader(
        refs,
        AssetType.OtherCollectables,
        this.permissions[PermissionCategory.OtherCollectables].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.OtherCollectables)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.OtherCollectableSummary,
          aggregateConstructor: BelongingSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return BelongingSummary.newAggregateRoot(
              transaction,
              refs.OtherCollectableSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Belonging].read) {
      this[AssetType.Belonging] = new SummaryLoader(
        refs,
        AssetType.Belonging,
        this.permissions[PermissionCategory.Belonging].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.Belonging)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.BelongingSummary,
          aggregateConstructor: BelongingSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return BelongingSummary.newAggregateRoot(
              transaction,
              refs.BelongingSummary
            );
          },
        }
      );
    }
    if (this.permissions[PermissionCategory.Wine].read) {
      this[AssetType.WineAndSpirits] = new SummaryLoader(
        refs,
        AssetType.WineAndSpirits,
        this.permissions[PermissionCategory.Wine].write,
        CoreFirestore.doc(
          getSeqDocPath(this.currentUserId, AssetType.WineAndSpirits)
        ) as DocumentReference<Sequence>,
        {
          ref: refs.WineSummary,
          aggregateConstructor: WineSummaryAggregate,
          newAggregateRoot: (transaction: Transaction) => {
            return WineSummary.newAggregateRoot(transaction, refs.WineSummary);
          },
        }
      );
    }
  }

  unsubscribe() {
    SyncedAssetTypesArray.forEach((assetType) => {
      const summaryLoader = this[assetType];
      if (summaryLoader !== undefined) summaryLoader.unsubscribe();
    });
  }

  async load(assetTypes: SyncedAssetTypes[]) {
    const deDuped = Array.from(new Set(assetTypes));
    return CoreFirestore.runTransaction(async (transaction) => {
      const promiseObj = Object.fromEntries(
        deDuped
          .filter((assetType) => this[assetType] !== undefined)
          .map((assetType) => [assetType, this[assetType]!.load(transaction)])
      );
      return await resolveObject(promiseObj);
    });
  }

  async syncAndGetData(assetTypes: SyncedAssetTypes[]) {
    const deDuped = Array.from(new Set(assetTypes));
    const promiseObj = Object.fromEntries(
      deDuped
        .filter((assetType) => this[assetType] !== undefined)
        .map((assetType) => [assetType, this[assetType]!.syncAndGetData()])
    );
    return await resolveObject(promiseObj);
  }

  private async getRelationsByKeyword(keyword: RelationSearchKeyword) {
    return CoreFirestore.getDocsFromCollection(
      this.relationCollectionRef!,
      CoreFirestore.where("keyword", "==", keyword)
    ).then(getQueriedData);
  }

  /*
   * The liabilities under myProperties, myCollectables, myBelongings
   * are associated liabilities from loan or mortgage accounts.
   * Their netValue do not include the associated liabilities.
   */
  async getGlobalDashboard(
    exchangeRate: ExchangeRate,
    encryption: Encryption,
    currency?: Currency
  ) {
    const summary = await this.syncAndGetData(SyncedAssetTypesArray);

    exchangeRate.checkInitialized();
    const exRate = await exchangeRate.getToTargetExchangeRates(
      currency || exchangeRate.BaseCurrency!
    );

    const globalDashboardData = await GlobalDashboard.fromSummaries(
      exRate,
      encryption,
      summary[AssetType.CashAndBanking]
        ?.summary as Optional<CashAndBankingSummary>,
      summary[AssetType.TraditionalInvestments]
        ?.summary as Optional<TraditionalInvestmentSummary>,
      summary[AssetType.OtherInvestment]
        ?.summary as Optional<OtherInvestmentSummary>,
      summary[AssetType.Cryptocurrency]
        ?.summary as Optional<CryptocurrencySummary>,
      summary[AssetType.Insurance]?.summary as Optional<InsuranceSummary>,
      summary[AssetType.Property]?.summary as Optional<PropertySummary>,
      summary[AssetType.Art]?.summary as Optional<ArtSummary>,
      summary[AssetType.WineAndSpirits]?.summary as Optional<WineSummary>,
      summary[AssetType.OtherCollectables]
        ?.summary as Optional<BelongingSummary>,
      summary[AssetType.Belonging]?.summary as Optional<BelongingSummary>,
      this.isDelegate
        ? undefined
        : await this.getRelationsByKeyword(RelationSearchKeyword.PropertyAsset),
      this[AssetType.CashAndBanking]
        ? await this.getRelationsByKeyword(
            RelationSearchKeyword.LiabilityAllocated
          )
        : undefined
    );
    return await GlobalDashboard.calculateCurrentValues(
      globalDashboardData,
      exRate,
      //#TODO need real price source
      new MockPriceSource(),
      this.permissions
    );
  }

  async getItemPrice(
    itemRefs: ItemRef[]
  ): Promise<{ [id: string]: (Amount | HoldingUnit | CryptoUnit)[] }> {
    const summary = await this.syncAndGetData(itemRefs.map((v) => v.assetType));

    const result: { [id: string]: (Amount | HoldingUnit | CryptoUnit)[] } = {};
    itemRefs.forEach((ref) => {
      switch (ref.assetType) {
        case AssetType.CashAndBanking: {
          const target = (<CashAndBankingSummary>(
            summary[AssetType.CashAndBanking].summary
          )).accounts[ref.assetId];
          if (!target) break;
          if (ref.subId) {
            const sub = target.subAccounts.find((v) => v.id == ref.subId);
            if (sub) {
              result[itemRefToId(ref)] = [sub.balance];
            }
          } else {
            result[itemRefToId(ref)] = target.subAccounts.map((v) => v.balance);
          }
          break;
        }
        case AssetType.TraditionalInvestments: {
          const target = (<TraditionalInvestmentSummary>(
            summary[AssetType.TraditionalInvestments].summary
          )).portfolio[ref.assetId];
          if (!target) break;
          if (ref.subId) {
            const item = target.holdings[ref.subId];
            if (item)
              result[itemRefToId(ref)] =
                item.holdingType == HoldingType.Cash
                  ? [{ currency: item.currency!, value: item.unit }]
                  : [
                      {
                        name: item.holdingName,
                        unit: item.unit,
                      },
                    ];
          } else {
            result[itemRefToId(ref)] = Object.values(target.holdings).map(
              (v) => {
                return v.holdingType == HoldingType.Cash
                  ? { currency: v.currency!, value: v.unit }
                  : {
                      name: v.holdingName,
                      unit: v.unit,
                    };
              }
            );
          }
          break;
        }
        case AssetType.OtherInvestment: {
          const target = (<OtherInvestmentSummary>(
            summary[AssetType.OtherInvestment].summary
          )).items[ref.assetId];
          if (!target) break;
          result[itemRefToId(ref)] = [target.value];
          break;
        }
        case AssetType.Cryptocurrency: {
          const target = (<CryptocurrencySummary>(
            summary[AssetType.Cryptocurrency].summary
          )).accounts[ref.assetId];
          if (!target) break;
          target.coins;
          if (ref.subId) {
            const coin = target.coins[ref.subId];
            if (coin)
              result[itemRefToId(ref)] = [
                {
                  coinName: ref.subId,
                  unit: coin.unit,
                },
              ];
          } else {
            result[itemRefToId(ref)] = Object.entries(target.coins).map(
              ([coinName, { unit }]) => {
                return {
                  coinName,
                  unit,
                };
              }
            );
          }
          break;
        }
        case AssetType.Insurance: {
          const target = (<InsuranceSummary>(
            summary[AssetType.Insurance].summary
          )).insurance[ref.assetId];
          if (!target) break;
          result[itemRefToId(ref)] = [target.value];
          break;
        }
        case AssetType.Property: {
          const target = (<PropertySummary>summary[AssetType.Property].summary)
            .properties[ref.assetId];
          if (!target) break;
          //#HACK encrypted property should have amount but it's defined through decorator
          result[itemRefToId(ref)] = [target.value! as Amount];
          break;
        }
        case AssetType.Art: {
          const target = (<ArtSummary>summary[AssetType.Art].summary).items[
            ref.assetId
          ];
          if (!target) break;
          result[itemRefToId(ref)] = [target.value];
          break;
        }
        case AssetType.WineAndSpirits: {
          const target = (<WineSummary>(
            summary[AssetType.WineAndSpirits].summary
          )).wines[ref.assetId];
          if (!target) break;
          if (ref.subId) {
            const purchase = target.purchases.find((v) => v.id == ref.subId);
            if (purchase) {
              result[itemRefToId(ref)] = [
                {
                  currency: purchase.valuePerBottle.currency,
                  value: mulAmount(
                    purchase.valuePerBottle.value,
                    purchase.bottleCount.bottles
                  ),
                },
              ];
            }
          } else {
            result[itemRefToId(ref)] = target.purchases.map((v) => ({
              currency: v.valuePerBottle.currency,
              value: mulAmount(v.valuePerBottle.value, v.bottleCount.bottles),
            }));
          }
          break;
        }
        case AssetType.OtherCollectables: {
          const target = (<BelongingSummary>(
            summary[AssetType.OtherCollectables].summary
          )).items[ref.assetId];
          if (!target) break;
          result[itemRefToId(ref)] = [target.value];
          break;
        }
        case AssetType.Belonging: {
          const target = (<BelongingSummary>(
            summary[AssetType.Belonging].summary
          )).items[ref.assetId];
          if (!target) break;
          result[itemRefToId(ref)] = [target.value];
          break;
        }
      }
    });
    return result;
  }
}

export interface ItemRef {
  assetId: string;
  assetType: SyncedAssetTypes;
  subtype?: string;
  subId?: string;
}
export function itemRefToId(input: ItemRef) {
  return input.subId ? `${input.assetId}_${input.subId}` : input.assetId;
}
