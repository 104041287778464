import * as React from 'react'
import { MinusIcon, PlusIcon } from 'lucide-react'
import { useController, type Control, type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form'

import { cn } from '@/utils/classnames'
import { Button, Input, InputProps } from '@/components/base'

function isNumber(value: string) {
  return /^(0|[1-9]\d*)(\.\d+)?$/.test(value)
}

type Bounds = {
  min: number
  max: number
}

function checkBounds(value: number, bounds: Bounds) {
  return value <= bounds.max && value >= bounds.min
}

const MIN = 0
const MAX = 99

interface QuantityInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  min?: number
  max?: number
  value: number
  onChange: (value: number) => void
}

export const QuantityInput = React.forwardRef<HTMLInputElement, QuantityInputProps>(
  ({ className, value, min = MIN, max = MAX, onChange, ...props }, ref) => {
    const handleIncrement = () => onChange(value + 1)
    const handleDecrement = () => onChange(value - 1)
    const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      e.target.select()
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value
      if (rawValue.length === 0) onChange(min)

      const intValue = parseInt(rawValue)
      const isValid = isNumber(rawValue) && checkBounds(intValue, { min, max })
      if (isValid) onChange(intValue)
    }

    return (
      <div className={cn('flex items-center rounded border bg-white', className)}>
        <Button
          className={'h-full w-9 rounded-r-none text-primary ease-out hover:border-primary'}
          onClick={handleDecrement}
          disabled={value === min}
        >
          <MinusIcon size={14} />
        </Button>
        <Input
          ref={ref}
          className={'flex-1 rounded-none border-x text-center focus:ring-0'}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          type={'number'}
          {...props}
        />
        <Button
          className={'h-full w-9 rounded-l-none text-primary ease-out hover:border-primary'}
          onClick={handleIncrement}
          disabled={value === max}
        >
          <PlusIcon size={14} />
        </Button>
      </div>
    )
  }
)
QuantityInput.displayName = 'QuantityInput'

interface FormQuantityInputProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  label?: string
  className?: string
  min?: number
  max?: number
  // control props
  name: Path
  control: Control<Values>
  rules?: RegisterOptions<Values, Path>
  onChanged?: (value: number) => void
}

export function FormQuantityInput<Values extends FieldValues, Path extends FieldPath<Values>>({
  label,
  className,
  min = MIN,
  max = MAX,
  name,
  control,
  rules,
  onChanged
}: FormQuantityInputProps<Values, Path>) {
  const id = React.useId()
  const { field } = useController({ control, name, rules })
  const { value, onChange, ...fieldProps } = field
  const isRequired = rules?.required !== undefined

  const handleChange = (value: number) => {
    onChange(value)
    onChanged?.(value)
  }

  return (
    <div className={cn('grid grid-cols-1 gap-y-1', className)}>
      <label
        htmlFor={id}
        className={cn('text-xs text-[#414554]', { 'after:ml-0.5 after:text-error after:content-["*"]': isRequired })}
      >
        {label}
      </label>
      <QuantityInput id={id} min={min} max={max} value={value} onChange={handleChange} {...fieldProps} />
    </div>
  )
}
