import '@/utils/i18n'
import '@/constants/globals.css'

import { Fragment } from 'react'
import type { NextComponentType } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { combineProviders } from 'react-combine-provider'
import TagManager from 'react-gtm-module'

import { roboto } from '@/constants/fonts'
import { usePageInfo } from '@/hooks/usePageInfo'
import { usePreviousRoute } from '@/hooks/usePreviousRoute'
import { MapProvider } from '@/store/map'
import { TooltipProvider } from '@/components/base'
import LanguageSync from '@/components/LanguageSync'
import Layout from '@/components/Layout'
import ProtectedPage from '@/components/ProtectedPage'
import { ReCaptcha } from '@/components/ReCaptcha'
import { Toaster } from '@/components/Toaster'

const version = {
  endpoint: process.env.NEXT_PUBLIC_API_ENDPOINT,
  buildId: process.env.buildId!
}

console.log(version)

if (
  (process.env.NEXT_PUBLIC_ENV === 'staging' || process.env.NEXT_PUBLIC_ENV === 'prod') &&
  typeof window !== 'undefined'
) {
  TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID! })
}

const CombineProvider = combineProviders([MapProvider, TooltipProvider, ReCaptcha])

export default function App({ Component, pageProps }: AppProps) {
  const { previous } = usePreviousRoute()
  const { isAuthRoute } = Component as NextComponentType & { isAuthRoute: boolean }
  const Protector = isAuthRoute ? Fragment : ProtectedPage
  const { title, desc } = usePageInfo()

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name={'description'} content={desc} />
        <meta name={'viewport'} content={'width=device-width, initial-scale=1'} />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              :root {
                --roboto: ${roboto.style.fontFamily};
              }
              .grecaptcha-badge { visibility: hidden; }
            `
          }}
        />
      </Head>

      <CombineProvider>
        <Protector>
          <Layout>
            <Component from={previous} {...pageProps} />
          </Layout>
        </Protector>
        <LanguageSync />
      </CombineProvider>

      <Toaster />
    </>
  )
}
