import * as React from 'react'
import { useState } from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'

export const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn('relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full', className)}
    {...props}
  />
))
Avatar.displayName = AvatarPrimitive.Root.displayName

type AvatarImageProps = React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> & {
  base64IV?: string
}

export const AvatarImage = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Image>, AvatarImageProps>(
  ({ className, src, base64IV, ...props }, ref) => {
    const database = useAuthStore((state) => state.database)
    const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))
    const [decryptedUrl, setDecryptedUrl] = useState<string | null>(null)

    React.useEffect(() => {
      if (!src) {
        setDecryptedUrl(null)
        return
      }

      const fetchAndDecryptImage = async () => {
        const ivSalt = base64IV ?? preferences.ivSalt
        try {
          const response = await fetch(src)
          const encryptedBytes = await response.arrayBuffer()
          const iv = database!.Encryption.current.convertBase64ToIVSalt(ivSalt)
          const decryptedBytes = await database!.Encryption.current.decryptBytes(encryptedBytes, iv)

          const decryptedBlob = new Blob([decryptedBytes], { type: 'image/jpeg' })
          const decryptedUrl = URL.createObjectURL(decryptedBlob)
          setDecryptedUrl(decryptedUrl)

          return () => {
            if (decryptedUrl) {
              URL.revokeObjectURL(decryptedUrl)
            }
          }
        } catch (error) {
          console.error('Failed to fetch and decrypt image:', error)
        }
      }

      const cleanup = fetchAndDecryptImage()

      return () => {
        if (cleanup) {
          cleanup.then((cleanupFunction) => {
            cleanupFunction && cleanupFunction()
          })
        }
      }
    }, [database, preferences.ivSalt, src, base64IV])

    return (
      <AvatarPrimitive.Image
        ref={ref}
        className={cn('aspect-square h-full w-full', className)}
        src={decryptedUrl ?? src}
        {...props}
      />
    )
  }
)
AvatarImage.displayName = AvatarPrimitive.Image.displayName

export const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn('flex h-full w-full items-center justify-center rounded-full bg-slate-500 text-white', className)}
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName
