import { ActionType, ItemActions } from "./base";
import { Amount, PathsOfDateField } from "../common";
import {
  EncryptedType,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { InvalidInput } from "../error";
import {
  OmitKeys,
  UpdateObject,
  validateValueInEnum,
  validateStringNotEmpty,
} from "../../utils";
import { Encryption } from "../../database/encryption";
import { CoreFirestore } from "../../../coreFirebase";

export interface Valuation extends ItemActions {
  // @Encrypted
  name: string;

  value: Amount;
  actionType: ActionType.AddValuation;
  method: ValuationMethod;
  updateValue: boolean; // include this value in your net worth calculations
  basis?: ValuationBasis;

  // TODO: Double check - This shouldn't be encrypted since it's a ref
  // @Encrypted
  valuedById?: string; // AddressBook ref

  // @Encrypted
  valuedByRepresentative?: string;
  inspectionDate?: Date;
  issueDate?: Date;
  // @Encrypted
  purpose?: string;
  // @Encrypted
  status?: string;
}
export namespace Valuation {
  export const datePaths: readonly PathsOfDateField<Valuation>[] = [
    "inspectionDate",
    "issueDate",
    "createAt",
    "updateAt",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Valuation> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    Valuation,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys =
    | ItemActions.EncryptedKeys
    | "name"
    | "valuedByRepresentative"
    | "purpose"
    | "status";
  export type Encrypted = EncryptedType<Valuation, EncryptedKeys>;
  export type EncryptedPart = Pick<Valuation, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
    //Valuation
    "name",
    "valuedByRepresentative",
    "purpose",
    "status",
  ];

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): Valuation {
    return ItemActions.fromCreate(
      createFields,
      ownerId,
      ActionType.AddValuation
    );
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    if (!validateStringNotEmpty(data.name)) {
      throw new InvalidInput("Valuation name cannot be empty");
    }
    ItemActions.validateEncryptedPart(data);
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    if (isCreate || data.value) {
      Amount.validate("value", data.value!);
      if (data.value!.value < 0) {
        throw new InvalidInput("Valuation value cannot be negative");
      }
    }
    if (
      (isCreate || data.method) &&
      !validateValueInEnum(data.method!, ValuationMethod)
    ) {
      throw new InvalidInput("Valuation method is invalid");
    }
    // optional fields
    if (data.basis && !validateValueInEnum(data.basis!, ValuationBasis)) {
      throw new InvalidInput("Valuation basis is invalid");
    }
  }

  export async function encrypt(
    rawData: Valuation,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<Valuation, EncryptedKeys>;

  export async function getSystemCreationEncrypted(
    newId: string,
    ownerId: string,
    value: Amount,
    encryption: Encryption
  ): Promise<Encrypted> {
    return Valuation.encrypt(
      Valuation.fromCreate(
        {
          id: newId,
          name: ValuationMethod.SystemCreation,
          value: value,
          method: ValuationMethod.SystemCreation,
          updateValue: true,
        },
        ownerId
      ),
      encryption
    );
  }
}

export enum ValuationMethod {
  SystemCreation = "SystemCreation",
  AuctionEstimate = "AuctionEstimate",
  Comparable = "Comparable",
  FairMarketValue = "FairMarketValue",
  InsuranceEstimate = "InsuranceEstimate",
  OpenMarketValue = "OpenMarketValue",
  ProfessionValuation = "ProfessionValuation",
  PurchaseCost = "PurchaseCost",
  ReplacementValue = "ReplacementValue",
  SecuredLoan = "SecuredLoan",
  SelfAppraised = "SelfAppraised",
}

export const valuationMethodValues = Object.values(ValuationMethod);

export enum ValuationBasis {
  AuctionEstimates = "AuctionEstimates",
  CharitableDonation = "CharitableDonation",
  Collateral = "Collateral",
  CostValue = "CostValue",
  EstateTax = "EstateTax",
  FairMarketValue = "FairMarketValue",
  FamilyDivision = "FamilyDivision",
  ForcedSale = "ForcedSale",
  GiftTax = "GiftTax",
  GovernmentIndemnity = "GovernmentIndemnity",
  Insurance = "Insurance",
  InventoryOfTaxExemptions = "InventoryOfTaxExemptions",
  NegotiatedSale = "NegotiatedSale",
  OpenMarketValuationEurope = "OpenMarketValuationEurope",
  ReplacementValue = "ReplacementValue",
  TaxValuation = "TaxValuation",
}

export const valuationBasisValues = Object.values(ValuationBasis);
