import * as React from 'react'
import { AnimatePresence, motion, type Variants } from 'framer-motion'
import { InfoIcon } from 'lucide-react'

import { cn } from '@/utils/classnames'
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import TruncatedText from '@/components/base/TruncatedText'
import { ChevronDownIcon } from '@/components/icon'

interface CollapsibleState {
  isOpen: boolean
  toggle: () => void
}

const CollapsibleContext = React.createContext<CollapsibleState>({
  isOpen: false,
  toggle: () => {}
})

interface CollapsibleProviderProps {
  children: React.ReactNode
}

function CollapsibleProvider({ children }: CollapsibleProviderProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = React.useCallback(() => setIsOpen((prev) => !prev), [])
  return <CollapsibleContext.Provider value={{ isOpen, toggle }}>{children}</CollapsibleContext.Provider>
}

export function useCollapsible() {
  return React.useContext(CollapsibleContext)
}

interface TriggerProps {
  className?: string
}

const Trigger = React.forwardRef<HTMLButtonElement, TriggerProps>(({ className }, ref) => {
  const { isOpen, toggle } = useCollapsible()
  return (
    <Button
      ref={ref}
      className={cn('group absolute bottom-0 left-0 w-full transition-transform', isOpen && '-rotate-180', className)}
      onClick={toggle}
    >
      <ChevronDownIcon className={'text-primary group-hover:text-primary-hover'} size={20} />
    </Button>
  )
})
Trigger.displayName = 'CollapsibleTrigger'

const contentVariants: Variants = {
  expanded: {
    height: 'auto',
    opacity: 1
  },
  collapsed: {
    height: 0,
    opacity: 0
  }
}

interface ContentProps {
  className?: string
  children: React.ReactNode
}

const Content = React.forwardRef<HTMLDivElement, ContentProps>(({ className, ...props }, ref) => {
  const { isOpen } = useCollapsible()
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          ref={ref}
          key={'collapsed-content'}
          className={cn('overflow-hidden', className)}
          variants={contentVariants}
          initial={'collapsed'}
          animate={'expanded'}
          exit={'collapsed'}
          {...props}
        />
      )}
    </AnimatePresence>
  )
})
Content.displayName = 'CollapsibleContent'

interface HeadingProps {
  children: React.ReactNode
  className?: string
}

const Heading = React.forwardRef<HTMLParagraphElement, HeadingProps>(({ children, className }, ref) => {
  return (
    <p ref={ref} className={cn('text-sm font-medium uppercase', className)}>
      {children}
    </p>
  )
})
Heading.displayName = 'CollapsibleHeading'

interface FieldProps {
  label: string
  children: React.ReactNode
  className?: string
  info?: string
}

export const Field = React.forwardRef<HTMLDivElement, FieldProps>(({ label, children, className, info }, ref) => {
  return (
    <div ref={ref} className={cn('text-sm', className)}>
      <div className={'flex items-center gap-x-1'}>
        <TruncatedText className={'text-[13px] text-[#6D83AC]/60'}>{label}</TruncatedText>
        {info && (
          <Tooltip>
            <TooltipTrigger asChild={true}>
              <InfoIcon className={'text-primary'} size={16} />
            </TooltipTrigger>
            <TooltipContent className={'rounded border-0 bg-primary px-2 py-1'}>
              <span className={'text-xs font-medium text-white'}>{info}</span>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      {children}
    </div>
  )
})
Field.displayName = 'CollapsibleField'

interface CollapsibleProps {
  className?: string
  children: React.ReactNode
}

export const Collapsible = Object.assign(
  React.forwardRef<HTMLDivElement, CollapsibleProps>(function Collapsible({ className, children, ...props }, ref) {
    return (
      <CollapsibleProvider>
        <div
          ref={ref}
          className={cn(
            'relative min-h-[200px] space-y-5 rounded border-l-[10px] bg-[#F5F5F5] p-5 pl-[10px] text-text',
            className
          )}
          {...props}
        >
          {children}
        </div>
      </CollapsibleProvider>
    )
  }),
  { Trigger, Content, Field, Heading }
)
