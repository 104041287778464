import Decimal from "decimal.js";
import {
  ConvertFunction,
  EXPORT_BATCH_SIZE,
  ExportRow,
  Progress,
  ProgressMetadata as ProgressMetadataBase,
  Querier,
} from ".";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  getQueriedData,
  QueryConstraint,
} from "../../../coreFirebase";
import { getAssetsByIds, Refs } from "../../refs";
import { Art } from "../../types/arts";
import { Belonging, BelongingsUtils } from "../../types/belongings";
import { Account } from "../../types/cashAndBanking";
import { CurrentAccount } from "../../types/cashAndBanking/currentAccount";
import { SavingAccount } from "../../types/cashAndBanking/savingAccount";
import { AssetType, Currency } from "../../types/common";
import { Insurance } from "../../types/insurance";
import {
  OtherInvestment,
  Option as OIOption,
  Loan as OILoan,
} from "../../types/otherInvestments";
import { OwnershipType, Property, PropertyUtils } from "../../types/properties";
import { Wine, WinePurchase } from "../../types/wineAndSprits";
import { Encrypted, Encryption } from "../encryption";
import { ExchangeRate } from "../exchangeRate";
import { PriceSource } from "../priceSource";
import { Cryptocurrency } from "../../types/cryptocurrencies";
import { AllowedDecimalPlaces } from "../../utils";
import {
  HoldingItem,
  HoldingType,
  Portfolio,
} from "../../types/traditionalInvestments";
import { Institution } from "../../types/cashAndBanking/institution";

type Cursor = {
  id: string;
};
type QuerierParams = {
  assetType: AssetType;
  collectionPath: string;
  cursor?: Cursor;
};
export type ProgressMetadata = ProgressMetadataBase<QuerierParams>;

//#TODO need to prevent resume export task on two different clients
export class FirestoreAssetExportQuerier implements Querier {
  metadataDocRef: DocumentReference<ProgressMetadata>;
  metadataExists: boolean = false;
  startedAt: Date;

  convertFunction: ConvertFunction;

  constraints: QueryConstraint[] = [];
  batchConstraint?: QueryConstraint;

  assetCollectionRef: CollectionReference;
  cursor?: Cursor;

  assetType: AssetType;
  batchSize: number;

  constructor(
    refs: Refs,
    assetType: AssetType,
    convertFunction: ConvertFunction,
    taskId?: string,
    batchSize?: number
  ) {
    this.convertFunction = convertFunction;
    this.startedAt = new Date();
    this.assetType = assetType;
    this.assetCollectionRef = refs.getAssetCollectionRef(assetType);
    this.metadataDocRef = CoreFirestore.docFromCollection(
      refs.getExportMetadataCollection(),
      taskId || CoreFirestore.genAssetId()
    );
    this.constraints.push(
      CoreFirestore.where("ownerId", "==", refs.userId),
      CoreFirestore.orderBy("createAt", "asc"),
      CoreFirestore.orderBy("id", "asc")
    );
    this.batchSize = batchSize || EXPORT_BATCH_SIZE;
  }

  async getTotalCount(): Promise<number> {
    return await CoreFirestore.getCountFromServer(
      this.assetCollectionRef,
      ...this.constraints
    ).then((v) => v.data().count);
  }

  async readMetadataAndGetProcess(): Promise<Progress> {
    const metadataResult = await CoreFirestore.getDoc(this.metadataDocRef).then(
      (v) => v.data()
    );

    let progress: Progress;
    if (metadataResult === undefined) {
      progress = {
        total: 0,
        finished: 0,
        batchSize: this.batchSize,
      };
    } else {
      this.metadataExists = true;
      this.startedAt = metadataResult.startedAt;
      progress = metadataResult.progress;
      if (this.assetType !== metadataResult.querierParams.assetType) {
        throw new Error("assetType path mismatch");
      }
      this.cursor = metadataResult.querierParams.cursor;
    }
    this.batchConstraint = CoreFirestore.limit(progress.batchSize);

    //#NOTE update progress.total with the latest count from server
    // - we're ordering data by `createAt`, if the field is write properly, what has been read won't change and new data should always added at the end
    progress.total = await this.getTotalCount();

    return progress;
  }

  async writeMetadata(progress: Progress, endId: string): Promise<void> {
    this.cursor = {
      id: endId,
    };
    const metadata: ProgressMetadata = {
      id: this.metadataDocRef.id,
      querierParams: {
        assetType: this.assetType,
        collectionPath: this.assetCollectionRef.path,
        cursor: this.cursor,
      },
      progress,
      startedAt: new Date(),
      description: `Exporting assets of ${this.assetType}`,
    };
    if (this.metadataExists) {
      await CoreFirestore.updateDoc<
        Partial<ProgressMetadata>,
        Partial<ProgressMetadata>
      >(this.metadataDocRef, {
        querierParams: metadata.querierParams,
        progress: metadata.progress,
      });
    } else {
      await CoreFirestore.setDoc(this.metadataDocRef, metadata);
    }
  }
  async deleteMetadata(taskId?: string): Promise<void> {
    if (taskId) {
      await CoreFirestore.deleteDoc(
        CoreFirestore.docFromCollection(this.metadataDocRef.parent, taskId)
      );
    } else {
      await CoreFirestore.deleteDoc(this.metadataDocRef);
    }
  }

  async getNextBatch(): Promise<{ total: number; rows: ExportRow[] }> {
    if (!this.batchConstraint) {
      throw new Error("batchConstraint is not initialized");
    }
    const total = await this.getTotalCount();
    const snapshots = this.cursor
      ? await CoreFirestore.getDocsFromCollection(
          this.assetCollectionRef,
          ...this.constraints,
          this.batchConstraint,
          CoreFirestore.startAfter(
            await CoreFirestore.getDoc(
              CoreFirestore.docFromCollection(
                this.assetCollectionRef,
                this.cursor.id
              )
            )
          )
        )
      : await CoreFirestore.getDocsFromCollection(
          this.assetCollectionRef,
          ...this.constraints,
          this.batchConstraint
        );

    const rows = await this.convertFunction(getQueriedData(snapshots));
    return { total, rows };
  }
}

export async function newQuerier(
  assetType: AssetType,
  refs: Refs,
  encryption: Encryption,
  exchangeRate: ExchangeRate,
  priceSource: PriceSource,
  taskId?: string,
  batchSize?: number
): Promise<FirestoreAssetExportQuerier> {
  exchangeRate.checkInitialized();
  let convertFunction: ConvertFunction;
  switch (assetType) {
    case AssetType.Art:
      convertFunction = (arts: Art.Encrypted[]) => {
        return Promise.all(
          arts.map((v) =>
            Art.decryptAndConvertDate(v, encryption).then((decrypted) => {
              const row: ExportRow = {
                id: decrypted.id,
                createdAt: decrypted.createAt,
                updatedAt: decrypted.updateAt,
                assetType,
                subtype: decrypted.subtype,
                name: decrypted.name,
                purchaseDate: decrypted.purchaseDate,
                currency: decrypted.value.currency,
                currentValue: decrypted.value.value,
                archived: decrypted.archived || false,
                notes: decrypted.notes || "",
              };
              return row;
            })
          )
        );
      };
      break;
    case AssetType.Belonging:
    case AssetType.OtherCollectables:
      convertFunction = (belongings: Encrypted<Belonging>[]) => {
        return Promise.all(
          belongings.map((v) =>
            BelongingsUtils.decryptAndConvertDate(v, encryption).then(
              (decrypted) => {
                const row: ExportRow = {
                  id: decrypted.id,
                  createdAt: decrypted.createAt,
                  updatedAt: decrypted.updateAt,
                  assetType,
                  subtype: decrypted.subtype,
                  name: decrypted.name,
                  purchaseDate: decrypted.purchaseDate,
                  currency: decrypted.value.currency,
                  currentValue: decrypted.value.value,
                  archived: decrypted.archived || false,
                  notes: decrypted.notes || "",
                };
                return row;
              }
            )
          )
        );
      };
      break;
    case AssetType.Property:
      convertFunction = (properties: Encrypted<Property>[]) => {
        return Promise.all(
          properties.map((v) =>
            PropertyUtils.decryptAndConvertDate(v, encryption).then(
              (decrypted) => {
                const row: ExportRow = {
                  id: decrypted.id,
                  createdAt: decrypted.createAt,
                  updatedAt: decrypted.updateAt,
                  assetType,
                  subtype: decrypted.subtype,
                  name: decrypted.name,
                  purchaseDate:
                    decrypted.ownershipType == OwnershipType.Own
                      ? decrypted.startDate
                      : undefined,
                  currency: decrypted.value.currency,
                  currentValue: decrypted.value.value,
                  archived: decrypted.archived || false,
                  notes: decrypted.notes || "",
                };
                return row;
              }
            )
          )
        );
      };
      break;
    case AssetType.WinePurchases:
      convertFunction = async (winePurchases: WinePurchase.Encrypted[]) => {
        const purchases = await Promise.all(
          winePurchases.map((v) =>
            WinePurchase.decrypt(WinePurchase.convertDate(v), encryption)
          )
        );

        let wineIdsSet = new Set<string>();
        purchases.forEach((purchase) => {
          wineIdsSet.add(purchase.wineId);
        });
        let wineIds = Array.from(wineIdsSet).map((id) =>
          Wine.buildWineId(refs.userId, id)
        );
        let result: [string, Wine.Encrypted][] =
          await getAssetsByIds<Wine.Encrypted>(
            refs,
            AssetType.WineAndSpirits,
            wineIds
          ).then((undecryptedWines) =>
            undecryptedWines.map((wine) => [wine.id, wine])
          );
        let idToWineNameMap = new Map<string, Wine.Encrypted>(result);
        return purchases.map((purchase) => {
          const wineId = Wine.buildWineId(refs.userId, purchase.wineId);
          const wine = idToWineNameMap.get(wineId);
          if (wine === undefined) {
            throw new Error(
              `Wine not found, id: ${wineId}, purchaseId: ${purchase.id}`
            );
          }
          const row: ExportRow = {
            id: purchase.id,
            createdAt: purchase.createAt,
            updatedAt: purchase.updateAt,
            assetType,
            subtype: wine.subtype,
            name: wine.name,
            purchaseDate: purchase.purchaseDate,
            currency: purchase.netWorth.currency,
            currentValue: purchase.netWorth.value,
            //#NOTE no archived and notes in WinePurchase
            archived: false,
            notes: "",
          };
          return row;
        });
      };
      break;
    case AssetType.Insurance:
      convertFunction = (insurances: Insurance.Encrypted[]) => {
        return Promise.all(
          insurances.map((v) =>
            Insurance.decryptAndConvertDate(v, encryption).then((decrypted) => {
              const row: ExportRow = {
                id: decrypted.id,
                createdAt: decrypted.createAt,
                updatedAt: decrypted.updateAt,
                assetType,
                subtype: decrypted.subtype,
                name: decrypted.name,
                purchaseDate: decrypted.startDate,
                currency: decrypted.value.currency,
                currentValue: decrypted.value.value,
                archived: decrypted.archived || false,
                notes: decrypted.notes || "",
              };
              return row;
            })
          )
        );
      };
      break;
    case AssetType.OtherInvestment:
      convertFunction = (otherInvestments: OtherInvestment.Encrypted[]) => {
        return Promise.all(
          otherInvestments.map((v) =>
            OtherInvestment.decryptAndConvertDate(v, encryption).then(
              (decrypted) => {
                const row: ExportRow = {
                  id: decrypted.id,
                  createdAt: decrypted.createAt,
                  updatedAt: decrypted.updateAt,
                  assetType,
                  subtype: decrypted.subtype,
                  name: decrypted.name,
                  purchaseDate:
                    (<OIOption>decrypted).investmentDate ||
                    (<OILoan>decrypted).startDate,
                  currency: decrypted.value.currency,
                  currentValue: decrypted.value.value,
                  archived: decrypted.archived || false,
                  notes: decrypted.notes || "",
                };
                return row;
              }
            )
          )
        );
      };
      break;
    case AssetType.CashAndBanking:
      convertFunction = (accounts: Account.Encrypted[]) => {
        return Promise.all(
          accounts.map((v) =>
            Account.decryptAndConvertDate(v, encryption).then(
              async (decrypted) => {
                const row: ExportRow = {
                  id: decrypted.id,
                  createdAt: decrypted.createAt,
                  updatedAt: decrypted.updateAt,
                  assetType,
                  subtype: decrypted.subtype,
                  name: decrypted.name,
                  purchaseDate: undefined,
                  currency: decrypted.value.currency,
                  currentValue: decrypted.value.value,
                  //#TODO is closed account considered archived?
                  archived: decrypted.archived || false,
                  notes: decrypted.notes || "",
                };
                if (
                  decrypted.subtype == Account.Type.CurrentAccount ||
                  decrypted.subtype == Account.Type.SavingAccount
                ) {
                  const account = decrypted as CurrentAccount | SavingAccount;
                  row.currency = account.accountCurrency;
                  let currentValue = new Decimal(0);
                  for (const subAccount of account.subAccounts) {
                    currentValue = new Decimal(subAccount.balance.value)
                      .mul(
                        await exchangeRate
                          .getExchangeRate(
                            subAccount.balance.currency,
                            account.accountCurrency
                          )
                          .then((v) => v.rate)
                      )
                      .toDecimalPlaces(AllowedDecimalPlaces)
                      .add(currentValue);
                  }
                }
                return row;
              }
            )
          )
        );
      };
      break;

    //#TODO need real price source to work properly
    case AssetType.Cryptocurrency:
      convertFunction = async (cryptos: Cryptocurrency.Encrypted[]) => {
        const decryptedCryptos = await Promise.all(
          cryptos.map((v) =>
            Cryptocurrency.decryptAndConvertDate(v, encryption)
          )
        );
        const cryptoKindSet = new Set<string>();
        decryptedCryptos.forEach((v) => {
          v.coins.forEach((coin) => {
            if (coin.symbol) cryptoKindSet.add(coin.symbol);
          });
        });

        const cryptoPriceMap = await priceSource.getCryptoPrice(
          Array.from(cryptoKindSet)
        );

        return decryptedCryptos.map((v) => {
          let totalValue = new Decimal(0);
          v.coins.forEach((coin) => {
            if (!coin.symbol) return;
            const price = cryptoPriceMap[coin.symbol];
            const rate = exchangeRate.getToBaseExchangeRate(
              price.currency
            ).rate;
            totalValue = new Decimal(coin.unit)
              .mul(price.value)
              .mul(rate)
              .toDecimalPlaces(AllowedDecimalPlaces)
              .add(totalValue);
          });
          const row: ExportRow = {
            id: v.id,
            createdAt: v.createAt,
            updatedAt: v.updateAt,
            assetType: AssetType.Cryptocurrency,
            subtype: v.subtype,
            name: v.name,
            purchaseDate: undefined,
            currency: exchangeRate.BaseCurrency!,
            currentValue: totalValue.toNumber(),
            archived: v.archived || false,
            notes: v.notes || "",
          };
          return row;
        });
      };
      break;
    case AssetType.TraditionalInvestments:
      convertFunction = async (portfolios: Portfolio.Encrypted[]) => {
        let totalValue = new Decimal(0);
        // const stocks = new Set<{
        //   symbol: string;
        //   exchange: string;
        // }>();
        const temp = new Set<string>();
        const result: ExportRow[] = [];
        for (const portfolio of portfolios) {
          for (const h of portfolio.holdings) {
            if (h.holdingType == HoldingType.Holding) {
              // const { symbol, exchange } = h as HoldingItem.Holding;
              // stocks.add({
              //   symbol,
              //   exchange,
              // });
              temp.add((h as HoldingItem.Holding).name);
            }
          }
        }
        const stockPriceMap = await priceSource.getStockPrice(
          //#TODO fix this when we can query stock price
          // Array.from(stocks)
          Array.from(temp)
        );
        for (const portfolio of portfolios) {
          for (const h of Object.values(portfolio.holdings)) {
            if (h.holdingType == HoldingType.Cash) {
              const cash = h as HoldingItem.Cash;
              const rate = await exchangeRate
                .getExchangeRate(
                  cash.investedValue.currency,
                  portfolio.portfolioCurrency
                )
                .then((v) => v.rate);
              totalValue = new Decimal(cash.investedValue.value)
                .mul(rate)
                .toDecimalPlaces(AllowedDecimalPlaces)
                .add(totalValue);
            } else {
              const holding = h as HoldingItem.Holding;
              const price = stockPriceMap[holding.name];
              const rate = await exchangeRate
                .getExchangeRate(price.currency, portfolio.portfolioCurrency)
                .then((v) => v.rate);
              totalValue = new Decimal(holding.unit)
                .mul(price.value)
                .mul(rate)
                .toDecimalPlaces(AllowedDecimalPlaces)
                .add(totalValue);
            }
          }

          result.push({
            id: portfolio.id,
            createdAt: portfolio.createAt,
            updatedAt: portfolio.updateAt,
            assetType,
            subtype: portfolio.subtype,
            name: portfolio.name,
            purchaseDate: undefined,
            currency: portfolio.portfolioCurrency,
            currentValue: totalValue.toNumber(),
            archived: portfolio.archived || false,
            notes: portfolio.notes || "",
          });
        }
        return result;
      };
      break;

    case AssetType.BankOrInstitution:
      convertFunction = async (rawBanks: Institution[]) => {
        const banks = rawBanks.map((v) => Institution.convertDate(v));
        return banks.map((bank) => {
          let totalValue = new Decimal(0);
          Object.values(bank.accounts).forEach((account) => {
            let bankValue = new Decimal(0);
            account.subAccounts.map((subAccount) => {
              const rate = exchangeRate.getToBaseExchangeRate(
                subAccount.balance.currency
              ).rate;
              bankValue = new Decimal(subAccount.balance.value)
                .mul(rate)
                .toDecimalPlaces(AllowedDecimalPlaces)
                .add(bankValue);
            });
            totalValue = totalValue.add(bankValue);
          });
          const row: ExportRow = {
            id: bank.id,
            createdAt: bank.createAt,
            updatedAt: bank.updateAt,
            assetType,
            subtype: "",
            name: bank.name,
            purchaseDate: undefined,
            currency: exchangeRate.BaseCurrency!,
            currentValue: totalValue.toNumber(),
            archived: false,
            notes: "",
          };
          return row;
        });
      };
      break;
    case AssetType.WineAndSpirits:
      convertFunction = async (wines: Wine.Encrypted[]) => {
        const decryptedWines = await Promise.all(
          wines.map((v) => Wine.decrypt(Wine.convertDate(v), encryption))
        );
        const rows: ExportRow[] = [];
        for (const wine of decryptedWines) {
          const purchaseIds = wine.purchases.map((v) => v.id);
          const purchases = await getAssetsByIds<WinePurchase.Encrypted>(
            refs,
            AssetType.WinePurchases,
            purchaseIds
          ).then((v) => v.map(WinePurchase.convertDate));
          purchases.sort(
            (a, b) => a.purchaseDate.getTime() - b.purchaseDate.getTime()
          );
          const purchaseDate =
            purchases.length > 0 ? purchases[0].purchaseDate : undefined;
          let currentValue = new Decimal(0);
          Object.entries(wine.value).forEach(([currency, value]) => {
            currentValue = new Decimal(value)
              .mul(
                exchangeRate.getToBaseExchangeRate(currency as Currency).rate
              )
              .toDecimalPlaces(AllowedDecimalPlaces)
              .add(currentValue);
          });

          rows.push({
            id: wine.id,
            createdAt: wine.createAt,
            updatedAt: wine.updateAt,
            assetType,
            subtype: wine.subtype,
            name: wine.name,
            purchaseDate,
            currency: exchangeRate.BaseCurrency!,
            currentValue: currentValue.toNumber(),
            archived: wine.archived || false,
            notes: wine.notes || "",
          });
        }
        return rows;
      };
      break;
    default:
      throw new Error("unsupported asset type");
  }
  return new FirestoreAssetExportQuerier(
    refs,
    assetType,
    convertFunction,
    taskId,
    batchSize
  );
}
