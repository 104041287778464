import { Currency, Optional } from "../../types/common";
import { AssetType } from "../../types/enums";
import { newQuerier } from "./firestoreQuerier";
import { Encryption } from "../encryption";
import { Refs } from "../../refs";
import { ExchangeRate } from "../exchangeRate";
import { PriceSource } from "../priceSource";

export const EXPORT_ROWS_PER_FILE = 10000;
export const EXPORT_BATCH_SIZE = 500;

export type ExportRow = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  assetType: AssetType;
  subtype: string;
  name: string;
  purchaseDate: Optional<Date>;
  currency: Currency;
  currentValue: number;
  archived: boolean;
  notes: string;
};

export type Progress = {
  total: number;
  finished: number;
  batchSize: number;
};
// path: UserData/{userId}/ExportMetadata/{taskId}
export type ProgressMetadata<T> = {
  id: string;
  querierParams: T;
  progress: Progress;
  startedAt: Date;
  description: string;
};

export interface Querier {
  /**
   * read the export progress and self query params from firestore
   *
   * @returns the export progress
   */
  readMetadataAndGetProcess: () => Promise<Progress>;

  /**
   * write the export progress to firestore together with query params of itself
   *
   * @param {Progress} progress - the export progress maintain by ExportHandler
   * @param {string} endId - the id in the current batch where system should resume after this id
   * @returns a promise that resolves when the progress is written
   */
  writeMetadata: (progress: Progress, endId: string) => Promise<void>;

  /**
   * delete the export progress held in querier
   *
   * @param {Optional<string>} taskId - the task id to identify the export task, leave undefined to delete the current task
   * @returns a promise that resolves when the metadata is deleted
   */
  deleteMetadata: (taskId?: string) => Promise<void>;

  /**
   * get the next batch of data, the cursor won't move if no `writeMetadata` called since last `getNextBatch`
   *
   * @returns {Promise<{ total: number; rows: ExportRow[] }>} latest total and current batch of data
   */
  getNextBatch: () => Promise<{ total: number; rows: ExportRow[] }>;
}

export type ConvertFunction<T = any> = (data: T[]) => Promise<ExportRow[]>;

export class ExportHandler {
  private currentBatch: string[] = [];
  constructor(private querier: Querier, private progress: Progress) {}

  /**
   *
   * @returns {Readonly<Progress>} the current progress of the export
   */
  getProgress(): Readonly<Progress> {
    return this.progress;
  }

  /**
   * get the next batch of data, the cursor won't move if no `markEndId` called since last `getNextBatch`
   *
   * @returns {Promise<ExportRow[]>} the next batch of data
   */
  async getNextBatch(): Promise<ExportRow[]> {
    const { total, rows } = await this.querier.getNextBatch();
    this.currentBatch = rows.map((v) => v.id);
    this.progress.total = total;
    if (rows.length === 0) {
      await this.querier.deleteMetadata();
    }
    return rows;
  }

  /**
   * mark with the id that is in this batch and outer system consider data as processed
   *
   * @param {string} endId - the id in the current batch where system should resume after this id
   * @returns a promise that resolves when the progress is written
   * @throws if the id is not found in the current batch
   */
  async markEndId(endId: string): Promise<void> {
    const index = this.currentBatch.findIndex((v) => v === endId);
    if (index === -1) {
      throw new Error(`id ${endId} not found in current batch`);
    } else {
      this.progress.finished += 1 + index;
    }
    await this.querier.writeMetadata(this.progress, endId);
  }

  async deleteExportTask(taskId: string): Promise<void> {
    await this.querier.deleteMetadata(taskId);
  }
}

/**
 * create a new export handler
 *
 * @param {AssetType} assetType - the asset type to export
 * @param {Refs} refs - the refs object to access firestore
 * @param {Encryption} encryption - the encryption object to decrypt the data
 * @param {Optional<string>} taskId - the task id to identify the export task, leave undefined to create a new task
 * @param {Optional<number>} batchSize - the batch size to read from firestore, normally, it should be fine to leave it undefined
 * @returns {Promise<ExportHandler>} a new export handler
 */
export async function newExportHandler(
  assetType: AssetType,
  refs: Refs,
  encryption: Encryption,
  exchangeRate: ExchangeRate,
  priceSource: PriceSource,
  taskId?: string,
  batchSize?: number
) {
  const querier = await newQuerier(
    assetType,
    refs,
    encryption,
    exchangeRate,
    priceSource,
    taskId,
    batchSize
  );
  const progress = await querier.readMetadataAndGetProcess();
  return new ExportHandler(querier, progress);
}
