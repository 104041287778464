import {
  Portfolio,
  Command,
  Event,
  PortfolioState,
  PortfolioTransaction,
  HoldingItem,
  PortfolioStateWriter,
} from "../types/traditionalInvestments";
import {
  AggregateRoot,
  Repo,
  Sequence,
  buildUpdateGroupCommand,
  getSeqDocPath,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import { AssetType, Attachment, Currency } from "../types/common";
import { EncryptionFieldKey } from "../encryption/utils";
import { UpdateObject } from "../utils";
import {
  TraditionalInvestmentSummary,
  TraditionalInvestmentSummaryAggregate,
} from "../types/traditionalInvestmentSummary";
import { ExchangeRate } from "./exchangeRate";
import { FullRefs, Refs, getAssetsByIds } from "../refs";
import { GroupsRepo } from "./groups";
import { EncryptionManager } from "./encryption";
import { RoleToAsset } from "../types/relations";
import { DataPoisoned } from "../types/error";
import { DbSharedFields } from "../types/database";
import { MockPriceSource } from "../database/priceSource";
import { SummaryManager } from "../types/summaryManager";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";
import { SummaryLoader } from "../types/summaryLoader";

export class TraditionalInvestmentRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }

  async getSyncedSummary(currency?: Currency) {
    const summary = (
      await this.summaryManager
        .get(AssetType.TraditionalInvestments)
        .syncAndGetData()
    ).summary;
    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return TraditionalInvestmentSummary.toDisplay(
      summary,
      exRate,
      //#TODO need real price source
      new MockPriceSource()
    );
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    ar: AggregateRoot<PortfolioState, Command, Event>
  ) {
    const portfolio = ar.state().portfolio;
    const data = ar.relatedUpdates() as Portfolio.RelatedUpdates;
    const repoAndAggregates: Portfolio.RelatedAggregates = {};
    if (data.addedGroupIds || data.removedGroupIds) {
      repoAndAggregates.group = {
        repo: await GroupsRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: [],
      };
      if (data.addedGroupIds) {
        for (const groupId of data.addedGroupIds) {
          repoAndAggregates.group!.aggregates.push(
            await GroupsRepo.newArAndUpdateGroupItem(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              groupId,
              portfolio.id,
              AssetType.TraditionalInvestments,
              portfolio.subtype
            )
          );
        }
      }
      if (data.removedGroupIds) {
        for (const groupId of data.removedGroupIds) {
          repoAndAggregates.group!.aggregates.push(
            await GroupsRepo.newArAndUpdateGroupItem(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              groupId,
              portfolio.id,
              AssetType.TraditionalInvestments,
              portfolio.subtype,
              false
            )
          );
        }
      }
    }
    if (repoAndAggregates.group) {
      const groupData = repoAndAggregates.group;
      groupData.aggregates.forEach((aggregate) => {
        groupData.repo.commitWithState(aggregate);
      });
    }
  }

  async getAllPortfolio(): Promise<Portfolio[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetCollectionRef<Portfolio.Encrypted>(
        AssetType.TraditionalInvestments
      );
    const assets = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) => Portfolio.decryptAndConvertDate(v, this.Encryption.current))
    );
    return assets.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getPortfoliosByIds(ids: string[]): Promise<Portfolio[]> {
    const result = await getAssetsByIds<Portfolio.Encrypted>(
      this.refs.currentRefs,
      AssetType.TraditionalInvestments,
      ids
    );
    return Promise.all(
      result.map((v) =>
        Portfolio.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async getPortfolioById(id: string): Promise<Portfolio> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      id
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => Portfolio.decryptAndConvertDate(v, this.Encryption.current));
  }

  async getPortfolioTransactions(
    holdingId: string
  ): Promise<PortfolioTransaction[]> {
    const txCollectionRef =
      this.refs.currentRefs.getPortfolioTransactionCollectionRef<PortfolioTransaction>(
        holdingId
      );
    const result = await CoreFirestore.getDocsFromCollection(
      txCollectionRef
    ).then(getQueriedData);
    result.forEach(PortfolioTransaction.convertDate);
    result.sort(PortfolioTransaction.sorter);
    return result;
  }

  async getPortfolioTransactionByIds(
    holdingId: string,
    ids: string[]
  ): Promise<PortfolioTransaction[]> {
    const txCollectionRef =
      this.refs.currentRefs.getPortfolioTransactionCollectionRef<PortfolioTransaction>(
        holdingId
      );

    const result = await CoreFirestore.getDocsByIdsPure<PortfolioTransaction>(
      txCollectionRef,
      ids
    );
    result.forEach(PortfolioTransaction.convertDate);
    result.sort(PortfolioTransaction.sorter);
    return result;
  }

  private async getPortfolioTransactionById(
    transaction: Transaction,
    holdingId: string,
    id: string
  ): Promise<PortfolioTransaction> {
    const txRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.getPortfolioTransactionCollectionRef<PortfolioTransaction>(
        holdingId
      ),
      id
    );
    const tx = await transaction.get(txRef).then(checkAndGetData);
    PortfolioTransaction.convertDate(tx);
    return tx;
  }

  async add(req: Portfolio.CreateFields) {
    const newDocRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      req.id
    );
    Portfolio.validateEncryptedPart(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);
      const iv = this.Encryption.current.generateNewIVSalt();
      const state: PortfolioState = {
        portfolio: Portfolio.defaultStateValue(),
        transactions: {},
      };

      const repo = await TraditionalInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const { holdings, ...rest } = req;
      const portfolio = Portfolio.fromCreate(
        { ...rest, holdings: [] },
        this.refs.currentRefs.userId
      );
      Portfolio.validateEncryptedPart(portfolio, true);
      const encrypted = await Portfolio.encrypt(
        portfolio,
        this.Encryption.current
      );

      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(
        Command.createAsset(
          this.refs.selfRefs.userId,
          encrypted,
          holdings.map((holding) => ({
            ...holding,
            id: CoreFirestore.genAssetId(),
          }))
        )
      );
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async update(req: Portfolio) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      req.id
    );
    Portfolio.validateEncryptedPart(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Portfolio.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();

      const currentData = await Portfolio.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const state: PortfolioState = {
        portfolio: currentUndecrypted,
        transactions: {},
      };
      const repo = await TraditionalInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const {
        updates,
        metadata: { addedToGroup, removedFromGroup },
      } = Portfolio.intoUpdate(currentData, req);
      const encryptedFieldsUpdated = Portfolio.encryptedKeysArray.reduce(
        (result, key) => result || Object.keys(updates).includes(key),
        false
      );

      const encryptedUpdate: UpdateObject<Portfolio.Encrypted> =
        Portfolio.removeEncryptedFields(updates);

      if (encryptedFieldsUpdated) {
        const encryptionPart: Portfolio.EncryptedPart = {};
        if (updates.accountNumber !== null) {
          encryptionPart.accountNumber =
            updates.accountNumber || currentData.accountNumber;
        }
        const newEncrypted = await Portfolio.encryptPartial(
          encryptionPart,
          this.Encryption.current
        );
        if (newEncrypted[EncryptionFieldKey]) {
          encryptedUpdate[EncryptionFieldKey] =
            newEncrypted[EncryptionFieldKey];
        }
      }
      if (updates.attachments) {
        encryptedUpdate.attachments = await Attachment.encryptArray(
          updates.attachments,
          this.Encryption.current
        );
      }

      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup
        )
      );
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  //#TODO need to remove all transaction
  async delete(id: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Portfolio.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();
      const state: PortfolioState = {
        portfolio: currentData,
        transactions: {},
      };
      const repo = await TraditionalInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async addHolding(portfolioId: string, createHolding: HoldingItem.Create) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      portfolioId
    );
    const holdingId = CoreFirestore.genAssetId();

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Portfolio.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();
      const state: PortfolioState = {
        portfolio: currentData,
        transactions: {},
      };
      const repo = await TraditionalInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(
        Command.addHolding(this.refs.selfRefs.userId, {
          ...createHolding,
          id: holdingId,
        })
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async addTransaction(
    portfolioId: string,
    holdingId: string,
    newTx: PortfolioTransaction.Create
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      portfolioId
    );
    const txId = CoreFirestore.genAssetId();
    const newTxData = PortfolioTransaction.fromCreate(
      newTx,
      txId,
      this.refs.currentRefs.userId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Portfolio.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();
      const state: PortfolioState = {
        portfolio: currentData,
        transactions: {},
      };
      const repo = await TraditionalInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(
        Command.addTransaction(
          this.refs.selfRefs.userId,
          holdingId,
          txId,
          newTxData
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateTransaction(
    portfolioId: string,
    holdingId: string,
    txId: string,
    updates: PortfolioTransaction.Update
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      portfolioId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Portfolio.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();
      const targetTx = await this.getPortfolioTransactionById(
        transaction,
        holdingId,
        txId
      );
      const state: PortfolioState = {
        portfolio: currentData,
        transactions: {
          [holdingId]: {
            [txId]: targetTx,
          },
        },
      };
      const repo = await TraditionalInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(
        Command.updateTransaction(
          this.refs.selfRefs.userId,
          holdingId,
          txId,
          updates
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteTransaction(
    portfolioId: string,
    holdingId: string,
    txId: string
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      portfolioId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Portfolio.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();
      const targetTx = await this.getPortfolioTransactionById(
        transaction,
        holdingId,
        txId
      );
      const state: PortfolioState = {
        portfolio: currentData,
        transactions: {
          [holdingId]: {
            [txId]: targetTx,
          },
        },
      };
      const repo = await TraditionalInvestmentRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(
        Command.deleteTransaction(this.refs.selfRefs.userId, holdingId, txId)
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

export namespace TraditionalInvestmentRepo {
  export async function removeGroup(
    refs: FullRefs,
    id: string,
    groupId: string
  ) {
    const docRef = refs.currentRefs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Portfolio.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();

      const state: PortfolioState = {
        portfolio: currentUndecrypted,
        transactions: {},
      };
      const repo = await newRepo(refs.currentRefs, transaction);
      const ar = Portfolio.newAggregateRoot(state);
      const currentGroupIds = ar.state().portfolio.groupIds;
      const arToCommit = buildUpdateGroupCommand(
        ar,
        Command.updateAsset,
        refs.selfRefs.userId,
        currentGroupIds,
        groupId,
        false
      );

      // group aggregate
      const groupRepo = await GroupsRepo.newRepo(refs.currentRefs, transaction);
      const aggregate = await GroupsRepo.newArAndUpdateGroupItem(
        refs.currentRefs,
        transaction,
        refs.selfRefs.userId,
        groupId,
        id,
        AssetType.TraditionalInvestments,
        currentUndecrypted.subtype,
        false
      );

      //commit
      if (arToCommit) {
        const events = arToCommit.applyAllChanges();
        repo.manualCommit(arToCommit, events);
      }
      groupRepo.commitWithState(aggregate);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Portfolio.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();
      const encryptedUpdate: UpdateObject<Portfolio.Encrypted> = {};
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Shareholder: {
            if (!currentUndecrypted.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder =
              currentUndecrypted.ownership.shareholder.filter(
                (s) => s.contactId !== contactId
              );
            if (
              updateShareholder.length ===
              currentUndecrypted.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            encryptedUpdate.ownership = {
              myOwnership: currentUndecrypted.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!currentUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = currentUndecrypted.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateBeneficiary.length === currentUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            encryptedUpdate.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const state: PortfolioState = {
        portfolio: currentUndecrypted,
        transactions: {},
      };
      const repo = await newRepo(refs, transaction);
      const ar = Portfolio.newAggregateRoot(state);
      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }

  export async function newRepo(
    refs: Refs,
    transaction: Transaction
  ): Promise<Repo<PortfolioState, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.TraditionalInvestments,
      new PortfolioStateWriter(
        refs.getAssetCollectionRef<Portfolio.Encrypted>(
          AssetType.TraditionalInvestments
        ),
        refs.getPortfolioTransactionRootCollectionRef(),
        refs.Relations,
        refs.userId
      )
    );
  }

  export async function newArAndUpdateGroup(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    assetId: string,
    groupId: string,
    isAdd: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Portfolio.Encrypted>(
      AssetType.TraditionalInvestments,
      assetId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = Portfolio.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) Portfolio.handleOutDated();
    const state: PortfolioState = {
      portfolio: currentData,
      transactions: {},
    };
    const ar = Portfolio.newAggregateRoot(state);
    const currentGroupIds = ar.state().portfolio.groupIds;
    return buildUpdateGroupCommand(
      ar,
      Command.updateAsset,
      executerId,
      currentGroupIds,
      groupId,
      isAdd
    );
  }
}

export class TraditionalInvestmentsAdminRepo {
  protected readonly executerId: string;
  protected readonly refs: Refs;
  protected readonly exRate: ExchangeRate;
  protected readonly summary: SummaryLoader<
    TraditionalInvestmentSummary,
    TraditionalInvestmentSummaryAggregate
  >;

  constructor(
    executerId: string,
    refs: Refs,
    exRate: ExchangeRate,
    summary: TraditionalInvestmentSummary
  ) {
    if (!CoreFirestore.isAdmin()) {
      throw new Error("This repo is for admin only");
    }
    this.executerId = executerId;
    this.exRate = exRate;
    this.refs = refs;
    this.summary = new SummaryLoader(
      refs,
      AssetType.TraditionalInvestments,
      true,
      CoreFirestore.doc(
        getSeqDocPath(refs.userId, AssetType.TraditionalInvestments)
      ) as DocumentReference<Sequence>,
      {
        ref: refs.TraditionalInvestmentSummary,
        aggregateConstructor: TraditionalInvestmentSummaryAggregate,
        newAggregateRoot: (transaction: Transaction) => {
          return TraditionalInvestmentSummary.newAggregateRoot(
            transaction,
            refs.TraditionalInvestmentSummary
          );
        },
      }
    );
  }

  async syncSummary() {
    return (await this.summary.syncAndGetData()).summary;
  }
}
