import { IAggregateStateWriter, stateIsDeleted } from "../aggregate";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
} from "../../../coreFirebase";

import { Property } from "./property";
import { Command, Event } from "./command";
import { PropertyAggregate } from "./propertyAggregate";
import { Encrypted } from "../../database/encryption";
import { RelationsOfAsset, buildPropertyRelation } from "../relations";

export class PropertyStateWriter
  implements IAggregateStateWriter<Encrypted<Property>, Command, Event>
{
  transaction!: Transaction;
  assetCollectionRef: CollectionReference<Encrypted<Property>>;
  relationCollectionRef: CollectionReference<RelationsOfAsset>;

  constructor(
    assetRef: CollectionReference<Encrypted<Property>>,
    relationRef: CollectionReference<RelationsOfAsset>
  ) {
    this.assetCollectionRef = assetRef;
    this.relationCollectionRef = relationRef;
  }

  setStateTx(transaction: Transaction, aggregate: PropertyAggregate): void {
    const assetDocRef = CoreFirestore.docFromCollection(
      this.assetCollectionRef,
      aggregate.state.id
    );
    const relationDocRef = CoreFirestore.docFromCollection(
      this.relationCollectionRef,
      aggregate.state.id
    );
    if (stateIsDeleted(aggregate.state)) {
      this.deleteStateTx(transaction, assetDocRef, relationDocRef);
    } else {
      const { items, ...rest } = aggregate.state;
      transaction.set(assetDocRef, rest);
      transaction.set(relationDocRef, buildPropertyRelation(aggregate.state));
    }
    aggregate.relatedUpdates = {};
  }

  deleteStateTx(
    transaction: Transaction,
    assetDocRef: DocumentReference<Encrypted<Property>>,
    relationDocRef: DocumentReference<RelationsOfAsset>
  ): void {
    transaction.delete(assetDocRef);
    transaction.delete(relationDocRef);
  }
}
