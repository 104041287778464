import { useCallback, useEffect, useRef, useState, type CSSProperties } from 'react'
import OneSchemaImporter, { type OneSchemaImporterProps } from '@oneschema/react'
import { parse } from 'date-fns'
import { CheckCircleIcon, XCircleIcon } from 'lucide-react'
import { type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { create } from 'zustand'

import type { Art, ArtStyle, ArtType } from 'core/remodel/types/arts'
import { BelongingsUtils } from 'core/remodel/types/belongings'
import { LocationType, type Currency, type MeasurementUnits } from 'core/remodel/types/common'
import { type AcquisitionType } from 'core/remodel/types/common/acquisition'
import { AssetType, CustomizedType } from 'core/remodel/types/enums'
import { BottleSize, WinePricingMethod, WinePurchase, WineStatus } from 'core/remodel/types/wineAndSprits'
import { commonQuery, fetchOneSchemaToken } from '@/api/CommonService'
import {
  templateMap,
  type ArtRecord,
  type BelongingRecord,
  type OtherCollectableRecord,
  type Result,
  type Template,
  type TemplateRecord,
  type WineRecord
} from '@/types/oneSchema'
import { cn } from '@/utils/classnames'
import { delay } from '@/utils/delay'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { Button, Modal, Progress, Separator, Tooltip, TooltipContent, TooltipTrigger } from '@/components/base'
import Confirm from '@/components/Confirm'
import { ImportIcon, PlusIcon, UploadIcon } from '@/components/icon'

const clientId = process.env.NEXT_PUBLIC_ONE_SCHEMA_CLIENT_ID
const devMode = process.env.NODE_ENV !== 'production'

type ImporterState = {
  token: string | null
  isLoading: boolean
  template: Template | null
  setToken: (token: string | null) => void
  setIsLoading: (isLoading: boolean) => void
  setTemplate: (template: Template | null) => void
}

export const useImporterStore = create<ImporterState>((set) => ({
  token: null,
  isLoading: false,
  template: null,
  setToken: (token: string | null) => set({ token }),
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  setTemplate: (template: Template | null) => set({ template })
}))

const importerStyles: CSSProperties = {
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 9999
}
const importConfig = { type: 'local' } as const

interface DataImporterProps {
  template: Template
  className?: string
  disabled?: boolean
}

export function DataImporterTrigger({ className, template, disabled }: DataImporterProps) {
  const { toast } = useToast()
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { token, isLoading, setToken, setIsLoading, setTemplate } = useImporterStore((state) => state)
  const triggerDisabled = disabled || token !== null || isLoading

  const handleOpen = async () => {
    try {
      setIsLoading(true)
      setTemplate(template)
      const oneSchemaToken = await fetchOneSchemaToken(database!)([commonQuery.oneSchemaToken])
      setToken(oneSchemaToken)
    } catch (e) {
      e instanceof Error && toast({ variant: 'error', description: e.message })
    } finally {
      await delay()
      setIsLoading(false)
    }
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild={true}>
        <Button
          className={cn('h-9 w-9 text-white', className)}
          variant={'solid'}
          onClick={handleOpen}
          disabled={triggerDisabled}
        >
          <ImportIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t('Import')}</TooltipContent>
    </Tooltip>
  )
}

export function DataImporterModal() {
  const { toast } = useToast()
  const { i18n } = useTranslation()
  const [records, setRecords] = useState<TemplateRecord[] | null>(null)
  const { token, template, setToken, setTemplate, setIsLoading } = useImporterStore((state) => state)

  const hasRecords = records && records.length > 0

  const handleImporterClose = () => setToken(null)

  const handleMapperClose = () => {
    setIsLoading(false)
    setTemplate(null)
    setRecords(null)
  }

  const handleSuccess = (result: Result) => {
    setIsLoading(true)
    setRecords(result.records)
  }

  const handleError: OneSchemaImporterProps['onError'] = (error) => {
    toast({ variant: 'error', description: error.message })
  }

  return template ? (
    <>
      {token && clientId && (
        <OneSchemaImporter
          isOpen={true}
          userJwt={token}
          clientId={clientId}
          templateKey={templateMap[template]}
          importConfig={importConfig}
          style={importerStyles}
          devMode={devMode}
          onSuccess={handleSuccess}
          onError={handleError}
          onRequestClose={handleImporterClose}
          languageCode={i18n.language}
        />
      )}
      {hasRecords && <DataMapper template={template} records={records} onClose={handleMapperClose} />}
    </>
  ) : null
}

// should use `Mapping` to wait user select wine
type Status = 'Creating' | 'Mapping' | 'Uploading' | 'Completed' | 'Failed'

interface DataMapperProps {
  template: Template
  records: TemplateRecord[]
  onClose: () => void
}

function DataMapper({ template, records, onClose }: DataMapperProps) {
  const { toast } = useToast()
  const cancelRef = useRef(false)
  const uploadedRef = useRef(false)
  const [done, setDone] = useState<number>(0)
  const [status, setStatus] = useState<Status>('Uploading')
  const [isMinimized, setIsMinimized] = useState<boolean>(false)
  const [catalogueIds, setCatalogueIds] = useState<string[]>([])
  const database = useAuthStore((state) => state.database)
  const progress = (done * 100) / records.length
  // const groupId = useRef(database!.genAssetId())
  // const wineNames = useMemo(() => {
  //   if (template !== 'WineAndSpirits') return []
  //   return records.map((r) => (r as WineRecord).wine_name)
  // }, [records, template])

  const uploadData = useCallback(
    async (records: TemplateRecord[], index = 0) => {
      const sliced = records.slice(index)
      const slicedIds = catalogueIds.slice(index)
      switch (template) {
        case 'Arts': {
          const results = sliced.map((r) => toArt(r as ArtRecord))
          for (const result of results) {
            if (cancelRef.current) return
            const art: Art.CreateFields = { id: database!.genAssetId(), groupIds: [], ...result }
            await Promise.all([
              database!.art.add(art),
              database!.addCustomizedType(CustomizedType.ArtArtistName, result.artist)
            ])
            setDone((num) => num + 1)
          }
          break
        }
        case 'OtherCollectables': {
          const results = sliced.map((r) => toOtherCollectable(r as OtherCollectableRecord))

          for (const result of results) {
            if (cancelRef.current) return
            const other: BelongingsUtils.CreateFields = {
              id: database!.genAssetId(),
              groupIds: [],
              ...result
            }
            await Promise.all([
              database!.otherCollectable.add(other),
              database!.addCustomizedType(CustomizedType.OtherCollectablesModel, result.name),
              database!.addCustomizedType(CustomizedType.OtherCollectablesBrand, result.brand),
              database!.addCustomizedType(CustomizedType.OtherCollectablesType, result.subtype)
            ])
            setDone((num) => num + 1)
          }
          break
        }
        case 'MyBelongings': {
          const results = sliced.map((r) => toBelonging(r as BelongingRecord))
          for (const result of results) {
            if (cancelRef.current) return
            const belonging: BelongingsUtils.CreateFields = {
              id: database!.genAssetId(),
              groupIds: [],
              ...result
            }
            await Promise.all([
              database!.belonging.add(belonging),
              database!.addCustomizedType(CustomizedType.BelongingType, result.subtype),
              database!.addCustomizedType(CustomizedType.BelongingBrand, result.brand)
            ])
            setDone((num) => num + 1)
          }
          break
        }
        case 'WineAndSpirits':
          const results = sliced.map((r) => toWine(r as WineRecord))
          for (let i = 0; i < results.length; i++) {
            if (cancelRef.current) return
            const wine: WinePurchase.CreateFields = {
              ...results[i],
              id: database!.genAssetId(),
              bottles: results[i].bottles.map((bottle) => ({ ...bottle, bottleId: database!.genAssetId() }))
            }
            const wineInfo = await database?.getWinesCatalogues({ id: results[i].wineId })
            const purchaseId = database!.genAssetId()
            const personalRefNo = ''
            await database?.wine.add(wineInfo![0], wine.wineId, wine, purchaseId, personalRefNo, [])
            setDone((num) => num + 1)
          }
          break
      }
    },
    [catalogueIds, database, template]
  )

  const handleUpload = async (index = 0) => {
    try {
      setStatus('Uploading')
      await uploadData(records, index)
      toast({ variant: 'success', description: 'Upload successful!' })
      setStatus('Completed')
    } catch (e) {
      e instanceof Error && toast({ variant: 'error', description: e.message })
      setStatus('Failed')
    }
  }

  // const handleCreate = async (data: CreateValues) => {
  //   const group: Group.CreateFields = { id: groupId.current, name: data.name, items: [] }
  //   await database!.group.add(group)
  //   if (template === 'WineAndSpirits') {
  //     setStatus('Mapping')
  //   } else {
  //     await handleUpload()
  //   }
  // }

  // TODO store catalogue ids and upload data
  // const handleMap = async (data: MapValues) => {
  //   setCatalogueIds(data.catalogueIds)
  //   await handleUpload()
  // }

  const handleReload = async () => {
    await handleUpload(done)
  }

  const handleClose = useCallback(() => {
    cancelRef.current = true
    setDone(0)
    setStatus('Uploading')
    setIsMinimized(false)
    onClose()
  }, [onClose])

  useEffect(() => {
    const upload = async () => {
      if (uploadedRef.current) return
      uploadedRef.current = true

      await uploadData(records)
      toast({ variant: 'success', description: 'Upload successful!' })
      setStatus('Completed')
    }

    if (status === 'Uploading') {
      upload()
    } else {
      handleClose()
    }
  }, [records, status, uploadData, toast, handleClose])

  return (
    <>
      {/* {status === 'Creating' && <ImportCreating onSubmit={handleCreate} onClose={handleClose} />} */}
      {/* {status === 'Mapping' && <ImportMapping wineNames={wineNames} onSubmit={handleMap} onClose={handleClose} />} */}
      {status === 'Uploading' && (
        <ImportUploading
          progress={progress}
          onClose={handleClose}
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
        />
      )}
      {/* {status === 'Completed' && <ImportCompleted onClose={handleClose} isMinimized={isMinimized} />} */}
      {status === 'Failed' && <ImportFailed onReload={handleReload} onClose={handleClose} />}
    </>
  )
}

// type CreateValues = { name: string }

// interface ImportCreatingProps {
//   onSubmit: SubmitHandler<CreateValues>
//   onClose: () => void
// }

// function ImportCreating({ onSubmit, onClose }: ImportCreatingProps) {
//   const {
//     control,
//     handleSubmit,
//     formState: { isSubmitting }
//   } = useForm<CreateValues>({
//     defaultValues: { name: `${new Date().toISOString()}_ImportGroup` }
//   })
//   const { t } = useTranslation()
//   return (
//     <Modal className={'max-w-[640px] rounded'}>
//       <Modal.Header className={'border-b'}>
//         <div className={'flex items-center gap-x-2 text-text'}>
//           <GroupIcon />
//           <label className={'text-sm font-medium'}>{t('CreateGroup')}</label>
//         </div>
//         <Modal.CloseButton className={'text-text'} onClose={onClose} />
//       </Modal.Header>
//       <Modal.Content className={'p-0'}>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <div className={'flex flex-col p-6'}>
//             <div className={'mb-6'}>
//               <p className={'mb-4 text-sm text-text'}>{t('CreateGroupName')}</p>
//               <FormInput
//                 className={'w-1/2'}
//                 control={control}
//                 name={'name'}
//                 label={t('Field.GroupName')}
//                 rules={{ required: true }}
//               />
//             </div>
//             <Separator />
//             <div className={'flex h-[352px] flex-col items-center justify-center gap-y-6'}>
//               <Image src={'/images/table.svg'} alt={'Table'} width={200} height={100} />
//               <p className={'text-lg font-bold text-text'}>{t('ReadyToBeImported')}</p>
//             </div>
//           </div>
//           <Separator />
//           <fieldset className={'flex items-center justify-end gap-x-2 p-4'} disabled={isSubmitting}>
//             <Button className={'w-[140px]'} variant={'outline'} size={'md'} onClick={onClose}>
//               {t('Cancel')}
//             </Button>
//             <Button className={'w-[140px]'} variant={'solid'} size={'md'} type={'submit'}>
//               {t('Create')}
//             </Button>
//           </fieldset>
//         </form>
//       </Modal.Content>
//     </Modal>
//   )
// }

type MapValues = {
  catalogueIds: string[]
}

interface ImportMappingProps {
  wineNames: string[]
  // should return catalogue ids
  onSubmit: SubmitHandler<MapValues>
  onClose: () => void
}

// TODO mapping wine
// function ImportMapping({ wineNames, onSubmit, onClose }: ImportMappingProps) {
//   const { t } = useTranslation()
//   const {
//     control,
//     formState: { isSubmitting },
//     handleSubmit
//   } = useForm<MapValues>({ defaultValues: { catalogueIds: [] } })
//   return (
//     <Modal className={'max-w-[640px] rounded'}>
//       <Modal.Header className={'border-b'}>
//         <div className={'flex items-center gap-x-2 text-text'}>
//           <UploadIcon />
//           <label className={'text-sm font-medium'}>{t('DataImport')}</label>
//         </div>
//         <Modal.CloseButton className={'text-text'} onClose={onClose} />
//       </Modal.Header>
//       <Modal.Content>
//         <form className={'flex h-[572px] flex-col'} onSubmit={handleSubmit(onSubmit)}>
//           <div className={'flex min-h-0 flex-1 flex-col space-y-4'}>
//             <div className={'grid grid-cols-2 gap-4'}>
//               <p className={'text-sm'}>{'Wine Name'}</p>
//               <p className={'text-sm'}>{'Wine'}</p>
//             </div>
//             <Separator />
//             <ul className={'space-y-2 overflow-scroll'}>
//               {wineNames.map((name, index) => (
//                 <li key={index} className={'grid grid-cols-2 gap-4'}>
//                   <div className={'rounded border'}>
//                     <p className={'flex h-9 items-center rounded bg-grey-input px-3 text-sm text-text'}>{name}</p>
//                   </div>
//                   <Autocomplete placeholder={'Select wine'} />
//                 </li>
//               ))}
//             </ul>
//           </div>
//           <fieldset className={'flex items-center justify-end gap-x-2 p-4'} disabled={isSubmitting}>
//             <Button className={'w-[140px]'} variant={'outline'} size={'md'} onClick={onClose}>
//               {t('Cancel')}
//             </Button>
//             <Button className={'w-[140px]'} variant={'solid'} size={'md'} type={'submit'}>
//               {t('Upload')}
//             </Button>
//           </fieldset>
//         </form>
//       </Modal.Content>
//     </Modal>
//   )
// }

interface ImportUploadingProps {
  progress: number
  isMinimized: boolean
  onClose: () => void
  setIsMinimized: (isMinimized: boolean) => void
}

function ImportUploading({ progress, isMinimized, onClose, setIsMinimized }: ImportUploadingProps) {
  const { t } = useTranslation()
  const [isEscape, setIsEscape] = useState(false)

  return isMinimized ? (
    <div className={cn('fixed bottom-6 right-6 z-50 grid w-[420px] overflow-y-auto rounded-md bg-white shadow-xl')}>
      <Button
        className={cn('absolute right-3 top-3 rounded-full p-px text-primary hover:bg-grey/25')}
        onClick={() => setIsMinimized(false)}
      >
        <PlusIcon size={20} />
      </Button>
      <div className={'flex flex-col items-center justify-center gap-4 p-4'}>
        <p>{t('ImportingData')}</p>
        <Progress className={'h-3'} value={progress} />
      </div>
    </div>
  ) : (
    <>
      <Modal className={'max-w-[640px] rounded'}>
        <Modal.Header className={'border-b'}>
          <div className={'flex items-center gap-x-2 text-text'}>
            <UploadIcon />
            <label className={'text-sm font-medium'}>{t('DataImport')}</label>
          </div>
          <Button onClick={() => setIsMinimized(true)}>
            <div className={'h-6 w-6 rounded-full text-2xl/6 text-text hover:bg-grey/25'}>{'—'}</div>
          </Button>
        </Modal.Header>
        <Modal.Content>
          <div className={'flex h-[572px] flex-col items-center justify-center gap-y-2'}>
            <div className={'flex flex-col gap-y-6'}>
              <div className={'space-y-2 text-center text-sm font-medium'}>
                <p>{t('ValidateData')}</p>
                <p>{t('DoNotCloseWindow')}</p>
              </div>
              <div className={'relative flex items-center gap-x-2'}>
                <Progress className={'h-3'} value={progress} />
                <span className={'absolute -right-8 text-xs text-primary'}>{`${progress.toFixed()}%`}</span>
              </div>
            </div>
          </div>
          <div className={'flex items-center justify-end gap-x-2 p-4'}>
            <Button className={'w-[140px]'} variant={'solid'} size={'md'} onClick={() => setIsEscape(true)}>
              {t('Cancel')}
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      {isEscape && (
        <Confirm
          title={t('CancelImport')}
          content={t('ConfirmCancelImport')}
          onCancel={() => setIsEscape(false)}
          onConfirm={onClose}
          cancelLabel={t('Dismiss')}
          confirmLabel={t('Confirm')}
        />
      )}
    </>
  )
}

interface ImportCompletedProps {
  isMinimized: boolean
  onClose: () => void
}

function ImportCompleted({ isMinimized, onClose }: ImportCompletedProps) {
  const { t } = useTranslation()

  useEffect(() => {
    if (isMinimized) {
      onClose()
    }
  }, [isMinimized, onClose])

  return isMinimized ? null : (
    <Modal className={'max-w-[640px] rounded'}>
      <Modal.Header className={'border-b'}>
        <div className={'flex items-center gap-x-2 text-text'}>
          <UploadIcon />
          <label className={'text-sm font-medium'}>{t('DataImport')}</label>
        </div>
        <Modal.CloseButton className={'text-text'} onClose={onClose} />
      </Modal.Header>
      <Modal.Content className={'gap-0 p-0'}>
        <div className={'flex h-[500px] flex-col items-center justify-center gap-y-6 p-6'}>
          <CheckCircleIcon className={'text-[#538518]'} size={80} />
          <div className={'space-y-2 text-center text-text'}>
            <p className={'text-lg font-bold'}>{t('ImportSuccessful')}</p>
            <p className={'text-sm'}>{t('ImportSuccessfulContent')}</p>
          </div>
        </div>
        <Separator />
        <div className={'flex items-center justify-end gap-x-2 p-4'}>
          <Button className={'w-[140px]'} variant={'solid'} size={'md'} onClick={onClose}>
            {t('Confirm')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

interface ImportFailedProps {
  onReload: () => Promise<void>
  onClose: () => void
}

function ImportFailed({ onReload, onClose }: ImportFailedProps) {
  const { t } = useTranslation()
  return (
    <Modal className={'max-w-[640px] rounded'}>
      <Modal.Header className={'border-b'}>
        <div className={'flex items-center gap-x-2 text-text'}>
          <UploadIcon />
          <label className={'text-sm font-medium'}>{t('DataImport')}</label>
        </div>
        <Modal.CloseButton className={'text-text'} onClose={onClose} />
      </Modal.Header>
      <Modal.Content className={'gap-0 p-0'}>
        <div className={'flex h-[500px] flex-col items-center justify-center gap-y-6 p-6'}>
          <XCircleIcon className={'text-[#C3626E]'} size={80} />
          <div className={'space-y-2 text-center text-text'}>
            <p className={'text-lg font-bold'}>{t('ImportFailed')}</p>
            <p className={'text-sm'}>{t('ImportFailedContent')}</p>
          </div>
        </div>
        <Separator />
        <div className={'flex items-center justify-end gap-x-2 p-4'}>
          <Button className={'w-[140px]'} variant={'outline'} size={'md'} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button className={'w-[140px]'} variant={'solid'} size={'md'} onClick={onReload}>
            {t('Reload')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

function toArt(record: ArtRecord) {
  const art: Omit<Art.CreateFields, 'id' | 'groupIds'> = {
    name: record.art_title_,
    assetType: AssetType.Art,
    artist: record.artist_name_,
    subtype: record.art_type_ as ArtType,
    artStyle: record.art_style as ArtStyle,
    location: {
      locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
      locationId: record.location_of_asset,
      roomId: record.room,
      position: record.position,
      notes: record.location_notes
    },
    personalRefNo: record.personal_reference_number,
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    // HACK
    purchasePrice: {
      currency: record.currency_ as Currency,
      value: Number(record.purchase_price_)
    },
    price: {
      currency: record.currency_ as Currency,
      value: Number(record.item_price)
    },
    number: Number(record.number_of_items),
    value: {
      currency: record.valuation_currency as Currency,
      value: Number(record.current_valuation_)
    },
    totalCost: {
      currency: record.currency_ as Currency,
      value:
        record.acquisition_type === 'Direct'
          ? Number(record.purchase_price_) +
            Number(record.other_costs ?? 0) -
            Number(record.discountprice_adjustment ?? 0)
          : Number(record.hammer_price ?? 0) +
            Number(record.tax_payable ?? 0) +
            Number(record.buyer_premium ?? 0) +
            Number(record.other_costs ?? 0)
    },
    acquisition: {
      acquisitionType: record.acquisition_type as AcquisitionType,
      sellerId: record.seller,
      invoiceNumber: record.invoice_number,
      priceAsValue: record.set_valuation_as_total_price === 'YES' ? true : false,
      discountAdjustment: {
        currency: record.currency_ as Currency,
        value: record.discountprice_adjustment ? Number(record.discountprice_adjustment) : 0
      },
      otherCost: { currency: record.currency_ as Currency, value: record.other_costs ? Number(record.other_costs) : 0 },
      auctionDetail: {
        hammerPrice: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.hammer_price ? Number(record.hammer_price) : 0
        },
        taxPayable: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.tax_payable ? Number(record.tax_payable) : 0
        },
        buyerPremium: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.buyer_premium ? Number(record.buyer_premium) : 0
        },
        lotNumber: record.lot_number,
        paddleNumber: record.paddle_number,
        auctionNumber: record.auction_namenumber
      }
    },
    measurementUnits: record.measurement as MeasurementUnits,
    height: record.height ? Number(record.height) : 0,
    width: record.width ? Number(record.width) : 0,
    depth: record.depth ? Number(record.depth) : 0,
    weight: record.weight ? Number(record.weight) : 0,
    dimensionsSummary: record.dimensions_summary,
    geography: record.geography,
    medium: record.medium,
    provenance: record.provenance,
    inscription: record.inscription,
    catalogueText: record.catalogue_text,
    ownership: {
      myOwnership: Number(record.my_ownership_.split('%')[0]),
      shareholder: []
    },
    beneficiary: []
  }
  return art
}

function toOtherCollectable(record: OtherCollectableRecord) {
  const other: Omit<BelongingsUtils.CreateFields, 'id' | 'groupIds'> = {
    assetType: AssetType.OtherCollectables,
    name: record.belonging_name_,
    subtype: record.belonging_type_,
    brand: record.brand_manufacturer_,
    location: {
      locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
      locationId: record.location_of_asset,
      roomId: record.room,
      position: record.position,
      notes: record.location_notes
    },
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    //HACK
    purchasePrice: {
      currency: record.currency_ as Currency,
      value: Number(record.purchase_price_)
    },
    number: Number(record.number_of_items_),
    price: {
      currency: record.currency_ as Currency,
      value: Number(record.item_price_)
    },
    value: {
      currency: record.valuation_currency as Currency,
      value: Number(record.current_valuation_)
    },
    totalCost: {
      currency: record.currency_ as Currency,
      value:
        record.acquisition_type === 'Direct'
          ? Number(record.purchase_price_) +
            Number(record.other_costs ?? 0) -
            Number(record.discountprice_adjustment ?? 0)
          : Number(record.hammer_price ?? 0) +
            Number(record.tax_payable ?? 0) +
            Number(record.buyer_premium ?? 0) +
            Number(record.other_costs ?? 0)
    },
    acquisition: {
      acquisitionType: record.acquisition_type as AcquisitionType,
      sellerId: record.seller,
      invoiceNumber: record.invoice_number,
      priceAsValue: record.set_total_price_as_asset_value === 'YES' ? true : false,
      discountAdjustment: {
        currency: record.currency_ as Currency,
        value: record.discountprice_adjustment ? Number(record.discountprice_adjustment) : 0
      },
      otherCost: { currency: record.currency_ as Currency, value: record.other_costs ? Number(record.other_costs) : 0 },
      auctionDetail: {
        hammerPrice: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.hammer_price ? Number(record.hammer_price) : 0
        },
        taxPayable: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.tax_payable ? Number(record.tax_payable) : 0
        },
        buyerPremium: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.buyer_premium ? Number(record.buyer_premium) : 0
        },
        lotNumber: record.lot_number,
        paddleNumber: record.paddle_number,
        auctionNumber: record.auction_namenumber
      }
    },
    measurementUnits: record.measurement_units as MeasurementUnits,
    height: record.height ? Number(record.height) : 0,
    width: record.width ? Number(record.width) : 0,
    depth: record.depth ? Number(record.depth) : 0,
    weight: record.weight ? Number(record.weight) : 0,
    dimensionsSummary: record.dimensions_summary,
    geography: record.geography,
    medium: record.medium,
    provenance: record.provenance,
    inscription: record.inscription,
    catalogueText: record.catalogue_text,
    notes: record.primary_notes,
    attributeNotes: record.attribute_notes,
    ownership: {
      myOwnership: Number(record.my_ownership_.split('%')[0]),
      shareholder: []
    },
    beneficiary: []
  }
  return other
}

function toBelonging(record: BelongingRecord) {
  const belonging: Omit<BelongingsUtils.CreateFields, 'id' | 'groupIds'> = {
    assetType: AssetType.Belonging,
    name: record.belonging_name_,
    subtype: record.belonging_type_,
    brand: record.brand_manufacturer_,
    location: {
      locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
      locationId: record.location_of_asset,
      roomId: record.room,
      position: record.position,
      notes: record.location_notes
    },
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    // HACK
    purchasePrice: {
      currency: record.currency_ as Currency,
      value: Number(record.purchase_price_)
    },
    number: Number(record.number_of_items_),
    price: {
      currency: record.currency_ as Currency,
      value: Number(record.item_price_)
    },
    value: {
      currency: record.valuation_currency as Currency,
      value: Number(record.current_valuation_)
    },
    totalCost: {
      currency: record.currency_ as Currency,
      value:
        record.acquisition_type === 'Direct'
          ? Number(record.purchase_price_) +
            Number(record.other_costs ?? 0) -
            Number(record.discountprice_adjustment ?? 0)
          : Number(record.hammer_price ?? 0) +
            Number(record.tax_payable ?? 0) +
            Number(record.buyer_premium ?? 0) +
            Number(record.other_costs ?? 0)
    },
    acquisition: {
      acquisitionType: record.acquisition_type as AcquisitionType,
      sellerId: record.seller,
      invoiceNumber: record.invoice_number,
      priceAsValue: record.set_total_price_as_asset_value === 'YES' ? true : false,
      discountAdjustment: {
        currency: record.currency_ as Currency,
        value: record.discountprice_adjustment ? Number(record.discountprice_adjustment) : 0
      },
      otherCost: { currency: record.currency_ as Currency, value: record.other_costs ? Number(record.other_costs) : 0 },

      auctionDetail: {
        hammerPrice: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.hammer_price ? Number(record.hammer_price) : 0
        },
        taxPayable: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.tax_payable ? Number(record.tax_payable) : 0
        },
        buyerPremium: {
          currency: record.currency_ as Currency,
          value: record.acquisition_type === 'Auction' && record.buyer_premium ? Number(record.buyer_premium) : 0
        },
        lotNumber: record.lot_number,
        paddleNumber: record.paddle_number,
        auctionNumber: record.auction_namenumber
      }
    },
    measurementUnits: record.measurement_units as MeasurementUnits,
    height: record.height ? Number(record.height) : 0,
    width: record.width ? Number(record.width) : 0,
    depth: record.depth ? Number(record.depth) : 0,
    weight: record.weight ? Number(record.weight) : 0,
    dimensionsSummary: record.dimensions_summary,
    geography: record.geography,
    medium: record.medium,
    provenance: record.provenance,
    inscription: record.inscription,
    catalogueText: record.catalogue_text,
    notes: record.primary_notes,
    attributeNotes: record.attribute_notes,
    ownership: {
      myOwnership: Number(record.my_ownership_.split('%')[0]),
      shareholder: []
    },
    beneficiary: []
  }
  return belonging
}

function toWine(record: WineRecord) {
  const wine: Omit<WinePurchase.CreateFields, 'groupIds'> = {
    id: '', // generated when addPurchase
    wineId: record.wineId,
    purchaseDate: parse(record.purchase_date, 'dd/MM/yyyy', new Date()),
    deliveryDate: parse(record.delivery_date, 'dd/MM/yyyy', new Date()),
    bottleSize: record.bottle_size as BottleSize,
    pricingMethod: record.pricing_method === 'Bottle Price' ? WinePricingMethod.Bottle : WinePricingMethod.Lot,
    price: { currency: record.currency as Currency, value: Number(record.bottle_price) },
    totalCost: {
      currency: record.currency as Currency,
      value:
        record.pricing_method === 'Lot/Bulk Price'
          ? Number(record.bottle_price) + Number(record.other_costs)
          : Number(record.bottle_price) * Number(record.number_of_bottles) + Number(record.other_costs)
    },
    valuePerBottle: { currency: record.currency as Currency, value: Number(record.valuation_per_bottle) },
    acquisition: {
      acquisitionType: record.acquisition_type as AcquisitionType,
      auctionDetail: {
        hammerPrice: { currency: record.currency as Currency, value: Number(record.hammer_price) },
        taxPayable: { currency: record.currency as Currency, value: Number(record.tax_payable) },
        buyerPremium: { currency: record.currency as Currency, value: Number(record.buyer_premium) }
      },
      otherCost: { currency: record.currency as Currency, value: Number(record.other_costs) },
      priceAsValue: record.set_total_price_as_asset_value === 'Yes' ? true : false,
      sellerId: record.sellerretailer
    },
    ownership: { myOwnership: Number(record.my_ownership_?.split('%')[0]), shareholder: [] },
    beneficiary: [],
    bottles: [
      {
        bottleId: '', // generated when addPurchase
        location: {
          locationType: record.location_type === 'My Property' ? LocationType.MyProperty : LocationType.Address,
          locationId: record.location_of_asset,
          roomId: record.bin,
          position: record.shelf,
          notes: record.location_notes
        },
        status: record.status === 'Pending' ? WineStatus.Pending : WineStatus.Delivered
      }
    ]
  }
  return wine
}
