import {
  Currency,
  DateFormat,
  Language,
  NumberFormat,
  Optional,
  TimeZone,
} from "./common";

// This is a Firebase Auth Type, not stored in firestore anywhere
export interface Profile {
  name: string;
  email: string;
  photo?: string;
}

export interface Preferences {
  baseCurrency: Optional<Currency>;
  baseLanguage: Language;
  timeZone: TimeZone;
  dateFormat: DateFormat;
  numberFormat: NumberFormat;
  notification: {
    updateEmail: boolean;
    tip: boolean;
    delegatesLogIn: boolean;
  };
  ivSalt: string;
  remindMFA?: Date;
}

export interface Notification {
  id: string; // Unique identifier for the notification
  title: string; // Title of the notification
  message: string; // Message content of the notification
  link?: string; // Optional link associated with the notification
  action?: string; // Optional action associated with the notification
  read: boolean; // Indicates whether the notification has been read
  timestamp: Date; // Timestamp of when the notification was created
}

/* Default instance */
export const defaultPreferences: Preferences = {
  baseCurrency: undefined,
  baseLanguage: Language.EN,
  timeZone: (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ??
    TimeZone.America_New_York) as TimeZone,
  dateFormat: DateFormat.MMDDYYYY,
  numberFormat: NumberFormat.Comma,
  notification: {
    updateEmail: false,
    tip: false,
    delegatesLogIn: false,
  },
  ivSalt: "",
};
