import { OtherCollectableType } from "./belongings";
import { Amount, AssetType, Currency } from "./common";

export type ReportAssetType =
  | AssetType.CashAndBanking
  | AssetType.TraditionalInvestments
  | AssetType.OtherInvestment
  | AssetType.Art
  | AssetType.WineAndSpirits
  | AssetType.OtherCollectables
  | AssetType.Property
  | AssetType.Belonging;

export interface ComparativeNetWorthReport {
  assetType: {
    [key in ReportAssetType]: NetWorthReportDetails;
  };
  // other collectables
  subCategory: {
    [key in OtherCollectableType | string]: NetWorthReportDetails;
  };
  total: NetWorthReportDetails;
}

export interface NetWorthReportDetails {
  original: {
    purchasePrice: Amount;
    liabilities: Amount;
    netInvestment: Amount;
  };
  current: {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
  };
  percentageChange: {
    assets: number;
    liabilities: number;
    net: number;
  };
}

export function defaultNetWorthReportDetails(
  currency: Currency
): NetWorthReportDetails {
  return {
    original: {
      purchasePrice: { currency, value: 0 },
      liabilities: { currency, value: 0 },
      netInvestment: { currency, value: 0 },
    },
    current: {
      assets: { currency, value: 0 },
      liabilities: { currency, value: 0 },
      netValue: { currency, value: 0 },
    },
    percentageChange: {
      assets: 0,
      liabilities: 0,
      net: 0,
    },
  };
}
