import { Amount, AssetType, TargetCurrencyExchangeRateDataMap } from "./common";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import { EventEnvelope } from "./event";
import Decimal from "decimal.js";
import { AllowedDecimalPlaces, calculatePercentage } from "../utils";
import { Event as PropertyEvent } from "./properties/command";
import * as Property from "./properties";
import { Configuration } from "./properties/configuration";
import { OwnerDetail } from "./properties/ownerDetail";
import { ContactLocation } from "./properties/contactLocation";
import { Encrypted, Encryption } from "../database/encryption";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  PropertySummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";
import {
  EncryptableObject,
  IsAmount,
  IsBoolean,
  IsNumber,
  IsOptional,
  NotEncrypted,
} from "../decorators";
import { OtherCollectableType } from "./belongings";

export type Event = EventEnvelope<PropertyEvent>;
export interface PropertySummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.PropertySummary, 2>;
  id: AssetType.Property;
  properties: {
    [id: string]: Encrypted<SummaryLocationItem>;
  };
}
//#TODO: Need further check if the decorators are used properly
export class SummaryLocationItem {
  @NotEncrypted
  @IsAmount()
  value!: Amount;
  @NotEncrypted
  @IsNumber()
  ownedPercentage!: number;
  @NotEncrypted
  @IsOptional()
  mainImage?: string;
  @EncryptableObject(ContactLocation)
  location!: ContactLocation;
  @NotEncrypted
  @IsOptional()
  configuration?: ConfigurationSummary;
  @NotEncrypted
  @IsBoolean()
  sold!: boolean;
  @NotEncrypted
  @IsBoolean()
  archived!: boolean;
  @NotEncrypted
  @IsAmount()
  purchasePrice!: Amount;
}

export interface ConfigurationSummary {
  bedroom: number;
  bathroom: number;
  otherRoom: number;
  carPark: number;
}
namespace ConfigurationSummary {
  export function fromEncryptedConfiguration(
    input: Encrypted<Configuration>
  ): ConfigurationSummary {
    return {
      bedroom: input.bedroom!.length,
      bathroom: input.bathroom!.length,
      otherRoom: input.otherRoom!.length,
      carPark: input.carPark!.length,
    };
  }
}

// TODO move to property type after property refactor branch is merged
export interface BreakdownItem {
  label: AssetType | OtherCollectableType | string;
  value: Amount;
  percentage: number;
}

// TODO move to property type after property refactor branch is merged
export interface PropertyAssetsBreakdown {
  assets: BreakdownItem[];
  liabilities: BreakdownItem[];
}

export namespace PropertySummary {
  export function assureVersion(
    input: PropertySummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      PropertySummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface DisplayDecrypted {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    locations: (SummaryLocationItem & { id: string; percentage: number })[];
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<PropertySummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new PropertySummaryAggregate(summary));
  }

  export function defaultValue(): PropertySummary {
    const defaultSummary: PropertySummary = {
      "@type": PropertySummaryTypeVersion,
      id: AssetType.Property,
      properties: {},
      version: 0,
    };
    return defaultSummary;
  }

  export async function toDisplayAndDecrypted(
    input: PropertySummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    encryption: Encryption
  ): Promise<DisplayDecrypted> {
    const currency = exchangeRate.targetCurrency;
    let totalAssets = new Decimal(0);
    const resultLocations: DisplayDecrypted["locations"] = [];
    for (const [id, item] of Object.entries(input.properties)) {
      // NOTE do not include sold and archived
      const decrypted = await encryption.decryptObject(
        item,
        SummaryLocationItem
      );
      const value =
        item.sold || item.archived
          ? new Decimal(0)
          : new Decimal(decrypted.value.value)
              .mul(decrypted.ownedPercentage)
              .div(100)
              .mul(exchangeRate.rates[decrypted.value.currency].rate)
              .toDecimalPlaces(AllowedDecimalPlaces);
      totalAssets = totalAssets.add(value);
      decrypted.value = {
        currency,
        value: value.toNumber(),
      };
      resultLocations.push({ id, ...decrypted, percentage: 0 });
    }

    const result: DisplayDecrypted = {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: 0,
      },
      netValue: {
        currency,
        value: totalAssets.toNumber(),
      },
      locations: resultLocations,
    };
    result.locations.forEach((location) => {
      location.percentage = calculatePercentage(
        location.value.value,
        totalAssets
      );
    });
    result.locations.sort((a, b) => b.value.value - a.value.value);
    return result;
  }

  export type RelatedUpdates = never;
}

// const RelatedObjKeys: (keyof Belonging.Encrypted & string)[] = [
//   "subtype",
//   "brand",
//   "value",
//   "location",
//   "purchaseDate",
//   // "ownership", //#NOTE update this manually
// ];

export class PropertySummaryAggregate extends AggregateBase<
  PropertySummary,
  never,
  PropertyEvent
> {
  state: PropertySummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: PropertySummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case PropertyEvent.Kind.AssetCreated: {
        const asset = event.data.asset;
        this.state.properties[event.aggregateId] = {
          value: asset.value as Amount,
          ownedPercentage:
            asset.ownershipType == Property.OwnershipType.Own
              ? (<OwnerDetail>asset.detail)?.ownership?.myOwnership || 100
              : 100,
          location: asset.location!,
          purchasePrice:
            asset.ownershipType == Property.OwnershipType.Own
              ? asset.price
              : Amount.defaultValue(),
          sold: false,
          archived: false,
        };
        if (asset.configuration)
          this.state.properties[event.aggregateId].configuration =
            ConfigurationSummary.fromEncryptedConfiguration(
              asset.configuration as Encrypted<Configuration>
            );
        if (asset.mainImage)
          this.state.properties[event.aggregateId].mainImage = asset.mainImage;
        const sellerId = (<OwnerDetail>asset.detail)?.acquisition?.sellerId;
        break;
      }
      case PropertyEvent.Kind.AssetUpdated: {
        const updates = event.data.asset;
        if (updates.value)
          this.state.properties[event.aggregateId].value =
            updates.value as Amount;
        if (updates.location)
          this.state.properties[event.aggregateId].location = updates.location;
        if (updates.configuration)
          this.state.properties[event.aggregateId].configuration =
            ConfigurationSummary.fromEncryptedConfiguration(
              updates.configuration as Encrypted<Configuration>
            );
        if (updates.mainImage)
          this.state.properties[event.aggregateId].mainImage =
            updates.mainImage;

        break;
      }
      case PropertyEvent.Kind.AssetDeleted:
        delete this.state.properties[event.aggregateId];
        break;
      case PropertyEvent.Kind.AssetArchived:
        this.state.properties[event.aggregateId].archived = true;
        break;
      case PropertyEvent.Kind.ArchivedAssetRestored:
        this.state.properties[event.aggregateId].archived = false;
        break;
      case PropertyEvent.Kind.ShareholderUpdated:
        if (event.data.current) {
          this.state.properties[event.aggregateId].ownedPercentage =
            event.data.current.myOwnership || 100;
        }
        break;
      case PropertyEvent.Kind.ValueUpdated:
        this.state.properties[event.aggregateId].value = event.data.current;
        break;
      case PropertyEvent.Kind.MainImageSet:
        if (event.data.current)
          this.state.properties[event.aggregateId].mainImage =
            event.data.current;
        else delete this.state.properties[event.aggregateId].mainImage;
        break;
      case PropertyEvent.Kind.SoldInfoAdded:
        this.state.properties[event.aggregateId].sold = true;
        break;
      case PropertyEvent.Kind.SoldInfoDeleted:
        this.state.properties[event.aggregateId].sold = false;
        break;
    }
    return this;
  }
}
