import type { ReactNode } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { cn } from '@/utils/classnames'
import { useIsAppMode } from '@/hooks/useIsAppMode'
import NavBar from '@/components/NavBar'
import SideBar from '@/components/SideBar'

interface LayoutProps {
  children: ReactNode
}

const checkList = ['setup', 'auth', 'plaid-proxy']

export default function Layout({ children }: LayoutProps) {
  const router = useRouter()
  const hasBackground = checkList.some((p) => router.asPath.includes(p))
  const isAppMode = useIsAppMode()
  return hasBackground ? (
    <main className={'relative min-h-screen bg-background'}>
      {/* My Assets Logo */}
      <Image
        src={'/images/myassets-logo.svg'}
        alt={'My Assets Logo'}
        className={'absolute left-10 top-10 w-auto'}
        width={0}
        height={0}
        priority={true}
      />

      {/* Circuit */}
      <Image
        src={'/images/auth/circuit.svg'}
        alt={'Ornament'}
        className={'absolute right-0 top-1/2 w-auto -translate-y-1/2'}
        width={0}
        height={0}
        priority={true}
      />

      {children}
    </main>
  ) : (
    <>
      <NavBar className={cn(isAppMode && 'hidden')} />
      <SideBar className={cn(isAppMode && 'hidden')} />
      <div className={cn(!isAppMode && 'ml-[60px] mt-[60px] pr-[calc(100%-100vw+12px)]')}>{children}</div>
    </>
  )
}
